import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class CurrentContractStoreService {
  public selectedContract$: Observable<any> = new Observable();

  constructor() {}

  public setSelectedContract(contract: any): void {
    this.selectedContract$ = new Observable((observer) => {
      observer.next(contract);
    });
  }
}
