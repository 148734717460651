<div class="m-6 xl:m-10">

  <app-back class="mb-5 cursor-pointer"></app-back>

  <div class="flex item-center w-full mb-4">
    <h2 class="flex-grow text-xl font-bold text-am-primary-navy-100">{{ "withdrawals.form.titulo" | translate }}</h2>

    <div class="flex gap-4">
      <p-button pRipple [hidden]="!canEdit" size="small" [label]="'withdrawals.form.guardarButton' | translate" (click)="save()" [disabled]="loading" [loading]="loading"></p-button>
    </div>
  </div>

  <div class="flex flex-col gap-4" [formGroup]="form">
    <h3 class="flex-grow text-xl text-am-primary-navy-100">{{ "withdrawals.form.datos_inspeccion.titulo" | translate }}</h3>

    <!-- FORM DE INSPECCION -->
    <div class="flex flex-col gap-2 justify-between w-[100%]" formGroupName="inspection">
      <div class="flex flex-row gap-2 justify-between w-[100%]">
        <div class="flex flex-col w-1/4">
          <label class="text-sm font-semibold mb-2">{{ "withdrawals.form.datos_inspeccion.referencia" | translate }}</label>
          <input pInputText formControlName="catastralReference">
        </div>
        <div class="flex flex-col w-1/4">
          <label class="text-sm font-semibold mb-2">{{ "withdrawals.form.datos_inspeccion.anno_construccion" | translate }}</label>
          <input pInputText formControlName="year">
        </div>

        <div class="flex flex-col w-1/2">
          <label class="text-sm font-semibold mb-2">{{ "withdrawals.form.datos_inspeccion.direccion" | translate }}</label>
          <input pInputText formControlName="address">
        </div>

        <div class="flex flex-col w-1/4">
          <label class="text-sm font-semibold mb-2">{{ "withdrawals.form.datos_inspeccion.fecha" | translate }}</label>
          <p-calendar id="date" [readonlyInput]="true" dateFormat="dd/mm/yy" formControlName="date"></p-calendar>
        </div>
      </div>
      <div *ngIf="enlaceCatastro != ''" class="flex flex-col gap-2 justify-between w-[100%] mt-4">
        <div class="flex flex-col w-1/4">
          <label class="text-sm font-semibold mb-2">{{ "withdrawals.form.datos_inspeccion.enlace" | translate }}</label>
          <a class="items-start p-button-text" [href]="enlaceCatastro" target="_blank">{{ "withdrawals.form.datos_inspeccion.enlacePlaceholder" | translate }}</a>
        </div>
        <div class="flex flex-col w-1/4">
          <img class="w-full" [src]="imagenCatastro" alt="">
        </div>
      </div>
    </div>

    <!-- FORM DE RETIRADA -->
    <h3 class="flex-grow text-xl text-am-primary-navy-100 mt-8">{{ "withdrawals.form.datos_tecnicos.titulo" | translate }}</h3>

    <div class="flex flex-col gap-2 justify-between w-[100%]">

      <div class="flex flex-row gap-6">
        <div class="flex flex-col w-1/4">
          <label class="text-sm font-semibold mb-2">{{ "withdrawals.form.datos_tecnicos.empresa" | translate }}</label>
          <p-dropdown
            formControlName="empresaRetirada"
            [options]="empresasRetirada"
            optionLabel="businessName"
            [placeholder]="'withdrawals.form.datos_tecnicos.empresaPlaceholder' | translate"
            (onChange)="selectEmpresa($event)" />
        </div>

        <div class=" flex flex-col w-1/4 p-input-underline">
          <label for="retirada" class="text-sm font-semibold mb-2">{{ "withdrawals.form.datos_tecnicos.retirado" | translate }}</label>
          @if (selectedLanguage == 'es') {
            <p-selectButton id="retirada" [options]="retiradaOptionsES" formControlName="completado" optionLabel="name" optionValue="id"></p-selectButton>
          } @else {
            <p-selectButton id="retirada" [options]="retiradaOptionsEN" formControlName="completado" optionLabel="name" optionValue="id"></p-selectButton>
          }
        </div>
      </div>

      <div class="flex flex-col w-[100%]">
        <label class="text-sm font-semibold mb-2">{{ "withdrawals.form.datos_tecnicos.notas" | translate }}</label>
        <textarea pInputTextarea formControlName="notas"></textarea>
      </div>
    </div>


    <!-- FORM DE COSTES -->
    <h3 class="flex-grow text-xl text-am-primary-navy-100 mt-8">{{ "withdrawals.form.datos_costes.titulo" | translate }}</h3>

    <div class="flex flex-wrap gap-2  xl:justify-between w-[100%]">
      <div class="flex flex-col w-1/4">
        <label class="text-sm font-semibold mb-2">{{ "withdrawals.form.datos_costes.desamiantado" | translate }}</label>
        <p-inputNumber formControlName="coste_desamiantado" mode="currency" currency="EUR" locale="es-ES" (onBlur)="calcularTotal()"></p-inputNumber>
      </div>
      <div class="flex flex-col w-1/4">
        <label class="text-sm font-semibold mb-2">{{ "withdrawals.form.datos_costes.transporte" | translate }}</label>
        <p-inputNumber  formControlName="coste_transporte" mode="currency" currency="EUR" locale="es-ES" (onBlur)="calcularTotal()"></p-inputNumber>
      </div>
      <div class="flex flex-col w-1/4">
        <label class="text-sm font-semibold mb-2">{{ "withdrawals.form.datos_costes.entrega" | translate }}</label>
        <p-inputNumber  formControlName="coste_entrega" mode="currency" currency="EUR" locale="es-ES" (onBlur)="calcularTotal()"></p-inputNumber>
      </div>
      <div class="flex flex-col w-1/4">
        <label class="text-sm font-semibold mb-2">{{ "withdrawals.form.datos_costes.subvencion" | translate }}</label>
        <p-inputNumber formControlName="subvencion" mode="decimal" [minFractionDigits]="2" [maxFractionDigits]="2" [suffix]="' %'" (onBlur)="calcularTotal()"></p-inputNumber>
      </div>
      <div class="flex flex-col w-1/4">
        <label class="text-sm font-semibold mb-2">{{ "withdrawals.form.datos_costes.total" | translate }}</label>
        <p-inputNumber formControlName="total" mode="currency" currency="EUR" locale="es-ES"></p-inputNumber>
      </div>
    </div>

    <!-- FORM DE DOCUMENTOS -->
  <h3 class="flex-grow text-xl text-am-primary-navy-100 mt-8">{{ "withdrawals.form.documentos.titulo" | translate }} </h3>
  <div class="flex gap-4">
    <p-button pRipple size="small" [label]="'withdrawals.form.documentos.descargarPlantillaButton' | translate" (click)="downloadDocument()" [disabled]="loading" [loading]="loading"></p-button>
  </div>

  <div class="flex flex-row flex-wrap gap-2 xl:gap-4">

    <div class="flex flex-col xl:w-1/5">
      <label class=" text-am-secondary-navy-60 mt-6 mb-3 block">{{ "withdrawals.form.documentos.memorias" | translate }}</label>
      <app-image-control-input flex="flex-col"  [control]="form.get('doc_memoria')" [label]="'withdrawals.form.documentos.memoriaPlaceholder' | translate"  (imageAdded)="documentAdded($event, 'doc_memoria')" (imageRemoved)="documentRemoved($event, 'doc_memoria')" ></app-image-control-input>
    </div>

    <div class="flex flex-col xl:w-1/5">
      <label class=" text-am-secondary-navy-60 mt-6 mb-3 block">{{ "withdrawals.form.documentos.actividades" | translate }}</label>
      <app-image-control-input flex="flex-col" [control]="form.get('doc_actividad')"  [label]="'withdrawals.form.documentos.actividadesPlaceholder' | translate" (imageAdded)="documentAdded($event, 'doc_actividad')" (imageRemoved)="documentRemoved($event, 'doc_actividad')"></app-image-control-input>
    </div>

    <div class="flex flex-col xl:w-1/5">
      <label class=" text-am-secondary-navy-60 mt-6 mb-3 block">{{ "withdrawals.form.documentos.formacion" | translate }}</label>
      <app-image-control-input flex="flex-col"  [control]="form.get('doc_formacion')"  [label]="'withdrawals.form.documentos.formacionPlaceholder' | translate" (imageAdded)="documentAdded($event, 'doc_formacion')" (imageRemoved)="documentRemoved($event, 'doc_formacion')"></app-image-control-input>
    </div>

    <div class="flex flex-col xl:w-1/5">
      <label class=" text-am-secondary-navy-60 mt-6 mb-3 block">{{ "withdrawals.form.documentos.vertederos" | translate }}</label>
      <app-image-control-input flex="flex-col" [control]="form.get('doc_vertederos')"  [label]="'withdrawals.form.documentos.vertederosPlaceholder' | translate" (imageAdded)="documentAdded($event, 'doc_vertederos')" (imageRemoved)="documentRemoved($event, 'doc_vertederos')"></app-image-control-input>
    </div>

    <div class="flex flex-col xl:w-1/5">
      <label class=" text-am-secondary-navy-60 mt-6 mb-3 block">{{ "withdrawals.form.documentos.otros" | translate }}</label>
      <app-image-control-input flex="flex-col"  [control]="form.get('doc_otros')" [label]="'withdrawals.form.documentos.otrosPlaceholder' | translate" (imageAdded)="documentAdded($event, 'doc_otros')" (imageRemoved)="documentRemoved($event, 'doc_otros')"></app-image-control-input>
    </div>

  </div>
  </div>

</div>
