import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ViewChild,
} from '@angular/core';
import { PermissionsComponent } from 'src/app/shared/components/permissions/permissions.component';
import { UserService } from 'src/app/shared/services/user.service';

@Component({
  selector: 'app-user-permissions',
  templateUrl: './user-permissions.component.html',
  styleUrls: ['./user-permissions.component.scss'],
})
export class UserPermissionsComponent implements AfterViewInit {
  @ViewChild('ap') ap?: PermissionsComponent;

  constructor(
    private userService: UserService,
    private detector: ChangeDetectorRef,
  ) {}

  async ngAfterViewInit() {
    this.detector.detach();
    await this.ap?.update(this.userService.user?.permissions);
    this.detector.reattach();
    this.detector.detectChanges();
  }
}
