import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { CurrentContractStoreService } from '../current-store.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { lastValueFrom } from 'rxjs';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  FormGroup,
} from '@angular/forms';
import { MessageService } from 'primeng/api';
import { Location } from '@angular/common';
import { RequestAproval } from '../request-aproval.interface';

@Component({
  selector: 'app-edit-contract-linked-detail',
  templateUrl: './edit-contract-linked-detail.component.html',
  styleUrls: ['./edit-contract-linked-detail.component.css'],
})
export class EditContractLinkedDetailComponent implements OnInit {
  locality: any = null;
  contract: any = null;

  baseConditions: any[] = [];
  extraConditions: any[] = [];
  currentConditions: any[] = [];

  stateOptions: any[] = [
    { label: 'No', value: false },
    { label: 'Si', value: true },
  ];

  form!: UntypedFormGroup;

  constructor(
    private store: CurrentContractStoreService,
    private http: HttpClient,
    private fb: UntypedFormBuilder,
    private messageSvc: MessageService,
    private location: Location,
    private detector: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this.store.selectedContract$.subscribe(async (contract) => {
      this.detector.detach();

      this.contract = contract;
      this.locality = contract.locality;

      console.log(this.contract);

      this.extraConditions = await this.getExtraConditions();
      this.currentConditions = await this.getCurrentConditions();

      console.log(this.extraConditions);
      console.log(this.currentConditions);

      this.form = this.fb.group({});

      for (const condition of this.extraConditions) {
        const exists =
          this.currentConditions.filter(
            (extra: any) => extra.facturable.id == condition.id,
          ).length > 0;

        this.form.addControl(condition.id, this.fb.control(exists), {});
      }

      console.log(this.form);

      if (this.contract.typeOfContract == 0) {
        this.getByHabConditions();
      } else {
        this.getBySerConditions();
      }

      this.detector.reattach();
      this.detector.detectChanges();
    });
  }

  public getPriceForCondition(condition: any) {
    const c = this.currentConditions.filter(
      (c) => c.facturable.id == condition.id,
    )[0];

    if (c) {
      let cx = '';

      switch (c.factor) {
        case 0:
          cx = 'por Expediente';
          break;
        case 1:
          cx = 'por Inspección';
          break;
        case 2:
          cx = 'Fijo';
      }

      return c.qty + `€ (${cx})`;
    }

    return 'No facturable';
  }

  sendToAproval() {
    console.log(this.form.value);

    const request_body: RequestAproval = {
      contract_id: this.contract.id,
      typeOfContract: 0,
      conditions: this.baseConditions.map((condition) => condition.id),
      extra_conditions: this.extraConditions
        .filter((condition) => this.form.value[condition.id])
        .map((condition) => condition.id),
    };

    console.log(request_body);

    this.sendRequestAproval(request_body).then(() => {
      this.messageSvc.add({
        severity: 'success',
        summary: 'Solicitud enviada',
        detail: 'La solicitud de aprobación ha sido enviada correctamente',
      });
      this.location.back();
    });
  }

  private getByHabConditions() {
    this.http
      .get<any>(`${environment.api}contracts/conditions-by-habitant`)
      .subscribe((data) => {
        this.baseConditions = data;
      });
  }

  private getBySerConditions() {
    this.http
      .get<any>(`${environment.api}contracts/conditions-by-service`)
      .subscribe((data) => {
        this.baseConditions = data;
      });
  }

  private async getExtraConditions() {
    return await lastValueFrom(
      this.http.get<any[]>(
        `${environment.api}contracts/conditions-contract-extras`,
      ),
    );
  }

  private async getCurrentConditions() {
    return await lastValueFrom(
      this.http.get<any[]>(
        `${environment.api}contracts/conditions-contract-active/byhab/${this.contract.id}`,
      ),
    );
  }

  private async sendRequestAproval(request: RequestAproval) {
    return await lastValueFrom(
      this.http.post(`${environment.api}contracts/request-reapproval`, request),
    );
  }
}
