import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-schedule-pop',
  templateUrl: './schedule-pop.component.html',
  styleUrls: ['./schedule-pop.component.scss'],
})
export class SchedulePopComponent {
  @Output() emitClose = new EventEmitter();
  @Output() emitSchedule = new EventEmitter();

  close() {
    this.emitClose.emit();
  }

  scheduleFn() {
    this.close();
    this.emitSchedule.emit();
  }

  startNow() {
    this.close();
  }
}
