import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-inspections-record-select',
  templateUrl: './inspections-record-select.component.html',
  styleUrls: ['./inspections-record-select.component.scss'],
})
export class InspectionsRecordSelectComponent {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
  ) {}

  ngOnInit() {}

  async selected(data: any) {
    console.log(data);
    await this.router.navigate(['list'], {
      relativeTo: this.route,
      queryParams: { record: data.id },
    });
  }
}
