import { Component } from '@angular/core';
import { Location } from '@angular/common';
@Component({
  selector: 'app-back',
  template: `
    <div class="flex flex-row">
      <a
        class="flex items-center text-am-primary-turquoise-100 text-sm font-bold"
        (click)="location.back()"
      >
        <i class="pi pi-angle-left mr-2"></i>
        {{ 'admin.back' | translate }}
      </a>
      <p>&nbsp;</p>
    </div>
  `,
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
})
export class BackComponent {
  constructor(public location: Location) {}
}
