<div class="m-10">
  <!-- Title -->
  <div class="flex item-center w-full mt-14 mb-4">
      <h2 class="flex-grow text-xl font-bold text-am-primary-navy-100">{{'admin.aprove_linked_contract' | translate}}</h2>
  </div>

  <div class="flex max-md:flex-col max-md:gap-4 justify-between">
    <div class="notification__title flex flex-col gap-1">
        <h2 class="text-am-primary-navy-100 font-bold text-am-2xl">Ayto. {{locality?.name}}</h2>
        <p class="text-am-secondary-navy-80 text-base ">{{locality?.address || 'Sin direccion conocida'}}</p>
        <p class="text-am-secondary-navy-80 text-base">{{locality?.phone || 'Sin telefono conocido'}}</p>
        <a class="text-base text-am-primary-turquoise-100 font-semibold"
        target="_blank"
        [href]="locality?.web">{{locality?.web}}</a>
    </div>
  </div>

  <!-- Div para condiciones base -->
  <div class="rounded-md border border-emerald-500 mt-4">

    <h2 class="p-4">Limites poblacionales</h2>
    <p-table [value]="baseConditions">

      <ng-template pTemplate="header">
        <tr>
          <th>Desde</th>
          <th>Hasta</th>
          <th>Precio</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-data>
        <tr>
          <td>{{ data.lowLimit }}</td>
          <td>{{ data.highLimit }}</td>
          <td>{{ data.price | currency:'':'' }} €</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
  <!-- Div para condiciones extra -->
  <div class="rounded-md border border-emerald-500 mt-4">
    <div *ngFor="let condition of extraConditions; ; let first = first; let i = index">
      <div class="grid grid-cols-5 p-4 gap-4" [ngClass]="{ 'border-t border-emerald-200': !first }" [formGroup]="form">
        <p-selectButton [options]="stateOptions" [formControlName]="i + 1" [allowEmpty]="false"  optionLabel="label" optionValue="value"></p-selectButton>
        <h2 class="self-center">{{ condition.title }}</h2>
        @if (condition.extraType == 1) {
          <p-inputNumber pRipple inputId="currency-spain" currency="EUR" locale="es-ES" placeholder="Cantidad" [formControlName]="(i + 1) + '_qty'" ></p-inputNumber>
          <p-dropdown [options]="calculusOptions" optionLabel="label" optionValue="value" placeholder="Seleccione opción" [formControlName]="(i + 1) + '_fac'"></p-dropdown>
        }
        @else {
          <h2 class="self-center justify-self-center">-</h2>
          <h2 class="self-center justify-self-center">-</h2>
        }
        <h2 class="self-center justify-self-center">{{ condition.extraType == 0 ? (condition.price | currency:'':'') + '€'  : '' }}</h2>
      </div>
    </div>
  </div>

  <!-- Div para los descuentos -->
  <div class="rounded-md border border-emerald-500 mt-4">
    <h2 class="p-4">Descuentos</h2>
    <div class="grid grid-cols-5 p-4 gap-4">
      <p-dropdown [options]="discountOptions" optionLabel="label" optionValue="value" placeholder="Seleccione opción" [(ngModel)]="discountType"></p-dropdown>
      @if (discountType < 1) {
        <p-inputNumber pRipple inputId="currency-spain" currency="EUR" locale="es-ES" placeholder="Cantidad" [(ngModel)]="discountQty" ></p-inputNumber>
      }
      @else {
        <p-inputNumber pRipple inputId="percent" prefix="%" placeholder="%" [(ngModel)]="discountQty" ></p-inputNumber>
      }
    </div>
  </div>

  <!-- Botonera -->
  <div class="rounded-md border border-emerald-500 mt-4 p-4">
    <h2>El Expediente se activará una vez el Ayuntamiento en cuestion autorice la vinculación</h2>
    <h2 class="mb-8">Mientras tanto el Expediente seguirá apareciendo como pendiente y no podra realizar ninguna acción sobre él</h2>
    <p-button pRipple [label]="'Aprobar'" [style]="{'minWidth':'160px'}" iconPos="left" size="small" (click)="aproveContract()"></p-button>
  </div>
</div>
