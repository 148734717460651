import { HttpClient } from '@angular/common/http';
import {
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  signal,
  WritableSignal,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifyFormService } from 'src/app/shared/services/notify-form.service';
import { environment } from 'src/environments/environment';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-notify-location',
  templateUrl: './notify-location.component.html',
  styleUrls: ['./notify-location.component.scss'],
})
export class NotifyLocationComponent implements OnInit {
  public form = this.formService.form.controls.step_1;
  public images: any[] = [];
  public filteredTowns: string[] = [];

  @Input() ine: string = '';
  @Input() internalId?: number;

  public localities: any[] = [];

  public locality?: any;

  loadingMap: boolean = true;

  coordinatesSelected: boolean = false;

  localityName!: WritableSignal<string>;

  constructor(
    private formService: NotifyFormService,
    public router: Router,
    private http: HttpClient,
    private route: ActivatedRoute,
    private detector: ChangeDetectorRef,
  ) {}

  finishLoading() {
    //this.detector.detach();
    //console.log("finishLoading");
    this.loadingMap = false;
    //this.detector.reattach();
    //this.detector.detectChanges();
  }

  async ngOnInit() {
    this.localityName = signal('');

    this.detector.detach();

    this.localities = await lastValueFrom(
      this.http.get<any[]>(environment.api + 'localities/'),
    );

    //this.ine = this.route.snapshot.queryParams['ine'];

    this.form.patchValue({ ine: this.ine });

    //this.internalId = this.route.snapshot.queryParams['internalId'];

    this.coordinatesSelected = false;

    console.log(this.localities);

    if (this.internalId) {
      this.locality = await lastValueFrom(
        this.http.get(environment.api + 'locality/' + this.internalId),
      );

      this.localityName.set(this.locality.name);
      this.form.patchValue({ city: this.locality.name });

      localStorage.setItem('internalId', this.internalId?.toString() || '');
    } else {
      const loc = this.localities.filter((l) => l.ine === this.ine);

      if (loc.length > 0) {
        this.locality = loc[0];
        this.internalId = this.locality.internalId;
        this.localityName.set(this.locality.name);
        this.form.patchValue({ city: this.locality.name });

        localStorage.setItem('internalId', this.internalId?.toString() || '');
      }
    }

    localStorage.setItem('ine', this.ine);

    if (this.form.value.images && this.form.value.images[0] !== '') {
      this.images = this.form.value.images;
    }

    this.detector.reattach();
    this.detector.detectChanges();
  }

  async validate() {
    console.log(this.form);

    if (this.form.valid) {
      await this.router.navigate(['/notify/contact'], {
        queryParams: { ine: this.ine, internalId: this.internalId },
      });
    } else {
      this.form.markAllAsTouched();
    }
  }

  setCoordinates(param: { coordinates: [number, number]; location: string }) {
    console.log('setCoordinates');
    console.log(param);

    this.coordinatesSelected = true;

    this.form.patchValue({ coordinates: param.coordinates });
    this.form.patchValue({ address: param.location });
  }

  // async toBase64(file: any) {
  //   return new Promise(resolve => {
  //   const reader = new FileReader();
  //   reader.addEventListener('loadend', () => {
  //     resolve(reader.result);
  //   });
  //   reader.readAsDataURL(file)})
  // }

  // async addImage(e: any) {
  //   const capturedImg = e.target.files[0];
  //   const img64 = await this.toBase64(capturedImg);
  //   this.images.push(img64);
  //   this.notifyForm.patchValue({images: this.images});
  // }

  // removeImg(index: number) {
  //   this.images.splice(index, 1);
  //   this.notifyForm.patchValue({images: this.images});
  // }

  updateImages(images: string[]) {
    this.images = images;
    this.form.patchValue({ images: this.images });
  }

  /*
  filterTowns(event: AutoCompleteCompleteEvent) {
    let filtered: string[] = [];
    let query = event.query;

    for (let i = 0; i < (this.localities as string[]).length; i++) {
      let locality = (this.localities as string[])[i] as any;
      const name = locality.name;
      if (name && name.toLowerCase().normalize('NFD').replace(/\p{Diacritic}/gu, '').indexOf(
        query.toLowerCase().normalize('NFD').replace(/\p{Diacritic}/gu, '')
      ) == 0) {
        filtered.push(locality)
      }
    }
    this.filteredTowns = filtered;
  }
  */
}
