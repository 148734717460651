import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-inspections-explore',
  templateUrl: './inspections-explore.component.html',
  styleUrls: ['./inspections-explore.component.scss'],
})
export class InspectionsExploreComponent {
  items = [
    {
      id: 'map',
      label: 'Mapa',
      command: async () => {
        await this.router.navigate(['map'], {
          relativeTo: this.route,
          queryParamsHandling: 'preserve',
        });
      },
    },
    {
      id: 'list',
      label: 'Listado',
      command: async () => {
        await this.router.navigate(['list'], {
          relativeTo: this.route,
          queryParamsHandling: 'preserve',
        });
      },
    },
    {
      id: 'calendar',
      label: 'Agenda',
      command: async () => {
        await this.router.navigate(['calendar'], {
          relativeTo: this.route,
          queryParamsHandling: 'preserve',
        });
      },
    },
  ];
  activeItem? = this.items[0];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
  ) {
    //select tab based on url
    const url = this.router.url;
    const urlParts = url.split('?')[0].split('/');
    const lastPart = urlParts[urlParts.length - 1];
    this.activeItem = this.items.find((item) => item.id === lastPart);
  }
}
