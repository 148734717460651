<footer id="footer" class="text-white mt-20">
  <div class="container mx-auto p-4 text-sm">
    <div class="flex">
      <div class="logo text-3xl font-bold mr-20 max-lg:self-center">
        Ag4asbestos
      </div>
      <div class="flex max-lg:flex-col max-lg:gap-1 lg:items-center">
        <div class="mx-2 flex justify-start gap-1 pb-2">
          <i class="pi pi-globe" style="font-size: 1.2rem; color: turquoise; "></i>
          <a href="https://agforest.ai">https://agforest.ai</a>
        </div>
        <div class="mx-2 flex justify-start gap-1 pb-2">
          <span class="pi pi-send" style="font-size: 1.2rem; color: turquoise; "></span> {{'footer.email' | translate}}
        </div>
        <div class="mx-2 flex justify-start gap-1 pb-2">
          <span class="pi pi-map-marker" style="font-size: 1.2rem; color: turquoise; "></span> {{'footer.address' |
          translate}}
        </div>
      </div>
    </div>
    <div class="sep my-2 mx-4 max-md:my-8"></div>
    <div class="flex text-xs max-sm:flex-col-reverse max-md:gap-4">
      <div>
        {{'footer.copyright' | translate}}
      </div>
      <div class="flex-1"></div>
      <div>
        <a [routerLink]="['aviso-legal', selectedLanguage]" class="mx-2 font-semibold" href="#">{{'footer.legal' | translate}}</a>
        <a [routerLink]="['privacidad', selectedLanguage]" class="mx-2 font-semibold" href="#">{{'footer.cookies' | translate}}</a>
      </div>
    </div>
  </div>
</footer>
