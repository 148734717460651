import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MessageService, PrimeNGConfig } from 'primeng/api';
import { User, UserService } from 'src/app/shared/services/user.service';

@Component({
  selector: 'app-my-account',
  templateUrl: './my-account.component.html',
  styleUrls: ['./my-account.component.scss'],
})
export class MyAccountComponent {
  langs = [
    { name: 'Español', code: 'es' },
    { name: 'Inglés', code: 'en' },
  ];
  user?: User;

  selectedLang = 'es';

  editing = false;
  firstName = '';
  lastName = '';

  constructor(
    private readonly userService: UserService,
    public translate: TranslateService,
    private config: PrimeNGConfig,
    private message: MessageService,
  ) {}

  ngOnInit(): void {
    this.user = this.userService.user;
    this.selectedLang = this.user?.lang || 'es';
    this.firstName = this.user?.firstName || '';
    this.lastName = this.user?.lastName || '';
  }

  editName(_event: any) {
    this.editing = true;
    console.log(this.firstName);
  }

  async updateName(_event: any) {
    this.editing = false;
    //this.user!.name = `${this.firstName} ${this.lastName}`;
    //this.user!.firstName = this.firstName;
    //this.user!.lastName = this.lastName;
    await this.userService.setProfileName(this.firstName, this.lastName);

    //this.userService.setUser(this.user!);
  }

  cancelUpdateName(_event: any) {
    this.editing = false;
  }

  changeLang(lang: any) {
    this.selectedLang = lang.value;
  }

  async saveLang() {
    const result: { access_token: string } = await this.userService.setLang(
      this.selectedLang,
    );

    this.translate.setDefaultLang(this.selectedLang);
    this.translate
      .get('primeng')
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      .subscribe((res) => this.config.setTranslation(res));

    if (this.user) {
      this.user.access_token = result.access_token || '';

      console.log(this.user.access_token);

      if (this.userService.rememberPassword) {
        //localStorage.setItem(UserService.STORAGE_USER_KEY, this.user.serialize());
        // eslint-disable-next-line angular/document-service
        document.cookie = `authToken=${this.user.access_token};`;
      }
    }

    this.message.add({
      severity: 'success',
      summary: 'Idioma',
      detail: 'Idioma actualizado correctamente',
    });
  }
}
