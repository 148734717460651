import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  constructor(private messageService: MessageService) {}

  displayHttpError(e: any) {
    if ((e as Response).status === 403) {
      this.messageService.add({
        severity: 'error',
        summary: 'Prohibido',
        detail: 'No tienes permisos para acceder a estos datos',
      });
    } else if ((e as Response).status === 401) {
      /*
      this.messageService.add({
        severity: 'error',
        summary: 'No autorizado',
        detail: 'No tienes permisos para acceder a estos datos',
      });
      */
    } else if ((e as Response).status === 404) {
      this.messageService.add({
        severity: 'error',
        summary: 'No encontrado',
        detail: 'No se han encontrado los datos solicitados',
      });
    } else if ((e as Response).status === 500) {
      this.messageService.add({
        severity: 'error',
        summary: 'Error interno',
        detail: 'Ha ocurrido un error interno',
      });
    } else if ((e as Response).status === 0) {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'No se ha podido conectar con el servidor',
      });
    }
  }
}
