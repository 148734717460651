<div class="flex justify-between items-center mt-20 mb-10">
  <h1 class=" text-xl">{{'inspections.location' | translate}}</h1>
  <p-button #addLoc [hidden]="!canEdit" pRipple type="button" icon="pi pi-plus" [label]="'inspections.addNewLocationButton' | translate"
    (click)="addLocation()" size="small" [outlined]="true"></p-button>
</div>

<div [formGroup]="form">
  <div formArrayName="locations">
    <div *ngFor="let group of form.get('locations')?.controls; let i = index" [formGroup]="group">
      <div class="p-input-underline">
        <input class="w-[60%]" pInputText id="locationName" type="text" formControlName="name"
          [placeholder]="'inspections.locationPlaceholder' | translate" [style]="{'font-size': '18px', 'font-weight': '400'}" maxlength="35">
        <button pButton pRipple type="button" icon="pi pi-trash" class="p-button-text p-button-rounded p-button-plain"
          (click)="removeLocation(i)"></button>
      </div>
      <div class=" flex flex-col mt-10 mb-10 ml-5 mr-5">
        <div class="flex flex-row">
          <div class=" flex flex-col p-input-underline mr-8">
            <div class="flex flex-col">
              <label for="samplingStrategy" class="  text-am-secondary-navy-60 ">{{'inspections.samplingStrategy' |
                translate}}</label>
              <p-dropdown [options]="strategyOptions" formControlName="samplingStrategy"
                [placeholder]="'inspections.estrategiaMuestreoPlaceholder' | translate" [style]="{'minWidth':'100%'}"></p-dropdown>
            </div>
          </div>
          <div class=" flex flex-col p-input-underline mr-8" *ngIf="extended">
            <label for="management" class="text-am-secondary-navy-60 ">{{'inspections.management' | translate}}</label>
            <p-dropdown [options]="mcaManagementOptions" formControlName="management" [placeholder]="'inspections.gestionMCAPlaceholder' | translate"
              [style]="{'minWidth':'100%'}"></p-dropdown>
          </div>
          <div class="flex flex-col p-input-underline grow">
            <label for="material" class="  text-am-secondary-navy-60 ">{{'inspections.material' | translate}}</label>
            <p-autoComplete class="grow" formControlName="material" [suggestions]="suggestions"
              (completeMethod)="search($event)" [placeholder]="'inspections.materialPlaceholder' | translate" maxlength="50"></p-autoComplete>
          </div>
        </div>

        <div class="flex flex-col mt-8">
          <div class="flex flex-row">
            <div class=" flex flex-col p-input-underline mr-8">
              <div class="flex flex-col">
                <label for="asbestos_quantity" class="  text-am-secondary-navy-60 ">{{'inspections.asbestos_quantity' | translate}}</label>
                <p-inputNumber formControlName="asbestos_quantity" [min]="0" [max]="9999" [mode]="'decimal'" [step]="0.1" [style]="{'minWidth':'100%'}"></p-inputNumber>
              </div>
            </div>

            <div class=" flex flex-col p-input-underline" *ngIf="extended">
              <label for="asbestos_quantity_unit" class="text-am-secondary-navy-60 ">{{'inspections.asbestos_quantity_unit' | translate}}</label>
              <p-dropdown [options]="objectKeys(asbestosUnit)" formControlName="asbestos_quantity_unit"
                [placeholder]="'inspections.unidadPlaceholder' | translate" [style]="{'minWidth':'100%'}"></p-dropdown>
            </div>
          </div>
          <div class="flex flex-col p-input-underline mt-6 mb-6">
            <label for="asbestos_quantity_description" class="  text-am-secondary-navy-60 ">{{'inspections.asbestos_quantity_description' | translate}}</label>
            <textarea pInputTextarea id="asbestos_quantity_description" type="text" [placeholder]="'inspections.asbestosDescPlaceholder' | translate" formControlName="asbestos_quantity_description"></textarea>
          </div>
        </div>

        <div class="flex flex-row mt-8">
          <div class=" flex flex-col p-input-underline mr-8" *ngIf="extended">
            <label for="mca" class="  text-am-secondary-navy-60 ">{{'inspections.MCA' | translate}}</label>
            @if (selectedLanguage == 'es') {
              <p-selectButton id="mca" [options]="mcaOptionsES" formControlName="mca" optionLabel="name" optionValue="id"></p-selectButton>
            }
            @else {
              <p-selectButton id="mca" [options]="mcaOptionsEN" formControlName="mca" optionLabel="name" optionValue="id"></p-selectButton>
            }
          </div>

          <div class=" flex flex-col" *ngIf="extended">
            <label for="sample_withdrawal_date" class="font-medium text-sm text-am-secondary-navy-60 ">{{'inspections.withdrawalDate' | translate}}</label>
            <p-calendar [showIcon]="true" [placeholder]="'Ej. 2021-01-01'" formControlName="sample_withdrawal_date" dateFormat="dd/mm/yy"></p-calendar>
          </div>
        </div>
      </div>

    <div class="ml-5 mr-5">
      <label class=" text-am-secondary-navy-60 mb-3 block">{{'inspections.photos' | translate}}</label>
      <app-image-control-input [control]="group.get('pictures')" [label]="'Subir imagen'"
        (imageAdded)="imageAdded($event, i)" (imageRemoved)="imageRemoved($event, i)"></app-image-control-input>

      <label class=" text-am-secondary-navy-60 mt-10 mb-3 block">{{'inspections.laboratorieSamples' |
        translate}}</label>
      <app-image-control-input [control]="group.get('picturesLab')" [label]="'Añadir foto de la etiqueta'"
        (imageAdded)="labAdded($event, i)" (imageRemoved)="labRemoved($event, i)"></app-image-control-input>

      <label class=" text-am-secondary-navy-60 mt-10 mb-3 block">{{'inspections.plans' | translate}}</label>
      <app-image-control-input [control]="group.get('picturesSchemas')" [label]="'Subir plano'"
        (imageAdded)="schemaAdded($event, i)" (imageRemoved)="schemaRemoved($event, i)"></app-image-control-input>

    </div>
    <div *ngIf="extended" class="mt-10" [hidden]="!canCreateReport">
      <h4 class=" text-xl">{{'inspections.inspectionSheet' | translate}}</h4>
      <div class="mt-4">
        <p-button pRipple type="button" icon="pi pi-plus" label="{{'inspections.inspectionSheet' | translate}}"
          (click)="openReport(group)" size="small" [outlined]="true"></p-button>
      </div>
    </div>


    <app-popupfichainsp #popup *ngIf="visible" [index]="fichaIndex" [form]="editingForm" (closeReport)="onClosePopup()"
      (saveReport)="onSaveReport($event)"></app-popupfichainsp>

    <div class="mt-6 mb-4 h-2 border-t-2 border-t-navy-60"></div>

  </div>
</div>
</div>
