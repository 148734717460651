/* eslint-disable @typescript-eslint/unbound-method */
import { HttpClient } from '@angular/common/http';
import { Component, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {
  ConfirmEventType,
  ConfirmationService,
  Confirmation,
  MessageService,
} from 'primeng/api';
import { lastValueFrom } from 'rxjs';
import { PermissionsComponent } from 'src/app/shared/components/permissions/permissions.component';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { environment } from 'src/environments/environment';
import { Location } from '@angular/common';

@Component({
  selector: 'app-organization-upsert-users',
  templateUrl: './organization-upsert-users.component.html',
  styleUrls: ['./organization-upsert-users.component.scss'],
})
export class OrganizationUpsertUsersComponent {
  @ViewChild('ap') ap?: PermissionsComponent;
  @ViewChild('ad') ad?: Confirmation;

  user?: any;
  loading?: boolean;
  loadingClients?: boolean;
  deleteLoading?: boolean;
  roles?: any;
  form = new FormGroup({
    firstName: new FormControl(null, Validators.required),
    lastName: new FormControl(null, Validators.required),
    email: new FormControl(null, [Validators.required, Validators.email]),
    role: new FormControl(null, Validators.required),
    password: new FormControl(null, Validators.required),
    permissions: new FormControl(null, Validators.required),
    records: new FormControl<any>(null),
  });

  errors: { [key: string]: string | boolean } = {};

  owner_id?: string;
  embedded: boolean = false;

  constructor(
    private readonly http: HttpClient,
    private confirmationService: ConfirmationService,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    private toastService: MessageService,
  ) {}

  async ngAfterViewInit() {
    const data = await lastValueFrom(
      this.http.get(environment.api + 'organization/roles'),
    ); //.toPromise().then((data: any) => {

    this.roles = data;
    this.form.get('role')?.setValue(this.roles[0]);
    //});

    const params = this.route.snapshot.queryParams;

    if (params['id']) {
      await this.loadUser(params['id']);
    } else {
      await this.ap?.getDefaultsByRole(this.roles[0]?.id);
    }
  }

  async loadUser(id: string) {
    this.loading = true;
    const user = (await this.http
      .get(environment.api + 'organization/users/getById/' + id)
      .toPromise()) as any;
    this.user = user;
    this.form.get('password')?.clearValidators();
    this.form.get('password')?.updateValueAndValidity();

    this.form.patchValue({
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      role: this.roles.find((role: any) => role.id === user?.role),
      permissions: user?.permissions,
      records: user?.records,
    });

    console.log(user?.permissions);
    await this.ap?.update(user?.permissions);
    this.loading = false;
  }

  async roleChange(event: any) {
    if (event?.value && event.originalEvent && !this.loading) {
      await this.ap?.getDefaultsByRole(event.value.id);
    }
  }
  permissionsChange(event: any) {
    this.form.get('permissions')?.setValue(event);
  }
  async save() {
    if (this.form.valid) {
      this.loading = true;

      const values: any = this.form.value;
      const permissions = values.permissions;
      const mappedPermissions: { scope: string; type: string }[] = [];
      for (const scope in permissions) {
        for (const type in permissions[scope]) {
          if (permissions[scope][type]) {
            mappedPermissions.push({ scope, type });
          }
        }
      }
      values.permissions = mappedPermissions;
      values.role = values.role.id;
      console.log(values);

      try {
        const url = this.user
          ? this.http.put<any>(
              environment.api + 'organization/users/' + this.user.id,
              values,
            )
          : this.http.post<any>(environment.api + 'organization/users', values);

        const data = await lastValueFrom(url);

        console.log(data);

        this.loading = false;

        await this.router.navigate(['admin/users']);
      } catch (e: any) {
        console.log(e);

        if (e.error.message === 'EMAIL_ALREADY_EXIST') {
          this.form.get('email')?.setErrors({ emailAlreadyExist: true });
          this.toastService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'El correo ya existe',
          });
          UtilsService.markGroupControlsAsDirty(this.form);
        }

        this.loading = false;
      }
    } else {
      this.loading = false;
      UtilsService.markGroupControlsAsDirty(this.form);
    }
  }

  delete() {
    this.confirmationService.confirm({
      key: 'delete',
      accept: async () => {
        this.deleteLoading = true;
        await this.http
          .delete(environment.api + 'organizations/users/' + this.user.id)
          .toPromise()
          .then((_data: any) => {
            this.location.back();
          })
          .catch((error) => {
            console.log(error);
          });
        this.deleteLoading = false;
      },
      reject: (_type: ConfirmEventType) => {},
    });
  }
  addRecord() {
    this.confirmationService.confirm({
      key: 'addRecord',
    });
  }

  recordSelected(e: any) {
    console.log(e);
    this.ad?.reject?.();
    //filter duplicates
    if (
      this.form
        .get('records')
        ?.value?.find((record: any) => record?.id === e?.id) !== undefined
    )
      return;

    this.form
      .get('records')
      ?.setValue([...(this.form.get('records')?.value || []), e]);
  }

  getBack() {
    this.location.back();
  }
}
