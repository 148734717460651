/* eslint-disable @typescript-eslint/unbound-method */
import { Injectable } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class NotifyFormService {
  readonly form = new FormGroup({
    step_1: new FormGroup({
      ine: new FormControl('', [Validators.required]),
      address: new FormControl(''),
      city: new FormControl(''),
      coordinates: new FormControl([0, 0]),
      message: new FormControl(''),
      images: new FormControl([] as any[]),
    }),
    step_2: new FormGroup({
      name: new FormControl(null),
      email: new FormControl(null, [Validators.email]),
      phone: new FormControl(null),
      check_data_protection: new FormControl(false, Validators.requiredTrue),
      captcha: new FormControl(null, Validators.required),
    }),
  });

  validateLocality(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
      const propertyVal = control.value && control.value['code'];
      const newFc = new FormControl(propertyVal);
      const failedValidators = [Validators.required]
        .map((v) => v(newFc))
        .filter((v) => !!v);
      return failedValidators.length
        ? { invalidProperty: failedValidators }
        : null;
    };
  }

  constructor() {}
}
