/* eslint-disable angular/document-service */
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnInit,
  Renderer2,
} from '@angular/core';
import { Router } from '@angular/router';
import {
  PermissionScope,
  PermissionType,
  User,
  UserService,
} from 'src/app/shared/services/user.service';

@Component({
  selector: 'app-menu-private',
  templateUrl: './menu-private.component.html',
  styleUrls: ['./menu-private.component.scss'],
})
export class MenuPrivateComponent implements OnInit, AfterViewInit {
  showAdmin = false;
  showRecords = false;
  showInspections = false;
  showPopup = false;

  user?: User;
  canViewAdmin: boolean = false;
  canViewExpedientes: boolean = false;
  canViewInspecciones: boolean = false;
  canViewRetiradas: boolean = false;
  isClient: boolean = false;
  isProvider: boolean = false;
  isOrganization: boolean = false;

  constructor(
    public router: Router,
    private readonly userService: UserService,
    private renderer: Renderer2,
    private detector: ChangeDetectorRef,
  ) {}

  ngAfterViewInit(): void {
    this.detector.detach();

    this.user = this.userService.getUser();

    this.isClient = this.user?.is_client || false;
    this.isProvider = this.user?.is_provider || false;
    this.isOrganization = this.user?.is_organization || false;

    this.canViewAdmin = this.isOrganization || false;
    this.canViewExpedientes =
      this.user?.hasPermission(
        PermissionScope.RECORDS,
        PermissionType.VIEW_ALL,
      ) || false;
    this.canViewInspecciones =
      this.user?.hasPermission(
        PermissionScope.INSPECTIONS,
        PermissionType.VIEW_ALL,
      ) || false;
    this.canViewRetiradas =
      this.user?.hasPermission(
        PermissionScope.WITHDRAWALS,
        PermissionType.VIEW_ALL,
      ) || false;

    /*

    this.showAdmin = this.userService.user?.hasAnyPermission([
      {scope: PermissionScope.ADMIN, type: PermissionType.VIEW}
    ]) || false;

    this.showRecords = this.userService.user?.hasPermission(PermissionScope.RECORDS,  PermissionType.VIEW_ALL) || false;

    this.showInspections = this.userService.user?.hasAllPermissions([
      {scope: PermissionScope.INSPECTIONS, type: PermissionType.VIEW_ALL},
      {scope: PermissionScope.RECORDS, type: PermissionType.VIEW_ALL},
    ]) || false;
    */

    this.detector.reattach();
    this.detector.detectChanges();
  }
  ngOnInit(): void {
    //this.addLocation();
    this.user = this.userService.getUser();

    this.isClient = this.user?.is_client || false;
    this.isProvider = this.user?.is_provider || false;
    this.isOrganization = this.user?.is_organization || false;

    this.canViewExpedientes =
      this.user?.hasPermission(
        PermissionScope.RECORDS,
        PermissionType.VIEW_ALL,
      ) || false;
    this.canViewInspecciones =
      this.user?.hasPermission(
        PermissionScope.INSPECTIONS,
        PermissionType.VIEW_ALL,
      ) || false;
    this.canViewRetiradas =
      this.user?.hasPermission(
        PermissionScope.WITHDRAWALS,
        PermissionType.VIEW_ALL,
      ) || false;
  }

  hasSectionPermission() {
    this.renderer.addClass(document.body, 'overflow_hidden');
    this.showPopup = true;
  }

  closePopup(): void {
    this.showPopup = false;
    this.renderer.removeClass(document.body, 'overflow_hidden');
  }
}
