<div id="box" class="flex relative">
  <openlayers-map #olmap
    id="olmap" style="display:flex"
    fillPointsOpacity="1"
    fillPolygonsOpacity="1"

    (mapRefreshStart)="onMapRefreshStart($event)"
    (mapRefreshEnd)="onMapRefreshEnd($event)"

    data-is-draw-enabled="{{enable_draw}}"
    data-must-show-on-click-feature="{{show_on_click}}"
    data-is-adress-search-bar-enabled="{{enable_address_bar}}"
    data-is-get-coordinates-on-click-enabled="{{get_coordinates_on_click}}"
    data-is-geolocation-enabled="{{is_geolocation_enabled}}"
    data-is-draw-enabled-on-startup="{{draw_enabled_on_startup}}"
    data-layer-style="{{data_layer_style}}">
  </openlayers-map>
</div>
