<!--Boton volver-->
<a *ngIf="isAdmin"  class="flex items-center text-am-primary-turquoise-100 font-bold mt-10 ml-10" (click)="goBack()">
    <i class="pi pi-angle-left mr-2"></i>
    {{'admin.back' | translate}}
</a>

<!--Div con Titulo y botones guardar y cancelar-->
<div class="flex item-center w-full my-14">

  <div class="flex flex-col w-full">
    <!--<h2 *ngIf="!inserting && owner_id" class="flex-grow ml-10 mb-8 text-xl font-bold text-am-primary-navy-100">{{selectedProvider.businessName}}</h2>-->

    <h2 class="flex-grow ml-10 text-xl font-bold text-am-primary-navy-100">{{form.get('businessName')?.value ? form.get('businessName')?.value : 'Nuevo cliente'}}</h2>
  </div>
    <div class="mr-8  flex gap-x-4">
        <p-button pRipple [label]="'Guardar'" [style]="{'minWidth':'160px'}"
            iconPos="left"  size="small" (click)="save()" [loading]="loading" [disabled]="deleteLoading"></p-button>
    </div>
</div>


<!--Div Formulario datos de usuario-->

<div class=" flex flex-wrap items-start ml-10 gap-10">

    <div *ngIf="inserting && !owner_id" class=" flex flex-col w-1/4 p-input-underline">
      <label for="owner_id" class="font-medium text-sm text-am-secondary-navy-60 ">Proveedor</label>
      <p-dropdown id="owner_id" name="owner_id" [options]="providers.providers" [formControl]="form.get('owner_id')" optionLabel="businessName" placeholder="Proveedor" (onChange)="selectProvider($event)"></p-dropdown>
    </div>


    <!--CIF-->
    <div class=" flex flex-col w-1/4 p-input-underline">
        <label for="cif" class="font-medium text-sm text-am-secondary-navy-60 ">{{'admin.uniqueId' | translate}}</label>
        <input pInputText id="cif" type="text" [placeholder]="'admin.placeholder6' | translate" [formControl]="form.get('cif')" maxlength="12">
    </div>
    <!--Razon social-->
    <div class=" flex flex-col w-1/4 p-input-underline">
        <label for="businessName" class="font-medium text-sm text-am-secondary-navy-60 ">{{'admin.businessName' | translate}}</label>
        <input pInputText id="businessName" type="text" [placeholder]="'admin.placeholder7' | translate" [formControl]="form.get('businessName')" maxlength="30">
    </div>

    <!--telefono-->
    <div class=" flex flex-col w-1/4 p-input-underline">
        <label for="phone" class="font-medium text-sm text-am-secondary-navy-60 ">{{'admin.numberPhone' | translate}}</label>
        <input pInputText id="phone" type="number" [placeholder]="'admin.placeholder9' | translate" [formControl]="form.get('phone')" maxlength="12">
    </div>
    <!--direccion-->
    <div class=" flex flex-col w-1/4 p-input-underline">
        <label for="address" class="font-medium text-sm text-am-secondary-navy-60 ">{{'admin.address' | translate}}</label>
        <input pInputText id="address" type="text" [placeholder]="'admin.placeholder10' | translate" [formControl]="form.get('address')" maxlength="50">
    </div>
    <!--email-->
    <div class=" flex flex-col w-1/4 p-input-underline">
        <label for="email" class="font-medium text-sm text-am-secondary-navy-60 ">{{'admin.eMail' | translate}}</label>
        <input pInputText id="email" type="email" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$" [placeholder]="'admin.placeholder8' | translate" [formControl]="form.get('email')" maxlength="50">
        <small *ngIf="errors['EMAIL_ALREADY_EXIST']" class="text-red-500">Ya existe un usuario con este email</small>
    </div>


</div>

<p-confirmDialog #cd [style]="{ width: '50vw' }">
    <ng-template pTemplate="header">
        <h3 class="font-bold">{{'admin.deleteUser' | translate}}</h3>
    </ng-template>
    <ng-template pTemplate="message">
        <div style="width:100%" class="text-sm text-center font-semibold w-full">
            <div>
                {{'admin.sureToDelete1' | translate}}<strong>{{client?.email}}</strong>{{'admin.sureToDelete2' | translate}}
            </div>
            <div>
                {{'admin.sureToDelete3' | translate}}
            </div>
        </div>
    </ng-template>
    <ng-template pTemplate="footer">
        <p-button type="button" [label]="'admin.cancel' | translate" (click)="cd.reject()" size="small" [outlined]="true"></p-button>
        <p-button type="button" [label]="'admin.deleteUser' | translate" (click)="cd.accept()" size="small" severity="danger"></p-button>
    </ng-template>
</p-confirmDialog>

