
<div [style]="{'width' :'inherit'}">
  <p-dropdown
  id="user"
  [options]="users"
  optionLabel="name"
  [formControl]="control"
  [style]="{'maxWidth':'100%', 'minWidth':'100%'}"
  [showClear]="true"
  [filter]="true" filterBy="email"
  [placeholder]="'ui.general.selectUser' | translate"
  optionLabel="email">
  <ng-template let-item pTemplate="selectedItem">
      {{item?.email}}
  </ng-template>
  <ng-template let-item pTemplate="item">
      {{item?.email}}
  </ng-template>
  <ng-template pTemplate="loader" let-options="options">
      <div class="flex align-items-center py-2 px-3" style="height: 38px">
          <p-skeleton [width]="options.even ? '60%' : '50%'" height="1rem"></p-skeleton>
      </div>
  </ng-template>

</p-dropdown>
</div>
