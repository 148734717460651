import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ConfirmEventType, ConfirmationService } from 'primeng/api';
import { Table } from 'primeng/table';
import { environment } from 'src/environments/environment';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-admin-list-clients',
  templateUrl: './admin-list-clients.component.html',
  styleUrls: ['./admin-list-clients.component.scss'],
})
export class AdminListClientsComponent implements OnInit {
  @ViewChild('dt') table?: Table;

  clients?: any[] = [];
  totalRecords?: number;
  loading?: boolean;

  constructor(
    private http: HttpClient,
    private confirmationService: ConfirmationService,
  ) {}
  async ngOnInit() {
    await this.load();
  }

  async load(_event?: any) {
    this.loading = true;

    const result = await lastValueFrom(
      this.http.get<any>(environment.api + 'owners/clients'),
    );

    this.clients = result?.clients as any[];

    console.log(this.clients);

    this.totalRecords = this.clients.length;

    this.loading = false;
  }

  /*******************************************************
   *
   *
   */

  deleteProvider(data: any) {
    this.confirmationService.confirm({
      key: 'delete',
      accept: async () => {
        this.loading = true;
        await this.http
          .delete(environment.api + 'owners/client/' + data?.owner_id)
          .toPromise()
          .then(async (_data: any) => {
            await this.load();
          })
          .catch((error) => {
            console.log(error);
          });
        this.loading = false;
      },
      reject: (_type: ConfirmEventType) => {},
    });
  }
}
