import { HttpClient } from '@angular/common/http';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { lastValueFrom } from 'rxjs';
import {
  PermissionScope,
  PermissionType,
  User,
  UserService,
} from 'src/app/shared/services/user.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-inspec-pop',
  templateUrl: './inspec-pop.component.html',
  styleUrls: ['./inspec-pop.component.scss'],
})
export class InspecPopComponent implements OnInit {
  @Output() emitClose = new EventEmitter();

  @Input() inspection: any = null;
  @Input() recordId?: number;

  user?: User;
  inspection_time: Date = new Date();

  canEdit: boolean = false;

  form: FormGroup = new FormGroup({
    inspector: new FormControl(),
  });

  constructor(
    private router: Router,
    private http: HttpClient,
    private userSvc: UserService,
    private detector: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.user = this.userSvc.getUser();

    this.canEdit =
      this.user?.hasPermission(
        PermissionScope.INSPECTIONS,
        PermissionType.EDIT,
      ) || false;

    console.log(this.inspection);

    delete this.inspection?.data?.inspector?.password;

    this.form.get('inspector')?.patchValue(this.inspection?.data?.inspector);
    this.inspection_time = new Date(this.inspection?.data?.date) || new Date();
  }

  lang = 'es';

  close() {
    this.emitClose.emit();
  }

  formatMonth(month: number) {
    return new Intl.DateTimeFormat(this.lang, { month: 'long' }).format(
      new Date().setMonth(month),
    );
  }

  async selected(data: any) {
    if (this.form.get('inspector')?.value === null) {
      this.emitClose.emit();
      return;
    }

    const result: any = await lastValueFrom(
      this.http.put(
        environment.api + 'inspection/inspector/' + data.agForestId,
        {
          inspector: this.form.get('inspector')?.value,
          date: this.inspection_time,
        },
      ),
    );

    console.log(result);

    if (result !== null && result?.id > 0) {
      this.emitClose.emit();
    }

    //this.router.navigate(['inspection'], { queryParams: { recordId: this.recordId, id: data.agForestId }});
  }

  async openInspection(data: any) {
    if (this.form.get('inspector')?.value !== null) {
      await lastValueFrom(
        this.http.put(
          environment.api + 'inspection/inspector/' + data.agForestId,
          { inspector: this.form.get('inspector')?.value },
        ),
      );
    }

    this.emitClose.emit();

    await this.router.navigate(['inspection'], {
      queryParams: { recordId: this.recordId, id: data.agForestId },
    });
  }
}
