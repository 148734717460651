<div class="notification__content flex flex-col py-8 px-8 max-md:py-4 max-md:px-3 gap-8">
    <div class="to__list flex gap-4 items-center" *ngIf="!embedded">
      <a href="/" class="text-am-primary-turquoise-100 font-bold text-sm cursor-pointer">{{'searchLocation.return' | translate}}</a>
    </div>
    <div class="flex max-md:flex-col max-md:gap-4 justify-between">
        <div class="notification__title flex flex-col gap-1" *ngIf="locality">
            <h2 class="text-am-primary-navy-100 font-bold text-am-2xl">Ayto. {{locality?.name}}</h2>
            <p class="text-am-secondary-navy-80 text-base ">{{locality?.address}}</p>
            <p class="text-am-secondary-navy-80 text-base">{{locality?.phone}}</p>
            <a class="text-base text-am-primary-turquoise-100 font-semibold"
            target="_blank"
            [href]="fixUrl(locality?.web)">{{fixUrl(locality?.web)}}</a>
        </div>

        <div style="background: #E5EAED" class="p-5 ml-1 flex items-center gap-5">
          <div>
            <h3 class="text-am-secondary-navy-80 font-bold text-base mb-1">{{'home.youKnowLocationTitle' | translate}}</h3>
            <p class="mb-2 text-sm" *ngIf="false">{{'home.youKnowLocationSubtitle' | translate}}</p>
          </div>
            <button [disabled]="!errorCondition" (click)="notifyWaste()"
            class="text-center block w-2/3 h-9 py-2 px-4 border-0 rounded-am-md bg-am-primary-turquoise-100 text-white cursor-pointer">{{'home.notify' | translate}}</button>
        </div>
    </div>


    <div *ngIf="!showWarning" class="font-bold text-am-1xl">{{totalRecords}} {{'searchLocation.locationsWith' | translate}}</div>
    <div *ngIf="showWarning" class="font-bold text-am-1xl">
      <p>{{'searchLocation.showPrivacyWarning' | translate}}</p>
    </div>
    <app-estates-list [ine]="ine" [loadData]="!showWarning" (count)="totalRecords = $event"></app-estates-list>
</div>

