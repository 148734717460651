import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-aviso-legal-es',
  templateUrl: './aviso-legal_es.component.html',
  styleUrls: ['./aviso-legal_es.component.css'],
})
export class AvisoLegalComponentES implements OnInit {
  constructor() {}

  ngOnInit() {}
}
