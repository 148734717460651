import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { SelectItem } from 'primeng/api';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-client-input',
  templateUrl: './client-input.component.html',
  styleUrls: ['./client-input.component.scss'],
})
export class ClientInputComponent {
  clients: SelectItem[] = [];

  @Input() control?: FormControl;
  @Output() onChange: EventEmitter<any> = new EventEmitter();

  loading: boolean = false;
  constructor(private readonly http: HttpClient) {}

  async ngOnInit() {
    await this.loadClients(null);
  }

  async loadClients(event: any) {
    this.loading = true;
    console.log(event);
    await this.http
      .get(environment.api + 'client')
      .toPromise()
      .then((d: any) => {
        this.clients = d.data;
      });
    this.loading = false;
  }

  onChangeClient(event: any) {
    console.log('emitting onchange');

    this.onChange.emit(event.value);
  }
}
