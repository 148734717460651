<app-menu-private></app-menu-private>

<!--Menu Cliente o Usuarios-->
<div class="flex item-center w-full h-12 mt-0.5">
    <p (click)="changeTab('dashboard')" [ngClass]="{'border-b-4 border-am-primary-turquoise-100': selectedItem === 'dashboard'}"
        class="w-full text-center h-full py-3 font-bold text-am-secondary-navy-80 border-b-4 border-am-secondary-navy-30  default:border-am-primary-turquoise-100 hover:border-am-primary-turquoise-100 focus:border-am-primary-turquoise-100 cursor-pointer" routerLink="/withdrawals/dashboard">
        {{'withdrawals.bar.dashboard' | translate}}</p>
    <p (click)="changeTab('retiradas')" [ngClass]="{'border-b-4 border-am-primary-turquoise-100': selectedItem === 'retiradas'}"
        class="w-full text-center h-full py-3 font-bold text-am-secondary-navy-80 border-b-4 border-am-secondary-navy-30 hover:border-am-primary-turquoise-100 focus:border-am-primary-turquoise-100 cursor-pointer" routerLink="/withdrawals/withdrawal">
        {{'withdrawals.bar.withdrawals' | translate}}</p>
</div>
<div class="p-10">
  <router-outlet></router-outlet>
</div>
