import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-image-control-input',
  templateUrl: './image-control-input.component.html',
  styleUrls: ['./image-control-input.component.scss'],
})
export class ImageControlInputComponent {
  @Input() public error: boolean = false;
  @Input() public label: string = 'Subir Imagen';

  @Input() flex: string = 'flex-row';

  @Output() imageAdded: EventEmitter<any> = new EventEmitter<any>();
  @Output() imageRemoved: EventEmitter<any> = new EventEmitter<any>();

  @Input() control?: FormControl;

  @ViewChild('image') image!: HTMLImageElement;

  constructor(private messageService: MessageService) {}

  async toBase64(file: any) {
    return new Promise((resolve) => {
      const reader = new FileReader();

      reader.addEventListener('loadend', () => {
        resolve(reader.result);
      });

      reader.readAsDataURL(file);
    });
  }

  async addImage(e: any) {
    const capturedImg = e.target.files[0];
    const img64 = await this.toBase64(capturedImg);

    const image = {
      filename: capturedImg.name,
      filetype: capturedImg.type,
      data: img64,
    };
    //check control if is array and add to it
    if (this.control) {
      if (Array.isArray(this.control.value)) {
        this.control.value.push(image);
        this.imageAdded.emit(image);
      } else {
        this.control.setValue([image]);
      }
    }
  }

  removeImg(index: number) {
    console.log(this.control?.value);
    console.log(index);

    const image = this.control?.value[index];

    this.imageRemoved.emit(image);

    /*
    const controls = this.control?.value;
    const newControls = [];

    console.log(controls);

    for (let i = 0; i++; controls.length) {
      if (i != index) {
        newControls.push(controls[i]);
      }
    }

    this.control?.setValue(newControls);

    console.log(this.control?.value);
    */
  }

  isPDF(index: number) {
    if (this.control?.value.length === 0) return false;

    const parts = this.control?.value[index].filename.split('.');

    return parts[parts.length - 1] === 'pdf';
  }

  downloadDocument(index: number) {
    if (this.control?.value.length === 0) return;

    const obj: any[] = [...(this.control?.value ?? [])];
    const url = obj[index]?.url;

    console.log(index);
    console.log(obj[index]);
    console.log(url);

    // eslint-disable-next-line angular/document-service
    const link = document.createElement('a');
    link.href = url;
    link.download = this.control?.value[index].filename;
    link.click();
    link.remove();

    this.messageService.add({
      severity: 'success',
      summary: 'Completado',
      detail: 'Fichero descargado correctamente',
    });
  }
}
