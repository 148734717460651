import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  signal,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Sidebar } from 'primeng/sidebar';
import { User, UserService } from 'src/app/shared/services/user.service';

@Component({
  selector: 'app-aside',
  templateUrl: './aside.component.html',
  styleUrls: ['./aside.component.scss'],
})
export class AsideComponent implements OnInit, AfterViewInit {
  isAdmin: boolean = false;
  owner_id: string = '';
  isProvider: boolean = false;
  user?: User;

  sidebarVisible = signal(false);

  mustShowBurguer = signal(false);

  innerWidth: number = 0;

  @Input() embed: boolean = false;

  selectedLanguage = 'es';

  @ViewChild('aside') sidenav!: Sidebar;

  constructor(
    private readonly userService: UserService,
    private readonly router: Router,
    private location: Location,
    private http: HttpClient,
    private detector: ChangeDetectorRef,
    private translator: TranslateService,
  ) {}
  ngAfterViewInit(): void {
    this.user = this.userService.getUser();

    this.isProvider = this.user?.is_provider || false;

    this.isAdmin = this.user?.role === 'ADMIN';

    if (this.user?.owner_id) {
      console.log('owner_id', this.user?.owner_id);
      this.owner_id = this.user.owner_id;
    }

    // eslint-disable-next-line angular/window-service
    this.innerWidth = window.innerWidth;

    if (this.innerWidth < 1048) {
      this.mustShowBurguer.set(true);
    } else {
      this.mustShowBurguer.set(false);
    }

    //const result = await lastValueFrom(this.http.get(environment.api + 'owners/' + user?.id)) as any;

    //this.owner_id = result.owner_id;
  }

  async ngOnInit() {
    this.selectedLanguage = this.translator.currentLang;
  }

  async logout() {
    this.userService.logout();
    await this.router.navigate(['/login']);
  }

  getBack() {
    console.log('back');

    this.location.back();
  }

  toggleSidebar() {
    console.log('toggleSidebar');

    if (this.innerWidth > 1024) {
      this.sidebarVisible.set(true);
    } else {
      //this.detector.detach();

      this.sidebarVisible.set(!this.sidebarVisible());
      //this.detector.reattach();
      //this.detector.detectChanges();
    }
  }

  onResize(event: any) {
    this.detector.detach();
    this.innerWidth = event.target.innerWidth;

    if (this.innerWidth < 1048) {
      this.mustShowBurguer.set(true);
    } else {
      this.mustShowBurguer.set(false);
    }

    this.detector.reattach();
    this.detector.detectChanges();
  }
}
