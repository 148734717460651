<app-menu-private></app-menu-private>


<!--Menu Cliente o Usuarios-->
<div class="grid grid-flow-row-dense grid-cols-6 ml-4 gap-4" (window:resize)="onResize($event)">

  <div class="card flex flex-wrap justify-content-center mt-6 gap-2">
    @if (mustShowBurguer()) {
      <p-sidebar #aside [(visible)]="sidebarVisible" position="left">
        <ng-container [ngTemplateOutlet]="navbar"></ng-container>
      </p-sidebar>
    }
    @else {
      <div class="mt-8">
        <ng-container [ngTemplateOutlet]="navbar"></ng-container>
      </div>
    }

    <p-button [hidden]="!mustShowBurguer()" type="button" class="w-8 h-8 mt-8" (click)="toggleSidebar()" icon="pi pi-bars"></p-button>

  </div>
  <div class="col-span-6 xl:col-span-5">
    <router-outlet></router-outlet>
  </div>
</div>

<ng-template #navbar>
  <ul (click)="toggleSidebar()">
    <li class="text-am-primary-navy-100 font-semibold text-sm leading-am-4 border-b">
      <a [routerLink]="['/admin/clients']" class="py-4 flex flex-row items-center justify-between"
        routerLinkActive="text-am-primary-turquoise-100">{{'admin.clients' | translate}}</a>
    </li>

    <li class="text-am-primary-navy-100 font-semibold text-sm leading-am-4 border-b">
      <a [routerLink]="['/admin/providers']" class="py-4 flex flex-row items-center justify-between"
        routerLinkActive="text-am-primary-turquoise-100">{{'admin.companies' | translate}}</a>
    </li>

    <li class="text-am-primary-navy-100 font-semibold text-sm leading-am-4 border-b">
      <a [routerLink]="['/admin/pending-contracts']" class="py-4 flex flex-row items-center justify-between"
        routerLinkActive="text-am-primary-turquoise-100">{{'admin.pending_contracts' | translate}}</a>
    </li>

    <li class="text-am-primary-navy-100 font-semibold text-sm leading-am-4 border-b">
      <a [routerLink]="['/admin/pending-auth-contracts']" class="py-4 flex flex-row items-center justify-between"
        routerLinkActive="text-am-primary-turquoise-100">{{'admin.pending_auth_contracts' | translate}}</a>
    </li>

    <li class="text-am-primary-navy-100 font-semibold text-sm leading-am-4 border-b">
      <a [routerLink]="['/admin/pending-reapproval-contracts']"
        class="py-4 flex flex-row items-center justify-between"
        routerLinkActive="text-am-primary-turquoise-100">{{'admin.pending_reapproval_contracts' | translate}}</a>
    </li>

    <li class="text-am-primary-navy-100 font-semibold text-sm leading-am-4 border-b">
      <a [routerLink]="['/admin/bills/emitted']" class="py-4 flex flex-row items-center justify-between"
        routerLinkActive="text-am-primary-turquoise-100">{{'admin.bills_emitted' | translate}}</a>
    </li>

    <li class="text-am-primary-navy-100 font-semibold text-sm leading-am-4 border-b">
      <a [routerLink]="['/admin/providerclients']" class="py-4 flex flex-row items-center justify-between"
        routerLinkActive="text-am-primary-turquoise-100">{{'admin.provider_clients' | translate}}</a>
    </li>

    <li class="text-am-primary-navy-100 font-semibold text-sm leading-am-4 border-b">
      <a [routerLink]="['/admin/users']" class="py-4 flex flex-row items-center justify-between"
        routerLinkActive="text-am-primary-turquoise-100">{{'admin.organization_users' | translate}}</a>
    </li>
    <li class="text-am-primary-navy-100 font-semibold text-sm leading-am-4 border-b">
      <a [routerLink]="['/admin/withdrawalcompanies']" class="py-4 flex flex-row items-center justify-between"
        routerLinkActive="text-am-primary-turquoise-100">{{'admin.withdrawal_companies' | translate}}</a>
    </li>
  </ul>
</ng-template>
<app-popupnopermissions *ngIf="showPopup" (closePop)="closePopup()"></app-popupnopermissions>
