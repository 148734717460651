<div class="m-10">
  <!-- Title -->
  <div class="flex item-center w-full mt-14 mb-4">
      <h2 class="flex-grow text-xl font-bold text-am-primary-navy-100">{{'admin.modify_independent_contract' | translate}}</h2>
  </div>

  <div class="flex max-md:flex-col max-md:gap-4 justify-between">
    <div class="notification__title flex flex-col gap-1">
        <h2 class="text-am-primary-navy-100 font-bold text-am-2xl">{{client?.businessName}}</h2>
        <p class="text-am-secondary-navy-80 text-base ">{{client?.address || 'Sin direccion conocida'}}</p>
        <p class="text-am-secondary-navy-80 text-base">{{client?.phone || 'Sin telefono conocido'}}</p>
    </div>
  </div>

  <!-- Div para condiciones base -->
  <div class="rounded-md border border-emerald-500 mt-4">
    <h2 class="p-4">Limites por cantidad de inspecciones</h2>
    <p-table [value]="baseConditions">

      <ng-template pTemplate="header">
        <tr>
          <th>Desde</th>
          <th>Hasta</th>
          <th>Precio</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-data>
        <tr>
          <td>{{ data.lowLimit }}</td>
          <td>{{ data.highLimit }}</td>
          <td>{{ data.price | currency:'':'' }} €</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
  <!-- Div para condiciones extra -->
  <div class="rounded-md border border-emerald-500 mt-4">
    <div *ngFor="let condition of extraConditions; ; let first = first; let i = index" [formGroup]="formExtras">
      <div class="grid grid-cols-4 p-4 gap-4" [ngClass]="{ 'border-t border-emerald-200': !first }">
        <p-selectButton [options]="stateOptions" [formControlName]="i + 1" [allowEmpty]="false"  optionLabel="label" optionValue="value"></p-selectButton>
        <h2 class="self-center">{{ condition.title }}</h2>
        @if (formExtras.get(i + 1 + '').value) {
          <h2 class="self-center justify-self-end font-bold">{{condition.extraType == 1 ? getPriceForCondition(condition) : ''}}</h2>
        }
        @else {
          <h2 class="self-center justify-self-end font-semibold text-emerald-500">{{ condition.extraType == 1 ? 'Solicitar Precio' : '-' }}</h2>
        }
        <h2 class="self-center justify-self-center font-bold">{{ condition.extraType == 0 ? (condition.price | currency:'':'') + '€'  : '' }}</h2>
      </div>
    </div>
  </div>

  <!-- Botonera -->
  <div class="rounded-md border border-emerald-500 mt-4 p-4">
    <h2>El Expediente se activará una vez aprobado por el equipo de agForest y el Ayuntamiento en cuestion autorice la vinculación</h2>
    <h2 class="mb-8">Mientras tanto el Expediente le seguirá apareciendo como pendiente y no podra realizar ninguna acción sobre él</h2>
    <p-button pRipple [label]="'Enviar'" [style]="{'minWidth':'160px'}" iconPos="left" size="small" (click)="sendToAproval()"></p-button>
  </div>
</div>
