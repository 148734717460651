import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-recover',
  templateUrl: './recover.component.html',
  styleUrls: ['./recover.component.scss'],
})
export class RecoverComponent {
  // eslint-disable-next-line @typescript-eslint/unbound-method
  control = new FormControl(null, [Validators.required, Validators.email]);

  constructor(
    public router: Router,
    public readonly http: HttpClient,
  ) {}

  async sendEmail() {
    if (this.control.valid) {
      //send email
      console.log('send email');
      await this.http
        .post(environment.api + 'user/remember', { email: this.control.value })
        .toPromise();
      await this.router.navigate(['/recoverpass/sentcode'], {
        state: { email: this.control.value },
      });
    } else {
      this.control.markAllAsTouched();
    }
  }
}
