import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ConfirmationService, ConfirmEventType } from 'primeng/api';
import { Inspection } from 'src/interfaces/inspections';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { OverlayPanel } from 'primeng/overlaypanel';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
})
export class CalendarComponent implements OnInit, AfterViewInit {
  @Input() dates: any[] = [];

  lang = 'es';

  today = new Intl.DateTimeFormat(this.lang).format(new Date());
  actualYear = new Date().getFullYear();
  actualMonth = new Date().getMonth();
  weekDaysNames: string[] = [];

  showingMonth = this.actualMonth;
  showingYear = this.actualYear;
  selectedDay = '';

  daysPerMonth: number[] = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
  monthNames: string[] = [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre',
  ];

  @Output() onMonthChange = new EventEmitter<Date>();
  @Output() onSelect = new EventEmitter<any>();
  @Output() onOpen = new EventEmitter<any>();
  @Output() onCreate = new EventEmitter<any>();
  @Output() onChangeDate = new EventEmitter<any>();
  @Output() onDelete = new EventEmitter<any>();

  currentDate: Date = new Date();
  changedDate: Date | null = null;

  draggedItem: any;

  current?: Date;

  @ViewChild('tooltip') tooltip: OverlayPanel | undefined;
  // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
  selectedInspect: any | null = null;

  clickCount = 0;
  singleClickTimer: any;
  trashHidden = true;

  months = this.monthNames.map((name, index, _a) => {
    const year = this.currentDate.getFullYear();

    let days = this.daysPerMonth[index];

    if (year % 4 === 0 && index === 1) {
      days = 29;
    }

    return {
      monthNum: index + 1,
      monthsName: name,
      monthsDays: days,
      //firstWeekDayName,
      //firstWeekDay
    };
  });

  constructor(
    private http: HttpClient,
    private confirmationService: ConfirmationService,
    private detector: ChangeDetectorRef,
    private translate: TranslateService,
  ) {}

  ngAfterViewInit(): void {
    this.detector.detach();

    console.log(this.dates);
    this.weekDaysNames = [...Array(7)].map((_, key) =>
      Intl.DateTimeFormat(this.translate.defaultLang, {
        weekday: 'long',
      }).format(new Date(2023, 4, key + 1)),
    );

    this.detector.reattach();
    this.detector.detectChanges();
  }

  ngOnInit(): void {
    this.emitMonthChange();
  }

  reload() {
    this.detector.detectChanges();
  }

  emitMonthChange() {
    const date = new Date(0, 0, 1, 0, 0, 0, 0);
    date.setMonth(this.showingMonth);
    date.setFullYear(this.showingYear);
    this.current = date;

    this.onMonthChange.emit(date);
  }

  selectDate(e: Date) {
    console.log(e);
    this.showingMonth = e.getMonth();
    this.showingYear = e.getFullYear();

    this.emitMonthChange();
  }

  firstWeekDay(month: number) {
    const dayNum = new Date(this.showingYear, month, 1).getDay();

    if (dayNum === 0) {
      return 7;
    }

    return dayNum;
  }

  calculateRestOfDays(
    daysOfTheActualMonth: number,
    daysOfThePrevMonth: number,
  ) {
    const ceil = Math.ceil((daysOfTheActualMonth + daysOfThePrevMonth) / 7) * 7;
    return ceil - daysOfTheActualMonth - daysOfThePrevMonth;
  }

  calculateActualDay(day: number) {
    if (
      Number(this.today.split('/')[1]) ===
        this.months[this.showingMonth].monthNum &&
      Number(this.today.split('/')[2]) === this.showingYear &&
      Number(this.today.split('/')[0]) === day
    ) {
      return true;
    }
    return false;
  }

  haveAInspection(
    day: number,
    month: number,
    year: number,
  ): Inspection[] | null {
    const date: Inspection[] = this.dates?.filter(
      (d: Inspection) =>
        d.date !== undefined &&
        d.date.getDate() === day + 1 &&
        d.date.getMonth() === month &&
        d.date.getFullYear() === year,
    );
    if (date?.length) {
      return date;
    }
    return null;
  }

  isWeekend(day: number) {
    const dayNum = new Date(this.showingYear, this.showingMonth, day).getDay();

    if (dayNum === 0 || dayNum === 6) {
      return true;
    }
    return false;
  }

  monthBefore() {
    if (this.showingMonth > 0) {
      this.showingMonth = this.showingMonth - 1;
    } else {
      this.showingMonth = 11;
      this.showingYear = this.showingYear - 1;
    }
    this.emitMonthChange();
  }

  monthAfter() {
    if (this.showingMonth < 11) {
      this.showingMonth = this.showingMonth + 1;
    } else {
      this.showingMonth = 0;
      this.showingYear = this.showingYear + 1;
    }
    this.emitMonthChange();
  }

  selectDay(day: number, month: number, year: number) {
    this.clickCount++;
    if (this.clickCount === 1) {
      // eslint-disable-next-line angular/timeout-service
      this.singleClickTimer = setTimeout(() => {
        this.clickCount = 0;
        this.selectedDay = `${day}/${month}/${year}`;
      }, 250);
    }
  }

  select(data: any) {
    this.clickCount++;
    if (this.clickCount === 1) {
      // eslint-disable-next-line angular/timeout-service
      this.singleClickTimer = setTimeout(() => {
        this.clickCount = 0;
        this.onSelect.emit(data);
      }, 250);
    }
  }

  open(data: any) {
    this.onOpen.emit(data);
  }

  create(day: number, month: number, year: number) {
    this.selectedDay = `${day}/${month}/${year}`;

    console.log(this.selectedDay);

    this.onCreate.emit(new Date(year, month - 1, day).valueOf());
  }

  formatMinutesTo00(minutes: number) {
    return minutes < 10 ? `0${minutes}` : minutes;
  }

  dragStart(inspection: any) {
    console.log(inspection);

    this.draggedItem = inspection;
  }

  dragEnd(
    _event: any,
    _dd: string,
    _mm: string,
    _yyyy: string,
    _inspeccion: any,
  ) {
    if (this.draggedItem && this.changedDate) {
      this.onChangeDate.emit({
        date: this.changedDate,
        inspection: this.draggedItem,
      });
    }
  }

  onDrop(event: any, dd: string, mm: string, yyyy: string) {
    if (event.target.id !== 'trash') {
      const hour = (this.draggedItem.date as Date).getHours();
      const minutes = (this.draggedItem.date as Date).getMinutes();
      this.changedDate = new Date(`${yyyy}-${mm}-${dd} ${hour}:${minutes}`);
      //this.onSelect.emit({date: new Date(`${yyyy}-${mm}-${dd}`), inspection: this.draggedItem});
    } else {
      this.delete();
    }
  }

  onHover(inspec: any, event: Event) {
    this.selectedInspect = inspec;
    // eslint-disable-next-line angular/timeout-service
    setTimeout(() => {
      if (!this.draggedItem) {
        this.tooltip?.toggle(event);
        //tooltip.show(event, event.target);
      }
    }, 200);
  }

  onLeave(i: number, event: Event) {
    // eslint-disable-next-line angular/timeout-service
    setTimeout(() => {
      this.tooltip?.toggle(event);
    }, 350);
  }

  delete() {
    this.confirmationService.confirm({
      key: 'delete',
      accept: async () => {
        this.onDelete.emit(this.draggedItem);
      },
      reject: (_type: ConfirmEventType) => {},
    });
  }
}
