import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalService } from 'src/app/shared/services/global.service';

@Component({
  selector: 'app-notify-header',
  templateUrl: './notify-header.component.html',
  styleUrls: ['./notify-header.component.scss'],
})
export class NotifyHeaderComponent implements OnInit {
  //@Input() ine?: string;
  //@Input() internalId?: number;

  @Input() aytoName: string = '';

  locality: any;
  constructor(
    public router: Router,
    private http: HttpClient,
    public readonly global: GlobalService,
    private location: Location,
    private route: ActivatedRoute,
  ) {}

  async ngOnInit() {
    //this.ine = this.route.snapshot.queryParams['ine'];
    //this.internalId = this.route.snapshot.queryParams['internalId'];
    //console.log(this.ine)
    //this.locality =  await lastValueFrom(this.http.get(environment.api  + 'locality/' + this.internalId));
  }

  getBack() {
    this.location.back();
  }
}
