<div class="text-sm text-center font-semibold w-full">
    <span class="p-input-icon-right mb-2 w-full">
        <i class="pi pi-search"></i>
        <input class="w-full" type="text" pInputText [formControl]="searchForm" placeholder="Buscar expediente" />
    </span>
    <p-scroller [items]="records" [itemSize]="52" scrollHeight="300px" styleClass="border-1 surface-border">
        <ng-template pTemplate="item" let-item let-options="options">
            <div class="flex align-items-center p-4 item" (click)="recordClick(item)">{{ item.reference }}</div>
        </ng-template>
    </p-scroller>
</div>