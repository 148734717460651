<app-menu-private></app-menu-private>



<div class="m-10">
    <div class="flex item-center w-full mt-14 mb-4">
        <h2 class="flex-grow text-xl font-bold text-am-primary-navy-100">{{'inspections.selectRecord' | translate}}</h2>
    </div>

    <app-record-list [embedded]="true"  [deletable]="false" (onSelected)="selected($event)"></app-record-list>
</div>
