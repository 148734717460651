import {
  ApplicationRef,
  ComponentFactoryResolver,
  Injectable,
  Injector,
} from '@angular/core';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';

@Injectable({
  providedIn: 'root',
})
export class PdfGeneratorService {
  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private appRef: ApplicationRef,
    private injector: Injector,
  ) {}

  // async generatePdf(data: Inspection, filename: string): Promise<void> {
  //   const componentRef = this.componentFactoryResolver
  //     .resolveComponentFactory(PdfTemplateComponent)
  //     .create(this.injector);

  //   componentRef.instance.data = data;

  //   this.appRef.attachView(componentRef.hostView);

  //   const domElem = (componentRef.hostView as EmbeddedViewRef<any>)
  //     .rootNodes[0] as HTMLElement;

  //   const pdf = new jsPDF();
  //   pdf.html(domElem.outerHTML, {
  //     callback: (pdf: any) => {
  //       pdf.output('dataurlnewwindow');
  //       pdf.save(filename + '.pdf');
  //     },
  //   });

  //   this.appRef.detachView(componentRef.hostView);
  //   componentRef.destroy();
  // }

  async generatePdf(html: any, filename: string): Promise<void> {
    const pdf = new jsPDF('p', 'pt', 'a4');
    const options = {
      background: 'white',
      scale: 3,
    };
    await html2canvas(html as HTMLElement, options)
      .then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const bufferX = 0;
        const bufferY = 0;
        const imgProps = pdf.getImageProperties(imgData);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
        pdf.addImage(
          imgData,
          'PNG',
          bufferX,
          bufferY,
          pdfWidth,
          pdfHeight,
          undefined,
          'FAST',
        );
        return pdf;
      })
      .then((pdf) => {
        pdf.output('dataurlnewwindow');
        pdf.save(filename + '.pdf');
      });

    // pdf.html(html, {
    //   callback: (pdf: any) => {
    //     pdf.output('dataurlnewwindow');
    //     pdf.save(filename + '.pdf');
    //   },
    // });
  }

  // async getImageAsBase64(url: string): Promise<string> {
  //   const response = await fetch(url);
  //   const blob = await response.blob();

  //   return new Promise((resolve, reject) => {
  //     const reader = new FileReader();
  //     reader.onloadend = () => resolve(reader.result as string);
  //     reader.onerror = reject;
  //     reader.readAsDataURL(blob);
  //   });
  // }

  //Datos de prueba

  sample = {
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [0.0, 0.0],
          [200.0, 0.0],
          [200.0, 200.0],

          [0.0, 200.0],
          [0.0, 0.0],
        ],
      ],
    },
    inspection_date: '2023-10-25T08:00:00Z',
    withdrawal_date: '2023-11-10T12:30:00Z',
    year: '2023',
    catastral_reference: '123-456-789',
    record_number: 'R123456',
    locality: 'Sample Locality',
    address: '123 Main Street',
    location_use: 'HOUSE',
    owner: 'John Doe',
    inspection_number: 'INSP001',
    communication_council_date: '2023-09-15T14:00:00Z',
    inspection_type: 'Regular Inspection',
    inspection_objetive: 'Asbestos Check',
    inspection_inspector: 'Inspector Smith',
    inspection_sampler: 'Sampler Name',
    inspection_documentation_manager: 'Documentation Manager',
    inspection_duration: '3.5',
    inspection_sampling_duration: '2 hours',
    inspection_actions: 'None',
    inspection_scope: 'Limited',
    inspection_epis_epc: 'EPI123',
    inspection_proceedment_type: 'On-site Inspection',
    inspection_waste: 'INTERNAL',
    inspection_note: 'No issues found.',
    inspection_is_inform: true,
    inspection_formation_note: 'Inspector Qualification Note',
    locations: [
      {
        location_name: 'Location 2',
        MCA: 'YES',
        MCA_management: 'MPCA',
        sample_withdrawal_date: '2023-10-26T09:30:00Z',
        analysis_method: 'Analysis Method',
        material: 'Sample Material',
        pictures_to_add: [
          {
            filename: 'pic1.jpg',
            data: '{{image_data}}',
          },
        ],
        pictures_lab_to_add: [
          {
            filename: 'lab1.jpg',
            data: '{{image_data}}',
          },
        ],
        pictures_schemas_to_add: [
          {
            filename: 'schema1.jpg',
            data: '{{image_data}}',
          },
        ],
        risk_number_exposed: 2,
        risk_technical_accesibility: 4,
        risk_material_status: 1,
        risk_variery: 3,
        risk_fiability: 4,
        risk_level_location: 2,
        risk_level_quantity: 1,
        risk_level_accessibility: 3,
        risk_level_number_exposed: 1,
        risk_level_technical_accesibility: 2,
        risk_level_material_status: 1,
        risk_level_variery: 3,
        risk_level_fiability: 4,
      },
      {
        location_name: 'Location 1',
        MCA: 'YES',
        MCA_management: 'MPCA',
        sample_withdrawal_date: '2023-10-26T09:30:00Z',
        analysis_method: 'Analysis Method',
        material: 'Sample Material',
        pictures_to_add: [
          {
            filename: 'pic1.jpg',
            data: '{{image_data}}',
          },
        ],
        pictures_lab_to_add: [
          {
            filename: 'lab1.jpg',
            data: '{{image_data}}',
          },
        ],
        pictures_schemas_to_add: [
          {
            filename: 'schema1.jpg',
            data: '{{image_data}}',
          },
        ],
        risk_number_exposed: 2,
        risk_technical_accesibility: 4,
        risk_material_status: 1,
        risk_variery: 3,
        risk_fiability: 4,
        risk_level_location: 2,
        risk_level_quantity: 1,
        risk_level_accessibility: 3,
        risk_level_number_exposed: 1,
        risk_level_technical_accesibility: 2,
        risk_level_material_status: 1,
        risk_level_variery: 3,
        risk_level_fiability: 4,
      },
    ],
  };
}
