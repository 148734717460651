import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-popupnopermissions',
  templateUrl: './popupnopermissions.component.html',
  styleUrls: ['./popupnopermissions.component.scss'],
})
export class PopupNoPermissionsComponent implements OnInit {
  @Output() closePop: EventEmitter<void> = new EventEmitter<void>();

  constructor() {}

  ngOnInit(): void {}

  closePopup(): void {
    this.closePop.emit();
  }
}
