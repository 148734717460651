<div class="recover__password flex flex-col items-center justify-center py-24 px-8 gap-4">
  <h2 class="font-bold text-am-2xl leading-am-8 text-am-primary-navy-100">{{'login.recoverPasswordTitle' | translate}}</h2>
  <p class="text-center font-semibold text-sm leading-am-4 text-am-secondary-navy-80 mb-5">P{{'login.recoverPasswordSubtitle1' | translate}}<span class="block">{{'login.recoverPasswordSubtitle2' | translate}}</span></p>
  <form class="forgotten__pass__form flex flex-col gap-9">
    <div class="forgotten__email flex flex-col gap-1.5">
      <label for="email" class="font-medium leading-am-3 text-xs text-am-secondary-navy-60 translate-x-1">{{'login.email' | translate}}</label>
      <input type="text" name="email" id="email" [placeholder]="'login.email' | translate" [formControl]="control"
      [ngClass]="{'border-red-500': control.invalid && control.touched, 'border': true}"
      class="outline-0 w-72 h-11 rounded-am-md border py-1.5 pr-2 pl-4 border-am-secondary-navy-30 placeholder:font-medium placeholder:text-sm placeholder:leading-am-4 placeholder:text-am-neutral-gray focus:border-am-secondary-turquoise-60">
    </div>
    <button class="w-72 h-10 rounded-am-md bg-am-primary-turquoise-100 text-white px-4" (click)="sendEmail()">{{'login.sendEmail' | translate}}</button>
  </form>
</div>
