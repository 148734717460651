
<div class="m-10">

  <div>
    <h1>Consumo para el mes actual</h1>
    <h1 class="mb-4">Contratos activos {{ contratos }}</h1>
    <h1>Base Imponible : <span class="font-bold">{{baseImponible | currency:'':'' }}€</span></h1>
  </div>

  <div>
    <p-table #dt class="" [value]="lineas" [paginator]="true" [rows]="10" [totalRecords]="totalRecords"
    [loading]="loading" [showCurrentPageReport]="true" currentPageReportTemplate="{currentPage} de {totalPages}">

    <ng-template pTemplate="header">
      <tr>
        <th>Descripcion</th>
        <th>Precio</th>
        <th>Descuento</th>
        <th>Cantidad</th>
        <th>Base Imponible</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-data>
      <tr>
        <td>{{ data.description}}</td>
        <td class="font-semibold">{{data.price  | currency:'':'' }} €</td>
        <td class="font-semibold">{{ data.discount | currency:'':'' }} €</td>
        <td>{{ data.quantity }}</td>
        <td class="font-bold">{{ data.baseImponible | currency:'':'' }} €</td>
      </tr>
    </ng-template>


    <ng-template pTemplate="emptymessage" let-columns let-frozen="frozen">
      <tr>
        <td colspan="5">
          <div class="text-center p-10">No hay resultados</div>
        </td>
      </tr>
    </ng-template>

    <ng-template pTemplate="sorticon" let-number>
      <i *ngIf="number == 0" class="pi pi-sort" style="opacity:0.2"></i>
      <i *ngIf="number == 1" class="pi pi-sort-down"></i>
      <i *ngIf="number == -1" class="pi pi-sort-up"></i>
    </ng-template>

  </p-table>

  </div>

</div>
