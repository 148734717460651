<div class="py-24 flex flex-col gap-16" >

  <p>
    {{ 'userZone.notifications_form.message' | translate }}
  </p>


  <div class="flex flex-row text-am-primary-navy-100 font-semibold text-sm leading-am-4 gap-8">
    <p class="flex items-center">{{ 'userZone.notifications_form.consent' | translate }} </p>
    <p-inputSwitch class="flex items-center" [(ngModel)]="checked"></p-inputSwitch>
  </div>

  <button pButton type="button" label="{{ 'userZone.notifications_form.send' | translate}}" class="p-button-success w-1/6" (click)="send()"></button>

</div>
