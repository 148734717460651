
<div class="flex item-center w-full mb-4">
    <h2 class="flex-grow text-xl font-bold text-am-primary-navy-100">{{'admin.inspections' | translate}}</h2>

    @if (canCreate){
      <p-button [hidden]="!canCreate" pRipple size="small" label="{{'inspections.addInspection' | translate}}" icon="pi pi-plus" iconPos="left" (onClick)="addInspection()" ></p-button>
    }
    <!-- <p-button pRipple size="small" [label]="'Añadir inspección'" icon="pi pi-plus" iconPos="left" [routerLink]="'/inspections/create'" [queryParams]="{record:recordId}"></p-button> -->
</div>

<div class="mb-8" style="background: #f0f0f0; text-align: center;">
  <div class="relative">
    <div class="absolute z-10 w-full h-full flex items-center justify-center bg-opacity-50 bg-gray-700"  *ngIf="loadingMap">
      <p-progressSpinner styleClass="custom-spinner"></p-progressSpinner>
    </div>
    <div class="relative z-10">
      <app-map #map loadFromUrl=false [data]="geometries"
        [get_coordinates_on_click]="false"
        [is_geolocation_enabled]="false"
        [enable_address_bar]="true"
        [enable_draw]="true"
        [show_on_click]="true"
        class="flex h-[600px]" (onDrawGeometry)="geometry($event)" (onFinishLoading)="finishLoading()"></app-map>
    </div>
  </div>
</div>

<app-inspections-list (onSelected)="selected($event)" [recordId]="recordId" [geometry]="geometrySelected"></app-inspections-list>
