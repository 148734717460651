import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { debounceTime } from 'rxjs';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-select-record',
  templateUrl: './select-record.component.html',
  styleUrls: ['./select-record.component.scss'],
})
export class SelectRecordComponent {
  searchForm = new FormControl();

  @Output() selected = new EventEmitter<any>();

  records?: any[];
  totalRecords?: number;
  loading: boolean = false;

  constructor(private http: HttpClient) {}

  async ngOnInit() {
    await this.load();

    this.searchForm.valueChanges
      .pipe(debounceTime(200))
      // eslint-disable-next-line @typescript-eslint/no-misused-promises, rxjs/no-async-subscribe
      .subscribe(async (value: any) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        await this.load(value);
      });
  }

  async load(searchTerm: string = '') {
    this.loading = true;
    const page = 0;
    const limit = 50;

    const requestUrl = `${environment.api}record?page=${page}&limit=${limit}&searchTerm=${searchTerm}`;

    this.http
      .get(requestUrl)
      .pipe(debounceTime(200))
      .subscribe((data: any) => {
        this.records = data.data;
        this.totalRecords = data.total;
      });

    this.loading = false;
  }

  recordClick(record: any) {
    this.selected.emit(record);
  }
}
