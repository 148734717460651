import { Component, Renderer2 } from '@angular/core';
import { RecordFormService } from 'src/app/shared/services/record-form.service';

@Component({
  selector: 'app-record-site',
  templateUrl: './record-site.component.html',
  styleUrls: ['./record-site.component.scss'],
})
export class RecordSiteComponent {
  public schedulePopVisible = false;

  public form = this.formService.form.controls.locations;

  constructor(
    private formService: RecordFormService,
    private renderer: Renderer2,
  ) {}

  openPop() {
    this.schedulePopVisible = true;
    // eslint-disable-next-line angular/document-service
    this.renderer.addClass(document.body, 'overflow-hidden');
  }

  closePop() {
    this.schedulePopVisible = false;
    // eslint-disable-next-line angular/document-service
    this.renderer.removeClass(document.body, 'overflow-hidden');
  }

  // FUNCIONALIDAD DE PRUEBA PARA MOSTRAR LISTA DE MCA E INSPECCIONES
  showMCA() {
    this.form.patchValue({
      MCA: 'MCA',
    });
  }

  public MCA = [
    {
      type: 'Material con amianto',
      risk: 2,
      schedule: '15/03/2023',
      withdrawal: false,
      withdrawal_date: null,
    },
    {
      type: 'Material con amianto',
      risk: 1,
      schedule: '15/01/2023',
      withdrawal: true,
      withdrawal_date: '21/01/2023',
    },
    {
      type: 'Material con amianto',
      risk: 0,
      schedule: '15/03/2023',
      withdrawal: true,
      withdrawal_date: '12/12/2022',
    },
  ];

  showInspections() {
    this.form.patchValue({
      inspections: 'INSPECTIONS',
    });
  }

  public INSPECTIONSPENDING = [
    {
      date: '19-02-2023',
      inspector: 'Nombre Apellido',
      type: 'Inspección',
      status: false,
      notes: null,
      inProccess: false,
    },
    {
      date: '19-02-2023',
      inspector: 'Nombre Apellido',
      type: 'Inspección',
      status: false,
      notes: 'Pendinte resultados laboratorio.',
      inProccess: true,
    },
  ];

  public INSPECTIONSFINISH = [
    {
      date: '19-02-2023',
      inspector: 'Nombre Apellido',
      type: 'Inspección',
      status: true,
      notes: 'Pendinte resultados laboratorio.',
      inProcces: null,
    },
    {
      date: '19-02-2023',
      inspector: 'Nombre Apellido',
      type: 'Inspección',
      status: true,
      notes: 'Pendinte resultados laboratorio.',
      inProcces: null,
    },
    {
      date: '19-02-2023',
      inspector: 'Nombre Apellido',
      type: 'Inspección',
      status: true,
      notes: 'Pendinte resultados laboratorio.',
      inProcces: null,
    },
  ];
}
