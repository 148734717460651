/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable rxjs/no-async-subscribe */
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  FormGroup,
} from '@angular/forms';
import { MessageService } from 'primeng/api';
import { lastValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';
import { RequestAproval } from '../request-aproval.interface';
import { ContractStoreService } from '../store.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-new-contract-independent-detail',
  templateUrl: './new-contract-independent-detail.component.html',
  styleUrls: ['./new-contract-independent-detail.component.css'],
})
export class NewContractIndependentDetailComponent implements OnInit {
  client: any = null;
  contract: any = null;

  baseConditions: any[] = [];
  extraConditions: any[] = [];

  stateOptions: any[] = [
    { label: 'No', value: false },
    { label: 'Si', value: true },
  ];

  formExtras: UntypedFormGroup = new FormGroup({});

  constructor(
    private store: ContractStoreService,
    private http: HttpClient,
    private fb: UntypedFormBuilder,
    private messageSvc: MessageService,
    private location: Location,
  ) {}

  async ngOnInit() {
    this.baseConditions = await this.getBySerConditions();
    this.extraConditions = await this.getExtraConditions();

    for (const condition of this.extraConditions) {
      this.formExtras.addControl(condition.id, this.fb.control(false));
    }

    this.store.selectedContract$.subscribe(async (contract) => {
      this.contract = contract;
      this.client = contract.client;
    });
  }

  sendToAproval() {
    const request_body: RequestAproval = {
      contract_id: this.contract.id,
      typeOfContract: 1,
      conditions: this.baseConditions.map((condition) => condition.id),
      extra_conditions: this.extraConditions
        .filter((condition) => this.formExtras.value[condition.id])
        .map((condition) => condition.id),
    };

    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    this.sendRequestAproval(request_body).then(() => {
      this.messageSvc.add({
        severity: 'success',
        summary: 'Solicitud enviada',
        detail: 'La solicitud de aprobación ha sido enviada correctamente',
      });
      this.location.back();
    });
  }

  private async getBySerConditions() {
    return await lastValueFrom(
      this.http.get<any[]>(`${environment.api}contracts/conditions-by-service`),
    );
  }

  private async getExtraConditions() {
    return await lastValueFrom(
      this.http.get<any[]>(
        `${environment.api}contracts/conditions-contract-extras`,
      ),
    );
  }

  private async sendRequestAproval(request: RequestAproval) {
    return await lastValueFrom(
      this.http.post(`${environment.api}contracts/request-approval`, request),
    );
  }
}
