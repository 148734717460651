<div class="notification__content flex flex-col py-5 px-8 gap-8" *ngIf="router.url !== '/sent'">
  <div class="to__list flex gap-4 items-center" *ngIf="!global.embedded">
    <a (click)="getBack()" class="text-am-primary-turquoise-100 font-bold text-sm cursor-pointer">{{'notify.toBack1' | translate}}</a>
  </div>
  <div class="notification__title flex flex-col gap-4">
    <h2 class="text-am-primary-navy-100 font-bold text-am-2xl">{{'notify.title' | translate}}</h2>
    <h3 class="text-am-secondary-navy-80 font-bold text-base">{{'notify.subtitle' | translate}} {{aytoName.toUpperCase()}}</h3>
  </div>
  <div class="notification__steps flex gap-8">
    <div class="step__div flex items-center justify-center gap-4">
      <div class="step__number flex items-center justify-center border rounded-full w-8 h-8 font-bold text-am-lg" [ngClass]="{step__selected: router.url === '/notify/location', step__noSelected: router.url !== '/notify/location'}">
        <span>1</span>
      </div>
      <span class="step__name text-am-secondary-navy-80 font-bold text-sm">{{'notify.step1' | translate}}</span>
    </div>
    <div class="step__div flex items-center justify-center gap-4">
      <div class="step__number flex items-center justify-center border rounded-full w-8 h-8 font-bold text-am-lg" [ngClass]="{step__selected: router.url !== '/notify/location', step__noSelected: router.url === '/notify/location'}">
        <span>2</span>
      </div>
      <span class="step__name text-am-secondary-navy-80 font-bold text-sm">{{'notify.step2' | translate}}</span>
    </div>
  </div>

  <!-- <div>Prueba
  <div routerLink="/notify" routerLinkActive="bg-am-primary-turquoise-100">Hola</div>
  <div [ngClass]="{'bg-am-primary-turquoise-100': router.url === '/notify/contact'}">Mundo</div>
</div>
<router-outlet></router-outlet> -->
