<app-notify-header [aytoName]="localityName()"></app-notify-header>
 <div class="notification__content flex flex-col py-5 px-8 gap-8">
  <div [formGroup]="form" (keydown.enter)="$event.preventDefault()">
    <div>
      <p class="text-am-secondary-navy-60 font-medium mb-2">Seleccione una ubicación en el mapa para continuar</p>
    </div>
  <div class="notification__location flex flex-col gap-6">
    <div class="in__location flex flex-col gap-1 relative">
      <div class="absolute z-10 w-full h-full flex items-center justify-center bg-opacity-50 bg-gray-700"  *ngIf="loadingMap">
        <p-progressSpinner styleClass="custom-spinner"></p-progressSpinner>
      </div>
      <!-- <label for="location" class="text-am-secondary-navy-60 font-medium text-xs">{{'notify.address' | translate}}</label>
      <input type="text" name="address" id="address" [placeholder]="'notify.placeholder1' | translate" formControlName="address"
      [ngClass]="{'border-red-500': form.get('address')?.invalid && form.get('address')?.touched, 'border': true}"
      class="outline-0 border border-am-secondary-navy-30 rounded-am-md gap-2 text-am-primary-navy-100 font-semibold text-sm placeholder:text-am-secondary-turquoise-30 placeholder:font-medium placeholder:text-sm focus:border focus:border-am-secondary-turquoise-60 h-11 py-2.5 pr-2 pl-4.5 w-100"> -->
      <div class="relative z-0">
        <app-map [ine]="locality.ine" [loadFromUrl]="true"  [enable_address_bar]="true" [enable_draw]="false" [draw_is_enabled_on_startup]="false" [is_geolocation_enabled]="true" [get_coordinates_on_click]="true" (onCoordinatesSelected)="setCoordinates($event)" (onFinishLoading)="finishLoading()"  class="flex h-[400px]"></app-map>
      </div>

    </div>
    <div class="in__message flex flex-col gap-1">
      <label for="message" class="text-am-secondary-navy-60 font-medium text-xs">{{'notify.message' | translate}}</label>
      <textarea name="message" id="message" [placeholder]="'notify.placeholder2' | translate"
      formControlName="message"
      [ngClass]="{'border-red-500': form.get('message')?.invalid && form.get('message')?.touched, 'border': true}"
        class="outline-0 border border-am-secondary-navy-30 rounded-am-md gap-2 text-am-primary-navy-100 font-semibold text-sm placeholder:text-am-secondary-turquoise-30 placeholder:font-medium placeholder:text-sm focus:border focus:border-am-secondary-turquoise-60 resize-none h-21 pt-3 pr-2 pb-1.5 pl-4.5 w-auto" maxlength="255"></textarea>
    </div>
    <div class="in__image flex flex-col gap-1">
      <label for="images" class="text-am-secondary-navy-60 font-medium text-xs">{{'notify.photos' | translate}}</label>
        <!-- <div class="imgs__div flex flex-row gap-4">
          <figure class="imgs__preview w-72 h-49 border border-am-secondary-navy-20 m-0 relative" *ngFor="let img of images; let i = index">
            <img [src]="img" alt="" class="w-full h-full">
            <span (click)="removeImg(i)" class="absolute top-4 right-4 cursor-pointer"></span>
            </figure>
        <div class="upFile__div relative inline-block w-72 h-49">
          <span class="bg-am-secondary-navy-5 text-am-secondary-navy-80 font-bold text-sm flex justify-center items-center gap-4 border-2 border-dashed border-am-secondary-navy-60 absolute left-0 right-0 top-0 bottom-0">Subir Imagen</span>
        <input type="file" name="images" id="images" (change)="addImage($event)" class="outline-0 opacity-0 w-full h-full cursor-pointer inline-block">
      </div>
    </div> -->
      <app-image-input [label]="'notify.uploadImg' | translate" [images]="images" (returnImage)="updateImages($event)"
      [error]="form.get('images')?.invalid && form.get('images')?.touched || false"></app-image-input>
    </div>
    <button class="next__button w-72 h-10 py-2.5 px-4 border-0 rounded-am-md bg-am-primary-turquoise-100 text-white cursor-pointer" [disabled]="!coordinatesSelected"  (click)="validate()">{{'notify.next' | translate}}</button>
  </div>
  </div>
</div>

