import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { lastValueFrom } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import {
  PermissionScope,
  PermissionType,
  User,
  UserService,
} from 'src/app/shared/services/user.service';

@Component({
  selector: 'app-inspections-explore-list',
  templateUrl: './inspections-explore-list.component.html',
  styleUrls: ['./inspections-explore-list.component.scss'],
})
export class InspectionsExploreListComponent {
  user?: User;
  canCreate: boolean = false;
  canEdit: boolean = false;
  canDelete: boolean = false;
  canView: boolean = false;
  canViewAll: boolean = false;
  isClient: boolean = false;
  isProvider: boolean = false;
  isOrganization: boolean = false;

  owner_id?: string = '';

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private http: HttpClient,
    private userSvc: UserService,
  ) {}

  recordId: any;
  record: any;
  ine: string = '';

  private clientPermissions() {
    this.canCreate = false;
    this.canEdit = false;
    this.canDelete = false;
    this.canView = true;
    this.canViewAll = true;
  }

  private providerPermissions() {
    this.canCreate =
      this.user?.hasPermission(
        PermissionScope.INSPECTIONS,
        PermissionType.CREATE,
      ) || false;
    this.canEdit =
      this.user?.hasPermission(
        PermissionScope.INSPECTIONS,
        PermissionType.EDIT,
      ) || false;
    this.canDelete =
      this.user?.hasPermission(
        PermissionScope.INSPECTIONS,
        PermissionType.DELETE,
      ) || false;
    this.canView =
      this.user?.hasPermission(
        PermissionScope.INSPECTIONS,
        PermissionType.VIEW,
      ) || false;
    this.canViewAll =
      this.user?.hasPermission(
        PermissionScope.INSPECTIONS,
        PermissionType.VIEW_ALL,
      ) || false;
  }

  private organizationPermissions() {
    this.canCreate = false;
    this.canEdit = false;
    this.canDelete = false;
    this.canView =
      this.user?.hasPermission(
        PermissionScope.INSPECTIONS,
        PermissionType.VIEW,
      ) || false;
    this.canViewAll =
      this.user?.hasPermission(
        PermissionScope.INSPECTIONS,
        PermissionType.VIEW_ALL,
      ) || false;
  }

  async ngOnInit() {
    this.user = this.userSvc.getUser();

    this.isClient = this.user?.is_client || false;
    this.isProvider = this.user?.is_provider || false;
    this.isOrganization = this.user?.is_organization || false;

    this.owner_id = this.user?.owner_id;

    if (this.isClient) {
      this.clientPermissions();
    }

    if (this.isProvider) {
      this.providerPermissions();
      this.owner_id = this.user?.owner_id || '';
    }

    if (this.isOrganization) {
      this.organizationPermissions();
    }

    console.log('can create : ', this.canCreate);
    console.log('owner id : ', this.owner_id);

    // eslint-disable-next-line @typescript-eslint/no-misused-promises, rxjs/no-async-subscribe
    this.recordId = this.route.snapshot.queryParams['record'];

    const data = await lastValueFrom(
      this.http.get(environment.api + 'expedientes/byid/ro/' + this.recordId),
    );

    console.log(data);
    this.record = data;

    this.canCreate = this.canCreate ? this.record.status == 'ACTIVE' : false;

    console.log(this.record);

    this.ine = this.record?.locality.ine;
  }

  async selected(data: any) {
    console.log(data);
    await this.router.navigate(['inspection'], {
      queryParams: { recordId: this.recordId, id: data.agForestId },
    });
  }

  async addInspection() {
    console.log(this.recordId);
    console.log(this.record.locality.ine);

    await this.router.navigate(['/inspections/create'], {
      queryParams: { recordId: this.recordId, ine: this.record.locality.ine },
    });
  }
}
