<div class="fixed inset-0 flex w-full h-full items-center justify-center z-50">
  <div class=" w-[355px] h-[595px] mx-auto bg-white p-4 border border-am-secondary-navy-30 shadow-lg">
    <div class="flex flex-col">
      <img src="/assets/icons/close_IconBlue.svg" class="self-end justify-end cursor-pointer" (click)="close()">
  </div>
  <div class="pt-3 pb-8 px-1 flex flex-col gap-2 justify-center">
    <div class="flex gap-5">
      <h2 class="font-medium text-am-lg leading-am-6 text-am-secondary-navy-80 w-4/5">{{inspection.data?.record?.locality?.name || '--'}}</h2>
      <div class="w-[17.18px] h-[17.18px] rounded-full bg-black mt-1"></div>
    </div>
    <div>
      <span class="font-normal text-xl leading-[23.48px] text-am-primary-turquoise-100">{{inspection.date.toLocaleDateString()}}</span>
    </div>
    <div>
      <p-calendar
        inputId="calendar-timeonly"
        [showTime]="true"
        [(ngModel)]="inspection_time"
        [timeOnly]="true" />
      <!--<span class="font-normal text-xl leading-[23.48px] text-am-secondary-navy-80">{{inspection.date.toLocaleTimeString([], {hourCycle: 'h23', hour: '2-digit', minute: '2-digit'})}}</span>-->
    </div>
    <div class="flex gap-4">
      <img src="/assets/icons/pin-map.svg" alt="">
      <div class="flex flex-col py-6">
        <span class="font-normal text-base leading-[18.78px] text-am-secondary-navy-80">{{inspection.data?.address || '--'}}</span>
      </div>
    </div>
    <!--Inspector-->
    <div class="flex flex-col pl-[17px] mb-4 p-input-underline max-w-[100%]">
      <label for="inspector" class="text-am-secondary-navy-60 ">Inspector</label>
      <app-user-input class="w-[90%]" [control]="form.get('inspector')"></app-user-input>
    </div>
    <textarea [disabled]="true" [placeholder]="'inspections.placeholder1' | translate" class="self-center resize-none w-[294px] h-[131px] border border-am-secondary-navy-30 rounded-am-md pt-3 pr-2 pb-1.5 pl-[17px] text-sm leading-am-4 text-am-primary-navy-100 placeholder:text-sm placeholder:leading-am-4 font-semibold placeholder:text-am-secondary-navy-30 outline-none focus:border-2 focus:border-am-primary-turquoise-100">{{ inspection.data?.relevantInformation }}</textarea>
  </div>
  <div class="flex gap-6 justify-center">
    <button (click)="selected(inspection.data)" [hidden]="!canEdit" class="flex items-center gap-2 border border-am-primary-turquoise-100 rounded-am-md h-[40px] py-[10px] px-[16px] font-bold text-sm leading-am-4 text-am-primary-turquoise-100"><img src="/assets/icons/edit.svg" alt="">{{'inspections.edit' | translate}}</button>
    <button (click)="openInspection(inspection.data)" class="rounded-am-md bg-am-primary-turquoise-100 font-bold text-white text-sm leading-am-4 h-[40px] py-[10px] px-[16px]">{{'inspections.initInspection' | translate}}</button>
  </div>
    </div>
</div>
