import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './public/home/home.component';
import { NotifyLocationComponent } from './notification/notify-location/notify-location.component';
import { LocalityComponent } from './public/locality/locality.component';
import { NotifyContactComponent } from './notification/notify-contact/notify-contact.component';
import { NotifySentComponent } from './notification/notify-sent/notify-sent.component';
import { LoginComponent } from './login/login/login.component';
import { RecoverComponent } from './login/recover/recover.component';
import { SentCodeComponent } from './login/sent-code/sent-code.component';
import { NewPassComponent } from './login/new-pass/new-pass.component';

import { UserInfoComponent } from './account/base/user-info.component';
import { MyAccountComponent } from './account/profile/my-account.component';

import { RecordSiteComponent } from './records/record-site/record-site.component';

import { UserPermissionsComponent } from './account/permissions/user-permissions.component';
import { AdminListClientsComponent } from './admin/clients/list/admin-list-clients.component';
import { AdminUpsertUsersComponent } from './admin/users/upsert/admin-upsert-users.component';
import { AdminUpsertClientsComponent } from './admin/clients/upsert/admin-upsert-clients.component';
import { RecordsListComponent } from './records/list/records-list.component';
import { UpsertRecordComponent } from './records/upsert/upsert-record.component';
import { InspectionsRecordSelectComponent } from './inspections/record-select/inspections-record-select.component';
import { InspectionsExploreComponent } from './inspections/explore/inspections-explore.component';
import { InspectionsExploreListComponent } from './inspections/explore/list/inspections-explore-list.component';
import { InspectionsExploreMapComponent } from './inspections/explore/map/inspections-explore-map.component';
import { InspectionsCreateComponent } from './inspections/create/inspections-create.component';
import { InspectionEditComponent } from './inspections/edit/inspection-edit.component';
import { InspectionsExploreCalendarComponent } from './inspections/explore/calendar/inspections-explore-calendar.component';
import { ReportComponent } from './inspections/edit/report/report.component';
import { AdminNavigationComponent } from './admin/admin-navigation.component';
import { AdminListProvidersComponent } from './admin/providers/list/admin-list-providers.component';
import { AdminUpsertProvidersComponent } from './admin/providers/upsert/admin-upsert-providers.component';
import { ProviderClientsListProvidersComponent } from './admin/providerclients/list/provider-clients-list-providers.component';
import { ProviderClientsUpsertProvidersComponent } from './admin/providerclients/upsert/provider-clients-upsert-providers.component';
import { AccountNotificationsComponent } from './account/notifications/account-notifications.component';
import { SecurityComponent } from './account/security/security.component';
import { PoliciesComponent } from './account/policies/policies.component';
import { OrganizationListUsersComponent } from './admin/organization/list/organization-list-users.component';
import { OrganizationUpsertUsersComponent } from './admin/organization/upsert/organization-upsert-users.component';
import { RetiradasComponent } from './retiradas/retiradas.component';
import { DashboardComponent } from './retiradas/dashboard/dashboard.component';
import { RetiradasListComponent } from './retiradas/retiradas/retiradas-list.component';
import { RetiradasInspectionListComponent } from './retiradas/retiradas-inspection-list/retiradas-inspection-list.component';
import { RetiradasFormComponent } from './retiradas/retiradas-form/retiradas-form.component';
import { PrivacidadComponentES } from './public/privacidad/privacidad_es.component';
import { AvisoLegalComponentES } from './public/aviso-legal/aviso-legal_es.component';
import { AvisoLegalComponentEN } from './public/aviso-legal/aviso-legal_en.component';
import { PendingContractsComponent } from './account/pending-contracts/pending-contracts.component';
import { NewContractLinkedDetailComponent } from './account/pending-contracts/new-contract-linked-detail/new-contract-linked-detail.component';
import { NewContractIndependentDetailComponent } from './account/pending-contracts/new-contract-independent-detail/new-contract-independent-detail.component';
import { AprovalPendingContractsComponent } from './admin/pending-contracts/aproval-pending-contracts.component';
import { AproveContractLinkedDetailComponent } from './admin/pending-contracts/aprove-contract-linked-detail/aprove-contract-linked-detail.component';
import { AproveContractIndependentDetailComponent } from './admin/pending-contracts/aprove-contract-independent-detail/aprove-contract-independent-detail.component';
import { PendingAuthContractsComponent } from './account/pending-auth-contracts/pending-auth-contracts.component';
import { AdminPendingAuthContractsComponent } from './admin/pending-auth-contracts/admin-pending-auth-contracts.component';
import { CurrentBillComponent } from './account/current-bill/current-bill.component';
import { PendingApprovalContractsComponent } from './account/pending-approval-contracts/pending-approval-contracts.component';
import { CurrentContractsComponent } from './account/current-contracts/current-contracts.component';
import { EditContractIndependentDetailComponent } from './account/current-contracts/edit-contract-independent-detail/edit-contract-independent-detail.component';
import { EditContractLinkedDetailComponent } from './account/current-contracts/edit-contract-linked-detail/edit-contract-linked-detail.component';
import { ReAprovalContractsComponent } from './admin/pending-reapproval-contracts/reaproval-contracts.component';
import { ReAproveContractLinkedDetailComponent } from './admin/pending-reapproval-contracts/reaprove-contract-linked-detail/reaprove-contract-linked-detail.component';
import { ReAproveContractIndependentDetailComponent } from './admin/pending-reapproval-contracts/reaprove-contract-independent-detail/reaprove-contract-independent-detail.component';
import { AdminStoredBillsComponent } from './admin/stored-bills/admin-stored-bills.component';
import { AdminCurrentBillComponent } from './admin/stored-bills/current-bill/admin-current-bill.component';
import { StoredBillsComponent } from './account/stored-bills/stored-bills.component';
import { StoredBillDetailComponent } from './account/stored-bills/current-bill/stored-bill-detail.component';
import { EmpresasRetiradasListComponent } from './admin/empresas-retirada/list/empresas-retiradas-list.component';
import { EmpresasRetiradasUpsertComponent } from './admin/empresas-retirada/upsert/empresas-retiradas-upsert.component';
import { PrivacidadComponentEN } from './public/privacidad/privacidad_en.component';

const routes: Routes = [
  //Public
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: 'home', component: HomeComponent },
  { path: 'privacidad/es', component: PrivacidadComponentES },
  { path: 'privacidad/en', component: PrivacidadComponentEN },
  { path: 'aviso-legal/es', component: AvisoLegalComponentES },
  { path: 'aviso-legal/en', component: AvisoLegalComponentEN },
  { path: 'locality/:ine', component: LocalityComponent },

  { path: 'notify', redirectTo: '/notify/location', pathMatch: 'full' },
  { path: 'notify/location', component: NotifyLocationComponent },
  { path: 'notify/contact', component: NotifyContactComponent },
  { path: 'notify/sent', component: NotifySentComponent },
  { path: 'login', component: LoginComponent },
  { path: 'recoverpass', component: RecoverComponent },
  { path: 'recoverpass/sentcode', component: SentCodeComponent },
  { path: 'recoverpass/newpass', component: NewPassComponent },
  {
    path: 'account',
    component: UserInfoComponent,
    children: [
      { path: 'profile', component: MyAccountComponent },
      { path: 'permissions', component: UserPermissionsComponent },
      { path: 'company', component: AdminUpsertProvidersComponent },
      { path: 'company/users', component: AdminUpsertUsersComponent },
      {
        path: 'company/clients',
        component: ProviderClientsListProvidersComponent,
      },
      {
        path: 'company/clients/upsert',
        component: ProviderClientsUpsertProvidersComponent,
      },
      { path: 'notifications', component: AccountNotificationsComponent },
      { path: 'security', component: SecurityComponent },
      { path: 'shareddata', component: PoliciesComponent },
      { path: 'pending-contracts', component: PendingContractsComponent },
      {
        path: 'pending-approval-contracts',
        component: PendingApprovalContractsComponent,
      },
      {
        path: 'pending-contracts/new-linked',
        component: NewContractLinkedDetailComponent,
      },
      {
        path: 'pending-contracts/new-independent',
        component: NewContractIndependentDetailComponent,
      },
      {
        path: 'pending-auth-contracts',
        component: PendingAuthContractsComponent,
      },
      { path: 'current-contracts', component: CurrentContractsComponent },
      { path: 'current-bill', component: CurrentBillComponent },
      {
        path: 'current-contracts/edit-linked',
        component: EditContractLinkedDetailComponent,
      },
      {
        path: 'current-contracts/edit-independent',
        component: EditContractIndependentDetailComponent,
      },
      { path: 'bills/emitted', component: StoredBillsComponent },
      { path: 'bills/emitted/detail', component: StoredBillDetailComponent },
    ],
  },
  {
    path: 'users',
    //component: UserInfoComponent,
    children: [
      { path: 'company', component: AdminUpsertProvidersComponent },
      { path: 'company/users', component: AdminUpsertUsersComponent },
    ],
  },
  //Private
  //{ path: 'admin', redirectTo: '/admin/clients', pathMatch: 'full' },
  {
    path: 'admin',
    component: AdminNavigationComponent,
    children: [
      { path: 'users', component: OrganizationListUsersComponent },
      { path: 'users/upsert', component: OrganizationUpsertUsersComponent },
      { path: 'clients', component: AdminListClientsComponent },
      { path: 'clients/upsert', component: AdminUpsertClientsComponent },
      { path: 'company', component: AdminUpsertProvidersComponent },
      { path: 'company/users', component: AdminUpsertUsersComponent },
      { path: 'providers', component: AdminListProvidersComponent },
      { path: 'providers/upsert', component: AdminUpsertProvidersComponent },
      {
        path: 'providerclients',
        component: ProviderClientsListProvidersComponent,
      },
      {
        path: 'providerclients/upsert',
        component: ProviderClientsUpsertProvidersComponent,
      },
      {
        path: 'pending-contracts',
        component: AprovalPendingContractsComponent,
      },
      {
        path: 'pending-contracts/aprove-linked',
        component: AproveContractLinkedDetailComponent,
      },
      {
        path: 'pending-contracts/aprove-independent',
        component: AproveContractIndependentDetailComponent,
      },
      {
        path: 'pending-auth-contracts',
        component: AdminPendingAuthContractsComponent,
      },
      {
        path: 'pending-reapproval-contracts',
        component: ReAprovalContractsComponent,
      },
      {
        path: 'pending-contracts/reaprove-linked',
        component: ReAproveContractLinkedDetailComponent,
      },
      {
        path: 'pending-contracts/reaprove-independent',
        component: ReAproveContractIndependentDetailComponent,
      },
      { path: 'bills/emitted', component: AdminStoredBillsComponent },
      { path: 'bills/emitted/detail', component: AdminCurrentBillComponent },
      {
        path: 'withdrawalcompanies',
        component: EmpresasRetiradasListComponent,
      },
      {
        path: 'withdrawalcompanies/upsert',
        component: EmpresasRetiradasUpsertComponent,
      },
    ],
  },

  { path: 'records', component: RecordsListComponent },
  { path: 'records/upsert', component: UpsertRecordComponent },
  { path: 'records/site', component: RecordSiteComponent },
  { path: 'records/site/:id', component: RecordSiteComponent },

  { path: 'inspections', component: InspectionsRecordSelectComponent },
  { path: 'inspections/create', component: InspectionsCreateComponent },
  {
    path: 'inspections',
    component: InspectionsExploreComponent,
    children: [
      { path: 'list', component: InspectionsExploreListComponent },
      { path: 'map', component: InspectionsExploreMapComponent },
      { path: 'calendar', component: InspectionsExploreCalendarComponent },
    ],
  },
  { path: 'inspection', component: InspectionEditComponent },
  { path: 'inspection/report', component: ReportComponent },

  {
    path: 'withdrawals',
    component: RetiradasComponent,
    children: [
      { path: 'dashboard', component: DashboardComponent },
      { path: 'withdrawal', component: RetiradasListComponent },
      { path: 'withdrawal/list', component: RetiradasInspectionListComponent },
      { path: 'withdrawal/upsert', component: RetiradasFormComponent },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { bindToComponentInputs: true })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
