
<div class="flex flex-col gap-5 py-7 w-[770px]">
  <div class="permissions__table grid-flow-row auto-rows-max">
    <div class="permissions__rows grid grid-cols-7 pb-2 items-center">
      <h3 class="invisible col-span-3">Labels</h3>
      <div class="checks__permissions__div col-span-4 grid grid-cols-5 justify-items-center">
        <span class="font-normal text-xs leading-am-3 text-am-primary-navy-100" *ngFor="let type of types">{{'ui.tables.profile.permissions.perms.' + types_es[type] | translate}}</span>
        </div>
      </div>
    <div class="permissions__rows grid grid-cols-7 border-b border-am-secondary-navy-10 py-5 items-center" *ngFor="let scope of scope_literal">
      <h3 class="col-span-3 text-am-primary-navy-100 text-sm leading-am-4 font-semibold">{{'ui.tables.profile.permissions.entities.' + scopes_es[scope] | translate}}</h3>
      <div class="checks__permissions__div col-span-4 grid grid-cols-5 justify-items-center">

        <p-checkbox  [formControl]="form.get(scope)?.get(type)" *ngFor="let type of types" binary="true"></p-checkbox>


      </div>
    </div>
  </div>
  </div>
