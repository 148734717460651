<div class="py-16 flex flex-col gap-8" >
  <div class="flex flex-col">

    <div class="flex flex-col gap-6 rounded-md border-2 bg-primary-turquoise-100 p-24">
      <p>
        {{ 'userZone.security_form_pass.message' | translate }}
      </p>
      <div class="flex flex-col gap-4 w-1/3">
        <div class="in__password flex flex-col gap-2">
          <label for="password" class="font-medium text-xs leading-am-3 text-am-secondary-navy-60 ml-1">{{'login.password' | translate}}</label>
          <p-password [(ngModel)]="oldPassword" [feedback]="false"></p-password>
        </div>

        <div class="in__password flex flex-col gap-2">
          <label for="password" class="font-medium text-xs leading-am-3 text-am-secondary-navy-60 ml-1"> {{ 'userZone.security_form_pass.newpass' | translate }}</label>
          <p-password [(ngModel)]="newPassword1" [feedback]="false"></p-password>
        </div>

        <div class="in__password flex flex-col gap-2">
          <label for="password" class="font-medium text-xs leading-am-3 text-am-secondary-navy-60 ml-1"> {{ 'userZone.security_form_pass.repeatpass' | translate }}</label>
          <p-password [(ngModel)]="newPassword2" [feedback]="false"></p-password>
        </div>

        <button pButton type="button" label=" {{ 'userZone.security_form_pass.change' | translate }}" class="p-button-success min-w-1/3 mt-4" (click)="changePassword()"></button>
      </div>

    </div>

  </div>

  <div class="flex flex-col gap-8 rounded-md border-2 bg-primary-turquoise-100 p-24">
    <p class="font-bold">
      {{ 'userZone.security_form_delete.message' | translate }}
    </p>
    <div class="flex flex-row gap-6">
      <input type="text" [(ngModel)]="deletePassword" pInputText maxlength="20">

      <button pButton type="button" label=" {{ 'userZone.security_form_delete.delete' | translate }}" class="p-button-danger" (click)="deleteAccount()"></button>

    </div>

  </div>
</div>
