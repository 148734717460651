import { Component } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss'],
})
export class ReportComponent {
  steps = [
    {
      label: 'Entorno',
    },
    {
      label: 'Puntuacion',
    },
  ];
  questions = [
    {
      name: 'Localización del equipo o instalación',
      options: [
        {
          name: 'Al aire libre o totalmente encapsulado',
          value: 1,
        },
        {
          name: 'En espacios cerrados, aunque ocasionalmente accesibles',
          value: 5,
        },
        {
          name: 'Habitaciones entre 250 y 1000 metros cúbicos',
          value: 3,
        },
        {
          name: 'Espacios menores de 250 metros cúbicos. Espacios que actúan como plenum en un sistema de acondicionamiento de aire independientemente del tamaño, o materiales sometidos a corrientes de aire',
          value: 10,
        },
      ],
    },
    {
      name: 'Extensión/ cantidad del material con amianto',
      options: [
        {
          name: 'Pequeñas cantidades, 1 unidad (junta, cuerda, cordón, placa, bajante)',
          value: 1,
        },
        {
          name: 'Menos de 10 metros lineales y/o cuadrados',
          value: 5,
        },
        {
          name: 'Entre 11 y 50 metros lineales y/o cuadrados',
          value: 10,
        },
        {
          name: 'Más de 51 metros cuadrados y/o lineales',
          value: 15,
        },
      ],
    },
    {
      name: 'Accesibilidad a las personas usuarias al material con amianto',
      options: [
        {
          name: 'Inaccesible en el uso cotidiano de la instalación',
          value: 10,
        },
        {
          name: 'Difícilmente accesible en el uso cotidiano de la instalación (accesibilidad baja). Altura superior a 2,2 metros. Posibilidad de interacción ocasional del MCA',
          value: 5,
        },
        {
          name: 'Fácilmente accesible en el uso cotidiano de la instalación (accesibilidad media). Posibilidad de interacción fácil del MCA',
          value: 1,
        },
        {
          name: 'Accesible para cualquier uso accesibilidad alta). Altura inferior a 2,2 metros. Posibilidad de interacción frecuente del MCA',
          value: 1,
        },
      ],
    },
    {
      name: 'Número de personas potencialmente expuestas (incluye personas trabajadoras y usuarias)',
      options: [
        {
          name: 'Ninguno',
          value: 10,
        },
        {
          name: 'De 1 a 3',
          value: 5,
        },
        {
          name: 'De 4 a 10',
          value: 1,
        },
        {
          name: 'Más de 10',
          value: 1,
        },
      ],
    },
    {
      name: 'Accesibilidad técnica',
      options: [
        {
          name: 'Nunca se realizan actividades que impliquen manipular o perturbar el material',
          value: 10,
        },
        {
          name: 'Se realizan actividades que implican circulación por la zona de localización del material con amianto, o actuaciones que implican manipular el material muy ocasionalmente (menos de 1 al mes)',
          value: 5,
        },
        {
          name: 'Se realizan actividades que implican circulación por la zona de localización del material con amianto, o actuaciones que implican manipular el material muy ocasionalmente (menos de 1 al mes)',
          value: 1,
        },
      ],
    },
    {
      name: 'Estado del material (o posible deterioro)',
      options: [
        {
          name: 'Buen estado: no hay daños visibles',
          value: 10,
        },
        {
          name: 'Poco daño: pequeños arañazos o marcas, bordes rotos en tableros, losetas, etc.',
          value: 5,
        },
        {
          name: 'Daño medio: rotura significativa de materiales o grandes roturas en pequeñas áreas donde los materiales dañados pueden liberar fibras, daños por agua visibles',
          value: 1,
        },
        {
          name: 'Gran daño o desprendimiento de las láminas que conforman los materiales, proyectados o "flocages" y aislamientos térmicos. Escombros de amianto visibles.',
          value: 0,
        },
      ],
    },
    {
      name: 'Variedad de amianto',
      options: [
        {
          name: 'Solo crisotilo',
          value: 10,
        },
        {
          name: 'Presencia de anfiboles (crocidolita, amosita, tremolita, antofilita y/o actinolita)',
          value: 5,
        },
      ],
    },
    {
      name: 'Friabilidad del material',
      options: [
        {
          name: 'Compuestos reforzados conteniendo amianto, plásticos reforzados, resinas, vinilos, etc. Material NO FRIABLE, duro y que no puede ser dañado con la mano. Se precisa un objeto para dañarlo. El material esta integro',
          value: 10,
        },
        {
          name: 'Proyectados, "flocages" o aislantes sellados; tableros aislantes (con la cara expuesta pintada o encapsulada), amianto cemento, etc. Material de BAJA FRIABILIDAD, se puede dañar con la mano, aunque con dificultad. El material puede resultar dañado por medio de impactos. El material tiene base de cemento y deja gránulos en la mano, pero no polvo. El material ha perdido su integridad en parte',
          value: 5,
        },
        {
          name: 'Tableros aislantes sin encapsular o aislamientos y proyectados o "flocages" encapsulados. Material de FRIABILIDAD MEDIA, es fácil de fragmentar por la simple presión de la mano. Se pueden retirar trozos simplemente a mano. El material tiene base de cemento y deja un residuo polvoriento en las manos al frotarlo',
          value: 1,
        },
        {
          name: 'Aislamientos y proyectados o "flocages" sin encapsular. Material de ALTA FRIABILIDAD, se fragmenta muy fácilmente solo con tocarlo, pudiendo desprenderse',
          value: 0,
        },
      ],
    },
  ];

  form?: any;

  constructor() {
    this.form = new FormGroup(
      this.questions.reduce((acc: any, question, index: any) => {
        acc[index] = new FormControl(0);
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return acc;
      }, {}),
    );
  }
}
