
<app-menu-private></app-menu-private>

<div class="m-10" >
    <!--Main Administracion-->
    <!--Div con Titulo y boton añadir usuario-->
    <div class="flex item-center w-full mt-14 mb-4">
        <h2 class="flex-grow text-xl font-bold text-am-primary-navy-100">{{'records.records' | translate}}</h2>

        <p-button [hidden]="!canCreate" pRipple size="small" [label]="'records.addRecord' | translate" icon="pi pi-plus" iconPos="left" routerLink="/records/upsert" ></p-button>
    </div>

    <app-record-list [owner_id]="owner_id"  [deletable]="true" (onSelected)="selected($event)"></app-record-list>
</div>
