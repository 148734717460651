import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable, catchError, throwError } from 'rxjs';
import { UserService } from './shared/services/user.service';
import { ToastService } from './shared/services/toast.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private readonly auth: UserService,
    private readonly toastService: ToastService,
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    const clonedRequest = request.clone({
      headers: request.headers.set(
        'Authorization',
        'Bearer ' + this.auth.user?.access_token || '',
      ),
    });

    return next.handle(clonedRequest).pipe(
      catchError((error: unknown) => {
        this.toastService.displayHttpError(error);
        return throwError(error);
      }),
    );
  }
}
