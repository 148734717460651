import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-image-input',
  templateUrl: './image-input.component.html',
  styleUrls: ['./image-input.component.scss'],
})
export class ImageInputComponent {
  @Input() public images: any[] = [];
  @Input() public error: boolean = false;
  @Input() public label: string = 'Subir Imagen';
  @Output() returnImage = new EventEmitter<string[]>();

  async toBase64(file: any) {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.addEventListener('loadend', () => {
        resolve(reader.result);
      });
      reader.readAsDataURL(file);
    });
  }

  async addImage(e: any) {
    const capturedImg = e.target.files[0];
    const img64 = await this.toBase64(capturedImg);
    this.images.push(img64);
    this.returnImage.emit(this.images);
  }

  removeImg(index: number) {
    this.images.splice(index, 1);
    this.returnImage.emit(this.images);
  }
}
