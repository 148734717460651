<div class="m-8">
<div style="text-align: left;">
  <div >
      <h1><strong>Aviso Legal</strong></h1>
  </div>
</div>
<br>
<div style="text-align: left;">
  <div >
      <p >El uso que usted haga del sitio web est&aacute; sujeto a los siguientes t&eacute;rminos y condiciones: Agforest S.L. (la &laquo;Empresa&raquo;) se reserva el derecho de cambiar estos t&eacute;rminos en cualquier momento con efecto inmediato al publicarlos en este sitio web.</p>
      <br>
      <p ><strong><strong >Propiedad Intelectual</strong></strong></p>
      <p >Los derechos de autor de los textos, im&aacute;genes, pantallas y dem&aacute;s materiales de este Sitio Web son propiedad de la Empresa o de terceros. A menos que se autorice a continuaci&oacute;n, ninguno de los materiales puede copiarse, reproducirse, distribuirse, descargarse, mostrarse o transmitirse de ninguna forma o por ning&uacute;n medio sin el permiso previo por escrito de la Empresa o del propietario de los derechos de autor. Puede ver, copiar, descargar e imprimir documentos de la Empresa en este sitio web &uacute;nicamente para fines personales, no comerciales e informativos, siempre que no modifique los documentos.</p>
      <p ><br></p>
      <p ><strong><strong >Fines informativos &uacute;nicamente</strong></strong></p>
      <p >Los materiales y la informaci&oacute;n en este sitio web han sido preparados o ensamblados por la Empresa y est&aacute;n destinados &uacute;nicamente a fines informativos. Parte de la informaci&oacute;n puede estar fechada y puede no reflejar los desarrollos legales m&aacute;s actuales. Cualquier opini&oacute;n expresada en o a trav&eacute;s de este sitio web es la opini&oacute;n del autor individual y puede que no refleje las opiniones de la Empresa o de cualquier otro abogado individual.</p>
      <p ><br></p>
      <p ><strong><strong >Sin relaci&oacute;n abogado-cliente</strong></strong></p>
      <p >La transmisi&oacute;n de la informaci&oacute;n contenida en este sitio web no tiene como objetivo crear una relaci&oacute;n abogado-cliente entre usted y la Empresa. Este sitio web contiene informaci&oacute;n sobre asuntos legales y no reemplaza el asesoramiento legal de un abogado calificado con licencia en la jurisdicci&oacute;n correspondiente. La Empresa renuncia expresamente a toda responsabilidad con respecto a las acciones tomadas o no tomadas en base a la informaci&oacute;n contenida o faltante en este sitio web.</p>
      <p ><br></p>
      <p ><strong><strong >Uso en otras jurisdicciones</strong></strong></p>
      <p >Los abogados de la Empresa ejercen la abogac&iacute;a solo en jurisdicciones donde estamos autorizados para hacerlo. No buscamos representar a nadie en ninguna jurisdicci&oacute;n donde este sitio web no cumpla o sea inconsistente con las reglas que rigen las comunicaciones sobre servicios legales en un estado en particular.</p>
      <p ><br></p>
      <p ><strong><strong >Sin garant&iacute;as/limitaci&oacute;n de responsabilidad</strong></strong></p>
      <p >La informaci&oacute;n proporcionada en este sitio web se proporciona &laquo;tal cual&raquo; sin garant&iacute;a de ning&uacute;n tipo, ya sea expresa o impl&iacute;cita, incluidas, entre otras, las garant&iacute;as de comerciabilidad, idoneidad para un prop&oacute;sito particular o no infracci&oacute;n. Peri&oacute;dicamente a&ntilde;adimos, cambiamos, mejoramos y actualizamos la informaci&oacute;n y los documentos de este sitio web. La Empresa no asume ninguna responsabilidad por cualquier error u omisi&oacute;n en el contenido de este sitio web.</p>
      <p ><br></p>
      <p ><strong><strong >Enlaces de terceros</strong></strong></p>
      <p >De vez en cuando, podemos publicar enlaces a sitios de Internet mantenidos por terceros. No controlamos ninguna informaci&oacute;n, productos o servicios en dichos sitios vinculados, ni respaldamos dicha informaci&oacute;n, productos o servicios mediante la publicaci&oacute;n de esos enlaces. La Empresa no hace representaciones ni garant&iacute;as con respecto a la confiabilidad de la informaci&oacute;n contenida o a la que se puede acceder desde dichos sitios web, ni la Empresa representa ni garantiza que cualquier sitio web accesible a trav&eacute;s de este sitio est&eacute; libre de virus, cookies u otros. contenido electr&oacute;nico no deseado. Usted navega por Internet bajo su propio riesgo.</p>
      <p ><br></p>
      <p ><strong><strong >Privacidad</strong></strong></p>
      <p >Entendemos que la privacidad y la protecci&oacute;n de su informaci&oacute;n personal son importantes para usted. Si elige registrarse o enviar informaci&oacute;n a este sitio web, acepta el uso de esos datos de acuerdo con esta declaraci&oacute;n de privacidad. No somos responsables y no hacemos ninguna declaraci&oacute;n sobre las pol&iacute;ticas de privacidad de los sitios vinculados a este sitio, que pueden tener diferentes pol&iacute;ticas de privacidad.</p>
      <p ><br></p>
      <p >No debe enviar informaci&oacute;n confidencial o informaci&oacute;n que considere privilegiada a la Empresa hasta que haya recibido un reconocimiento por escrito de la Empresa de que la Empresa ha aceptado brindarle servicios legales a usted o a su empresa. El contenido de cualquier correspondencia que env&iacute;e por correo electr&oacute;nico o Internet no se considerar&aacute; confidencial a menos que haya sido confirmado como cliente.</p>
      <p ><br></p>
      <p ><strong><strong >Recopilaci&oacute;n de informaci&oacute;n</strong></strong></p>
      <p >No tiene que enviar ninguna informaci&oacute;n personal para usar este sitio web. El sitio recopila solo informaci&oacute;n de identificaci&oacute;n personal proporcionada por los visitantes de forma voluntaria, que puede incluir su nombre, cargo actual, direcci&oacute;n de correo electr&oacute;nico y n&uacute;mero de tel&eacute;fono o fax.</p>
      <p ><br></p>
      <p ><strong><strong >Cookies y balizas web</strong></strong></p>
      <p>Podemos usar cookies del navegador para recopilar informaci&oacute;n no identificable personalmente. Las cookies son
        peque&ntilde;os archivos de texto que se colocan en el disco duro de una computadora que permiten a Agforest S.L. y/o
        al anfitri&oacute;n de su sitio web registrar cu&aacute;ntas veces un usuario o computadoras dentro de la red de
        computadoras de un usuario han visitado el sitio web, la cantidad de veces que se ha accedido a varias p&aacute;ginas
        del sitio web y otra informaci&oacute;n similar. Nuestras p&aacute;ginas web tambi&eacute;n pueden incorporar balizas
        web o tecnolog&iacute;as de seguimiento similares para permitirnos rastrear c&oacute;mo se utiliza el sitio web.
        Podemos combinar su informaci&oacute;n con informaci&oacute;n similar recopilada de otros usuarios para ayudar a
        mejorar el servicio del sitio web. No utilizamos estos datos para crear o mantener un perfil personal confidencial de
        usted o para recopilar informaci&oacute;n confidencial de usted o sobre usted. La mayor&iacute;a de los navegadores
        permiten que las personas rechacen las cookies.</p>
      <p ><br></p>
      <p ><strong><strong >Uso de la informaci&oacute;n</strong></strong></p>
      <p >La informaci&oacute;n que env&iacute;e a Agforest S.L. solo se utilizar&aacute;, si se utiliza, para fines limitados. Para<br>por ejemplo, cuando se registra para recibir nuestros boletines o solicita comunicaciones futuras sobre actualizaciones o eventos de Agforest S.L., podemos usar la informaci&oacute;n que env&iacute;a para crear un perfil personal para personalizar nuestra respuesta a otras solicitudes o consultas. Tambi&eacute;n podemos usar la informaci&oacute;n para personalizar su experiencia en el sitio web. Nos aseguraremos de que cualquier actividad de marketing en la que participemos cumpla con la ley aplicable. Puede solicitar que dejemos de enviarle materiales electr&oacute;nicos en cualquier momento.</p>
      <p ><br></p>
      <p ><strong><strong >Divulgaci&oacute;n de informaci&oacute;n a terceros</strong></strong></p>
      <p >No divulgamos ninguna informaci&oacute;n personal no p&uacute;blica sobre clientes o visitantes de nuestro sitio web a nadie, excepto seg&uacute;n lo permita la ley.</p>
      <p ><br></p>
      <p ><strong><strong >Seguridad de la informaci&oacute;n</strong></strong></p>
      <p ><br></p>
      <p >Contamos con est&aacute;ndares comerciales razonables de tecnolog&iacute;a y seguridad operativa para proteger la informaci&oacute;n proporcionada por los visitantes de p&eacute;rdida, uso indebido, alteraci&oacute;n o destrucci&oacute;n.</p>
      <p ><br></p>
      <p ><strong><strong >Cambios a nuestra Pol&iacute;tica de privacidad</strong></strong></p>
      <p >Agforest S.L. se reserva el derecho de modificar o enmendar esta declaraci&oacute;n de privacidad en cualquier momento y por cualquier motivo. Esta pol&iacute;tica entra en vigencia el 17 de noviembre de 2022. Cualquier cambio sustancial a la pol&iacute;tica se publicar&aacute; en este sitio web. Por su uso continuo o repetido del sitio web, usted acepta los t&eacute;rminos de la pol&iacute;tica revisada. Sin embargo, nada de lo contenido en este documento crea o tiene la intenci&oacute;n de crear un acuerdo de abogado-cliente entre Agforest S.L. y cualquier usuario que visite el sitio web o proporcione informaci&oacute;n de identificaci&oacute;n de cualquier tipo.</p>
      <p ><br></p>
      <p ><strong><strong >Preguntas</strong></strong></p>
      <p ><br></p>
      <p >Si tiene alguna pregunta o inquietud con respecto a su privacidad mientras usa este sitio web, dir&iacute;jala al correo electr&oacute;nico de contacto que encontrar&aacute; en nuestra p&aacute;gina web.</p>
      <p ><br></p>
      <p ><strong><strong >Ubicaci&oacute;n de la oficina principal</strong></strong></p>
      <p ><br></p>
      <p >La oficina principal de la Firma se encuentra en Madrid, Espa&ntilde;a.</p>
  </div>
</div>
</div>
