import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import {
  ConfirmEventType,
  ConfirmationService,
  MessageService,
} from 'primeng/api';
import { Table } from 'primeng/table';

import { environment } from 'src/environments/environment';
import { lastValueFrom } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from 'src/app/shared/services/user.service';

@Component({
  selector: 'app-provider-clients-list-providers',
  templateUrl: './provider-clients-list-providers.component.html',
  styleUrls: ['./provider-clients-list-providers.component.scss'],
})
export class ProviderClientsListProvidersComponent implements OnInit {
  @ViewChild('dt') table?: Table;

  providers?: any[] = [];
  totalRecords?: number;
  loading?: boolean;

  user?: any;

  isAdmin: boolean = false;
  isClient: boolean = false;
  isProvider: boolean = false;

  owner_id?: string;

  _typesFilter: string[] = [];
  set typesFilter(value: string[] | undefined) {
    this._typesFilter = value || [];
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    this.load();
  }
  get typesFilter() {
    return this._typesFilter;
  }

  types?: string[] = [];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private userSvc: UserService,
    private http: HttpClient,
    private confirmationService: ConfirmationService,
    private messageSvc: MessageService,
  ) {}

  async ngOnInit() {
    this.user = this.userSvc.getUser();

    this.isAdmin = this.user.is_organization;
    this.isClient = this.user.is_client;
    this.isProvider = this.user.is_provider;

    this.owner_id = this.user.owner_id;

    await this.load();
  }

  async load(_event?: any) {
    this.loading = true;

    let result = [];

    if (this.isProvider) {
      result = await lastValueFrom(
        this.http.get<any>(
          environment.api + 'owners/provider/clients/byowner/' + this.owner_id,
        ),
      );
    }

    if (this.isAdmin) {
      result = await lastValueFrom(
        this.http.get<any>(environment.api + 'owners/provider/clients'),
      );
      console.log('isAdmin');
      console.log(result);
    }

    console.log(result);

    this.providers = result as any[];

    console.log(this.providers);

    this.totalRecords = this.providers.length;

    this.loading = false;
  }

  /*******************************************************
   *
   *
   */

  deleteProvider(data: any) {
    this.confirmationService.confirm({
      key: 'delete',
      accept: async () => {
        this.loading = true;

        try {
          await lastValueFrom(
            this.http.delete(
              environment.api + 'owners/provider/clients/' + data?.id,
            ),
          );
          await this.load();
          this.loading = false;
        } catch (e: any) {
          console.log(e);
          this.messageSvc.add({
            severity: 'error',
            summary: 'Error',
            detail:
              'Error al eliminar el cliente del proveedor. Esta asignado a un expediente',
          });
          this.loading = false;
        }
      },
      reject: (_type: ConfirmEventType) => {},
    });
  }
}
