import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/shared/services/user.service';
import { AdminStoredBillStoreService } from '../admin-stored-bill-store.service';

@Component({
  selector: 'app-admin-current-bill',
  templateUrl: './admin-current-bill.component.html',
  styleUrls: ['./admin-current-bill.component.css'],
})
export class AdminCurrentBillComponent implements OnInit {
  factura: any = null;
  lineas: any = null;
  baseImponible = 0;
  contratos = 0;

  user: any = null;

  totalRecords: number = 0;
  loading = false;

  constructor(
    private http: HttpClient,
    private userService: UserService,
    public store: AdminStoredBillStoreService,
  ) {}

  ngOnInit() {
    this.store.selectedBill$.subscribe((bill) => {
      this.loading = true;

      this.factura = bill;
      this.lineas = bill.lines;
      this.contratos = bill.lines.length;
      this.baseImponible = bill.baseImponible;

      this.loading = false;
    });

    this.user = this.userService.user;

    console.log(this.user);

    //this.getFactura();
  }
}
