import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-sent-code',
  templateUrl: './sent-code.component.html',
  styleUrls: ['./sent-code.component.scss'],
})
export class SentCodeComponent {
  constructor(
    private readonly router: Router,
    private readonly http: HttpClient,
  ) {
    this.email = this.router.getCurrentNavigation()?.extras.state?.['email'];
  }
  email?: string;
  wrongCode = false;
  loading = false;

  form = new FormGroup({
    // eslint-disable-next-line @typescript-eslint/unbound-method
    code1: new FormControl(null, Validators.required),
    // eslint-disable-next-line @typescript-eslint/unbound-method
    code2: new FormControl(null, Validators.required),
    // eslint-disable-next-line @typescript-eslint/unbound-method
    code3: new FormControl(null, Validators.required),
    // eslint-disable-next-line @typescript-eslint/unbound-method
    code4: new FormControl(null, Validators.required),
    // eslint-disable-next-line @typescript-eslint/unbound-method
    code5: new FormControl(null, Validators.required),
  });

  move(e: KeyboardEvent, cur: HTMLInputElement, nxt: HTMLInputElement | null) {
    if (cur.value.length === 1 && nxt) {
      nxt.focus();
    }
  }

  back(e: KeyboardEvent, cur: HTMLInputElement, pre: HTMLInputElement | null) {
    if (e.key === 'Backspace' && cur.value.length === 0 && pre) {
      pre.focus();
    }
  }

  adjust(pre: HTMLInputElement | null) {
    if (pre && pre.value.length === 0) {
      pre.focus();
    }
  }

  async entryCode() {
    if (this.form.invalid) return;

    this.loading = true;
    this.wrongCode = false;
    const code = Object.values(this.form.value).join('');
    await this.http
      .post(`${environment.api}user/code`, { email: this.email, code })
      .toPromise()
      .then(async (res: any) => {
        await this.router.navigate(['/recoverpass/newpass'], {
          state: { access_token: res.access_token },
        });
      })
      .catch((_err) => {
        this.wrongCode = true;
      });
    this.loading = false;
  }
}
