<p-autoComplete name="location" id="location" [placeholder]="'Ej. Medina del Campo'" [formControl]="_control"
    class="autocomplete__towns"
    [suggestions]="filteredLocalities"
    field="name"
    dataKey="code"
    (onSelect)="_onSelect($event)"

    [inputStyle]="{'minWidth':'100%'}"
    [style]="{'minWidth':'100%'}"
    (completeMethod)="filterTowns($event)"></p-autoComplete>
