import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-privacidad-en',
  templateUrl: './privacidad_en.component.html',
  styleUrls: ['./privacidad_en.component.css'],
})
export class PrivacidadComponentEN implements OnInit {
  constructor() {}

  ngOnInit() {}
}
