<div class="m-8" style="text-align: left;">
  <div>
    <div style="text-align: left;">
      <div>
        <div>
          <h1><strong>Pol&iacute;tica de Privacidad y Cookies</strong></h1>
        </div>
      </div>
    </div>
    <div style="text-align: justify;">
      <div>
        <div>
          <div><br></div>
        </div>
        <div>
          <div>
            <div>
              <p>En relaci&oacute;n con los datos de car&aacute;cter personal facilitados por los interesados en los
                formularios habilitados en el sitio web<span>&nbsp;</span><a href="https://censo.agforest.ai/"
                  target="_blank" rel="noopener">https://censo.agforest.ai</a>, se informa de que se cumple
                estrictamente la normativa vigente en materia de protecci&oacute;n de datos de car&aacute;cter
                personal y que los datos proporcionados, en su caso, ser&aacute;n incluidos en un fichero
                responsabilidad de AGFOREST, S.L. (en adelante, Agforest) para su tratamiento conforme a las
                finalidades descritas en esta Pol&iacute;tica de Privacidad y en los formularios habilitados en cada
                caso, prestando a los interesados el consentimiento para dicho tratamiento mediante la
                aceptaci&oacute;n de la presente pol&iacute;tica de privacidad o mediante el env&iacute;o de
                informaci&oacute;n por cualquier de los medios previstos en el sitio web.</p>
              <p><br></p>
              <p><strong><strong><span>Finalidad de los datos</span></strong></strong></p>
              <p><span>Agforest recoge determinados datos personales que son introducidos voluntariamente por los
                  usuarios en los distintos formularios que existen en el sitio web
                  https://censo.agforest.ai/</span><span>.</span></p>
              <p><span>Los datos son utilizados con las siguientes finalidades:</span></p>
              <ul>
                <li>
                  <p>Gestionar las solicitudes de informaci&oacute;n que se realicen a trav&eacute;s del formulario
                    de contacto previsto en el sitio web (&ldquo;Cont&aacute;ctanos&rdquo;).</p>
                </li>
                <li>
                  <p>Gestionar las solicitudes de reserva de servicios realizadas a trav&eacute;s del sitio web
                    (&ldquo;Reserva tu servicio&rdquo;).</p>
                </li>
                <li>
                  <p>Enviar, por medios electr&oacute;nicos, el bolet&iacute;n de noticias a los usuarios que hayan
                    manifestado que desean recibir este tipo de informaci&oacute;n mediante los formularios de
                    Newsletter habilitados en el sitio web.</p>
                </li>
                <li>
                  <p>Gestionar las candidaturas presentadas a trav&eacute;s del formulario &ldquo;&Uacute;nete a
                    nuestro equipo&rdquo; del sitio web.</p>
                </li>
                <li>
                  <p>Gestionar los comentarios publicados y/o compartidos por los usuarios del sitio web.</p>
                </li>
              </ul>
              <p><br></p>
              <p><strong><strong><span>Obligatoriedad de los datos</span></strong></strong></p>
              <p>Todos los campos requieren una respuesta obligatoria, salvo que se indique que son opcionales en el
                propio formulario. En caso de no rellenar los campos obligatorios, no ser&iacute;a posible gestionar
                la solicitud de reserva, publicar el comentario, contestar a la solicitud de contacto, remitir la
                newsletter o tener en cuenta la candidatura presentada, en funci&oacute;n del formulario utilizado.
              </p>
              <p><br></p>
              <p><strong><strong><span>Derechos de acceso, rectificaci&oacute;n, cancelaci&oacute;n y
                      oposici&oacute;n</span></strong></strong></p>
              <p>Los interesados pueden ejercitar sus derechos de acceso, rectificaci&oacute;n, cancelaci&oacute;n y
                oposici&oacute;n, y revocar el consentimiento prestado en cada caso para el tratamiento y/o
                cesi&oacute;n de sus datos, en cualquier momento solicit&aacute;ndolo por correo postal a AGFOREST
                S.L.; o remitiendo un mensaje de correo electr&oacute;nico a la siguiente direcci&oacute;n
                electr&oacute;nica<span>&nbsp;</span><a href="mailto:agforest&#64;agforest.ai">agforest&#64;agforest.ai</a>.
                En todo caso, deber&aacute; acompa&ntilde;arse la solicitud de una copia del documento oficial que
                acredite la identidad del titular de los datos.</p>
              <p><br></p>
              <p><strong><strong><span>Responsable del tratamiento</span></strong></strong></p>
              <p>El Responsable del tratamiento de los datos es AGFOREST S.L. con domicilio sito en calle Conde de
                Vilches, 25, Madrid, 28028. Madrid.</p>
              <p>Agforest cumple con la normativa de la Agencia Espa&ntilde;ola de Protecci&oacute;n de Datos,
                pudi&eacute;ndose consultar en<span>&nbsp;</span><a href="http://www.agpd.es/" target="_blank"
                  rel="noopener">www.agpd.es</a></p>
                          <p ><br></p>
                          <p ><strong><strong ><span >Cesi&oacute;n de datos</span></strong></strong></p>
                          <p >Los datos proporcionados por los interesados no son cedidos a terceros, salvo que se autorice expresamente a Agforest, y solo se comunican, en su caso, para poder gestionar los servicios que solicitan los usuarios. La comunicaci&oacute;n de los datos est&aacute; ligada con la finalidad misma para la que los datos han sido introducidos y por tanto relacionada con la libre y leg&iacute;tima aceptaci&oacute;n de la relaci&oacute;n jur&iacute;dica existente entre los interesados y Agforest, cuyo desarrollo, cumplimiento y control implican necesariamente la conexi&oacute;n de los datos y que incluye la transmisi&oacute;n necesaria de los mismos. Mediante la aceptaci&oacute;n de la presente pol&iacute;tica de privacidad los interesados declaran haber sido informados y consienten, sin reservas, la comunicaci&oacute;n de sus datos en los t&eacute;rminos indicados en esta cl&aacute;usula. Los interesados pueden revocar el consentimiento prestado tal y como se se&ntilde;ala en la presente pol&iacute;tica.</p>
                          <p >Cuando un usuario publica o comparte un comentario, est&aacute; autorizando expresamente la publicaci&oacute;n de su identificaci&oacute;n en el sitio web y, por tanto, la comunicaci&oacute;n al resto de usuarios que acceden a agforest.es.</p>
                          <p >Los usuarios deben abstenerse de proporcionar datos personales de otras personas, salvo que est&eacute;n expresamente autorizados por estas para facilitarlos. Agforest podr&aacute; dirigirse en cualquier momento a los usuarios para que acrediten la existencia y contenido de las autorizaciones mencionadas.</p>
                          <p ><br></p>
                          <p ><strong><strong ><span >Seguridad</span></strong></strong></p>
                          <p >Agforest asegura la absoluta confidencialidad y privacidad de los datos personales tratados, adoptando para ello las medidas de seguridad, de &iacute;ndole t&eacute;cnica y/o organizativa, necesarias para evitar la alteraci&oacute;n, p&eacute;rdida, tratamiento o acceso no autorizado a los datos, garantizando as&iacute; la seguridad de los datos. Agforest no responder&aacute; en ning&uacute;n caso de las incidencias que puedan surgir en torno a los datos personales cuando se deriven bien de un ataque o acceso no autorizado a los sistemas de tal forma que sea imposible de detectar por las medidas de seguridad implantadas.</p>
                          <p ><br></p>
                          <p ><strong><strong ><span >Cookies</span></strong></strong></p>
                          <p >Agforest &uacute;nicamente utiliza, en su caso, cookies para efectuar el intercambio de datos a trav&eacute;s de redes de comunicaciones electr&oacute;nicas, o cuando estas son estrictamente necesarias para la prestaci&oacute;n de una funcionalidad, que forma parte de un servicio de la sociedad de la informaci&oacute;n, expresamente solicitada por el usuario. En caso que el uso de las cookies no cumpliera alguna de las finalidades indicadas, Agforest solicitar&aacute; el consentimiento informado del usuario para la utilizaci&oacute;n de dichos dispositivos de almacenamiento y recuperaci&oacute;n de datos en su equipo (p.ej. mediante una ventana emergente o la configuraci&oacute;n por el usuario del navegador o de otras aplicaciones, durante su instalaci&oacute;n o actualizaci&oacute;n). Las cookies que se utilizan no contienen informaci&oacute;n personal que identifique al usuario. En cualquier caso, el usuario puede configurar su navegador para que no se le instalen cookies o para recibir un aviso en pantalla cada vez que una cookie quiera instalarse, pudiendo decidir en cada momento si la quiere aceptar o no.</p>
                          <p ><br></p>
                          <p ><strong><strong ><span >Veracidad de los datos</span></strong></strong></p>
                          <p >Los usuarios son responsables de la veracidad de los datos proporcionados, comprometi&eacute;ndose a no introducir datos falsos y a proceder a la modificaci&oacute;n de los mismos si fuera necesario.</p>
                          <p ><br></p>
                          <p >Consulta<span >&nbsp;</span><span ><a href="
                  https://agforest.ai/aviso-legal">m&aacute;s</a></span>.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
