<div class="m-10" [formGroup]="form">
  <div class="absolute z-10 w-full h-full flex items-center justify-center bg-opacity-50 bg-gray-700"
    *ngIf="waiting">
    <p-progressSpinner styleClass="custom-spinner"></p-progressSpinner>
  </div>
  <app-back class="mb-5 cursor-pointer"></app-back>
  <div class="flex item-center w-full mb-4">
    <h2 class="flex-grow text-xl font-bold text-am-primary-navy-100">{{ 'inspections.detailTitle' | translate}}</h2>

    <div class="flex gap-4">
      <!-- remove -->
      <p-button pRipple [hidden]="!canCreate || !canEdit" size="small" label="{{ 'admin.save' | translate}}"
        (click)="save()" [disabled]="loading" [loading]="loading"></p-button>
    </div>
  </div>
  <div class="mb-8" style="background: #f0f0f0; text-align: center;">
    <div class="relative">
      <div class="absolute z-10 w-full h-full flex items-center justify-center bg-opacity-50 bg-gray-700"
        *ngIf="loadingMap">
        <p-progressSpinner styleClass="custom-spinner"></p-progressSpinner>
      </div>
      <div class="relative z-0">
        <app-map #map loadFromUrl="false"
          [enable_address_bar]="true"
          [enable_draw]="false"
          [get_coordinates_on_click]="false"
          [is_geolocation_enabled]="false"
          class="flex h-[600px]"
          (onDrawGeometry)="geometry($event)" (onFinishLoading)="finishLoading()"></app-map>
      </div>
    </div>
  </div>

  @if (isProvider) {
  <div class="relative">
    <div class="absolute right-0">
      <p-button type="button" icon="pi pi-file" label="{{ 'admin.generateReport' | translate}}"
        (click)="menuGenerateReport.toggle($event)" size="small" [outlined]="true"></p-button>
    </div>
    <div class="flex flex-wrap items-start gap-10">
      <div class=" flex flex-col w-1/4 p-input-underline">
        <label for="recordNumber" class="text-am-secondary-navy-60 ">{{'inspections.recordNumber' | translate}}</label>
        <input pInputText id="recordNumber" [disabled]="true" type="text" placeholder="12345678"
          formControlName="recordNumber" maxlength="16">
      </div>
    </div>
  </div>

  <p-menu #menuGenerateReport [popup]="true" [model]="generateReportMenu"></p-menu>
  }

  <h1 class=" text-xl mb-10 mt-20">{{'inspections.locationData' | translate}}</h1>
  <div class="flex flex-wrap items-start gap-10">
    <!--Localidad-->
    <div class=" flex flex-col w-1/4 p-input-underline">
      <label for="locality" class="  text-am-secondary-navy-60 ">Localidad</label>
      <input pInputText disabled="true" id="locality" type="text" placeholder="Ej. Madrid" formControlName="locality"
        maxlength="45">
    </div>
    <!--Dirección-->
    <div class=" flex flex-col w-1/4 p-input-underline">
      <label for="address" class="  text-am-secondary-navy-60 ">{{ 'inspections.address' | translate}}</label>
      <input pInputText id="address" type="text" placeholder="Calle Mayor, 9" formControlName="address">
    </div>
    <!--Año-->
    <div class=" flex flex-col w-1/4 p-input-underline">
      <label for="year" class="  text-am-secondary-navy-60 ">{{ 'inspections.year' | translate}}</label>
      <p-inputNumber id="year" formControlName="year" inputId="withoutgrouping" [useGrouping]="false" [min]="0"
        maxlength="4"></p-inputNumber>
    </div>
    <!--Referencia catastral-->
    <div class=" flex flex-col w-1/4 p-input-underline">
      <label for="catastralReference" class="  text-am-secondary-navy-60 ">{{ 'inspections.cadastralReference' |
        translate}}</label>
      <input pInputText id="catastralReference" type="text" placeholder="12345678" formControlName="catastralReference"
        maxlength="30">
    </div>
    <!--Uso emplazamiento-->
    <div class=" flex flex-col w-1/4 p-input-underline">
      <label for="locationUse" class="  text-am-secondary-navy-60 ">{{ 'inspections.locationUse' | translate}}</label>
      <p-dropdown [options]="objectKeys(locationUseOptions)" formControlName="locationUse"
        [placeholder]="'inspections.selectUse' | translate" [style]="{'minWidth':'100%'}">
        <ng-template let-item pTemplate="selectedItem">{{ locationUseOptions[item] }}</ng-template>
        <ng-template let-item pTemplate="item">{{ locationUseOptions[item] }}</ng-template>
      </p-dropdown>
    </div>
    <!--Titular edificio-->
    <div class=" flex flex-col w-1/4 p-input-underline">
      <label for="buildingOwner" class="  text-am-secondary-navy-60 ">{{ 'inspections.buildingOwner' |
        translate}}</label>
      <input pInputText id="buildingOwner" type="text" placeholder="Juan Perez" formControlName="buildingOwner"
        maxlength="45">
    </div>
  </div>
  <h1 class=" text-xl mt-20 mb-10">{{'inspections.inspection' | translate}}</h1>


  <div class="flex justify-stretch gap-10 mb-6">
    <!--Numero de inspeccion-->
    <div class="flex flex-col p-input-underline">
      <label for="inspectionNumber" class="text-am-secondary-navy-60 ">{{ 'inspections.inspectionNumber' |
        translate}}</label>
      <input pInputText styleClass="w-[85%]" id="inspectionNumber" type="text" placeholder="12345678"
        formControlName="inspectionNumber" maxlength="16">
    </div>
    <!--Tipo de inspeccion-->
    <div class="flex">
      <i class="pi pi-info font-medium text-sm text-am-secondary-navy-60 rounded-full mr-2 w-5 h-5 ring-1 ring-blue-500 items-center flex justify-center"
        [pTooltip]="'inspections.typeTooltip' | translate"></i>
      <div class="grid grid-rows-2[minmax/1fr, 2fr] row-start-2">
        <label for="inspectionType" class="text-am-secondary-navy-60 ">{{ 'inspections.inspectionType' |
          translate}}</label>
        <p-dropdown styleClass="w-[100%]" formControlName="inspectionType" placeholder="Seleccione un tipo"
          [options]="inspectionTypeOptions"></p-dropdown>
      </div>
    </div>
  </div>

  <div class="flex justify-stretch gap-10 mb-6">
    <!--Fecha de comunicacion al ayuntamiento-->
    <div class="flex flex-col">
      <label for="communication_council_date" class="text-am-secondary-navy-60 ">{{ 'inspections.communicationDate' |
        translate}}</label>
      <p-calendar [showIcon]="true" [placeholder]="'Ej. 2021-01-01'" dateFormat="dd/mm/yy"
        [formControl]="form.get('communication_council_date')"></p-calendar>
    </div>
    <!--Fecha de la inpeccion-->
    <div class="flex flex-col">
      <label for="date" class="text-am-secondary-navy-60 ">{{ 'inspections.inspectionDate2' | translate}}</label>
      <p-calendar id="date" [showIcon]="true" [readonlyInput]="true" dateFormat="dd/mm/yy"
        [formControl]="form.get('date')"></p-calendar>
    </div>
    <!--Objetivo de la inspección-->
    <div class="flex flex-col grow p-input-underline max-w-[50%]">
      <label for="inspectionObjective" class="text-am-secondary-navy-60 ">{{ 'inspections.inspectionTarget' |
        translate}}</label>
      <input pInputText id="inspectionObjective" type="text" [placeholder]="'inspections.inspectionTargetPlaceholder' |
        translate"
        formControlName="inspectionObjective" maxlength="30">
    </div>
  </div>

  <div class="flex justify-stretch gap-10 mb-6">
    <!--Inspector-->
    <div class="flex flex-col p-input-underline">
      <label for="inspector" class="text-am-secondary-navy-60 ">{{ 'inspections.inspector' | translate}}</label>
      <app-user-input [control]="form.get('inspector')"></app-user-input>
    </div>
    <!-- Tomador de muestras -->
    <div class="flex flex-col p-input-underline">
      <label for="inspection_sampler" class="w-auto text-am-secondary-navy-60 ">{{ 'inspections.samplingUser' |
        translate}}</label>
      <app-user-input [control]="form.get('inspection_sampler')"></app-user-input>
    </div>
    <!-- Encargado de la documentacion -->
    <div class="flex flex-col p-input-underline">
      <label for="inspection_documentation_manager" class="text-am-secondary-navy-60 ">{{
        'inspections.documentationUser' | translate}}</label>
      <app-user-input [control]="form.get('inspection_documentation_manager')"></app-user-input>
    </div>
  </div>

  <div class="flex justify-stretch gap-10 mb-6">
    <!--Actuaciones-->
    <div class="flex flex-col w-1/3 p-input-underline">
      <label for="actions" class="text-am-secondary-navy-60 ">{{ 'inspections.performances' | translate}}</label>
      <input pInputText id="actions" type="text" [placeholder]="'inspections.performancesPlaceholder' | translate" formControlName="actions"
        maxlength="30">
    </div>
    <!--Alcance-->
    <div class="flex flex-col w-1/3 p-input-underline">
      <label for="scope" class="text-am-secondary-navy-60 ">{{ 'inspections.scope' | translate}}</label>
      <input pInputText id="scope" type="text" [placeholder]="'inspections.scopePlaceholder' | translate" formControlName="scope" maxlength="30">
    </div>
    <!--EPIS Y EPC-->
    <div class="flex flex-col w-1/3 p-input-underline">
      <label for="epis" class="text-am-secondary-navy-60 ">{{ 'inspections.EPISAndEPC' | translate}}</label>
      <input pInputText id="epis" type="text" [placeholder]="'inspections.EPISAndEPCPlaceholder' | translate" formControlName="epis" maxlength="30">
    </div>
  </div>
  <div class="flex justify-stretch gap-10 mb-6">
    <!--Procedimiento de descontaminación-->
    <div class="flex flex-col w-1/2 p-input-underline">
      <label for="decontaminationProcedure" class="text-am-secondary-navy-60 ">{{ 'inspections.procedure' |
        translate}}</label>
      <input pInputText id="decontaminationProcedure" formControlName="decontaminationProcedure" [placeholder]="'inspections.procedurePlaceholder' | translate"
        class="text-am-secondary-navy-60" maxlength="30">
    </div>
    <!--Eliminacion de residuos-->
    <div class="flex flex-col w-1/3 p-input-underline">
      <label for="wasteDisposal" class="  text-am-secondary-navy-60 ">{{ 'inspections.wasteDisposal' |
        translate}}</label>
      <p-dropdown styleClass="w-[85%]" [options]="objectKeys(deleteWasteOptions)" formControlName="wasteDisposal"
        [placeholder]="'inspections.wasteDisposalPlaceholder' | translate">
        <ng-template let-item pTemplate="selectedItem">{{ deleteWasteOptions[item] }}</ng-template>
        <ng-template let-item pTemplate="item">{{ deleteWasteOptions[item] }}</ng-template>
      </p-dropdown>
    </div>
  </div>
  <div class="flex justify-stretch gap-10 mb-6">
    <div class="flex flex-col w-1/2 xl:w-1/3 p-input-underline">
      <label for="inspection_duration" class="  text-am-secondary-navy-60 ">{{ 'inspections.inspectionTime' |
        translate}}</label>
      <p-inputNumber id="inspection_duration" formControlName="inspection_duration" [step]="0.5" inputId="integeronly"
        mode="decimal" [min]="0" maxlength="4"></p-inputNumber>
    </div>
    <div class="flex flex-col w-1/2 xl:w-1/3 p-input-underline">
      <label for="inspection_sampling_duration" class="  text-am-secondary-navy-60 ">{{ 'inspections.samplingTime' |
        translate}}</label>
      <p-inputNumber id="inspection_sampling_duration" formControlName="inspection_sampling_duration"
        inputId="integeronly" mode="decimal" maxlength="4"></p-inputNumber>
    </div>
  </div>

  <div class="flex flex-wrap items-start gap-10 mt-10">
    <!--Información relevante-->
    <div class=" flex flex-col w-1/2">
      <label for="relevantInformation" class="  text-am-secondary-navy-60 fo">{{ 'inspections.relevantInfo' |
        translate}}</label>
      <textarea pInputTextarea id="relevantInformation" type="text" [placeholder]="'inspections.relevantInfo' | translate"
        formControlName="relevantInformation" maxlength="255"></textarea>
    </div>
    <!--Informe evaluación edificio - Checkbox-->
    <div class=" flex flex-col w-1/3 p-input-underline mt-5">
      <p-checkbox binary="true" [label]="'inspections.informeEvaluacionEdificio' | translate"
        formControlName="buildingEvaluationReport"></p-checkbox>
    </div>
  </div>
  <div class="mt-8">
    <div class=" flex flex-col w-1/2">
      <label for="future_inspector_notes" class="  text-am-secondary-navy-60 ">{{ 'inspections.qualify' |
        translate}}</label>
      <textarea pInputTextarea id="future_inspector_notes" type="text" [placeholder]="'inspections.qualify' | translate"
        formControlName="future_inspector_notes" maxlength="255"></textarea>
    </div>
  </div>

  <!--
    <div class="flex justify-between items-center mt-20 mb-10">
        <h1 class=" text-xl">{{'inspections.location' | translate}}</h1>
        <p-button pRipple type="button" icon="pi pi-plus" label="Añadir Localizacion" (click)="addLocation()" size="small" [outlined]="true"></p-button>
    </div>
    -->

  <!--Localizaciones-->
  <!--
    <div formArrayName="locations" *ngFor="let c of form.get('locations')?.controls; let i = index">
      <div [formGroupName]="i" class="mb-10">
          <div class="p-input-underline">
              <input class="w-[60%]" pInputText id="locationName" type="text" formControlName="name" placeholder="Ej. Cubierta Nave" [style]="{'font-size': '18px', 'font-weight': '400'}">
              <button pButton pRipple type="button" icon="pi pi-trash" class="p-button-text p-button-rounded p-button-plain" (click)="removeLocation(i)"></button>
          </div>
          <div class=" flex flex-wrap items-start gap-10 mt-10 mb-10 ml-5 mr-5">
              <div class=" flex flex-col w-1/4 p-input-underline">
                  <label for="samplingStrategy" class="  text-am-secondary-navy-60 ">Estrategia de muestreo</label>
                  <input pInputText formControlName="samplingStrategy" placeholder="Seleccione una estrategia" >
              </div>
              <div class=" flex flex-col w-1/4 p-input-underline">
                  <label for="management" class="  text-am-secondary-navy-60 ">Gestion</label>
                  <p-dropdown [options]="mcaManagementOptions" formControlName="management" placeholder="Seleccione una gestion" [style]="{'minWidth':'100%'}"></p-dropdown>
              </div>
              <div class=" flex flex-col w-1/4 p-input-underline">
                  <label for="material" class="  text-am-secondary-navy-60 ">Material</label>
                  <input pInputText formControlName="material" placeholder="Seleccione un material">
              </div>
              <div class=" flex flex-col w-1/4 p-input-underline">
                  <label for="mca" class="  text-am-secondary-navy-60 ">MCA</label>
                  <p-dropdown [options]="mcaOptions" formControlName="mca" placeholder="Seleccione un MCA" [style]="{'minWidth':'100%'}"></p-dropdown>
              </div>
          </div>
          <div class="ml-5 mr-5">
              <label class=" text-am-secondary-navy-60 mb-3 block">Fotografías</label>
              <app-image-control-input [control]="c.get('pictures')" [label]="'Subir imagen'"></app-image-control-input>
              <label class=" text-am-secondary-navy-60 mt-10 mb-3 block">Muestras de laboratorio</label>
              <app-image-control-input [control]="c.get('picturesLab')" [label]="'Añadir foto de la etiqueta'"></app-image-control-input>
              <label class=" text-am-secondary-navy-60 mt-10 mb-3 block">Planos</label>
              <app-image-control-input [control]="c.get('picturesSchemas')" [label]="'Subir plano'"></app-image-control-input>

          </div>
      </div>
      <h4 class=" text-xl">Ficha inspección</h4>
      <div class="mt-10">
          <p-button pRipple type="button" icon="pi pi-plus" label="Ficha de inspección" (click)="openReport()" size="small" [outlined]="true"></p-button>
      </div>
    </div>
    -->
  <app-localizaciones [form]="form"></app-localizaciones>

</div>



<p-confirmDialog key="delete" #cd [style]="{ width: '50vw' }">
  <ng-template pTemplate="header">
    <h3 class="font-bold">{{ 'inspections.deleteInspection' | translate}}</h3>
  </ng-template>
  <ng-template pTemplate="message">
    <div style="width:100%" class="text-sm text-center font-semibold w-full">
      <div>
        {{ 'inspections.deleteInspectionMsg' | translate}}
      </div>
    </div>
  </ng-template>
  <ng-template pTemplate="footer">
    <p-button type="button" label="{{ 'admin.cancel' | translate}}" (click)="cd.reject()" size="small"
      [outlined]="true"></p-button>
    <p-button type="button" label="{{ 'inspections.deleteButton' | translate}}" (click)="cd.accept()" size="small"
      severity="danger"></p-button>
  </ng-template>
</p-confirmDialog>

<!--
<p-confirmDialog key="report" #report [style]="{ width: '90vw' }">
    <ng-template pTemplate="header">
        <h3 class="font-bold">Ficha de inspección</h3>
    </ng-template>
    <ng-template pTemplate="message">
        <div>
            <div></div>
            <app-report class="block"></app-report>
        </div>
    </ng-template>
    <ng-template pTemplate="footer">
        <div class="pt-5">
            <p-button type="button" [label]="'Cancelar' " (click)="report.reject()" size="small" [outlined]="true"></p-button>
            <p-button type="button" [label]="'Continuar'" (click)="report.accept()" size="small" ></p-button>
        </div>
    </ng-template>
</p-confirmDialog>
-->

<div class="absolute h-0 overflow-hidden">
  <div id="pdfContent" #pdfContent>
    <div class="pdfPage">
      <div class="header">
        <h1>LOGO</h1>
      </div>
      <div class="pdfContain">
        <div class="titles">
          <h1>Informe de inspección</h1>
          <h2 class="subtitle">para identificar MCA (Materiales con Contenido de Amianto)</h2>
          <h3>Informe {{form.value.recordNumber || '000-000-000-000 | Versión 00'}}</h3>
          <h2 class="title_info">{{form.value.locationUse || 'Nave transformada en aparacamientos'}} en
            {{form.value.address || "la Calle San Antonio de Pádua, 3"}} - {{form.value.locality || '47400 Medina del
            Campo, Valladolid, España'}}</h2>
        </div>
        <div class="data">
          <!--
        <div class="span_container  items-center">
          <span class="category_span">Cliente</span>
          <span class="info_span">{{form.value.client || 'Salustio Zermeño Bellotas'}}</span>
        </div>
        -->
          <div class="span_container  items-center">
            <span class="category_span">Titular del edificio</span>
            <span class="info_span">{{form.value.buildingOwner || 'Salustio Zermeño Bellotas'}}</span>
          </div>
          <div class="span_container  items-center">
            <span class="category_span">Inspector</span>
            <span class="info_span">{{form.value.inspector?.firstName + ' ' + form.value.inspector?.lastName || 'Rubén
              Pasiegos Verpertino'}}</span>
          </div>
          <div class="span_container  items-center">
            <span class="category_span">Objetivo del cliente</span>
            <span class="info_span">{{form.value.inspectionObjective || 'Identificación de MCA y calendario para su
              posterior retirada'}}</span>
          </div>
          <div class="span_container items-center">
            <span class="category_span">Fecha de inspección</span>
            <span class="info_span">{{form.value.date | date:'dd/mm/yyyy' || '25/07/2022'}}</span>
          </div>
          <div class="span_container">
            <span class="category_span mt-1">Zona / edificio inspeccionado</span>
            <span class="info_span">Zona / edificio inspeccionado. Se inspecciona la cubierta y bajantes del inmueble
              (NOMBRE) de la Calle Antonio de Pádua 3 - 47000 Medina del Campo (Valladolid) - España</span>
          </div>
          <div class="span_container  items-center">
            <span class="category_span">Referencia catastral</span>
            <span class="info_span">{{form.value.catastralReference || '9744079UL3794S00001IJ'}}</span>
          </div>
          <div class="span_container  items-center">
            <span class="category_span">Fecha de informe</span>
            <span class="info_span">{{form.value.date | date:'dd/mm/yyyy' || '12/08/2022'}}</span>
          </div>
        </div>
      </div>
      <div class="footer">
        <div class="container mx-auto p-4 text-sm">
          <div class="flex">
            <div class="logo text-3xl font-bold mr-20 max-lg:self-center">
              LOGO
            </div>
            <div class="flex max-lg:flex-col max-lg:gap-1 lg:items-center">
              <div class="mx-2 flex justify-start gap-1 number">
                {{'footer.number' | translate}}
              </div>
              <div class="mx-2 flex justify-start gap-1 email">
                {{'footer.email' | translate}}
              </div>
              <div class="mx-2 flex justify-start gap-1 location">
                {{'footer.address' | translate}}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
