<div class="m-10">
  <!--Main Administracion-->
  <!--Div con Titulo y boton añadir usuario-->
  <div class="flex item-center w-full mt-14 mb-4">
    <h2 class="flex-grow text-xl font-bold text-am-primary-navy-100">{{'contracts.actives.title' | translate}}</h2>
  </div>


  <!--Div Input Busqueda y Filtrado-->
  <div class="flex justify-between mt-4 mb-4 items-start">
    <span class="p-input-icon-right h-max">
      <i class="pi pi-search"></i>
      <input type="text" pInputText class="p-inputtext-sm w-100" [placeholder]="'ui.general.buscar' | translate"
        (input)="dt.filterGlobal($event.target.value, 'contains')" />
    </span>
  </div>

  <p-table #dt class="" [value]="contracts" [paginator]="true" [rows]="10" [totalRecords]="totalRecords"
    [loading]="loading" [rowHover]="true" [showCurrentPageReport]="true"
    [globalFilterFields]="['businessName', 'email', 'cif']" currentPageReportTemplate="{currentPage} de {totalPages}">

    <ng-template pTemplate="header">
      <tr>
        <th pSortableColumn="typeOfContract">{{'contracts.actives.table.contractType' | translate}}<p-sortIcon field="typeOfContract"></p-sortIcon></th>
        <th>{{'contracts.actives.table.contractId' | translate}}</th>
        <th pSortableColumn="name">{{'contracts.actives.table.municipe' | translate}} <p-sortIcon field="record.recordINEId.name"></p-sortIcon></th>
        <th pSortableColumn="businessName">{{'contracts.actives.table.businessName' | translate}} <p-sortIcon field="client.businessName"></p-sortIcon></th>
        <th pSortableColumn="reference">{{'contracts.actives.table.reference' | translate}} <p-sortIcon field="record.reference"></p-sortIcon></th>
        <th pSortableColumn="createdAt">{{'contracts.actives.table.creationDate' | translate}}<p-sortIcon field="createdAt"></p-sortIcon></th>
        <th style="width: 4rem"></th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-data>
      <tr>
        <td>{{ data.typeOfContract == 0 ? 'VINCULADO' : 'INDEPENDIENTE' }}</td>
        <td>{{ data.id }}</td>
        <td class="font-semibold">{{ data.record.recordINEId?.name || '-' }}</td>
        <td class="font-semibold">{{ data.client?.businessName || '-' }}</td>
        <td>{{ data.record.reference }}</td>
        <td>{{ data.requestDate | date }}</td>
        <td>
          <button pButton pRipple type="button" icon="pi pi-chevron-right"
            class="p-button-text p-button-rounded p-button-plain" (click)="selectContract(data)"></button>

        </td>
      </tr>
    </ng-template>


    <ng-template pTemplate="emptymessage" let-columns let-frozen="frozen">
      <tr>
        <td colspan="7">
          <div class="text-center p-10">{{ 'ui.general.noresults' | translate }}</div>
        </td>
      </tr>
    </ng-template>

    <ng-template pTemplate="sorticon" let-number>
      <i *ngIf="number == 0" class="pi pi-sort" style="opacity:0.2"></i>
      <i *ngIf="number == 1" class="pi pi-sort-down"></i>
      <i *ngIf="number == -1" class="pi pi-sort-up"></i>
    </ng-template>

  </p-table>
</div>
