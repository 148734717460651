<div class="fixed inset-0 flex w-full h-full mx-auto items-center justify-center z-50 bg-am-gray-semitransparent">
<div class="bg-white w-100 h-48 relative p-6 rounded-xl flex flex-col justify-start gap-5">
  <div class="flex justify-end">
    <img src="../../../../assets/icons/close_Icon.svg" alt="close_icon" class="cursor-pointer w-6" (click)="closePopup()">
  </div>
<div class="flex justify-center items-center">
  <h3 class=" text-lg text-black text-center">{{ 'ui.training.message' | translate}} : <a href="mailto:soporte.amiantic@agforest.es" target="_blank" class="font-bold text-lg text-am-primary-turquoise-100 text-center ">soporte.amiantic&#64;agforest.es</a></h3>
</div>
</div>
</div>
