import { Injectable } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class RecordFormService {
  readonly form = new FormGroup({
    record_id: new FormControl(''),
    inspector_name: new FormControl(''),
    record_status: new FormControl(''),
    location_name: new FormControl(''),
    province_name: new FormControl(''),
    CP: new FormControl(''),
    main_contact: new FormGroup({
      main_contact_name: new FormControl(''),
      main_contact_phone: new FormControl(''),
      main_contact_email: new FormControl(''),
      client_enterprise: new FormControl(''),
      main_contact_CIF: new FormControl(''),
      main_contact_business_name: new FormControl(''),
      main_contact_address: new FormControl(''),
    }),
    others_contacts: new FormArray([
      new FormGroup({
        other_contact_name: new FormControl(''),
        other_contact_phone: new FormControl(''),
        other_contact_email: new FormControl(''),
        //  other_contact_CIF: new FormControl(''),
        //  other_contact_business_name: new FormControl(''),
        //  other_contact_address: new FormControl('')
      }),
    ]),
    documentation_and_notes: new FormGroup({
      documents: new FormControl(['']),
      notes: new FormControl(''),
    }),
    locations: new FormGroup({
      use_site: new FormControl(''),
      ownership: new FormControl(''),
      MCA: new FormControl(''),
      cadastral_reference: new FormControl(''),
      locality: new FormControl(''),
      risk_level: new FormControl(''),
      year: new FormControl(''),
      address: new FormControl(''),
      withdrawal_schedule: new FormControl(''),
      locations_notes: new FormControl(''),
      inspections: new FormControl(''),
    }),
  });
}
