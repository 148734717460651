<div id="top" class="m-10">
  <div class="absolute z-10 w-full h-full flex items-center justify-center bg-opacity-50 bg-gray-700" *ngIf="waiting">
    <p-progressSpinner styleClass="custom-spinner"></p-progressSpinner>
  </div>
    <div class="flex item-center w-full mb-4">
        <h2 class="flex-grow text-xl font-bold text-am-primary-navy-100">{{ 'inspections.addInspectionTitle' | translate}} </h2>

        <div class="flex flex-row">
          <div class="ml-4">
            <p-button pRipple size="small" [disabled]="loading" label="{{ 'admin.save' | translate}}" (onClick)="addInspection()" [disabled]="geometrySelected == null"></p-button>
          </div>
          <div class="ml-4">
              <p-button pRipple size="small" [disabled]="loading" label="{{ 'admin.saveexit' | translate}}" (onClick)="continue()" [disabled]="geometrySelected == null"></p-button>
          </div>

          <div class="ml-4">
            <p-button pRipple size="small" [outlined]="true" label="{{ 'admin.cancel' | translate}}" (click)="location.back()" ></p-button>
          </div>
        </div>

    </div>
    <div *ngIf="detailed">
        <app-inspections-create-general [ine]="ine" [record]="record" [newRecord]="newRecord" [form]="form" (onGeometrySelected)="geometry($event)"  ></app-inspections-create-general>
    </div>
    <div *ngIf="!detailed">
      <app-inspections-create-reduced [ine]="ine" [newRecord]="newRecord"  [form]="form" (onGeometrySelected)="geometry($event)"  ></app-inspections-create-reduced>
    </div>
    <div class="flex">
      <div class="mt-8 mr-4">
        <p-button pRipple size="small" [disabled]="loading" label="{{ 'admin.save' | translate}}" (onClick)="addInspection()" [disabled]="geometrySelected == null"></p-button>
      </div>
      <div class="mt-8">
          <p-button pRipple size="small" [disabled]="loading" label="{{ 'admin.saveexit' | translate}}" (onClick)="continue()" [disabled]="geometrySelected == null"></p-button>
      </div>
    </div>
</div>
