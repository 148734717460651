<div class="imgs__div flex gap-4" [ngClass]="flex">
    <figure class="imgs__preview w-72 h-49 border border-am-secondary-navy-20 m-0 relative" *ngFor="let img of control?.value; let i = index">
      <div class="w-full h-full relative">
        <img #image *ngIf="!isPDF(i)" [src]="img.data || img.url" alt="" class="object-contain w-72 h-49 cursor-pointer object-center" (click)="downloadDocument(i)">
        <img #image *ngIf="isPDF(i)" src="/assets/icons/PDF_file_icon.png" alt="" class="object-contain w-72 h-49 cursor-pointer object-center" (click)="downloadDocument(i)">

      </div>
      <span (click)="removeImg(i)" class="absolute top-4 right-4 cursor-pointer"></span>
    </figure>
    <div class="upFile__div relative inline-block w-72 h-49">
        <span [class.border-red-500]="error" class="bg-am-secondary-navy-5 text-am-secondary-navy-80 font-bold text-sm flex justify-center items-center gap-4 border-2 border-dashed border-am-secondary-navy-60 absolute left-0 right-0 top-0 bottom-0">{{label}}</span>
        <input type="file" name="images" id="images" (change)="addImage($event)" class="outline-0 opacity-0 w-full h-full cursor-pointer inline-block">
    </div>
</div>
