<div class="flex flex-col items-center justify-center gap-4">
  <div class="flex gap-1 justify-start w-5/6">

    <button class="h-11 py-[10px] px-4 text-am-primary-turquoise-100 border border-am-primary-turquoise-100 rounded-am-md flex items-center font-bold leading-am-4 text-sm" (click)="monthBefore()"><img src="assets/icons/tobackturquoise.svg" alt=""></button>

    <div class="font-bold text-am-primary-navy-100 px-1 text-center">

      <p-calendar inputStyleClass="text-am-primary-turquoise-100 font-bold leading-am-4"  view="month" dateFormat="mm/yy" [readonlyInput]="true" dateFormat="MM, yy" [(ngModel)]="current" (onSelect)="selectDate($event)"></p-calendar>

      <!-- <span>{{months[showingMonth].monthsName | titlecase}}, </span>
      <span>{{showingYear}}</span> -->
    </div>

    <button class="h-11 py-[10px] px-4 text-am-primary-turquoise-100 border border-am-primary-turquoise-100 rounded-am-md flex items-center font-bold leading-am-4 text-sm" (click)="monthAfter()"><img src="assets/icons/tonextturquoise.svg" alt=""></button>
  </div>
  <div class="grid grid-flow-row grid-cols-7 gap-1 w-5/6">
    <span class="text-center truncate font-normal text-base leading-[18.78px] text-am-secondary-navy-80" *ngFor="let day of weekDaysNames">{{day | titlecase}}</span>
  </div>
<ol class="list-none grid grid-flow-row grid-cols-7 gap-1 w-5/6">
  <li class="border w-full h-fit border-am-secondary-navy-30 p-2 aspect-square bg-am-secondary-navy-5"
      *ngFor="let shell of [].constructor(firstWeekDay((showingMonth) === -1 ? 11 : (showingMonth)) - 1); let c = count; let i = index">

    <span class="text-base leading-[18.78px] font-bold text-am-secondary-navy-30">{{months[(showingMonth - 1 === -1 ? 11 : showingMonth - 1)].monthsDays - (c - i - 1)}}</span>

  <li   (click)="selectDay(i + 1, showingMonth + 1, showingYear)"
        (dblclick)="create(i + 1, showingMonth + 1, showingYear);"
        class="border w-full h-fit border-am-secondary-navy-30 p-2 aspect-square cursor-pointer active:scale-[0.99] active:shadow-inner overflow-y-auto"
        [ngClass]="[calculateActualDay(i + 1) ? 'bg-am-secondary-turquoise-30' : '',isWeekend(i + 1) ? 'bg-am-secondary-navy-5' : '' ]"
        *ngFor="let shell of [].constructor(months[showingMonth].monthsDays); let i = index"
        data-dateto="{{ (i + 1) + '/' + (showingMonth + 1) + '/' + showingYear}}"
        pDroppable="inspections" (onDrop)="onDrop($event, i + 1, showingMonth + 1, showingYear)">
          <div class="flex items-end gap-2 mb-2">
            <span class="text-base leading-[18.78px] font-bold text-am-primary-navy-100">{{i + 1}}</span>
            <span class="font-medium text-xs leading-am-3 text-am-primary-navy-100 truncate" *ngIf="calculateActualDay(i + 1)">{{'inspections.today' | translate}}</span>
          </div>
          @if (haveAInspection(i, showingMonth, showingYear)) {
            <div class="align-end mb-2">
              <img id="trash" src="assets/icons/deleteImg.svg" class="w-4">
            </div>
          }
          <div class="overflow-scroll actual-scroll">
            <div *ngIf="haveAInspection(i, showingMonth, showingYear)" class="mt-1 ">
              <div  *ngFor="let inspec of haveAInspection(i, showingMonth, showingYear); let j = index"
                    class="flex items-center w-fit mb-1 pr-2"
                    (dblclick)="open(inspec);"
                    (click)="select(inspec);"
                    pDraggable="inspections"
                    data-date="{{ (i + 1) + '/' + (showingMonth + 1) + '/' + showingYear}}"
                    (onDragStart)="dragStart(inspec)"
                    (onDragEnd)="dragEnd($event, i + 1, showingMonth + 1, showingYear, inspec)"
                    (mouseenter)="onHover(inspec, $event)"
                    (mouseleave)="onLeave(j, $event)"
                    [ngClass]="actualInspection?.day === i + 1 && actualInspection?.hour === inspec.hour && actualInspection?.hour_end === inspec.hour_end ? 'bg-am-primary-navy-100 text-white' : 'bg-am-secondary-navy-10 text-am-primary-navy-100'">

                <div class="w-1 h-4 bg-am-secondary-navy-30 mr-1"></div>
                <span class="font-bold text-xs leading-am-3">{{inspec.date.getHours()}}</span>
                <span class="font-bold text-xs leading-am-3">:</span>
                <span class="font-bold text-xs leading-am-3 mr-1">{{formatMinutesTo00(inspec.date.getMinutes())}}</span>
                <span class="text-[0.656rem] leading-[0.771rem] font-normal">{{inspec.data.catastralReference}}</span>
              </div>
            </div>
          </div>
    </li>

    <li class="border w-full h-fit border-am-secondary-navy-30 p-2 aspect-square bg-am-secondary-navy-5" *ngFor="let shell of [].constructor(calculateRestOfDays(months[showingMonth].monthsDays, (firstWeekDay(showingMonth) - 1)) <= 0 ? 0 : calculateRestOfDays(months[showingMonth].monthsDays, (firstWeekDay(showingMonth) - 1))); let i = index">
    <span class="text-base leading-[18.78px] font-bold text-am-secondary-navy-30">{{i + 1}}</span>
  </li>
</ol>
</div>

<p-confirmDialog key="delete" #cd [style]="{ width: '50vw' }">
  <ng-template pTemplate="header">
      <h3 class="font-bold">Eliminar</h3>
  </ng-template>
  <ng-template pTemplate="message">
      <div style="width:100%" class="text-sm text-center font-semibold w-full">
          <div>
              ¿Seguro que deseas eliminar la inspección?
          </div>
      </div>
  </ng-template>
  <ng-template pTemplate="footer">
    <div class="flex flex-row mt-4">
      <p-button type="button" [label]="'Cancelar'" (click)="cd.reject()" size="small" [outlined]="true"></p-button>
      <p-button type="button" [label]="'Eliminar'" (click)="cd.accept()" size="small" severity="danger"></p-button>
    </div>
  </ng-template>
</p-confirmDialog>

<p-overlayPanel #tooltip>
  <div class="flex flex-col w-fit">
    <p>{{selectedInspect?.data.inspector.firstName}} {{selectedInspect?.data.inspector.lastName}} ({{selectedInspect?.data.inspector.email}})</p>
    <p>{{selectedInspect?.data.address}}</p>
    <p>{{selectedInspect?.data.province}}</p>
    <p>{{selectedInspect?.data.catastralReference || 'Sin referencia catastral'}}</p>
  </div>
</p-overlayPanel>
