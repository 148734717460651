import { HttpClient } from '@angular/common/http';
import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import {
  ConfirmEventType,
  ConfirmationService,
  FilterMetadata,
  MessageService,
} from 'primeng/api';
import { Table } from 'primeng/table';
import { environment } from 'src/environments/environment';
import { lastValueFrom } from 'rxjs';
import {
  PermissionScope,
  PermissionType,
  User,
  UserService,
} from '../../services/user.service';

@Component({
  selector: 'app-record-list',
  templateUrl: './record-list.component.html',
  styleUrls: ['./record-list.component.scss'],
})
export class RecordListComponent {
  @ViewChild('dt') table?: Table;

  data?: any[];
  totalRecords?: number;
  loading?: boolean;

  user?: User;
  canEdit: boolean = false;
  canDelete: boolean = false;
  canView: boolean = false;
  canViewAll: boolean = false;
  isClient: boolean = false;
  isProvider: boolean = false;
  isOrganization: boolean = false;

  providerOwnerId: string = '';
  clientInternalId?: number;

  @Input() embedded: boolean = false;

  @Input() deletable = false;
  @Input() owner_id?: string = '';

  @Output() onSelected = new EventEmitter<any>();

  constructor(
    private http: HttpClient,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private userSvc: UserService,
  ) {}

  private clientPermissions() {
    this.canEdit = false;
    this.canDelete = false;
    this.canView = true;
    this.canViewAll = true;
  }

  private providerPermissions() {
    this.canEdit =
      this.user?.hasPermission(PermissionScope.RECORDS, PermissionType.EDIT) ||
      false;
    this.canDelete =
      this.user?.hasPermission(
        PermissionScope.RECORDS,
        PermissionType.DELETE,
      ) || false;
    this.canView =
      this.user?.hasPermission(PermissionScope.RECORDS, PermissionType.VIEW) ||
      false;
    this.canViewAll =
      this.user?.hasPermission(
        PermissionScope.RECORDS,
        PermissionType.VIEW_ALL,
      ) || false;
  }

  private organizationPermissions() {
    this.canEdit =
      this.user?.hasPermission(PermissionScope.RECORDS, PermissionType.EDIT) ||
      false;
    this.canDelete =
      this.user?.hasPermission(
        PermissionScope.RECORDS,
        PermissionType.DELETE,
      ) || false;
    this.canView =
      this.user?.hasPermission(PermissionScope.RECORDS, PermissionType.VIEW) ||
      false;
    this.canViewAll =
      this.user?.hasPermission(
        PermissionScope.RECORDS,
        PermissionType.VIEW_ALL,
      ) || false;
  }

  async ngOnInit() {
    this.user = this.userSvc.getUser();

    this.isClient = this.user?.is_client || false;
    this.isProvider = this.user?.is_provider || false;
    this.isOrganization = this.user?.is_organization || false;

    if (this.isClient) {
      this.clientPermissions();

      const ayto: any = await lastValueFrom(
        this.http.get(
          environment.api + 'localities/by_owner/' + this.user?.owner_id,
        ),
      );

      console.log(ayto);

      const record: any = await lastValueFrom(
        this.http.get(
          environment.api + 'expedientes/bylocality/' + ayto.ineInternalId,
        ),
      );

      console.log(record);

      this.providerOwnerId = record.owner_id;
      this.clientInternalId = ayto.ineInternalId;

      //this.owner_id = record.owner_id;
    }

    if (this.isProvider) {
      this.providerPermissions();
      this.owner_id = this.user?.owner_id;
    }

    if (this.isOrganization) {
      this.organizationPermissions();
    }

    /*
    if (result?.type === 'client') {

      const ayto: any = await lastValueFrom(this.http.get(environment.api + 'localities/by_owner/' + this.owner_id));

      const record: any = await lastValueFrom(this.http.get(environment.api + 'record/bylocality/' + ayto.ineInternalId));

      console.log(record);

      this.owner_id = record.owner_id;

      this.canEdit = false;
      this.canDelete = false;

      if (this.user) {
        this.user.owner_id = this.owner_id || '';

        console.log(this.user);

        if (this.userSvc?.user) {

          this.isClient = true;
          this.user.is_client = true;
          this.userSvc.user = this?.user;

          this.canEdit = false;
          this.canDelete = false;

          console.log(this.userSvc.user);

        }

      }
    }
    */

    await this.load();
  }

  async load(event?: any) {
    this.loading = true;
    const page = event ? event.first / event.rows : 0;
    const limit = event ? event.rows : 10;
    const sortField = event ? event.sortField : this.table?._sortField || '';
    const sortOrder =
      (event
        ? event.sortOrder === 1
          ? 'asc'
          : 'desc'
        : this.table?._sortOrder === 1
          ? 'asc'
          : 'desc') || 'asc';
    const searchTerm =
      event?.globalFilter ||
      (this.table?.filters['global'] as FilterMetadata)?.value ||
      '';

    let requestUrl = '';

    console.log('is_client', this.isClient);
    console.log('is_provider', this.isProvider);
    console.log('is_organization', this.isOrganization);

    if (this.isClient && this.clientInternalId) {
      requestUrl = `${environment.api}expedientes/bymunicipe/${this.clientInternalId}?page=${page}&limit=${limit}&sortField=${sortField}&sortOrder=${sortOrder}&searchTerm=${searchTerm}`;
    }

    if (this.isOrganization) {
      requestUrl = `${environment.api}expedientes?page=${page}&limit=${limit}&sortField=${sortField}&sortOrder=${sortOrder}&searchTerm=${searchTerm}`;
    }

    if (this.isProvider) {
      if (this.canView) {
        requestUrl = `${environment.api}expedientes/${this.owner_id}?page=${page}&limit=${limit}&sortField=${sortField}&sortOrder=${sortOrder}&searchTerm=${searchTerm}`;
      } else {
        if (this.canViewAll) {
          requestUrl = `${environment.api}expedientes/list/${this.owner_id}?page=${page}&limit=${limit}&sortField=${sortField}&sortOrder=${sortOrder}&searchTerm=${searchTerm}`;
        }
      }
    }

    this.data = [];
    this.totalRecords = 0;

    console.log(requestUrl);

    const data = await lastValueFrom(this.http.get<any>(requestUrl));

    console.log(data);

    this.data = data.data;
    this.totalRecords = data.total;
    this.loading = false;
  }

  selected(data: any) {
    this.onSelected.emit(data);
  }

  delete(ev: Event, record: any) {
    ev?.stopPropagation();

    this.confirmationService.confirm({
      target: record,
      header: 'Eliminar expediente',
      message: `<strong>¿Seguro que eliminar el expediente ID <strong>${record?.id} (${record?.reference})</strong>?`,
      acceptLabel: 'Eliminar',
      accept: async () => {
        const index = this.data?.findIndex((r) => r.id === record.id);

        this.data = this.data?.filter((r) => r.id !== record.id);

        await this.http
          .delete(environment.api + 'expedientes/' + record.id)
          .toPromise()
          .then((_data: any) => {
            this.data = this.data?.filter((r) => r.id !== record.id);
            this.messageService.add({
              severity: 'success',
              summary: 'Expediente eliminado',
              detail: `El expediente ID ${record?.id} (${record?.reference}) ha sido eliminado`,
            });
          })
          .catch((error) => {
            console.log(error);
            this.data?.splice(index || 0, 0, record);
            this.messageService.add({
              severity: 'error',
              summary: 'Error al eliminar el expediente',
              detail: `Se ha producido un error al eliminar el expediente ID ${record?.id} (${record?.reference}) (${error?.message})`,
            });
          });
      },
      reject: (_type: ConfirmEventType) => {
        close();
      },
    });
  }

  deactivate(ev: Event, record: any) {
    ev?.stopPropagation();

    this.confirmationService.confirm({
      target: record,
      header: 'Desactivar expediente',
      message: `<strong>¿Seguro que desactivar el expediente ID <strong>${record?.id} (${record?.reference})</strong>?`,
      acceptLabel: 'Desactivar',
      accept: async () => {
        //const index = this.data?.findIndex((r) => r.id === record.id);
        this.data = this.data?.filter((r) => r.id !== record.id);
        await lastValueFrom(
          this.http.put(environment.api + 'expedientes/' + record.id, {
            status: 'INACTIVE',
          }),
        );
        this.messageService.add({
          severity: 'success',
          summary: 'Expediente desactivado',
          detail: `El expediente ID ${record?.id} (${record?.reference}) ha sido desactivado`,
        });
        await this.load();
      },
      reject: (_type: ConfirmEventType) => {
        close();
      },
    });
  }
}
