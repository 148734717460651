<div class="login__content flex flex-col items-center py-16 gap-8">
  <h2 class="font-bold text-am-2xl text-am-primary-navy-100 leading-am-8">{{'login.init' | translate}}</h2>
  <form [formGroup]="form" (keydown.enter)="$event.preventDefault()" class="flex flex-col gap-6">
<div class="in__email flex flex-col gap-2">
  <label for="email" class="font-medium text-xs leading-am-3 text-am-secondary-navy-60 ml-1">{{'login.email' | translate}}</label>
  <input type="text" name="email" id="email" [placeholder]="'login.placeholder' | translate" formControlName="email" autocomplete="username" [ngClass]="{'border-red-500': form.get('email')?.invalid && form.get('email')?.touched, 'border': true}"
  class="outline-0 w-72 h-11 rounded-am-md border py-1.5 pr-2 pl-4 border-am-secondary-navy-30 placeholder:font-medium placeholder:text-sm placeholder:leading-am-4 placeholder:text-am-neutral-gray-60 focus:border-am-secondary-turquoise-60">
</div>
<div class="in__password flex flex-col gap-2">
  <label for="password" class="font-medium text-xs leading-am-3 text-am-secondary-navy-60 ml-1">{{'login.password' | translate}}</label>
  <p-password name="password" id="password" placeholder="" formControlName="password" [toggleMask]="true" [feedback]="false" autocomplete="password" [inputStyleClass]="(form.get('password')?.invalid && form.get('password')?.touched ? 'border-red-500' : '') + ' outline-0 w-72 h-11 rounded-am-md border py-1.5 pr-2 pl-4 border-am-secondary-navy-30 text-am-primary-navy-100'" class="password__login"></p-password>
</div>
<div class="in__remember flex flex-row items-center">
  <p-inputSwitch name="remember" id="remember" formControlName="remember_password" class="remember__switch"></p-inputSwitch>
  <label for="remember" class="font-semibold text-sm leading-am-4 text-am-gray">{{'login.rememberPassword' | translate}}</label>
</div>
<div class="error__login text-red-500 text-sm" *ngIf="errorLogin" >{{'login.error' | translate}}</div>
<button class="entry__button bg-am-primary-turquoise-100 rounded-am-md text-white w-72 h-10 text-sm leading-am-4 font-bold py-2.5 px-4" (click)="login()">{{'login.entry' | translate}}</button>
  </form>
  <a href="" class="font-bold text-sm text-am-primary-mint-100 leading-am-4"
  routerLink="/recoverpass"
  >{{'login.forgotPassword' | translate}}</a>

  <!-- <button class="social__button border flex flex-row items-center justify-center gap-3 border-am-primary-navy-100 rounded-am-md py-2.5 px-4 w-72 h-10 text-am-primary-navy-100 font-bold text-sm leading-am-4"><span class="w-4 h-4 bg-red-400 rounded-full" (click)="signWithGoogle()"></span>Entrar con Google</button> -->

  <div id="g_id_onload"
  class="hidden"
  data-client_id="185109312610-44nc35thps28rislmuooq7aambl8c5k9.apps.googleusercontent.com"
  data-context="signin"
  data-ux_mode="popup"
  data-login_uri="http://localhost:4200"
  data-auto_prompt="false">
</div>

<div class="g_id_signin"
  data-type="standard"
  data-shape="rectangular"
  data-theme="outline"
  data-text="signin_with"
  data-size="large"
  data-logo_alignment="center"
  data-width="288">
</div>

</div>
