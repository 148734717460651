import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class StoredBillStoreService {
  public selectedBill$: Observable<any> = new Observable();

  constructor() {}

  public setSelectedBill(bill: any): void {
    this.selectedBill$ = new Observable((observer) => {
      observer.next(bill);
    });
  }
}
