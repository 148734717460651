import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-inspections-create-date',
  templateUrl: './inspections-create-date.component.html',
  styleUrls: ['./inspections-create-date.component.scss'],
})
export class InspectionsCreateDateComponent {
  @Input() form?: FormGroup;
}
