import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-permissions',
  templateUrl: './permissions.component.html',
  styleUrls: ['./permissions.component.scss'],
})
export class PermissionsComponent implements OnInit {
  scopes_es = {
    ADMIN: 'admin',
    CLIENTS: 'clientes',
    RECORDS: 'expedientes',
    INSPECTIONS: 'inspecciones',
    USERS: 'usuarios',
    INSPECTION_INFORM: 'informes',
    WITHDRAWALS: 'retiradas',
  };
  types_es = {
    VIEW: 'view',
    DELETE: 'delete',
    CREATE: 'create',
    EDIT: 'edit',
    VIEW_ALL: 'view_all',
  };

  public scopes: string[] = [];
  public types: string[] = [];

  public scope_literal: string[] = [];

  public form = new FormGroup({});

  isAdmin: boolean = false;

  @Input() editable: boolean = false;
  @Output() public permissionsChange: EventEmitter<any> =
    new EventEmitter<any>();
  @Input() set permissions(permissions: any) {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    this.update(permissions);
  }

  constructor(
    private readonly http: HttpClient,
    private userSvc: UserService,
  ) {}

  ngOnInit() {
    this.scope_literal = Object.keys(this.scopes_es);

    const user = this.userSvc.getUser();

    if (user?.owner_id == '') {
      this.isAdmin = true;
    }
  }

  async getDefaultsByRole(role: string) {
    const rolesPermissions = await this.http
      .get<{ [key: string]: any }>(environment.api + 'users/permissions')
      .toPromise();
    const permissions = rolesPermissions ? rolesPermissions[role] : {};
    await this.update(permissions);
  }

  async update(permissions: any) {
    console.log('update', permissions);
    if (!permissions) {
      console.error('Permissions empty');
      return;
    }

    const form = new FormGroup({});

    this.scopes = Object.keys(permissions);
    if (this.scopes.length === 0) {
      console.error('Permissions empty');
      return;
    }
    this.types = Object.keys(permissions[this.scopes[0]]);

    console.log('scopes', this.scopes);
    console.log('types', this.types);
    console.log('scope_literal', this.scope_literal);

    console.log('permissions', permissions);

    console.log('isAdmin', this.isAdmin);

    if (!this.isAdmin) {
      this.scope_literal = this.scope_literal.filter(
        (scope: any) => scope !== 'ADMIN',
      );
    }

    for (const scope of this.scope_literal) {
      const scopeForm = new FormGroup({});
      form.addControl(scope, scopeForm);

      for (const type in this.types_es) {
        let perm;

        if (permissions[scope] !== undefined) {
          perm = permissions[scope][type];
        } else {
          perm = false;
        }

        console.log('perm', perm);

        scopeForm.addControl(
          type,
          new FormControl({ value: perm, disabled: !this.editable }),
        );
      }
    }

    console.log('form', form);

    form.valueChanges.subscribe((value) => {
      this.permissionsChange.emit(value);
    });
    this.form = form;
    this.permissionsChange.emit(this.form.value);
  }
}
