import { ChangeDetectorRef, Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { PermissionScope, UserService } from './shared/services/user.service';
import { GlobalService } from './shared/services/global.service';
import { PrimeNGConfig } from 'primeng/api';
import { environment } from '../environments/environment';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'amiantic';

  embedded = false;

  constructor(
    private readonly userService: UserService,
    private router: Router,
    private readonly global: GlobalService,
    public translate: TranslateService,
    private config: PrimeNGConfig,
    private detector: ChangeDetectorRef,
  ) {
    if (environment.env === 'production') {
      console.log = (..._args) => {};
    }

    translate.addLangs(['es', 'en']);

    const lang = ['es', 'en'].includes(translate.getBrowserLang() || '')
      ? translate.getBrowserLang()
      : 'es';

    translate.setDefaultLang(lang || 'es');

    this.translate
      .get('primeng')
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      .subscribe((res) => this.config.setTranslation(res));

    /*
      } else {
      translate.use(lang);
      this.translate
        .get('primeng')
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        .subscribe((res) => this.config.setTranslation(res));
    }

    this.translate
      .get('primeng')
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      .subscribe((res) => this.config.setTranslation(res));
    */
  }

  async ngOnInit() {
    //initTE({ Input });

    this.detector.detach();
    const user = await this.userService.restoreLogin();

    console.log(user);
    this.detector.reattach();
    this.detector.detectChanges();

    if (user !== undefined) {
      if (user.checkHasViewPermission(PermissionScope.ADMIN)) {
        await this.router.navigate(['/admin/clients']);
        return;
      }

      if (user.checkHasViewPermission(PermissionScope.RECORDS)) {
        await this.router.navigate(['/records']);
      } else {
        await this.router.navigate(['/account/profile']);
      }
    }
  }

  md() {
    this.embedded = this.global.embedded;
  }
}
