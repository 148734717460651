import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-retiradas',
  templateUrl: './retiradas.component.html',
  styleUrls: ['./retiradas.component.css'],
})
export class RetiradasComponent implements OnInit {
  selectedItem: string = 'retiradas';

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private detector: ChangeDetectorRef,
  ) {}

  async ngOnInit() {
    await this.router.navigate(['withdrawal'], { relativeTo: this.route });

    this.router.events.subscribe((val) => {
      // Chequeamos NavigationStart para evitar errores
      if (val.constructor.name === 'NavigationEnd') {
        // Obtenemos la ruta actual
        const currentRoute = this.router.url;

        console.log(currentRoute);

        this.detector.detach();

        if (currentRoute.endsWith('dashboard')) {
          this.selectedItem = 'dashboard';
        }

        if (currentRoute.endsWith('withdrawal')) {
          this.selectedItem = 'retiradas';
        }

        console.log(this.selectedItem);

        this.detector.reattach();
        this.detector.detectChanges();
      }
    });
  }

  changeTab(tab: string) {
    this.selectedItem = tab;
  }
}
