<div class="m-10">
  <!--Main Administracion-->
  <!--Div con Titulo y boton añadir usuario-->
  <div class="flex item-center w-full mt-14 mb-4">
    <h2 class="flex-grow text-xl font-bold text-am-primary-navy-100">{{'admin.bills_emitted' | translate}}</h2>
  </div>


  <!--Div Input Busqueda y Filtrado-->
  <div class="flex justify-between mt-4 mb-4 items-start">
    <span class="p-input-icon-right h-max">
      <i class="pi pi-search"></i>
      <input type="text" pInputText class="p-inputtext-sm w-100" [placeholder]="'ui.general.buscar' | translate"
        (input)="dt.filterGlobal($event.target.value, 'contains')" />
    </span>
  </div>

  <p-table #dt class="" [value]="bills" [paginator]="true" [rows]="10" [totalRecords]="totalRecords"
    [loading]="loading" [rowHover]="true" [showCurrentPageReport]="true"
    [globalFilterFields]="['businessName', 'fromDate', 'toDate', 'cif']" currentPageReportTemplate="{currentPage} de {totalPages}">

    <ng-template pTemplate="header">
      <tr>
        <th pSortableColumn="cliente.businessName">{{ 'contracts.current_bill.table.client' | translate }}<p-sortIcon field="cliente.businessName"></p-sortIcon></th>
        <th pSortableColumn="name">{{ 'contracts.current_bill.table.cif' | translate }} <p-sortIcon field="CIF"></p-sortIcon></th>
        <th pSortableColumn="fromDate">{{ 'contracts.current_bill.table.from' | translate }} <p-sortIcon field="fromDate"></p-sortIcon></th>
        <th pSortableColumn="toDate">{{ 'contracts.current_bill.table.to' | translate }} <p-sortIcon field="toDate"></p-sortIcon></th>
        <th pSortableColumn="basImponible">{{ 'contracts.current_bill.table.base' | translate }}<p-sortIcon field="baseImponible"></p-sortIcon></th>
        <th style="width: 4rem"></th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-data>
      <tr>
        <td>{{ data.cliente.businessName }}</td>
        <td>{{ data.cliente.cif }}</td>
        <td>{{ data.fromDate | date  }}</td>
        <td>{{ data.toDate | date }}</td>
        <td>{{ (data.baseImponible | currency:'':'') + ' €' }}</td>
        <td>
          <button pButton pRipple type="button" icon="pi pi-chevron-right"
            class="p-button-text p-button-rounded p-button-plain" (click)="selectBill(data)"></button>

        </td>
      </tr>
    </ng-template>


    <ng-template pTemplate="emptymessage" let-columns let-frozen="frozen">
      <tr>
        <td colspan="6">
          <div class="text-center p-10">{{ 'ui.general.noresults' | translate}}</div>
        </td>
      </tr>
    </ng-template>

    <ng-template pTemplate="sorticon" let-number>
      <i *ngIf="number == 0" class="pi pi-sort" style="opacity:0.2"></i>
      <i *ngIf="number == 1" class="pi pi-sort-down"></i>
      <i *ngIf="number == -1" class="pi pi-sort-up"></i>
    </ng-template>

  </p-table>
</div>
