<div class="mb-8" style="background: #f0f0f0; text-align: center;">
  <div class="relative">
    <div class="absolute z-10 w-full h-full flex items-center justify-center bg-opacity-50 bg-gray-700"
      *ngIf="loadingMap">
      <p-progressSpinner styleClass="custom-spinner"></p-progressSpinner>
    </div>
    <div class="relative z-0">
      <app-map #map [loadFromUrl]="false" [data]="geometries"
        [enable_address_bar]="true"
        [draw_enabled_on_startup]="true"
        [enable_draw]="false"
        [is_geolocation_enabled]="true"
        [get_coordinates_on_click]="false"
        [show_on_click]="true"
        class="flex h-[600px]"
        (onDrawGeometry)="geometry($event)" (onFinishLoading)="finishLoading()"></app-map>
    </div>
  </div>
</div>
<div class="flex flex-col gap-10">
  <div class="flex gap-6">
    <div class=" flex flex-col p-input-underline">
      <label for="catastralReference" class="font-medium text-sm text-am-secondary-navy-60 ">{{
        'inspections.cadastralReferenceAbbreviated' | translate}}</label>
      <input pInputText id="catastralReference" type="text" [placeholder]="'Ej. 1234567890123456789'"
        [formControl]="form?.get('catastralReference')" maxlength="30">
    </div>
    <div class=" flex flex-col w-1/3 p-input-underline">
      <label for="locality" class="font-medium text-sm text-am-secondary-navy-60 ">{{ 'inspections.locality' |
        translate}}</label>
      <input pInputText id="locality" type="text" [placeholder]="'Ej. Madrid'" [formControl]="form?.get('locality')"
        maxlength="45">
    </div>
    <div class=" flex flex-col p-input-underline">
      <label for="year" class="font-medium text-sm text-am-secondary-navy-60 ">{{ 'inspections.year' |
        translate}}</label>
      <input pInputText id="year" type="text" [placeholder]="'Ej. 2021'" [formControl]="form?.get('year')"
        onkeydown="return (event.keyCode !== 69) && (event.keyCode !== 187) && (event.keyCode !== 189)">
    </div>
  </div>
  <div class="flex flex-stretch gap-6 mb-6">
    <!--Uso emplazamiento-->
    <div class=" flex flex-col w-1/3 p-input-underline">
      <label for="locationUse" class="font-medium text-sm text-am-secondary-navy-60 ">{{ 'inspections.locationUse' |
        translate}}</label>
      <p-dropdown [options]="objectKeys(locationUseOptions)" optionLabel="label"
        [formControl]="form?.get('locationUse')" placeholder="{{ 'inspections.selectUse' | translate}}"
        [style]="{'minWidth':'100%'}">
        <ng-template let-item pTemplate="selectedItem">{{ locationUseOptions[item] }}</ng-template>
        <ng-template let-item pTemplate="item">{{ locationUseOptions[item] }}</ng-template>
      </p-dropdown>
      </div>
      <div class=" flex flex-col w-1/3 p-input-underline">
        <label for="inspector" class="font-medium text-sm text-am-secondary-navy-60 ">{{ 'inspections.inspector' | translate}}</label>
        <app-user-input [control]="form?.get('inspector')"></app-user-input>
      </div>
    </div>
    <div class="flex gap-6  mb-6">
      <div class=" flex flex-col">
        <label for="date" class="font-medium text-sm text-am-secondary-navy-60 ">{{ 'inspections.inspectionDate2' | translate}}</label>
        <p-calendar [showTime]="true" [showIcon]="true" [placeholder]="'Ej. 2021-01-01'" dateFormat="dd/mm/yy"
          [formControl]="form?.get('date')"></p-calendar>
      </div>
    </div>
  </div>

<div class=" flex flex-col">
  <label for="relevantInformation" class="font-medium text-sm text-am-secondary-navy-60 ">{{ 'inspections.relevantInfo' | translate}}</label>
  <textarea pInputTextarea id="relevantInformation" type="text" [placeholder]="'inspections.relevantInfoPlaceholder' | translate"
    [formControl]="form?.get('relevantInformation')" maxlength="255"></textarea>
</div>
