<div class="flex flex-col" (window:resize)="onResize($event)">

  <div class="userinfo__aside flex flex-col gap-16">
    <div class="anchor__return flex gap-2 items-center">
      <a (click)="getBack()" class="text-am-primary-turquoise-100 font-bold text-sm cursor-pointer">{{'userZone.back' |
        translate}}</a>
    </div>
  </div>
  <div class="card flex flex-wrap justify-content-center gap-2">

    @if (mustShowBurguer()) {
      <p-sidebar #aside [(visible)]="sidebarVisible" position="left">
        <ng-container [ngTemplateOutlet]="navbar"></ng-container>
      </p-sidebar>
    }
    @else {
      <div class="mt-8">
        <ng-container [ngTemplateOutlet]="navbar"></ng-container>
      </div>
    }

  </div>

  <p-button [hidden]="!mustShowBurguer()" type="button" class="w-8 h-8 mt-8" (click)="toggleSidebar()" icon="pi pi-bars"></p-button>

</div>

<ng-template #navbar>
  <nav>
    <ul (click)="toggleSidebar()">
      <li class="text-am-primary-navy-100 font-semibold text-sm leading-am-4 border-b w-[345px]">
        <a [routerLink]="['profile']" class="py-4 flex flex-row items-center justify-between"
          routerLinkActive="text-am-primary-turquoise-100">{{'userZone.myAccount' | translate}}</a>
      </li>
      <!---
<li  class="text-am-primary-navy-100 font-semibold text-sm leading-am-4 border-b w-[345px]">
  <a [routerLink]="[null]" class="py-4 flex flex-row items-center justify-between" routerLinkActive="text-am-primary-turquoise-100">{{'userZone.accessAndSecurity' | translate}}</a>
</li>
<li  class="text-am-primary-navy-100 font-semibold text-sm leading-am-4 border-b w-[345px]">
  <a [routerLink]="[null]" class="py-4 flex flex-row items-center justify-between" routerLinkActive="text-am-primary-turquoise-100">{{'userZone.notificationManagement' | translate}}</a>
</li>
<li  class="text-am-primary-navy-100 font-semibold text-sm leading-am-4 border-b w-[345px]">
  <a [routerLink]="[null]" class="py-4 flex flex-row items-center justify-between" routerLinkActive="text-am-primary-turquoise-100">{{'userZone.privacy' | translate}}</a>
</li>
<li  class="text-am-primary-navy-100 font-semibold text-sm leading-am-4 border-b w-[345px]">
  <a [routerLink]="[null]" class="py-4 flex flex-row items-center justify-between" routerLinkActive="text-am-primary-turquoise-100">{{'userZone.billingAndPlans' | translate}}</a>
</li>
-->
      <li class="text-am-primary-navy-100 font-semibold text-sm leading-am-4 border-b w-[345px]">
        <a [routerLink]="['permissions']" class="py-4 flex flex-row items-center justify-between"
          routerLinkActive="text-am-primary-turquoise-100">{{'userZone.permissions' | translate}}</a>
      </li>
      <li *ngIf="isAdmin && owner_id != ''"
        class="text-am-primary-navy-100 font-semibold text-sm leading-am-4 border-b w-[345px]">
        <a [routerLink]="['company']" [queryParams]="{ id: owner_id, embed:true}"
          class="py-4 flex flex-row items-center justify-between"
          routerLinkActive="text-am-primary-turquoise-100">{{'userZone.company' | translate}}</a>
      </li>

      <li *ngIf="isAdmin && isProvider"
        class="text-am-primary-navy-100 font-semibold text-sm leading-am-4 border-b w-[345px]">
        <a [routerLink]="['company/clients']" [queryParams]="{ id: owner_id}"
          class="py-4 flex flex-row items-center justify-between"
          routerLinkActive="text-am-primary-turquoise-100">{{'userZone.customers' | translate}}</a>
      </li>
      <li *ngIf="isAdmin && isProvider"
        class="text-am-primary-navy-100 font-semibold text-sm leading-am-4 border-b w-[345px]">
        <a [routerLink]="['pending-contracts']" class="py-4 flex flex-row items-center justify-between"
          routerLinkActive="text-am-primary-turquoise-100">{{'userZone.contracts.pendingToSend' | translate}}</a>
      </li>

      <li *ngIf="isAdmin && isProvider"
        class="text-am-primary-navy-100 font-semibold text-sm leading-am-4 border-b w-[345px]">
        <a [routerLink]="['pending-approval-contracts']" class="py-4 flex flex-row items-center justify-between"
          routerLinkActive="text-am-primary-turquoise-100">{{'userZone.contracts.pendingToApprove' | translate}}</a>
      </li>

      <li *ngIf="isAdmin && isProvider"
        class="text-am-primary-navy-100 font-semibold text-sm leading-am-4 border-b w-[345px]">
        <a [routerLink]="['pending-auth-contracts']" class="py-4 flex flex-row items-center justify-between"
          routerLinkActive="text-am-primary-turquoise-100">{{'userZone.contracts.pendingToAuth' | translate}}</a>
      </li>

      <li *ngIf="isAdmin && isProvider"
        class="text-am-primary-navy-100 font-semibold text-sm leading-am-4 border-b w-[345px]">
        <a [routerLink]="['current-contracts']" class="py-4 flex flex-row items-center justify-between"
          routerLinkActive="text-am-primary-turquoise-100">{{'userZone.contracts.actives' | translate}}</a>
      </li>

      <li *ngIf="isAdmin && isProvider"
        class="text-am-primary-navy-100 font-semibold text-sm leading-am-4 border-b w-[345px]">
        <a [routerLink]="['current-bill']" class="py-4 flex flex-row items-center justify-between"
          routerLinkActive="text-am-primary-turquoise-100">{{'userZone.contracts.currentBill' | translate}}</a>
      </li>

      <li class="text-am-primary-navy-100 font-semibold text-sm leading-am-4 border-b w-[345px]">
        <a [routerLink]="['bills/emitted']" class="py-4 flex flex-row items-center justify-between" routerLinkActive="text-am-primary-turquoise-100">{{'userZone.contracts.billsEmitted' | translate}}</a>
    </li>

      <li class="text-am-primary-navy-100 font-semibold text-sm leading-am-4 border-b w-[345px]">
        <a [routerLink]="['notifications']" [queryParams]="{ id: owner_id, embed:true}"
          class="py-4 flex flex-row items-center justify-between"
          routerLinkActive="text-am-primary-turquoise-100">{{'userZone.notifications' | translate}}</a>
      </li>
      <li class="text-am-primary-navy-100 font-semibold text-sm leading-am-4 border-b w-[345px]">
        <a [routerLink]="['security']" [queryParams]="{ id: owner_id, embed:true}"
          class="py-4 flex flex-row items-center justify-between"
          routerLinkActive="text-am-primary-turquoise-100">{{'userZone.security' | translate}}</a>
      </li>
      <li class="text-am-primary-navy-100 font-semibold text-sm leading-am-4 border-b w-[345px]">
        <a [routerLink]="['shareddata']" [queryParams]="{ id: owner_id, embed:true}"
          class="py-4 flex flex-row items-center justify-between" routerLinkActive="text-am-primary-turquoise-100">{{
          "profile.policy" | translate }}</a>
      </li>
    </ul>
  </nav>

  <div class="close__session flex gap-2 items-center mt-12">
    <a (click)="logout()"
      class="text-am-primary-turquoise-100 font-bold text-sm cursor-pointer">{{'userZone.closeSesion' |
      translate}}</a>
  </div>
</ng-template>
