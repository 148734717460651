export const MaterialList = [
  'Adhesivos',
  'Cemento adhesivo',
  'Calafateo',
  'Cemento',
  'Adhesivo para conductos',
  'Cemento de acabado',
  'Cemento de horno',
  'Cemento aislante',
  'Cemento para juntas',
  'Cemento Masónico',
  'Masillas',
  'Mortero',
  'Cemento Plástico',
  'Sellador',
  'Papel de amianto',
  'Papel corrugado',
  'Tablero flexible',
  'Millboard',
  'Tablero permanente',
  'Rollo',
  'Papel pintado de vinilo.',
  'Forros de freno',
  'Pastillas de freno',
  'Frenos',
  'Forros de embrague',
  'Frenos de disco',
  'Frenos de tambor',
  'Zapatas de freno de ascensor',
  'Placas de transmisión',
  'Tablero de amianto',
  'Hojas de amianto',
  'Tuberías de cemento',
  'Plásticos',
  'Revestimiento de piedra',
  'Yeso Acústico',
  'asfalto',
  'Relleno',
  'Finalizar',
  'Compuesto para juntas',
  'Pintar',
  'Parcheo',
  'Yeso',
  'masilla',
  'Compuestos de masilla',
  'Calderas',
  'Cables y alambres',
  'Tableros Eléctricos',
  'Hornos',
  'Generadores',
  'Conductos de calefacción',
  'bombas',
  'Turbinas',
  'Anillos de válvula',
  'válvulas',
  'Chaquetas resistentes a la intemperie',
  'Aislamiento de cableado',
  'Tejas del techo',
  'Baldosas',
  'Suelos',
  'Losas',
  'Pisos de vinilo y linóleo',
  'Azulejos de pared',
  'Embalaje trenzado',
  'Material de junta',
  'Juntas',
  'Material de empaque',
  'Embalaje de cuerda',
  'Embalaje de hojas',
  'Relleno Agrícola',
  'Aislamiento del ático',
  'Filtros de cigarrillos',
  'Ollas de barro',
  'Fertilizante',
  'Campanas de extracción',
  'Soportes de hierro',
  'Fundas para tablas de planchar',
  'Campanas de laboratorio',
  'Mezclas para macetas',
  'Alfombrillas para estufa',
  'Paneles Acústicos',
  'Paneles de yeso',
  'Panel marino',
  'Paneles',
  'Placa de yeso',
  'Panel de yeso',
  'Aislamiento de bloques',
  'Silicato de calcio',
  'Aislamiento de conductos',
  'Aislamiento',
  'magnesia',
  'Aislamiento de tuberías',
  'Envoltura de tubería preformada',
  'Bloque de esponja',
  'Chaqueta sin mangas',
  'Delantales',
  'Casco de amianto',
  'Máscaras antipolvo',
  'Manoplas para sopladores de vidrio',
  'Guantes',
  'Guantes de laboratorio',
  'Mitones y manoplas',
  'respiradores',
  'Mangas',
  'Prendas Textiles',
  'Cortinas de amianto',
  'Aerosol de amianto',
  'Revestimiento de calderas',
  'Mantas ignífugas',
  'Compuertas cortafuegos',
  'Puertas de fuego',
  'Cemento ignífugo',
  'Materiales ignífugos',
  'Revestimiento aislante',
  'Revestimiento de techo',
  'Recubrimientos texturizados',
  'Recubrimiento',
  'Fibra de amianto',
  'Nieve falsa',
  'Amianto en bruto',
  'Silicato de calsilita',
  'Polvo de talco',
  'Tránsito',
  'vermiculita',
  'Castables',
  'Ladrillo refractario',
  'Marinita',
  'Cemento refractario',
  'Productos refractarios',
  'Revestimiento de cemento',
  'intermitente',
  'Revestimiento de techo',
  'Techado',
  'Tela asfáltica',
  'culebrilla',
  'Revestimiento',
  'Estuco',
  'Papel de alquitrán',
  'Cordón de amianto',
  'Cuerda de amianto',
  'Revestimiento de corcho',
  'Cuerda de lámina',
  'Cinta',
  'Wicking',
  'Manta de amianto',
  'Lona de amianto',
  'Tela de amianto',
  'Fieltro de amianto',
  'Vuelta de amianto',
  'Lana de amianto',
  'Hilo de amianto',
  'Rezagado',
  'itinerante',
  'Textiles',
  'fibrocemento',
  'fibromarmol',
  'vinilo',
  'cordón textil',
  'cartón amianto',
  'masilla',
  'textil',
  'pintura',
  'material bituminoso-asfáltico',
];
