import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  selectedLanguage = 'es';
  constructor(private translator: TranslateService) {}
  ngOnInit(): void {
    this.selectedLanguage = this.translator.defaultLang;
  }
}
