<div class="py-16 flex flex-col gap-8">
  <p-tabMenu [model]="items" [activeItem]="activeItem" (activeItemChange)="changeItem($event)"></p-tabMenu>

  <div *ngIf="activeItem?.target == 'privacidad'">
  @if (selectedLanguage == 'es') {
    <app-privacidad-es></app-privacidad-es>
  } 
  @else {
    <app-privacidad-en></app-privacidad-en>
  }
  </div>
  <div *ngIf="activeItem?.target == 'avisolegal'">
    @if (selectedLanguage == 'es') {
      <app-aviso-legal-es></app-aviso-legal-es>
    } 
    @else {
      <app-aviso-legal-en></app-aviso-legal-en>
    }
  </div>
</div>
