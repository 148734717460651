<div class="hero">
    <div class="content">
        <h2>{{'home.title' | translate}}</h2>
        <p>{{'home.subtitle' | translate}}</p>
        <div class="w-80 lg:w-100">
            <app-locality-input (onSelect)="onSelectLocality($event)"></app-locality-input>
        </div>
    </div>
</div>
<div class="container mx-auto px-10 flex flex-col items-center">
    <div class="flex flex-col gap-4 lg:gap-0 lg:flex-row pt-10 lg:pb-10 items-center">
        <div class="lg:mr-20 w-80 lg:w-100">
            <h3 class="!text-2xl font-semibold mb-4">{{'home.article1' | translate}}</h3>
            <p class=" text-neutral-500">{{'home.article1Subtitle' | translate}}</p>
        </div>
        <div class="image w-80 lg:w-100">
            <img src="/assets/home_0.png" alt="">
        </div>
    </div>
    <div class="flex flex-col gap-4 lg:gap-24 lg:flex-row pt-10 pb-10 items-center">
        <div class="image lg:mr-20 w-80 lg:w-100">
            <img src="/assets/home_1.png" alt="">
        </div>
        <div class="">
            <h3 class="!text-2xl font-semibold mb-4">{{'home.canOfferTitle' | translate}}</h3>
            <ul class="list-decorated text-neutral-500" [innerHTML]="'home.canOfferSubtitle' | translate"></ul>
        </div>
    </div>
    <div class="footer-card top p-5 mb-4">
        <div class="m-5">
            <h3 class="text-lg font-semibold mb-4">{{'home.wantToNotify' | translate}}</h3>
            <p class="mb-4">{{'home.wantToNNotifySubtitle' | translate}}</p>
            <div class="flex justify-center">
                <app-locality-input class="block sm:w-85 lg:w-100" (onSelect)="onNotifyLocality($event)"></app-locality-input>
            </div>
        </div>
    </div>

    <div class="footer-card bottom p-5">
        <div class="m-5">
            <h3 class="text-lg font-semibold mb-4">{{'home.youCityCouncil' | translate}}</h3>
            <p class="mb-4">{{'home.youCityCouncilSubtitle' | translate}}</p>
            <a href="https://agforest.ai/contacto" class="primary-button">{{'home.contactus' | translate}}</a>
        </div>
    </div>
</div>

