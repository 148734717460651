import {
  GoogleLoginProvider,
  SocialAuthServiceConfig,
} from '@abacritt/angularx-social-login';

export const credential: string =
  '185109312610-44nc35thps28rislmuooq7aambl8c5k9.apps.googleusercontent.com';

const config: SocialAuthServiceConfig = {
  providers: [
    {
      id: GoogleLoginProvider.PROVIDER_ID,
      provider: new GoogleLoginProvider(credential),
    },
  ],
  onError: (err) => console.error(err),
};

/**
 *
 */
export function googleLoginConfig() {
  return config;
}
