import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { lastValueFrom } from 'rxjs';
import { UserService } from 'src/app/shared/services/user.service';

@Component({
  selector: 'app-current-bill',
  templateUrl: './current-bill.component.html',
  styleUrls: ['./current-bill.component.css'],
})
export class CurrentBillComponent implements OnInit {
  factura: any = null;
  lineas: any = null;
  baseImponible = 0;
  contratos = 0;

  user: any = null;

  totalRecords: number = 0;
  loading = false;

  constructor(
    private http: HttpClient,
    private userService: UserService,
  ) {}

  async ngOnInit() {
    this.user = this.userService.user;

    console.log(this.user);

    await this.getFactura();
  }

  private async getFactura() {
    this.loading = true;

    const month = new Date().getMonth() + 1;

    this.factura = await lastValueFrom(
      this.http.post(
        `${environment.api}facturacion/partial/${this.user.owner_id}/${month}`,
        {},
      ),
    );
    this.lineas = this.factura.lines || [];
    this.contratos = this.lineas.length;

    this.baseImponible = this.lineas.reduce(
      (acc: number, linea: { baseImponible: number }) =>
        acc + linea.baseImponible,
      0,
    );

    console.log(this.factura);

    this.loading = false;
  }
}
