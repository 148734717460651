import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Table } from 'primeng/table';
import { lastValueFrom } from 'rxjs';
import { UserService } from 'src/app/shared/services/user.service';
import { environment } from 'src/environments/environment';
import { AdminContractStoreService } from './admin-store.service';

@Component({
  selector: 'app-aproval-pending-contracts',
  templateUrl: './aproval-pending-contracts.component.html',
  styleUrls: ['./aproval-pending-contracts.component.css'],
})
export class AprovalPendingContractsComponent implements OnInit {
  @ViewChild('dt') table?: Table;

  contracts?: any[] = [];
  totalRecords?: number;
  loading?: boolean;

  user?: any;

  isAdmin: boolean = false;
  isClient: boolean = false;
  isProvider: boolean = false;

  owner_id?: string;

  _typesFilter: string[] = [];
  set typesFilter(value: string[] | undefined) {
    this._typesFilter = value || [];
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    this.load();
  }
  get typesFilter() {
    return this._typesFilter;
  }

  types?: string[] = [];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private userSvc: UserService,
    private http: HttpClient,
    private confirmationService: ConfirmationService,
    private messageSvc: MessageService,
    private store: AdminContractStoreService,
  ) {}
  async ngOnInit() {
    this.user = this.userSvc.getUser();

    this.isAdmin = this.user.is_organization;
    this.isClient = this.user.is_client;
    this.isProvider = this.user.is_provider;

    this.owner_id = this.user.owner_id;

    await this.load();
  }

  async load(_event?: any) {
    this.loading = true;

    let result = [];

    if (this.isProvider) {
      result = await lastValueFrom(
        this.http.get<any>(
          environment.api + 'contracts/pending/' + this.owner_id,
        ),
      );
    }

    if (this.isAdmin) {
      result = await lastValueFrom(
        this.http.get<any>(environment.api + 'contracts/pending'),
      );
    }

    console.log(result);

    this.contracts = result as any[];

    console.log(this.contracts);

    this.totalRecords = this.contracts.length;

    this.loading = false;
  }

  async selectContract(contract: any) {
    this.store.setSelectedContract(contract);

    if (contract.typeOfContract == 0) {
      await this.router.navigate(['admin/pending-contracts/aprove-linked']);
    } else {
      await this.router.navigate([
        'admin/pending-contracts/aprove-independent',
      ]);
    }
  }
}
