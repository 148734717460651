import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { lastValueFrom } from 'rxjs';
import {
  PermissionScope,
  PermissionType,
  User,
  UserService,
} from 'src/app/shared/services/user.service';
import { environment } from 'src/environments/environment';
import { MessageService } from 'primeng/api';
import { CalendarComponent } from 'src/app/shared/components/calendar/calendar.component';

@Component({
  selector: 'app-inspections-explore-calendar',
  templateUrl: './inspections-explore-calendar.component.html',
  styleUrls: ['./inspections-explore-calendar.component.scss'],
})
export class InspectionsExploreCalendarComponent implements AfterViewInit {
  loading: boolean = false;

  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private router: Router,
    private userSvc: UserService,
    private messageService: MessageService,
  ) {}
  async ngAfterViewInit() {}

  user?: User;
  canCreate: boolean = false;
  canEdit: boolean = false;
  canDelete: boolean = false;
  canView: boolean = false;
  canViewAll: boolean = false;
  isClient: boolean = false;
  isProvider: boolean = false;
  isOrganization: boolean = false;

  dates: any[] = [];

  recordId?: number;
  date: Date = new Date();

  detailModalOpen = false;
  selectedDate: any = null;

  @ViewChild('calendar') calendar!: CalendarComponent;

  private clientPermissions() {
    this.canEdit = false;
    this.canDelete = false;
    this.canView = true;
    this.canViewAll = true;
  }

  private providerPermissions() {
    this.canCreate =
      this.user?.hasPermission(
        PermissionScope.INSPECTIONS,
        PermissionType.CREATE,
      ) || false;
    this.canEdit =
      this.user?.hasPermission(
        PermissionScope.INSPECTIONS,
        PermissionType.EDIT,
      ) || false;
    this.canDelete =
      this.user?.hasPermission(
        PermissionScope.INSPECTIONS,
        PermissionType.DELETE,
      ) || false;
    this.canView =
      this.user?.hasPermission(
        PermissionScope.INSPECTIONS,
        PermissionType.VIEW,
      ) || false;
    this.canViewAll =
      this.user?.hasPermission(
        PermissionScope.INSPECTIONS,
        PermissionType.VIEW_ALL,
      ) || false;
  }

  private organizationPermissions() {
    this.canEdit = false;
    this.canDelete = false;
    this.canView =
      this.user?.hasPermission(
        PermissionScope.INSPECTIONS,
        PermissionType.VIEW,
      ) || false;
    this.canViewAll =
      this.user?.hasPermission(
        PermissionScope.INSPECTIONS,
        PermissionType.VIEW_ALL,
      ) || false;
  }
  async ngOnInit() {
    this.loading = true;

    this.user = this.userSvc.getUser();

    this.isClient = this.user?.is_client || false;
    this.isProvider = this.user?.is_provider || false;
    this.isOrganization = this.user?.is_organization || false;

    if (this.isClient) {
      this.clientPermissions();
    }

    if (this.isProvider) {
      this.providerPermissions();
    }

    if (this.isOrganization) {
      this.organizationPermissions();
    }

    this.recordId = this.route.snapshot.queryParams['record'];
    this.date = new Date(Date.now());

    console.log(' pre load');

    await this.load();

    this.loading = false;
  }

  async onMonthChange(date: Date) {
    this.date = date;
    await this.load();
  }

  async closeModal() {
    this.detailModalOpen = false;

    await this.load();
  }

  async load() {
    console.log('load');
    console.log(this.recordId);
    console.log(this.date);

    if (!this.recordId || !this.date) return;

    const fromDate = new Date(
      this.date.getFullYear(),
      this.date.getMonth(),
      1,
      0,
      0,
      0,
    );
    const toDate = new Date(
      this.date.getFullYear(),
      this.date.getMonth() + 1,
      1,
      0,
      0,
      0,
    );

    const requestUrl = `${environment.api}inspection?record=${this.recordId}&fromDate=${fromDate.toISOString()}&toDate=${toDate.toISOString()}`;

    console.log(requestUrl);

    const result: any = await lastValueFrom(this.http.get(requestUrl));

    console.log(result);

    this.dates = [];
    this.dates = result?.elements.map((inspection: any) => {
      if (inspection) {
        console.log(inspection);
        let date: Date;

        if (inspection.date != null) {
          date = new Date(inspection.date);
        } else {
          date = new Date(Date.now());
        }

        return {
          date: date,
          title: inspection.catastralReference,
          data: inspection,
          recordId: this.recordId,
        };
      } else {
        return null;
      }
    });

    this.dates = this.dates.filter((d) => d != null);
  }

  reload() {
    this.calendar.reload();
  }

  async openInspection(inspection: any) {
    if (inspection) {
      await this.router.navigate(['inspection'], {
        queryParams: {
          recordId: inspection.recordId,
          id: inspection.data.agForestId,
        },
      });
    }
  }

  async createInspection(data: string) {
    const recordUrl = `${environment.api}expedientes/byid/ro/${this.recordId}`;
    const record: any = await lastValueFrom(this.http.get(recordUrl));

    console.log(record);

    if (record) {
      this.canCreate = this.canCreate ? record.status == 'ACTIVE' : false;
    }

    if (!this.canCreate) {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail:
          'No tienes permisos para crear inspecciones o el expediente asociado no está activo.',
      });

      return;
    }

    if (this.recordId) {
      await this.router.navigate(['inspections', 'create'], {
        queryParams: { recordId: this.recordId, proposalDate: data },
      });
    }
  }

  async changeDate(data: any) {
    console.log(data);

    const requestUrl = `${environment.api}inspection/update/date/${data.inspection.data.agForestId}`;

    console.log(requestUrl);

    const result: any = await lastValueFrom(
      this.http.put(requestUrl, { date: data.date }),
    );

    console.log(result);

    await this.load();
  }

  async deleteInspection(inspection: any) {
    const requestUrl = `${environment.api}inspection/${inspection.data.agForestId}`;
    await lastValueFrom(this.http.delete(requestUrl));
    await this.load();
  }
}
