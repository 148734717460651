
<div class="flex item-center w-full mb-4">
    <h2 class="flex-grow text-xl font-bold text-am-primary-navy-100">{{'admin.inspections' | translate}} </h2>

    @if (canCreate) {
      <p-button pRipple size="small" [label]="'inspections.addInspectionButton' | translate" icon="pi pi-plus" iconPos="left" (onClick)="addInspection()" ></p-button>
    }
</div>

<app-inspections-list  (onSelected)="selected($event)" [recordId]="recordId"></app-inspections-list>
