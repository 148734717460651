import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ReplaySubject, lastValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Buffer } from 'buffer';
import { TranslateService } from '@ngx-translate/core';

export enum PermissionType {
  CREATE = 'CREATE',
  EDIT = 'EDIT',
  DELETE = 'DELETE',
  VIEW = 'VIEW',
  VIEW_ALL = 'VIEW_ALL',
}
export enum PermissionScope {
  ADMIN = 'ADMIN',
  CLIENTS = 'CLIENTS',
  RECORDS = 'RECORDS',
  INSPECTIONS = 'INSPECTIONS',
  USERS = 'USERS',
  INSPECTION_INFORM = 'INSPECTION_INFORM',
  WITHDRAWALS = 'WITHDRAWALS',
}

export class User {
  id?: number;
  access_token: string = '';
  email?: any;
  name?: any;
  firstName?: any;
  lastName?: any;
  role?: any;
  lang?: string;
  sendNotifications?: boolean;
  permissions?: { [key: string]: { [key: string]: boolean } };
  owner_id?: string;
  is_client?: boolean;
  is_provider?: boolean;
  is_organization?: boolean;

  constructor() {}

  hasPermission(scope: PermissionScope, type: PermissionType): boolean {
    return (
      (this.permissions &&
        this.permissions[scope] &&
        this.permissions[scope][type]) ||
      false
    );
  }

  checkHasViewPermission(permission: PermissionScope) {
    return (
      Object.keys((this.permissions || {})[permission])?.some(
        (_key) =>
          (this.permissions || {})[permission][PermissionType.VIEW] === true,
      ) || false
    );
  }

  hasAnyPermission(
    permissions: { scope: PermissionScope; type: PermissionType }[],
  ): boolean {
    for (const permission of permissions) {
      if (this.hasPermission(permission.scope, permission.type)) return true;
    }
    return false;
  }
  hasAllPermissions(
    permissions: { scope: PermissionScope; type: PermissionType }[],
  ): boolean {
    for (const permission of permissions) {
      if (!this.hasPermission(permission.scope, permission.type)) return false;
    }
    return true;
  }

  serialize() {
    return JSON.stringify({
      id: this.id,
      access_token: this.access_token,
      email: this.email as string,
      name: this.name as string, // Fix: Update the type of the name property to string
      firstName: this.firstName as string,
      lastName: this.lastName as string,
      role: this.role as string,
      sendNotifications: this.sendNotifications,
      lang: this.lang,
      permissions: this.permissions,
      owner_id: this.owner_id,
      is_client: this.is_client,
      is_provider: this.is_provider,
      is_organization: this.is_organization,
    });
  }
  static deserializeFromAccessToken(
    access_token: string,
    permissions: any,
  ): User {
    const user = new User();
    user.deserializeFromAccessToken(access_token, permissions);

    console.log(user);

    return user;
  }

  private atob(b64txt: string) {
    const buff = Buffer.from(b64txt, 'base64');
    const txt = buff.toString('utf8');
    return txt;
  }
  private deserializeFromAccessToken(access_token: string, permissions: any) {
    this.access_token = access_token;
    const payload: string = this.atob(this.access_token?.split('.')[1] || '');

    console.log('payload', payload);

    const user: User = JSON.parse(payload) as User; // Fix: Explicitly define the type of the user variable as any

    this.id = user.id;
    this.email = user.email as string;
    this.name = user.name;
    this.firstName = user.firstName;
    this.lastName = user.lastName;
    this.role = user.role as string;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    this.permissions = permissions;
    this.sendNotifications = user.sendNotifications;
    this.lang = user.lang;
    this.owner_id = user.owner_id;
    this.is_client = user.is_client;
    this.is_provider = user.is_provider;
    this.is_organization = user.is_organization;
  }
  static deserialize(data: string): User {
    const user = new User();
    user.deserialize(data);
    return user;
  }
  private deserialize(data: string) {
    const dataOb: User = JSON.parse(data) as User;
    this.id = dataOb.id;
    this.access_token = dataOb.access_token;
    this.email = dataOb.email as string;
    this.name = dataOb.name as string;
    this.firstName = dataOb.firstName as string;
    this.lastName = dataOb.lastName as string;
    this.role = dataOb.role as string;
    this.permissions = dataOb.permissions;
    this.sendNotifications = dataOb.sendNotifications;
    this.lang = dataOb.lang;
    this.owner_id = dataOb.owner_id;
    this.is_client = dataOb.is_client;
    this.is_provider = dataOb.is_provider;
    this.is_organization = dataOb.is_organization;
  }
}

@Injectable({
  providedIn: 'root',
})
export class UserService {
  static STORAGE_USER_KEY = '___user';

  public loginEmmiter = new ReplaySubject<User | undefined>(1);

  public user?: User;

  rememberPassword?: boolean;

  constructor(
    private http: HttpClient,
    private translate: TranslateService,
  ) {}

  getUser() {
    return this.user;
  }

  async setProfileName(firstName: string, lastName: string) {
    this.user!.firstName = firstName;
    this.user!.lastName = lastName;
    this.user!.name = `${firstName} ${lastName}`;
    this.loginEmmiter.next(this.user);

    const result = await lastValueFrom(
      this.http.put(`${environment.api}user/set/name`, {
        email: this.user?.email,
        firstName,
        lastName,
      }),
    );

    return result;
  }

  async setLang(lang: string): Promise<{ access_token: string }> {
    this.user!.lang = lang;
    this.loginEmmiter.next(this.user);

    const result: { access_token: string } = await lastValueFrom(
      this.http.put<any>(
        `${environment.api}user/lang/${this.user?.id}/${lang}`,
        {},
      ),
    );

    return result;
  }

  login(
    access_token: string,
    remember_password: boolean,
    permissions: any,
    is_client: boolean,
    is_provider: boolean,
    is_organization: boolean,
  ): User {
    this.user = User.deserializeFromAccessToken(access_token, permissions);

    this.user.is_client = is_client;
    this.user.is_provider = is_provider;
    this.user.is_organization = is_organization;

    this.rememberPassword = remember_password;

    if (!remember_password) {
      //localStorage.setItem(UserService.STORAGE_USER_KEY, this.user.serialize());
      // eslint-disable-next-line angular/document-service
      document.cookie = 'authToken=;';
    } else {
      sessionStorage.setItem(
        UserService.STORAGE_USER_KEY,
        this.user.serialize(),
      );
      // eslint-disable-next-line angular/document-service
      document.cookie = `authToken=${access_token}`;
    }

    this.translate.setDefaultLang(this.user.lang || 'es');
    this.loginEmmiter.next(this.user);

    return this.user;
  }

  updateAccessToken(access_token: string) {
    this.user = User.deserializeFromAccessToken(
      access_token,
      this.user?.permissions,
    );

    this.translate.setDefaultLang(this.user.lang || 'es');

    //if (!this.rememberPassword) {
    //localStorage.setItem(UserService.STORAGE_USER_KEY, this.user!.serialize());
    //}
    //else {
    sessionStorage.setItem(UserService.STORAGE_USER_KEY, this.user.serialize());
    //}
  }

  async restoreLogin() {
    const serialized_user = sessionStorage.getItem(
      UserService.STORAGE_USER_KEY,
    );

    if (serialized_user) {
      this.user = User.deserialize(serialized_user);
      this.loginEmmiter.next(this.user);

      return this.user;
    } else {
      // eslint-disable-next-line angular/document-service
      const cookies = document.cookie.split('; ');
      const authToken = cookies
        .find((row) => row.startsWith('authToken'))
        ?.split('=')[1];

      if (authToken) {
        // Validate token
        try {
          const userBack: User = await lastValueFrom<User>(
            this.http.post<User>(`${environment.api}user/validate-token`, {
              access_token: authToken,
            }),
          );
          this.user = User.deserializeFromAccessToken(
            userBack.access_token,
            {},
          );
          this.user.permissions = userBack.permissions;
          this.user.is_client = userBack.is_client;
          this.user.is_provider = userBack.is_provider;
          this.user.is_organization = userBack.is_organization;

          sessionStorage.setItem(
            UserService.STORAGE_USER_KEY,
            this.user.serialize(),
          );

          console.log(this.user);

          this.translate.setDefaultLang(this.user.lang || 'es');

          // eslint-disable-next-line angular/document-service
          document.cookie = `authToken=${authToken}`;

          this.loginEmmiter.next(this.user);

          return this.user;
        } catch (e) {
          console.log(e);
          return undefined;
        }
      }

      return undefined;
    }

    console.log(this.user);

    return undefined;
  }

  logout() {
    console.log('logout');
    this.user = undefined;

    sessionStorage.removeItem(UserService.STORAGE_USER_KEY);

    // eslint-disable-next-line angular/document-service
    document.cookie = 'authToken=;';

    this.loginEmmiter.next(this.user);
  }
}
