import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  Input,
  ViewChild,
  Output,
  EventEmitter,
  OnInit,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { debounceTime, lastValueFrom, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
  //encapsulation: ViewEncapsulation.None,
})
export class MapComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() data: any = [];
  @Input() enable_draw: boolean = false;
  @Input() show_on_click: boolean = false;
  @Input() enable_address_bar: boolean = false;
  @Input() get_coordinates_on_click: boolean = false;
  @Input() data_layer_style: string = 'amianticPublic';
  //@Input() assets_path: string = "/assets/img"
  @Input() loadFromUrl?: boolean = false;
  @Input() ine: string = '';

  @Input() draw_enabled_on_startup: boolean = false;
  @Input() is_geolocation_enabled: boolean = false;

  @Output() onCoordinatesSelected: EventEmitter<{
    coordinates: [number, number];
    location: string;
  }> = new EventEmitter<{ coordinates: [number, number]; location: string }>();
  @Output() onFeatureClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() onDrawGeometry: EventEmitter<any> = new EventEmitter<any>();

  @Output() onBeginLoading: EventEmitter<void> = new EventEmitter<void>();
  @Output() onFinishLoading: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild('olmap') theMap!: ElementRef<HTMLElement>;

  private mapClickSubject = new Subject<Event>();

  private observer!: IntersectionObserver;

  isLoading: boolean = true;

  constructor(
    @Inject(DOCUMENT) public document: Document,
    private http: HttpClient,
  ) {
    this.mapClickSubject
      .pipe(debounceTime(500))
      .subscribe((event) => this.featureClick(event));
  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (changes['ine'] && !changes['ine'].isFirstChange()) {
      this.ine = changes['ine'].currentValue;
      await this.forceLoadMap(this.ine);
    }
  }

  async ngAfterViewInit(): Promise<void> {
    //this.isLoading = true;

    /*
    this.enable_draw  &&  this.theMap.nativeElement.setAttribute("data-is-draw-enabled","true");
    this.show_on_click && this.theMap.nativeElement.setAttribute("data-must-show-on-click-feature", "true");
    this.enable_address_bar && this.theMap.nativeElement.setAttribute("data-is-adress-search-bar-enabled", "true");
    this.get_coordinates_on_click && this.theMap.nativeElement.setAttribute("data-is-get-coordinates-on-click-enabled", "true");

    //this.theMap.nativeElement.setAttribute("data-assets-path", this.assets_path);
    this.theMap.nativeElement.setAttribute("data-assets-path", "/assets/images/");

    this.theMap.nativeElement.setAttribute("data-geojsons", JSON.stringify([]));

    (this.theMap.nativeElement as any).connectedCallback();
    */

    //await this.loadMap();

    //this.isLoading = false;
    console.log('enable_draw: ' + this.enable_draw);
    console.log('show_on_click: ' + this.show_on_click);
    console.log('enable_address_bar: ' + this.enable_address_bar);
    console.log('get_coordinates_on_click: ' + this.get_coordinates_on_click);
    console.log('loadFromUrl: ' + this.loadFromUrl);
    console.log('draw_enabled_on_startup: ' + this.draw_enabled_on_startup);
    console.log('is_geolocation_enabled: ' + this.is_geolocation_enabled);
    console.log('data_layer_style: ' + this.data_layer_style);

    await this.loadMap();
  }

  async forceLoadMap(ine: string) {
    this.ine = ine;
    await this.loadMap();
  }

  private isElementVisible(el: HTMLElement) {
    const rect = el.getBoundingClientRect();

    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <=
        // eslint-disable-next-line angular/window-service, angular/document-service
        (window.innerHeight || document.documentElement.clientHeight) &&
      // eslint-disable-next-line angular/window-service, angular/document-service
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  }

  private async loadMap() {
    //if (this.isElementVisible(this.theMap.nativeElement)) {

    this.isLoading = true;
    console.log('Loading map');

    console.log(this.loadFromUrl);

    if (this.loadFromUrl) {
      const [status, result] = await this.getDataFromAPI();

      if (status == 'OK') {
        this.data = result;

        console.log(JSON.stringify(this.data));
      } else {
        console.log('Error while loading data from API');

        //this.enable_address_bar = true;
        //this.enable_draw = true;
        //this.show_on_click = true;
      }
    }
    //}
    //else {
    //  this.enable_address_bar = true;
    //  this.enable_draw = true;
    //  this.show_on_click = true;
    //}

    this.enable_draw === true
      ? this.theMap.nativeElement.setAttribute('data-is-draw-enabled', 'true')
      : this.theMap.nativeElement.setAttribute('data-is-draw-enabled', 'false');
    this.show_on_click === true
      ? this.theMap.nativeElement.setAttribute(
          'data-must-show-on-click-feature',
          'true',
        )
      : this.theMap.nativeElement.setAttribute(
          'data-must-show-on-click-feature',
          'false',
        );
    this.enable_address_bar === true
      ? this.theMap.nativeElement.setAttribute(
          'data-is-adress-search-bar-enabled',
          'true',
        )
      : this.theMap.nativeElement.setAttribute(
          'data-is-adress-search-bar-enabled',
          'false',
        );
    this.get_coordinates_on_click === true
      ? this.theMap.nativeElement.setAttribute(
          'data-is-get-coordinates-on-click-enabled',
          'true',
        )
      : this.theMap.nativeElement.setAttribute(
          'data-is-get-coordinates-on-click-enabled',
          'false',
        );

    this.draw_enabled_on_startup === true
      ? this.theMap.nativeElement.setAttribute(
          'data-is-draw-enabled-on-startup',
          'true',
        )
      : this.theMap.nativeElement.setAttribute(
          'data-is-draw-enabled-on-startup',
          'false',
        );

    this.is_geolocation_enabled === true
      ? this.theMap.nativeElement.setAttribute(
          'data-is-geolocation-enabled',
          'true',
        )
      : this.theMap.nativeElement.setAttribute(
          'data-is-geolocation-enabled',
          'false',
        );

    this.data_layer_style &&
      this.theMap.nativeElement.setAttribute(
        'data-layer-style',
        this.data_layer_style,
      );

    this.ine &&
      this.theMap.nativeElement.setAttribute('data-ine-code', this.ine);
    //this.theMap.nativeElement.setAttribute("data-assets-path", this.assets_path);
    this.theMap.nativeElement.setAttribute(
      'data-assets-path',
      '/assets/images/',
    );

    console.log(
      JSON.stringify(
        this.theMap.nativeElement.getAttribute('data-is-draw-enabled'),
      ),
    );

    this.data &&
      this.theMap.nativeElement.setAttribute(
        'data-geojsons',
        JSON.stringify(this.data),
      );

    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    (this.theMap.nativeElement as any).connectedCallback();

    this.document.addEventListener('drawGeometry', (event) =>
      this.drawGeometry(event),
    );
    this.document.addEventListener('featureClick', (event) =>
      this.mapClickSubject.next(event),
    ); //this.featureClick(event));
    this.document.addEventListener('mapClick', (event) =>
      this.onMapClick(event),
    );
    //this.document.addEventListener("featureClick", (event) => this.onMapFeatureClick(event));

    //this.document.addEventListener("click", (event) => this.onMapClick(event));
  }

  async loadGeometry(geojsons: any) {
    this.data = geojsons;
    await this.loadMap();
  }

  async ngOnInit(): Promise<void> {
    this.document.addEventListener('mapRefreshStart', (event) =>
      this.onMapRefreshStart(event),
    );
    this.document.addEventListener('mapRefreshEnd', (event) =>
      this.onMapRefreshEnd(event),
    );
    this.isLoading = true;
  }

  onMapFeatureClick(_event: any) {}

  onMapClick(event: any) {
    this.onCoordinatesSelected.emit(event.detail);
  }

  featureClick(event: any) {
    console.log('onFeatureClick');

    this.onFeatureClick.emit(event.detail);
  }

  drawGeometry(event: any) {
    this.onDrawGeometry.emit(event.detail.geometry);
  }

  private async getDataFromAPI() {
    const start = Date.now();

    try {
      if (this.ine == '') {
        return ['KO', 'No hay valor para el parámetro INE'];
      }

      const headers = { 'Content-Type': 'application/json' };

      const result = await lastValueFrom(
        this.http.get<[string, any[]]>(
          `${environment.api}notification/map/${this.ine}`,
          { headers },
        ),
      );

      const end = Date.now();
      const elapsed = end - start;
      console.log(`Tiempo de respuesta: ${elapsed} ms`);

      return result;
    } catch (err) {
      console.log(err);
      return ['KO', err];
    }
  }

  onMapRefreshStart(_e: any) {
    this.isLoading = true;
    this.onBeginLoading.emit();
    console.log('Loading map');
  }

  onMapRefreshEnd(_e: any) {
    this.isLoading = false;

    console.log('Map loaded');
    this.onFinishLoading.emit();
  }
}
