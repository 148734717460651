<div class="flex flex-col gap-5 py-7">
  <h2 class="font-bold text-am-2xl leading-am-8 text-am-primary-navy-100">{{'userZone.myAccount' | translate}}</h2>
<div class="user__card flex flex-row gap-8 border border-am-secondary-navy-10 rounded-am-md w-[770px] h-[146px] p-5">
  <div class="card__avatar">
    <div class="w-[48px] h-[48px] bg-am-secondary-turquoise-60 rounded-full flex items-center justify-center"><span class="avatar__icon w-[32px] h-[32px]"></span></div>
  </div>
    <div class="card__info w-full">
      <h3 *ngIf="!editing" class="font-bold text-am-2xl leading-am-8 text-am-primary-navy-100">{{user?.name}}</h3>

      <div *ngIf="editing" class="flex flex-row">
        <div class=" flex flex-col w-1/2 p-input-underline">
          <label for="firstName" class="font-medium text-sm text-am-secondary-navy-60 ">{{'admin.name' | translate}}</label>
          <input id="firstName" type="text" pInputText [(ngModel)]="firstName" [placeholder]="'admin.placeholder1' | translate" />
        </div>
        <!--Apellidos-->
        <div class=" flex flex-col w-1/2 p-input-underline">
            <label for="lastName" class="font-medium text-sm text-am-secondary-navy-60 ">{{'admin.surnames' | translate}}</label>
            <input id="lastName" type="text" pInputText [(ngModel)]="lastName" [placeholder]="'admin.placeholder2' | translate" />
        </div>
        <div class="flex flex-col">
          <div class="card__edit" (click)="updateName($event)">
            <span><i class="pi pi-check"></i></span>
         </div>
         <div class="card__edit" (click)="cancelUpdateName($event)">
          <span><i class="pi pi-times"></i></span>
         </div>
        </div>
      </div>
      <span *ngIf="!editing" class="font-normal text-sm leading-am-4 text-am-secondary-navy-80">{{user?.email}}</span>
      <div *ngIf="!editing" class="w-fit h-[20px] rounded bg-am-primary-navy-100 mt-7 flex items-center justify-center">
        <span *ngIf="user?.role == 'ADMIN'" class="ml-4 mr-4 font-bold text-am-xs text-white">{{'userZone.group.admin' | translate}}</span>
        <span *ngIf="user?.role == 'INSPECTOR'" class="ml-4 mr-4 font-bold text-am-xs text-white">{{'userZone.group.inspector' | translate}}</span>
        <span *ngIf="user?.role == 'CLIENT'" class="ml-4 mr-4 font-bold text-am-xs text-white">{{'userZone.group.client' | translate}}</span>
      </div>
    </div>
   <div *ngIf="!editing" class="card__edit" (click)="editName($event)">
      <span class="edit__icon"></span>
   </div>
</div>

  <div class="language flex flex-col mt-6">
    <label for="language" class=" text-am-secondary-navy-60 font-medium text-xs leading-am-3 translate-x-1">{{'userZone.language' | translate}}</label>
    <div class="language flex flex-row gap-3">
      <p-dropdown [options]=langs optionLabel="name" optionValue="code" name="language" id="language" [(ngModel)]="selectedLang" class="lang__dropdown" (onChange)="changeLang($event)"></p-dropdown>
      <p-button pRipple (onClick)="saveLang()">{{ 'ui.general.guardarButton' | translate}}</p-button>
    </div>
  </div>
</div>
