import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import {
  ConfirmationService,
  ConfirmEventType,
  MessageService,
} from 'primeng/api';
import { Observable, lastValueFrom } from 'rxjs';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-admin-upsert-providers',
  templateUrl: './admin-upsert-providers.component.html',
  styleUrls: ['./admin-upsert-providers.component.scss'],
})
export class AdminUpsertProvidersComponent implements OnInit {
  client?: any;
  loading?: boolean;
  deleteLoading?: boolean;
  types?: string[];
  form = new FormGroup({
    owner_id: new FormControl(),
    // eslint-disable-next-line @typescript-eslint/unbound-method
    cif: new FormControl(null, Validators.required),
    // eslint-disable-next-line @typescript-eslint/unbound-method
    businessName: new FormControl(null, Validators.required),
    // eslint-disable-next-line @typescript-eslint/unbound-method
    email: new FormControl(null, [Validators.required, Validators.email]),
    phone: new FormControl(null),
    address: new FormControl(null),
  });

  errors: { [key: string]: string | boolean } = {};

  users: any[] = [];

  @Input() owner_id?: string = '';
  @Input() id?: string = '';

  inserting: boolean = false;

  @Input() embed: boolean = false;

  constructor(
    private readonly http: HttpClient,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    private toastService: MessageService,
  ) {}
  async ngOnInit() {
    const params = this.route.snapshot.queryParams;

    console.log(params);

    if (this.id !== undefined && this.id !== '') {
      this.owner_id = this.id;
      await this.loadUser(this.owner_id);
    } else {
      this.inserting = true;
    }

    /*
    if (params['embedded'] === undefined || params['embedded'] === 'false') {
      this.embedded = false
    }
    else {
      this.embedded = true;
    }
    */

    console.log('Embedded : ' + this.embed);
  }

  async loadUser(id: string) {
    this.loading = true;

    const client = (await this.http
      .get(environment.api + 'owners/providers/' + id)
      .toPromise()) as any;

    this.client = client;

    console.log(client);

    this.inserting = false;

    this.form.patchValue({
      owner_id: this.owner_id,
      cif: client.cif,
      businessName: client.businessName,
      email: client.email,
      phone: client.phone,
      address: client.address,
    });

    if (client != null) {
      console.log(client.owner_id);

      this.users = await lastValueFrom(
        this.http.get<any[]>(
          environment.api + 'owners/provider/users/' + client.owner_id,
        ),
      );

      console.log(this.users);
    } else {
      this.users = [];
    }

    this.loading = false;
  }

  async refreshUsers() {
    await this.loadUser(this.owner_id as string);
  }

  async save() {
    console.log(this.form);
    if (this.form.valid) {
      this.loading = true;

      const values: any = this.form.value;

      console.log(values);

      if (!this.inserting) {
        values.id = this.client.id;
      }

      const request: Observable<any> = !this.inserting
        ? this.http.put(environment.api + 'owners/provider', values)
        : this.http.post(environment.api + 'owners/provider', values);

      await request
        .toPromise()
        .then((_data: any) => {
          this.messageService.add({
            severity: 'success',
            summary: 'Success',
            detail: 'Cambios aplicados con exito',
          });
          this.location.back();

          //this.router.navigate(['../'], { relativeTo: this.route });
        })
        .catch((e) => {
          if (e.error.message === 'EMAIL_ALREADY_EXIST') {
            this.form.get('email')?.setErrors({ emailAlreadyExist: true });
            this.toastService.add({
              severity: 'error',
              summary: 'Error',
              detail: 'El correo ya existe',
            });
            UtilsService.markGroupControlsAsDirty(this.form);
          }

          //if (e.error?.message) {
          //  this.errors[e.error?.message] = true;
          //}
          //console.log(e);
        });

      this.loading = false;
    } else {
      UtilsService.markGroupControlsAsDirty(this.form);
    }
  }

  delete() {
    this.confirmationService.confirm({
      accept: async () => {
        this.deleteLoading = true;
        await this.http
          .delete(environment.api + 'owners/provider/' + this.client.id)
          .toPromise()
          .then(async (_data: any) => {
            await this.router.navigate(['../'], { relativeTo: this.route });
          })
          .catch((error) => {
            console.log(error);
          });
        this.deleteLoading = false;
      },
      reject: (_type: ConfirmEventType) => {},
    });
  }

  getBack() {
    this.location.back();
  }
}
