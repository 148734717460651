<div class="m-8">
<div style="text-align: left;">
  <div >
      <h1><strong>Legal Notice</strong></h1>
  </div>
</div>
<br>
<div style="text-align: left;">
  <div >
    <p style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Aptos",sans-serif;margin-top:0cm;margin-bottom:8.0pt;line-height:115%;text-align:justify;'><strong>Legal Notice</strong></p>
    <p style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Aptos",sans-serif;margin-top:0cm;margin-bottom:8.0pt;line-height:115%;text-align:justify;'>The use of this website is subject to the following terms and conditions: Agforest S.L. (the &quot;Company&quot;) reserves the right to change these terms at any time with immediate effect upon posting them on this website.</p>
    <p style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Aptos",sans-serif;margin-top:0cm;margin-bottom:8.0pt;line-height:115%;text-align:justify;'><strong>Intellectual Property</strong></p>
    <p style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Aptos",sans-serif;margin-top:0cm;margin-bottom:8.0pt;line-height:115%;text-align:justify;'>The copyright of the texts, images, screens, and other materials on this Website are owned by the Company or third parties. Unless authorized below, none of the materials may be copied, reproduced, distributed, downloaded, displayed, or transmitted in any form or by any means without the prior written permission of the Company or the copyright owner. You may view, copy, download, and print documents from the Company on this website solely for personal, non-commercial, and informational purposes, provided that you do not modify the documents.</p>
    <p style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Aptos",sans-serif;margin-top:0cm;margin-bottom:8.0pt;line-height:115%;text-align:justify;'><strong>Informational Purposes Only</strong></p>
    <p style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Aptos",sans-serif;margin-top:0cm;margin-bottom:8.0pt;line-height:115%;text-align:justify;'>The materials and information on this website have been prepared or assembled by the Company and are intended solely for informational purposes. Some information may be dated and may not reflect the most current legal developments. Any opinions expressed in or through this website are the opinion of the individual author and may not reflect the opinions of the Company or any other individual lawyer.</p>
    <p style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Aptos",sans-serif;margin-top:0cm;margin-bottom:8.0pt;line-height:115%;text-align:justify;'><strong>No Attorney-Client Relationship</strong></p>
    <p style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Aptos",sans-serif;margin-top:0cm;margin-bottom:8.0pt;line-height:115%;text-align:justify;'>The transmission of the information contained on this website is not intended to create an attorney-client relationship between you and the Company. This website contains information about legal matters and is not a substitute for legal advice from a qualified attorney licensed in the appropriate jurisdiction. The Company expressly disclaims all liability with respect to actions taken or not taken based on the information contained or omitted on this website.</p>
    <p style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Aptos",sans-serif;margin-top:0cm;margin-bottom:8.0pt;line-height:115%;text-align:justify;'><strong>Use in Other Jurisdictions</strong></p>
    <p style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Aptos",sans-serif;margin-top:0cm;margin-bottom:8.0pt;line-height:115%;text-align:justify;'>The Company&apos;s attorneys practice law only in jurisdictions where we are authorized to do so. We do not seek to represent anyone in any jurisdiction where this website does not comply or is inconsistent with the rules governing communications about legal services in a particular state.</p>
    <p style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Aptos",sans-serif;margin-top:0cm;margin-bottom:8.0pt;line-height:115%;text-align:justify;'><strong>No Warranties/Limitation of Liability</strong></p>
    <p style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Aptos",sans-serif;margin-top:0cm;margin-bottom:8.0pt;line-height:115%;text-align:justify;'>The information provided on this website is provided &quot;as is&quot; without any warranty of any kind, either express or implied, including, but not limited to, the implied warranties of merchantability, fitness for a particular purpose, or non-infringement. We periodically add, change, improve, or update the information and documents on this website. The Company assumes no liability or responsibility for any errors or omissions in the content of this website.</p>
    <p style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Aptos",sans-serif;margin-top:0cm;margin-bottom:8.0pt;line-height:115%;text-align:justify;'><strong>&nbsp;</strong></p>
    <p style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Aptos",sans-serif;margin-top:0cm;margin-bottom:8.0pt;line-height:115%;text-align:justify;'><strong>Third-Party Links</strong></p>
    <p style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Aptos",sans-serif;margin-top:0cm;margin-bottom:8.0pt;line-height:115%;text-align:justify;'>From time to time, we may post links to Internet sites maintained by third parties. We do not control any information, products, or services on linked sites, nor do we endorse such information, products, or services by posting those links. The Company makes no representations or warranties regarding the reliability of the information contained or accessed from such websites, nor does the Company represent or warrant that any website accessible through this site is free of viruses, cookies, or other unwanted electronic content. You browse the Internet at your own risk.</p>
    <p style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Aptos",sans-serif;margin-top:0cm;margin-bottom:8.0pt;line-height:115%;text-align:justify;'><strong>Privacy</strong></p>
    <p style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Aptos",sans-serif;margin-top:0cm;margin-bottom:8.0pt;line-height:115%;text-align:justify;'>We understand that privacy and the protection of your personal information are important to you. If you choose to register or submit information to this website, you agree to the use of such data in accordance with this privacy statement. We are not responsible for and make no representations about the privacy policies of sites linked to this site, which may have different privacy policies.</p>
    <p style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Aptos",sans-serif;margin-top:0cm;margin-bottom:8.0pt;line-height:115%;text-align:justify;'>You should not send confidential or privileged information to the Company until you have received written acknowledgment from the Company that it has agreed to provide legal services to you or your company. The content of any correspondence you send via email, or the Internet will not be considered confidential unless you have been confirmed as a client.</p>
    <p style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Aptos",sans-serif;margin-top:0cm;margin-bottom:8.0pt;line-height:115%;text-align:justify;'><strong>Information Collection</strong></p>
    <p style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Aptos",sans-serif;margin-top:0cm;margin-bottom:8.0pt;line-height:115%;text-align:justify;'>You do not have to submit any personal information to use this website. The site collects only personally identifiable information that is voluntarily provided by visitors, which may include your name, current position, email address, and telephone or fax number.</p>
    <p style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Aptos",sans-serif;margin-top:0cm;margin-bottom:8.0pt;line-height:115%;text-align:justify;'><strong>Cookies and Web Beacons</strong></p>
    <p style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Aptos",sans-serif;margin-top:0cm;margin-bottom:8.0pt;line-height:115%;text-align:justify;'>We may use browser cookies to collect non-personally identifiable information. Cookies are small text files placed on a computer&apos;s hard drive that allow Agforest S.L. and/or its website host to record how many times a user or computers within a user&apos;s network have visited the website, the number of times various pages of the website have been accessed, and other similar information. Our web pages may also incorporate web beacons or similar tracking technologies to allow us to track how the website is used. We may combine your information with similar information collected from other users to help improve the website service. We do not use these data to create or maintain a confidential personal profile of you or to collect confidential information from or about you. Most browsers allow individuals to decline cookies.</p>
    <p style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Aptos",sans-serif;margin-top:0cm;margin-bottom:8.0pt;line-height:115%;text-align:justify;'><strong>Use of Information</strong></p>
    <p style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Aptos",sans-serif;margin-top:0cm;margin-bottom:8.0pt;line-height:115%;text-align:justify;'>The information you submit to Agforest S.L. will only be used, if at all, for limited purposes. For example, when you register to receive our newsletters or request future communications about Agforest S.L. updates or events, we may use the information you submit to create a personal profile to tailor our response to other requests or inquiries. We may also use the information to personalize your experience on the website. We will ensure that any marketing activity in which we engage complies with applicable law. You may request that we stop sending you electronic materials at any time.</p>
    <p style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Aptos",sans-serif;margin-top:0cm;margin-bottom:8.0pt;line-height:115%;text-align:justify;'><strong>Disclosure of Information to Third Parties</strong></p>
    <p style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Aptos",sans-serif;margin-top:0cm;margin-bottom:8.0pt;line-height:115%;text-align:justify;'>We do not disclose any non-public personal information about clients or website visitors to anyone, except as permitted by law.</p>
    <p style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Aptos",sans-serif;margin-top:0cm;margin-bottom:8.0pt;line-height:115%;text-align:justify;'><strong>Information Security</strong></p>
    <p style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Aptos",sans-serif;margin-top:0cm;margin-bottom:8.0pt;line-height:115%;text-align:justify;'>We maintain commercially reasonable standards of technology and operational security to protect the information provided by visitors from loss, misuse, alteration, or destruction.</p>
    <p style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Aptos",sans-serif;margin-top:0cm;margin-bottom:8.0pt;line-height:115%;text-align:justify;'><strong>Changes to Our Privacy Policy</strong></p>
    <p style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Aptos",sans-serif;margin-top:0cm;margin-bottom:8.0pt;line-height:115%;text-align:justify;'>Agforest S.L. reserves the right to modify or amend this privacy statement at any time and for any reason. This policy is effective as of November 17, 2022. Any substantial changes to the policy will be posted on this website. By your continued or repeated use of the website, you agree to the terms of the revised policy. However, nothing contained herein is intended to create an attorney-client relationship between Agforest S.L. and any user visiting the website or providing any identifying information of any kind.</p>
    <p style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Aptos",sans-serif;margin-top:0cm;margin-bottom:8.0pt;line-height:115%;text-align:justify;'><strong>Questions</strong></p>
    <p style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Aptos",sans-serif;margin-top:0cm;margin-bottom:8.0pt;line-height:115%;text-align:justify;'>If you have any questions or concerns regarding your privacy while using this website, please direct them to the contact email found on our website.</p>
    <p style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Aptos",sans-serif;margin-top:0cm;margin-bottom:8.0pt;line-height:115%;text-align:justify;'><strong>Main Office Location</strong></p>
    <p style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Aptos",sans-serif;margin-top:0cm;margin-bottom:8.0pt;line-height:115%;text-align:justify;'>The Company&apos;s main office is in Madrid, Spain.</p>
    <p style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Aptos",sans-serif;margin-top:0cm;margin-bottom:8.0pt;line-height:115%;'>&nbsp;</p>    
  </div>
</div>
