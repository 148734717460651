<div class="m-10">
  <!--Main Administracion-->
  <!--Div con Titulo y boton añadir usuario-->
  <div class="flex item-center w-full mt-14 mb-4">
    <h2 class="flex-grow text-xl font-bold text-am-primary-navy-100">{{'admin.pending_auth_contracts' | translate}}</h2>
  </div>


  <!--Div Input Busqueda y Filtrado-->
  <div class="flex justify-between mt-4 mb-4 items-start">
    <span class="p-input-icon-right h-max">
      <i class="pi pi-search"></i>
      <input type="text" pInputText class="p-inputtext-sm w-100" [placeholder]="'ui.general.buscar' | translate "
        (input)="dt.filterGlobal($event.target.value, 'contains')" />
    </span>
  </div>

  <p-table #dt class="" [value]="contracts" [paginator]="true" [rows]="10" [totalRecords]="totalRecords"
    [loading]="loading" [rowHover]="true" [showCurrentPageReport]="true"
    [globalFilterFields]="['businessName', 'email', 'cif']" currentPageReportTemplate="{currentPage} de {totalPages}">

    <ng-template pTemplate="header">
      <tr>
        <th pSortableColumn="name">{{ 'ui.tables.profile.contratos.aprobacion.municipio' | translate}} <p-sortIcon field="record.recordINEId.name"></p-sortIcon></th>
        <th pSortableColumn="reference">{{ 'ui.tables.profile.contratos.aprobacion.referencia' | translate}} <p-sortIcon field="record.reference"></p-sortIcon></th>
        <th pSortableColumn="createdAt">{{ 'ui.tables.profile.contratos.aprobacion.fecha' | translate}}<p-sortIcon field="createdAt"></p-sortIcon></th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-data>
      <tr>
        <td class="font-semibold">{{ data.record.recordINEId?.name || '-' }}</td>
        <td>{{ data.record.reference }}</td>
        <td>{{ data.requestDate | date }}</td>
      </tr>
    </ng-template>


    <ng-template pTemplate="emptymessage" let-columns let-frozen="frozen">
      <tr>
        <td colspan="5">
          <div class="text-center p-10">{{ 'ui.general.noresults' | translate }}</div>
        </td>
      </tr>
    </ng-template>

    <ng-template pTemplate="sorticon" let-number>
      <i *ngIf="number == 0" class="pi pi-sort" style="opacity:0.2"></i>
      <i *ngIf="number == 1" class="pi pi-sort-down"></i>
      <i *ngIf="number == -1" class="pi pi-sort-up"></i>
    </ng-template>

  </p-table>
</div>
