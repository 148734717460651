import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './shared/components/header/header.component';
import { FooterComponent } from './shared/components/footer/footer.component';
import { HomeComponent } from './public/home/home.component';
import { NotifyLocationComponent } from './notification/notify-location/notify-location.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { NotifyContactComponent } from './notification/notify-contact/notify-contact.component';
import { NotifyHeaderComponent } from './notification/notify-header/notify-header.component';
import { NotifySentComponent } from './notification/notify-sent/notify-sent.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { DialogModule } from 'primeng/dialog';
import { InputSwitchModule } from 'primeng/inputswitch';
import { PasswordModule } from 'primeng/password';
import { DropdownModule } from 'primeng/dropdown';
import { DragDropModule } from 'primeng/dragdrop';

import { LoginComponent } from './login/login/login.component';
import { RecoverComponent } from './login/recover/recover.component';
import {
  RECAPTCHA_SETTINGS,
  RecaptchaFormsModule,
  RecaptchaModule,
} from 'ng-recaptcha';
import { RECAPTCHA_CONFIG } from 'src/configs/google/reCaptcha.config';
import { SocialLoginModule } from '@abacritt/angularx-social-login';
import { googleLoginConfig } from 'src/configs/google/socialLogin.config';
import { SentCodeComponent } from './login/sent-code/sent-code.component';
import { NewPassComponent } from './login/new-pass/new-pass.component';
import { MenuPrivateComponent } from './shared/components/menu-private/menu-private.component';
import { CalendarModule } from 'primeng/calendar';
import { UserInfoComponent } from './account/base/user-info.component';
import { AsideComponent } from './account/aside/aside.component';
import { MyAccountComponent } from './account/profile/my-account.component';
import { PermissionsComponent } from './shared/components/permissions/permissions.component';
import { RecordsListComponent } from './records/list/records-list.component';
import { TableModule } from 'primeng/table';
import { ImageInputComponent } from './shared/components/image-input/image-input.component';
import { PopupfichainspComponent } from './shared/components/popupfichainsp/popupfichainsp.component';
import { UpsertRecordComponent } from './records/upsert/upsert-record.component';
import { RecordSiteComponent } from './records/record-site/record-site.component';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule,
} from '@angular/common/http';
import { LocalityInputComponent } from './shared/components/locality-input/locality-input.component';
import { LocalityComponent } from './public/locality/locality.component';
import { SchedulePopComponent } from './records/schedule-pop/schedule-pop.component';
import { CalendarComponent } from './shared/components/calendar/calendar.component';
import { InspecPopComponent } from './inspections/explore/calendar/popup/inspec-pop.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AuthInterceptor } from './auth-interceptor.service';

import { CheckboxModule } from 'primeng/checkbox';
import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { StepsModule } from 'primeng/steps';
import { ChartModule } from 'primeng/chart';

import { UserPermissionsComponent } from './account/permissions/user-permissions.component';

import { AdminListUsersComponent } from './admin/users/list/admin-list-users.component';
import { AdminListClientsComponent } from './admin/clients/list/admin-list-clients.component';
import { AdminUpsertUsersComponent } from './admin/users/upsert/admin-upsert-users.component';

import { ConfirmationService, MessageService } from 'primeng/api';
import { ChipsModule } from 'primeng/chips';
import { SidebarModule } from 'primeng/sidebar';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { AdminUpsertClientsComponent } from './admin/clients/upsert/admin-upsert-clients.component';
import { MenuModule } from 'primeng/menu';
import { SkeletonModule } from 'primeng/skeleton';
import { SelectRecordComponent } from './shared/components/select-record/select-record.component';
import { ToastModule } from 'primeng/toast';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { InputNumberModule } from 'primeng/inputnumber';
import { EstatesListComponent } from './shared/components/estates-list/estates-list.component';
import { SelectButtonModule } from 'primeng/selectbutton';
import { TabViewModule } from 'primeng/tabview';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ClientInputComponent } from './shared/components/client-input/client-input.component';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { RecordListComponent } from './shared/components/record-list/record-list.component';
import { InspectionsRecordSelectComponent } from './inspections/record-select/inspections-record-select.component';
import { InspectionsExploreComponent } from './inspections/explore/inspections-explore.component';
import { TabMenuModule } from 'primeng/tabmenu';
import { InspectionsListComponent } from './shared/components/inspections-list/inspections-list.component';
import { InspectionsExploreListComponent } from './inspections/explore/list/inspections-explore-list.component';
import { InspectionsExploreMapComponent } from './inspections/explore/map/inspections-explore-map.component';
import { InspectionsExploreCalendarComponent } from './inspections/explore/calendar/inspections-explore-calendar.component';
import { InspectionsCreateComponent } from './inspections/create/inspections-create.component';
import { InspectionsCreateGeneralComponent } from './inspections/create/inspections-create-general/inspections-create-general.component';
import { InspectionsCreateDateComponent } from './inspections/create/inspections-create-date/inspections-create-date.component';
import { InspectionEditComponent } from './inspections/edit/inspection-edit.component';
import { ImageControlInputComponent } from './shared/components/image-control-input/image-control-input.component';
import { UserInputComponent } from './shared/components/user-input/user-input.component';
import { BackComponent } from './shared/components/back.component';
import { ReportComponent } from './inspections/edit/report/report.component';
import { RadioButtonModule } from 'primeng/radiobutton';
import { PopupNoPermissionsComponent } from './shared/components/popupnopermissions/popupnopermissions.component';
import { MapComponent } from './shared/components/map/map.component';
import { LocalizacionesComponent } from './shared/components/localizaciones/localizaciones.component';
import { InspectionsCreateReducedComponent } from './inspections/create/inspections-create-reduced/inspections-create-reduced.component';
import { AdminListProvidersComponent } from './admin/providers/list/admin-list-providers.component';
import { AdminNavigationComponent } from './admin/admin-navigation.component';
import { AdminUpsertProvidersComponent } from './admin/providers/upsert/admin-upsert-providers.component';
import { ProviderClientsListProvidersComponent } from './admin/providerclients/list/provider-clients-list-providers.component';
import { ProviderClientsUpsertProvidersComponent } from './admin/providerclients/upsert/provider-clients-upsert-providers.component';
import { AccountNotificationsComponent } from './account/notifications/account-notifications.component';
import { SecurityComponent } from './account/security/security.component';
import { PoliciesComponent } from './account/policies/policies.component';
import { OrganizationListUsersComponent } from './admin/organization/list/organization-list-users.component';
import { OrganizationUpsertUsersComponent } from './admin/organization/upsert/organization-upsert-users.component';
import { RetiradasComponent } from './retiradas/retiradas.component';
import { RetiradasListComponent } from './retiradas/retiradas/retiradas-list.component';
import { DashboardComponent } from './retiradas/dashboard/dashboard.component';
import { RetiradasInspectionListComponent } from './retiradas/retiradas-inspection-list/retiradas-inspection-list.component';
import { RetiradasFormComponent } from './retiradas/retiradas-form/retiradas-form.component';
import { StatsService } from './retiradas/Stats.service';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { TooltipModule } from 'primeng/tooltip';
import { PrivacidadComponentES } from './public/privacidad/privacidad_es.component';
import { PrivacidadComponentEN } from './public/privacidad/privacidad_en.component';
import { PendingContractsComponent } from './account/pending-contracts/pending-contracts.component';
import { NewContractLinkedDetailComponent } from './account/pending-contracts/new-contract-linked-detail/new-contract-linked-detail.component';
import { ContractStoreService } from './account/pending-contracts/store.service';
import { NewContractIndependentDetailComponent } from './account/pending-contracts/new-contract-independent-detail/new-contract-independent-detail.component';
import { AprovalPendingContractsComponent } from './admin/pending-contracts/aproval-pending-contracts.component';
import { AdminContractStoreService } from './admin/pending-contracts/admin-store.service';
import { AproveContractLinkedDetailComponent } from './admin/pending-contracts/aprove-contract-linked-detail/aprove-contract-linked-detail.component';
import { AproveContractIndependentDetailComponent } from './admin/pending-contracts/aprove-contract-independent-detail/aprove-contract-independent-detail.component';
import { PendingAuthContractsComponent } from './account/pending-auth-contracts/pending-auth-contracts.component';
import { AdminPendingAuthContractsComponent } from './admin/pending-auth-contracts/admin-pending-auth-contracts.component';
import { CurrentContractsComponent } from './account/current-contracts/current-contracts.component';
import { CurrentContractStoreService } from './account/current-contracts/current-store.service';
import { CurrentBillComponent } from './account/current-bill/current-bill.component';
import { PendingApprovalContractsComponent } from './account/pending-approval-contracts/pending-approval-contracts.component';
import { EditContractIndependentDetailComponent } from './account/current-contracts/edit-contract-independent-detail/edit-contract-independent-detail.component';
import { EditContractLinkedDetailComponent } from './account/current-contracts/edit-contract-linked-detail/edit-contract-linked-detail.component';
import { ReAprovalContractsComponent } from './admin/pending-reapproval-contracts/reaproval-contracts.component';
import { AdminReapprovalContractStoreService } from './admin/pending-reapproval-contracts/admin-reapproval-store.service';
import { ReAproveContractLinkedDetailComponent } from './admin/pending-reapproval-contracts/reaprove-contract-linked-detail/reaprove-contract-linked-detail.component';
import { ReAproveContractIndependentDetailComponent } from './admin/pending-reapproval-contracts/reaprove-contract-independent-detail/reaprove-contract-independent-detail.component';
import { AdminStoredBillsComponent } from './admin/stored-bills/admin-stored-bills.component';
import { AdminStoredBillStoreService } from './admin/stored-bills/admin-stored-bill-store.service';
import { AdminCurrentBillComponent } from './admin/stored-bills/current-bill/admin-current-bill.component';
import { StoredBillsComponent } from './account/stored-bills/stored-bills.component';
import { StoredBillStoreService } from './account/stored-bills/stored-bill-store.service';
import { StoredBillDetailComponent } from './account/stored-bills/current-bill/stored-bill-detail.component';
import { EmpresasRetiradasListComponent } from './admin/empresas-retirada/list/empresas-retiradas-list.component';
import { EmpresasRetiradasUpsertComponent } from './admin/empresas-retirada/upsert/empresas-retiradas-upsert.component';
import { AvisoLegalComponentES } from './public/aviso-legal/aviso-legal_es.component';
import { AvisoLegalComponentEN } from './public/aviso-legal/aviso-legal_en.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    LocalityComponent,
    NotifyLocationComponent,
    NotifyContactComponent,
    NotifyHeaderComponent,
    NotifySentComponent,
    LoginComponent,
    RecoverComponent,
    SentCodeComponent,
    NewPassComponent,
    UserInfoComponent,
    AsideComponent,
    MyAccountComponent,
    PermissionsComponent,
    RecordsListComponent,
    ImageInputComponent,
    PopupfichainspComponent,
    RecordSiteComponent,
    LocalityInputComponent,
    SchedulePopComponent,
    CalendarComponent,
    InspecPopComponent,
    UserPermissionsComponent,
    AdminListUsersComponent,
    AdminListClientsComponent,
    AdminUpsertUsersComponent,
    AdminUpsertClientsComponent,
    SelectRecordComponent,
    EstatesListComponent,
    UpsertRecordComponent,
    ClientInputComponent,
    RecordListComponent,
    InspectionsRecordSelectComponent,
    InspectionsExploreComponent,
    InspectionsListComponent,
    InspectionsExploreListComponent,
    InspectionsExploreMapComponent,
    InspectionsExploreCalendarComponent,
    InspectionsCreateComponent,
    InspectionsCreateGeneralComponent,
    InspectionsCreateReducedComponent,
    InspectionsCreateDateComponent,
    InspectionEditComponent,
    ImageControlInputComponent,
    UserInputComponent,
    BackComponent,
    ReportComponent,
    PopupNoPermissionsComponent,
    MapComponent,
    LocalizacionesComponent,
    AdminListProvidersComponent,
    AdminNavigationComponent,
    AdminUpsertProvidersComponent,
    ProviderClientsListProvidersComponent,
    ProviderClientsUpsertProvidersComponent,
    AccountNotificationsComponent,
    SecurityComponent,
    PoliciesComponent,
    OrganizationListUsersComponent,
    OrganizationUpsertUsersComponent,
    RetiradasComponent,
    RetiradasListComponent,
    DashboardComponent,
    RetiradasInspectionListComponent,
    RetiradasFormComponent,
    AvisoLegalComponentES,
    AvisoLegalComponentEN,
    PrivacidadComponentES,
    PrivacidadComponentEN,
    PendingContractsComponent,
    NewContractLinkedDetailComponent,
    NewContractIndependentDetailComponent,
    AprovalPendingContractsComponent,
    AproveContractLinkedDetailComponent,
    MenuPrivateComponent,
    AproveContractIndependentDetailComponent,
    PendingAuthContractsComponent,
    PendingApprovalContractsComponent,
    AdminPendingAuthContractsComponent,
    CurrentContractsComponent,
    CurrentBillComponent,
    EditContractIndependentDetailComponent,
    EditContractLinkedDetailComponent,
    ReAprovalContractsComponent,
    ReAproveContractLinkedDetailComponent,
    ReAproveContractIndependentDetailComponent,
    AdminStoredBillsComponent,
    AdminCurrentBillComponent,
    StoredBillsComponent,
    StoredBillDetailComponent,
    EmpresasRetiradasListComponent,
    EmpresasRetiradasUpsertComponent,
  ],
  imports: [
    HttpClientModule,
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    AutoCompleteModule,
    InputSwitchModule,
    PasswordModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    SocialLoginModule,
    DropdownModule,
    CommonModule,
    FormsModule,
    CalendarModule,
    InputNumberModule,
    TableModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoader,
        deps: [HttpClient],
      },
    }),
    CheckboxModule,
    InputTextModule,
    ButtonModule,
    ConfirmDialogModule,
    ChipsModule,
    OverlayPanelModule,
    MenuModule,
    SkeletonModule,
    ToastModule,
    SelectButtonModule,
    TabViewModule,
    InputTextareaModule,
    ProgressSpinnerModule,
    TabMenuModule,
    StepsModule,
    RadioButtonModule,
    BreadcrumbModule,
    TooltipModule,
    ChartModule,
    SidebarModule,
    DragDropModule,
    DialogModule,
    ToggleButtonModule,
  ],
  providers: [
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: RECAPTCHA_CONFIG,
    },
    {
      provide: 'SocialAuthServiceConfig',
      useFactory: googleLoginConfig,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    ConfirmationService,
    MessageService,
    StatsService,
    ContractStoreService,
    AdminContractStoreService,
    CurrentContractStoreService,
    AdminReapprovalContractStoreService,
    AdminStoredBillStoreService,
    StoredBillStoreService,
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  exports: [MenuPrivateComponent],
})
export class AppModule {}

/**
 * Creates a TranslateHttpLoader instance for loading translation files.
 * @param {HttpClient} http - The HttpClient instance used for making HTTP requests.
 * @returns {TranslateHttpLoader} - A new TranslateHttpLoader instance.
 */
export function httpTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
