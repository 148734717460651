<div class="m-10">
  <!--Main Administracion-->
  <!--Div con Titulo y boton añadir usuario-->
  <div class="flex item-center w-full mt-14 mb-4">
    <h2 class="flex-grow text-xl font-bold text-am-primary-navy-100">{{'admin.pending_to_send_contracts' | translate}}</h2>
  </div>


  <!--Div Input Busqueda y Filtrado-->
  <div class="flex justify-between mt-4 mb-4 items-start">
    <span class="p-input-icon-right h-max">
      <i class="pi pi-search"></i>
      <input type="text" pInputText class="p-inputtext-sm w-100" [placeholder]="'ui.general.buscar' | translate"
        (input)="dt.filterGlobal($event.target.value, 'contains')" />
    </span>
  </div>

  <p-table #dt class="" [value]="contracts" [paginator]="true" [rows]="10" [totalRecords]="totalRecords"
    [loading]="loading" [rowHover]="true" [showCurrentPageReport]="true"
    [globalFilterFields]="['businessName', 'email', 'cif']" currentPageReportTemplate="{currentPage} de {totalPages}">

    <ng-template pTemplate="header">
      <tr>
        <th pSortableColumn="typeOfContract">{{ 'ui.tables.profile.contratos.pendientes.tipo' | translate}} <p-sortIcon field="typeOfContract"></p-sortIcon></th>
        <th pSortableColumn="name">{{ 'ui.tables.profile.contratos.pendientes.municipio' | translate}} <p-sortIcon field="record.recordINEId.name"></p-sortIcon></th>
        <th pSortableColumn="businessName">{{ 'ui.tables.profile.contratos.pendientes.cliente' | translate}} <p-sortIcon field="client.businessName"></p-sortIcon></th>
        <th pSortableColumn="reference">{{ 'ui.tables.profile.contratos.pendientes.referencia' | translate}} <p-sortIcon field="record.reference"></p-sortIcon></th>
        <th pSortableColumn="createdAt">{{ 'ui.tables.profile.contratos.pendientes.fecha' | translate}}<p-sortIcon field="createdAt"></p-sortIcon></th>
        <th style="width: 4rem"></th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-data>
      <tr>
        <td>{{ data.typeOfContract == 0 ? 'VINCULADO' : 'INDEPENDIENTE' }}</td>
        <td class="font-semibold">{{ data.record.recordINEId?.name || '-' }}</td>
        <td class="font-semibold">{{ data.client?.businessName || '-' }}</td>
        <td>{{ data.record.reference }}</td>
        <td>{{ data.requestDate | date }}</td>
        <td>
          <button pButton pRipple type="button" icon="pi pi-chevron-right"
            class="p-button-text p-button-rounded p-button-plain" (click)="selectContract(data)"></button>

        </td>
      </tr>
    </ng-template>


    <ng-template pTemplate="emptymessage" let-columns let-frozen="frozen">
      <tr>
        <td colspan="5">
          <div class="text-center p-10">{{ 'ui.general.noresults' | translate}}</div>
        </td>
      </tr>
    </ng-template>

    <ng-template pTemplate="sorticon" let-number>
      <i *ngIf="number == 0" class="pi pi-sort" style="opacity:0.2"></i>
      <i *ngIf="number == 1" class="pi pi-sort-down"></i>
      <i *ngIf="number == -1" class="pi pi-sort-up"></i>
    </ng-template>

  </p-table>
</div>


<p-confirmDialog key="delete" #cd [style]="{ width: '50vw' }">
  <ng-template pTemplate="header">
    <h3 class="font-bold">Eliminar</h3>
  </ng-template>
  <ng-template pTemplate="message">
    <div style="width:100%" class="text-sm text-center font-semibold w-full">
      <div>
        ¿Seguro que deseas eliminar el proveedor y todos su usuarios?
      </div>
    </div>
  </ng-template>
  <ng-template pTemplate="footer">
    <p-button type="button" [label]="'Cancelar'" (click)="cd.reject()" size="small" [outlined]="true"></p-button>
    <p-button type="button" [label]="'Eliminar'" (click)="cd.accept()" size="small" severity="danger"></p-button>
  </ng-template>
</p-confirmDialog>
