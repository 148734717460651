<div>
  <div class="flex justify-between mt-4 mb-4 items-start max-md:flex-col-reverse max-md:gap-4 ">
    <div class="max-md:w-full">
      <span class="p-input-icon-right h-max max-md:w-full" *ngIf="listType == 0">
        <i class="pi pi-search"></i>
        <input type="text" pInputText class="p-inputtext-sm w-80 max-md:w-full" [placeholder]="'Buscar emplazamiento'"
          (input)="dt.filterGlobal($event.target.value, 'contains')" />
      </span>
    </div>

    <p-selectButton (onChange)="onViewChange($event)" [options]="listTypeSelectButtonValues" [(ngModel)]="listType"
      optionValue="value" class="toogle" [allowEmpty]="false">
      <ng-template let-item pTemplate>
        <i [class]="item.icon" style="margin-right: 0.5rem;"></i> {{item.label}}
      </ng-template>
    </p-selectButton>
  </div>

  <div [hidden]="listType != 0">
    <p-table  #dt class="public-list"
              [value]="data"
              [paginator]="true"
              [rows]="10"
              [lazy]="true"
              (onLazyLoad)="load($event)"
              [totalRecords]="totalRecords"
              [selectionMode]="'multiple'"
              [responsiveLayout]="'stack'"
              [breakpoint]="'960px'"
              [loading]="loading"
              [showCurrentPageReport]="true"
              [(selection)]="selected"
              [dataKey]="'catastralReference'"
              (selectionChange)="selectionChange($event)"
              currentPageReportTemplate="{currentPage} de {totalPages} páginas">

      <ng-template pTemplate="header">
        <tr>
          <th *ngIf="selectable"></th>
          <th>{{'searchLocation.ref' | translate}}</th>
          <th>{{'searchLocation.city' | translate}}</th>
          <th>{{'searchLocation.province' | translate}}</th>
          <th>{{'searchLocation.status' | translate}}</th>
          <th>{{'searchLocation.date' | translate}}</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-data>
        <tr>
          <td *ngIf="selectable"><p-tableCheckbox [value]="data"></p-tableCheckbox></td>
          <td class="font-semibold"><span class="p-column-title">Ref:</span>{{ data.catastralReference }}</td>
          <td><span class="p-column-title">{{'searchLocation.city' | translate}}:</span>{{ data.locality?.name }}</td>
          <td><span class="p-column-title">{{'searchLocation.province' | translate}}:</span>{{ data.locality?.province
            }}</td>
          <td><span class="p-column-title">Estado:</span>{{ data.status }}</td>
          <td><span class="p-column-title">Fecha:</span>{{ data.date | date}}</td>
        </tr>
      </ng-template>


      <ng-template pTemplate="emptymessage" let-columns let-frozen="frozen">
        <tr>
          <td [attr.colspan]="selectable ? 6 : 5">
            <div class="text-center p-10">No hay resultados</div>
          </td>
        </tr>
      </ng-template>

    </p-table>
  </div>

  <div [hidden]="listType != 1">
    <div class="relative">
      <div class="absolute z-10 w-full h-full flex items-center justify-center bg-opacity-50 bg-gray-700"
        *ngIf="loadingMap">
        <p-progressSpinner styleClass="custom-spinner"></p-progressSpinner>
      </div>
      <div class="relative z-0">
        <app-map [ine]="ine" [loadFromUrl]="true" [show_on_click]="true" [enable_address_bar]="true"
          [enable_draw]="false" [draw_is_enabled_on_startup]="false" [is_geolocation_enabled]="false"
          [get_coordinates_on_click]="false" class="flex h-[600px]" (onBeginLoading)="beginLoading()"
          (onFinishLoading)="finishLoading()"></app-map>
        <!-- <openlayers-map id="map"> -->
        <!-- Maps -->
        <!-- </openlayers-map> -->
      </div>
    </div>
  </div>
</div>
