import { AfterViewInit, Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MenuItem } from 'primeng/api';

@Component({
  selector: 'app-account-policies',
  templateUrl: './policies.component.html',
  styleUrls: ['./policies.component.scss'],
})
export class PoliciesComponent implements OnInit, AfterViewInit {
  items: MenuItem[] | undefined;

  activeItem: MenuItem | undefined;

  selectedLanguage = 'es';

  constructor(private translator: TranslateService) {}
  ngAfterViewInit(): void {
    this.selectedLanguage = this.translator.defaultLang;
  }

  ngOnInit() {

    this.items = [
      {
        label: this.translator.instant('ui.general.privacidad'),
        target: 'privacidad',
        icon: 'pi pi-fw pi-eye',
      },
      {
        label: this.translator.instant('ui.general.avisolegal'),
        target: 'avisolegal',
        icon: 'pi pi-fw pi-cloud-download',
      },
    ];

    this.activeItem = this.items[0];
  }

  changeItem(event: any) {
    this.activeItem = event;

    console.log(this.activeItem);
  }
}
