
<div class="m-10">
  <!--Main Administracion-->
  <!--Div con Titulo y boton añadir usuario-->
  <div class="flex item-center w-full mt-14 mb-4">
      <h2 class="flex-grow text-xl font-bold text-am-primary-navy-100">{{'admin.withdrawalManagement' | translate}}</h2>

      <p-button pRipple size="small" [label]="'admin.addCompany' | translate" icon="pi pi-plus" iconPos="left" routerLink="/admin/withdrawalcompanies/upsert" ></p-button>
  </div>


  <!--Div Input Busqueda y Filtrado-->
  <div class="flex justify-between mt-4 mb-4 items-start">
      <span class="p-input-icon-right h-max">
          <i class="pi pi-search"></i>
          <input type="text" pInputText class="p-inputtext-sm w-100" placeholder="Buscar" (input)="dt.filterGlobal($event.target.value, 'contains')" />
      </span>
  </div>

      <p-table #dt class="" [value]="companies" [paginator]="true" [rows]="10" [totalRecords]="totalRecords"
          [loading]="loading"
          [rowHover]="true"
          [showCurrentPageReport]="true"
          [globalFilterFields]="['businessName', 'email', 'cif']"
          currentPageReportTemplate="{currentPage} de {totalPages}">

      <ng-template pTemplate="header">
          <tr>
              <th pSortableColumn="businessName">Municipio <p-sortIcon field="businessName"></p-sortIcon></th>
              <th pSortableColumn="email">Email <p-sortIcon field="email"></p-sortIcon></th>
              <th pSortableColumn="cif">C.I.F. <p-sortIcon field="cif"></p-sortIcon></th>
              <th pSortableColumn="numeroRera">Numero RERA. <p-sortIcon field="numeroRera"></p-sortIcon></th>
              <th style="width: 2rem"></th>
              <th style="width: 2rem"></th>
          </tr>
      </ng-template>
      <ng-template pTemplate="body" let-data>
          <tr>
              <td class="font-semibold">{{ data.businessName }}</td>
              <td>{{ data.email }}</td>
              <td>{{ data.cif }}</td>
              <td>{{ data.numeroRera }}</td>
              <td>
                <button pButton pRipple type="button" icon="pi pi-trash" class="p-button-text p-button-rounded p-button-plain " (click)="deleteProvider($event, data)"></button>

              </td>
              <td>
                  <button pButton pRipple type="button" icon="pi pi-chevron-right" class="p-button-text p-button-rounded p-button-plain "
                  routerLink="/admin/withdrawalcompanies/upsert" [queryParams]="{id: data.id}"></button>

              </td>
          </tr>
      </ng-template>


      <ng-template pTemplate="emptymessage" let-columns let-frozen="frozen">
          <tr>
              <td colspan="5"><div class="text-center p-10">No hay resultados</div></td>
          </tr>
      </ng-template>

      <ng-template pTemplate="sorticon" let-number>
          <i *ngIf="number == 0" class="pi pi-sort" style="opacity:0.2"></i>
          <i *ngIf="number == 1" class="pi pi-sort-down"></i>
          <i *ngIf="number == -1" class="pi pi-sort-up"></i>
      </ng-template>

  </p-table>
</div>


<p-confirmDialog key="delete" #cd [style]="{ width: '50vw' }">
<ng-template pTemplate="header">
    <h3 class="font-bold">Eliminar</h3>
</ng-template>
<ng-template pTemplate="message">
    <div style="width:100%" class="text-sm text-center font-semibold w-full">
        <div>
            {{ 'admin.deleteWithdrawalCompany' | translate }}
        </div>
    </div>
</ng-template>
<ng-template pTemplate="footer">
  <div class="flex flex-row mt-6">
    <p-button type="button" [label]="'Cancelar'" (click)="cd.reject()" size="small" [outlined]="true"></p-button>
    <p-button type="button" [label]="'Eliminar'" (click)="cd.accept()" size="small" severity="danger"></p-button>
  </div>
</ng-template>
</p-confirmDialog>
