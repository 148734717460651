<div class="m-10">

  <app-back class="mb-5 cursor-pointer"></app-back>

  <div class="text-sm font-semibold mb-4">
    @if (currentLevel?.caption != '') {
    <h1 class="mb-6 text-xl font-light">{{'withdrawals.map.select' | translate}} {{ (currentLevel?.caption == 'Municipio' ? 'withdrawals.map.amas' : 'withdrawals.map.afem') | translate}} {{
      translateCaptionLevels(currentLevel?.caption) | translate}} {{'withdrawals.map.toShowGraphs' | translate}}</h1>
    }

    <p-breadcrumb class="max-w-full cursor-pointer" [model]="breadcrumbLevels"
      (onItemClick)="handleBreadcrumb($event)"></p-breadcrumb>
  </div>
  <div>
    <div class="relative">
      <div class="absolute z-10 w-full h-full flex items-center justify-center bg-opacity-50 bg-gray-700"
        *ngIf="loadingMap">
        <p-progressSpinner styleClass="custom-spinner"></p-progressSpinner>
      </div>
      <div class="relative z-0">
        <app-map #map [loadFromUrl]="false" [show_on_click]="false" [enable_address_bar]="false" [enable_draw]="false"
          [draw_is_enabled_on_startup]="false" [is_geolocation_enabled]="false" [get_coordinates_on_click]="false"
          class="flex h-[600px]" [data_layer_style]="layer_style" (onFinishLoading)="finishLoading()"
          (onFeatureClick)="nextLevel($event)"></app-map>
      </div>
    </div>
  </div>

  <div class="grid grid-rows-1 mt-8 gap-8">
    <div class="grid md:grid-cols-2 xl:grid-cols-3 gap-8  justify-evenly">
      @if (stats_data != null) {
      <div class="md:row-span-2">
        <h1>{{'withdrawals.graphs.importantInfo' | translate}}</h1>
        <div class="grid grid-cols-3 grid-rows-9 border rounded-md p-4 mt-4">
          <p class="font-semibold col-span-2">% Pre 2002</p>
          <p>{{stats_data.Percent_pre_2002 | number }}</p>
          <p class="font-semibold col-span-2">{{'withdrawals.graphs.volumeWith' | translate}}</p>
          <p>{{stats_data.Volume_with_asbestos | number}}</p>
          <p class="font-semibold col-span-2">{{'withdrawals.graphs.area' | translate}}</p>
          <p>{{stats_data.area | number}}</p>
          <p class="font-semibold col-span-2">{{'withdrawals.graphs.areaWith' | translate}}</p>
          <p>{{stats_data.area_with_asbestos | number}}</p>
          <p class="font-semibold col-span-2">{{'withdrawals.graphs.residentialArea' | translate}}</p>
          <p>{{stats_data.building_area | number}}</p>
          <p class="font-semibold col-span-2">{{'withdrawals.graphs.industrialArea' | translate}}</p>
          <p>{{stats_data.industrial_area | number}}</p>
          <p class="font-semibold col-span-2">{{'withdrawals.graphs.cadastralReferences' | translate}}</p>
          <p>{{stats_data.n_catastral_references | number}}</p>
          <p class="font-semibold col-span-2">{{'withdrawals.graphs.cadastralReferences2' | translate}} pre 2002</p>
          <p>{{stats_data.n_pre_2002 | number}}</p>
          <p class="font-semibold col-span-2">{{'withdrawals.graphs.provinces' | translate}}</p>
          <p>{{stats_data.n_provinces}}</p>
        </div>
      </div>
      }
      @if (sus_data != null) {
        <div class="min-h-sm">
          <h1>{{'withdrawals.graphs.urbanEv' | translate}}</h1>
          <p-chart type="bar" [data]="evo_data" responsive="true"></p-chart>
        </div>
      }
      @if (sus_data != null) {
        <div class="flex flex-colsize-fit col-start-2 xl:col-start-3">
          <h1>{{'withdrawals.graphs.susceptibility' | translate}}</h1>
          <p-chart class="justify-self-center" type="pie" [data]="sus_data" responsive="true"></p-chart>
        </div>
        }
    </div>
  </div>
