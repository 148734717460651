<!--Div que ocupa todo el body y tiene el fondo oscurecido-->
<div class="fixed inset-0 flex w-full h-full mx-auto items-center justify-center z-50 bg-am-gray-semitransparent">
  <!--Div con los formularios-->
  <div class="bg-white flex flex-col w-full h-[80%] mx-10 overflow-y-scroll relative">
    <!--Header del popUp-->
    <div class="sticky pos-0">
      <header class="bg-am-secondary-turquoise-60 flex justify-center h-16 items-center">
        <h1 class="font-bold text-sm text-white text-center flex-grow">Ficha de inspección {{info_num}} {{fecha}}
        </h1>
        <img src="./../../../assets/icons/close_IconWhite.svg"
          class="mx-5 cursor-pointer hover:scale-105 transition-all duration-500" (click)="close()">
      </header>
    </div>

    <!--Header Fases-->
    <div class="flex mt-8 mx-10">
      <!--Primera fase-->
      <div class="flex items-center w-fit mr-20">
        <div *ngIf="currentPhase == 1" class="rounded-2xl bg-am-primary-navy-100 mx-1">
          <p class="font-semibold mx-2 text-white">1</p>
        </div>

        <div *ngIf="currentPhase != 1" class="rounded-2xl bg-am-secondary-turquoise-30 mx-1">
          <p class="font-semibold mx-2">1</p>
        </div>
        <p class="font-semibold mx-1 text-sm mt-1">{{'inspections.environment' | translate}}</p>

      </div>
      <!--Segunda fase-->
      <div class="flex items-center w-fit mr-20">
        <div *ngIf="currentPhase != 2" class="rounded-2xl bg-am-secondary-turquoise-30 mx-1">
          <p class="font-semibold mx-2">2</p>
        </div>
        <div *ngIf="currentPhase == 2" class="rounded-2xl bg-am-primary-navy-100 mx-1">
          <p class="font-semibold mx-2 text-white">2</p>
        </div>


        <p class="font-semibold mx-1 text-sm mt-1">{{'inspections.material' | translate}}</p>

      </div>


      <!--Tercera fase-->
      <div class="flex items-center w-fit mr-20">
        <div *ngIf="currentPhase != 3" class="rounded-2xl bg-am-secondary-turquoise-30 mx-1">
          <p class="font-semibold mx-2">3</p>
        </div>
        <div *ngIf="currentPhase == 3" class="rounded-2xl bg-am-primary-navy-100 mx-1">
          <p class="font-semibold mx-2 text-white">3</p>
        </div>

        <p class="font-semibold mx-1 text-sm mt-1">{{'inspections.sumFactorValues' | translate}}</p>
      </div>
      <!--Cuarta fase-->
      <div class="flex items-center w-fit mr-20">
        <div *ngIf="currentPhase != 4" class="rounded-2xl bg-am-secondary-turquoise-30 mx-1">
          <p class="font-semibold mx-2">4</p>
        </div>
        <div *ngIf="currentPhase == 4" class="rounded-2xl bg-am-primary-navy-100 mx-1">
          <p class="font-semibold mx-2 text-white">4</p>
        </div>

        <p class="font-semibold mx-1 text-sm mt-1">{{'inspections.totalScore' | translate}}</p>
      </div>
    </div>

    <!--Formularios Fase 1-->
    <div *ngIf="currentPhase == 1" class="mx-14 mt-10">
      <!--Preguntas 1-->
      <div class="mb-10">
        <div class="mb-10" *ngFor="let q of questionsPhase1; let i=index">
          <h1 class="text-am-secondary-navy-80 text-xl mb-8">{{i + 1}}.
            {{ q.pregunta | translate}}</h1>
          <div *ngFor="let r of q.responses">
            <label class="cursor-pointer text-sm pl-16" (click)="addScore(q, r)">
              <p-radioButton [inputId]="q.pregunta + '_' + r.response + '_' + i" [name]="q.pregunta" class="w-5 h-4 mt-4" [(ngModel)]="q.response" [value]="r.response"></p-radioButton>
              {{ r.response | translate }}
            </label>
          </div>
          <div class="flex flex-row-reverse mt-4">
            <span class="ml-4 mr-4 font-bold text-am-xs text-white bg-slate-400 p-1 mr-[20%]">
              <p>{{'inspections.totalScore' | translate}}: {{q.score}}</p>
            </span>
          </div>
        </div>
      </div>

      <div class="flex flex-row-reverse border-t border-t-am-secondary-navy-20"></div>
      <button *ngIf="currentPhase == 1"
        class="bg-am-primary-turquoise-100 font-bold mt-3  mb-3 mr-5 py-2 px-4 rounded text-white"
        [disabled]="!checkPhase1Responses()" (click)="nextStep()">
        {{'inspections.next' | translate}}
      </button>
    </div>

    <!--Formularios Fase 2-->
    <div *ngIf="currentPhase == 2" class="mx-14 mt-10">
      <!--Preguntas 1-->
      <div class="mb-10">
        <div class="mb-10" *ngFor="let q of questionsPhase2; let i=index">
          <h1 class="text-am-secondary-navy-80 text-xl mb-8">{{i + 1}}.
            {{ q.pregunta | translate}}</h1>
          <div *ngFor="let r of q.responses">
            <label class="cursor-pointer text-sm pl-16" (click)="addScore(q, r)">
              <p-radioButton [inputId]="q.pregunta + '_' + r.response + '_' + i" [name]="q.pregunta" class="w-5 h-4 mt-4" [(ngModel)]="q.response" [value]="r.response"></p-radioButton>
              {{ r.response | translate }}
            </label>
          </div>
          <div class="flex flex-row-reverse mt-4">
            <span class="ml-4 mr-4 font-bold text-am-xs text-white bg-slate-400 p-1 mr-[20%]">
              <p>{{'inspections.totalScore' | translate}}: {{q.score}}</p>
            </span>
          </div>
        </div>

        <div class="flex flex-row-reverse border-t border-t-am-secondary-navy-20"></div>
        <button *ngIf="currentPhase == 2"
          class="bg-am-primary-turquoise-100 font-bold mt-3  mb-3 mr-5 py-2 px-4 rounded text-white"
          (click)="prevStep()">
          {{'inspections.previous' | translate}}
        </button>
        <button *ngIf="currentPhase == 2"
          class="bg-am-primary-turquoise-100 font-bold mt-3  mb-3 mr-5 py-2 px-4 rounded text-white"
          [disabled]="!checkPhase2Responses()" (click)="nextStep()">
          {{'inspections.next' | translate}}
        </button>

      </div>
    </div>

    <!--Formularios Fase 3-->
    <div *ngIf="currentPhase == 3" class="mx-14 mt-10">
      <div class="mb-10" *ngFor="let q of questionsPhase3; let i=index">
        <h1 class="text-am-secondary-navy-80 text-xl mb-8">{{i + 1}}.
          {{ q.pregunta | translate}}</h1>
        <div *ngFor="let r of q.responses">
          <label class="cursor-pointer text-sm pl-16" (click)="addScore(q, r)">
            <p-radioButton [inputId]="q.pregunta + '_' + r.response + '_' + i" [name]="q.pregunta" class="w-5 h-4 mt-4" [(ngModel)]="q.response" [value]="r.response"></p-radioButton>
            {{ r.response | translate }}
          </label>
        </div>
        <div class="flex flex-row-reverse mt-4">
          <span class="ml-4 mr-4 font-bold text-am-xs text-white bg-slate-400 p-1 mr-[20%]">
            <p>{{'inspections.totalScore' | translate}}: {{q.score}}</p>
          </span>
        </div>
      </div>

      <div class="flex flex-row-reverse border-t border-t-am-secondary-navy-20"></div>
      <button *ngIf="currentPhase == 3"
        class="bg-am-primary-turquoise-100 font-bold mt-3  mb-3 mr-5 py-2 px-4 rounded text-white" (click)="prevStep()">
        {{'inspections.previous' | translate}}
      </button>
      <button *ngIf="currentPhase == 3"
        class="bg-am-primary-turquoise-100 font-bold mt-3  mb-3 mr-5 py-2 px-4 rounded text-white"
        [disabled]="!checkPhase3Responses()" (click)="nextStep()">
        {{'inspections.next' | translate}}
      </button>

    </div>

    <!--Formularios Fase Final-->
    <div *ngIf="currentPhase == 4" class="mx-14 mt-10">
      <div class="mb-10" *ngFor="let q of allQuestions; let i=index">
        <h1 class="text-am-secondary-navy-80 text-xl mb-8">{{i + 1}}.
          {{ q.pregunta | translate}}</h1>

          <label class="cursor-pointer text-sm pl-16">
            {{ q.response | translate }}
          </label>

        <div class="flex flex-row mt-4">
          <span class="ml-4 mr-4 font-bold text-am-xs text-white bg-slate-400 p-1 ml-16">
            <p>{{'inspections.totalScore' | translate}}: {{q.score}}</p>
          </span>
        </div>
      </div>
      <div>
        <div class="flex flex-row mt-4 mb-8">
          <span class="font-bold text-am-xs text-white bg-sky-950 p-2">
            <p>Riesgo Total: {{totalScore | number : '1.0-2'}}</p>
          </span>
        </div>
      </div>
      <div class="flex flex-row-reverse border-t border-t-am-secondary-navy-20">
        <button *ngIf="currentPhase == 4"
          class="bg-am-primary-turquoise-100 font-bold mt-3  mb-3 mr-5 py-2 px-4 rounded text-white" (click)="prevStep()">
          {{'inspections.previous' | translate}}
        </button>
        <button *ngIf="currentPhase == 4"
          class="bg-am-primary-turquoise-100 font-bold mt-3  mb-3 mr-5 py-2 px-4 rounded text-white"
          (click)="finish()">
          Terminar
        </button>
      </div>
    </div>
  </div>
