import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class GlobalService {
  public embedded: boolean = false;

  constructor(private readonly route: ActivatedRoute) {
    this.route.queryParams.subscribe((p) => {
      if (p['embedded']) {
        this.embedded = p['embedded'] == 'true';
      }
    });
  }
}
