<div class="container mx-auto flex justify-between">
    <h1 routerLink="/">Ag4asbestos</h1>
    <div class="menu" *ngIf="!user">
        <ul>
            <li><a routerLink="/">{{isMd ? ('header.search' | translate) : ('header.searchMobile' | translate)}}</a></li>
            <div class="sep lg:!hidden"></div>
            <li (click)="goDown()"><a>{{isMd ? ('header.contact' | translate) : ('header.contactMobile' | translate)}}</a></li>
            <div class="sep"></div>
            <li><a routerLink="/login">{{isMd ? ('header.myZone' | translate) : ('header.myZoneMobile' | translate)}}</a></li>
        </ul>
        <div routerLink="/login" class="avatar max-sm:!hidden"></div>
        <!-- <div class="sep"></div>
        <div class="private-zone">
            <a routerLink="/login">{{'header.myZone' | translate}}</a>
            <div routerLink="/login" class="avatar"></div>
        </div> -->

    </div>
    <div class="menu" *ngIf="user">
        <div class="private-zone">
            <img src="../../../assets/icons/bell_Icon.svg" class="mr-6">
            <a routerLink="/account/profile">{{user.name}}</a>
            <div (click)="mn.toggle($event)" class="avatar"></div>
        </div>

    </div>
</div>
<p-menu #mn [model]="menu" [popup]="true"></p-menu>
