<div class="flex xl:justify-between mt-4 xl:mb-4">
  <div>
    <div class="mb-4">
      <span class="p-input-icon-right">
        <i class="pi pi-search"></i>
        <input type="text" pInputText class="p-inputtext-sm w-100" [(ngModel)]="term" [placeholder]="'ui.general.buscar' | translate" (input)="dt.filterGlobal($event.target.value, 'contains')" />
      </span>
    </div>
    <div class="flex flex-row">
      <div class=" flex flex-col mr-4">
        <p-calendar [showIcon]="true" dateFormat="dd/mm/yy" [placeholder]="'ui.general.dateFrom' | translate" [(ngModel)]="fromDate"></p-calendar>
      </div>
      <div class=" flex flex-col">
        <p-calendar [showIcon]="true" dateFormat="dd/mm/yy" [placeholder]="'ui.general.dateTo' | translate" [(ngModel)]="toDate" ></p-calendar>
      </div>
    </div>
  </div>

  <div class="flex xl:item-center xl:mb-4 lg:flex-row-reverse lg:w-full lg:-align-end ">
    <p-button pRipple size="small" [label]="'ui.general.buscar' | translate" icon="pi pi-search" iconPos="left" (onClick)="buscarInspecciones()"></p-button>
  </div>
</div>

<p-table #dt class="" [value]="data"
                      [paginator]="true"
                      [rows]="10"
                      [totalRecords]="totalRecords"
                      [lazy]="true"
                      (onLazyLoad)="load($event)"
                      [loading]="loading"
                      [rowHover]="true"
                      [showCurrentPageReport]="true"
                      [globalFilterFields]="['catastralReference', 'date', 'address', 'client', 'inspector', 'status']"
                      currentPageReportTemplate="{currentPage} de {totalPages}">

    <ng-template pTemplate="header">
        <tr>
            <th pSortableColumn="catastralReference">{{ "inspections.cadastralReferenceAbbreviated" | translate }}<p-sortIcon field="catastralReference"></p-sortIcon></th>
            <th pSortableColumn="date">{{ "inspections.inspectionDate1" | translate }} <p-sortIcon field="date"></p-sortIcon></th>
            <th pSortableColumn="address">{{ "inspections.address" | translate }}<p-sortIcon field="address"></p-sortIcon></th>
            <!-- <th pSortableColumn="client">Cliente <p-sortIcon field="client"></p-sortIcon></th> -->
            <th pSortableColumn="inspector">{{ "inspections.inspector" | translate }}<p-sortIcon field="inspector.email"></p-sortIcon></th>
            <th pSortableColumn="status">{{ "inspections.status" | translate }}<p-sortIcon field="status"></p-sortIcon></th>

            <th class="w-16"></th>
            <th class="w-16"></th>
            <th class="w-16"></th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-data>
        <tr  class="cursor-pointer" (click)="selected(data)">
            <td class="font-semibold">{{ data.catastralReference }}</td>
            <td>{{ (data?.date | date) || '-' }}</td>
            <td>{{ data?.address || '-'}}</td>
            <!-- <td>{{ data?.client?.businessName || '-'}}</td> -->
            <td>{{ data?.inspector?.email || '-' }}</td>
            <td>{{ data?.status }}</td>
            <td>
                <p-button pRipple [hidden]="!canEdit" type="button" [outlined]="true" size="small" (click)="selected(data)">{{ 'inspections.beginInspection' | translate}}</p-button>
            </td>
            <td>
              <button pButton pRipple [hidden]="!canDelete" type="button"  icon="pi pi-trash" class="p-button-text p-button-rounded p-button-plain " (click)="delete($event, data)"></button>
          </td>
            <td>
                <button pButton pRipple [hidden]="!canView" type="button"  icon="pi pi-chevron-right" class="p-button-text p-button-rounded p-button-plain "
                (click)="selected(data)"></button>
            </td>
        </tr>
    </ng-template>


    <ng-template pTemplate="emptymessage" let-columns let-frozen="frozen">
        <tr>
            <td [attr.colspan]="8"><div class="text-center p-10">{{ 'ui.general.noresults' | translate}}</div></td>
        </tr>
    </ng-template>

    <ng-template pTemplate="sorticon" let-number>
        <i *ngIf="number == 0" class="pi pi-sort" style="opacity:0.2"></i>
        <i *ngIf="number == 1" class="pi pi-sort-down"></i>
        <i *ngIf="number == -1" class="pi pi-sort-up"></i>
    </ng-template>

</p-table>

<p-confirmDialog key="delete" #cd [style]="{ width: '50vw' }">
  <ng-template pTemplate="header">
      <h3 class="font-bold">{{ 'inspections.deleteButton' | translate}}</h3>
  </ng-template>
  <ng-template pTemplate="message">
      <div style="width:100%" class="text-sm text-center font-semibold w-full">
          <div>
              {{'inspections.deleteInspectionMsg' | translate}}
          </div>
      </div>
  </ng-template>
  <ng-template pTemplate="footer">
    <div class="flex flex-row mt-4">
      <p-button type="button" [label]="'ui.general.cancelar' | translate" (click)="cd.reject()" size="small" [outlined]="true"></p-button>
      <p-button type="button" [label]="'ui.general.aceptar' | translate" (click)="cd.accept()" size="small" severity="danger"></p-button>
    </div>
  </ng-template>
</p-confirmDialog>
