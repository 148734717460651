<!--Menu Seccion privada-->
<div class="flex items-center w-full h-12 mt-0.5">
    <p *ngIf="isOrganization && canViewAdmin"
        class="w-full text-center h-full py-3 text-white font-bold cursor-pointer" routerLink="/admin/clients" [ngClass]="router.url.includes('/admin') ? 'bg-am-primary-navy-100' : 'bg-am-secondary-navy-60'">{{'privateNavBar.admin' | translate}}</p>

    <p *ngIf="(isProvider || isOrganization || isClient) && canViewExpedientes"
        class="w-full text-center h-full py-3 text-white font-bold cursor-pointer" routerLink="/records" [ngClass]="router.url.includes('/records') ? 'bg-am-primary-navy-100' : 'bg-am-secondary-navy-60'">{{'privateNavBar.records' | translate}}</p>

    <p  *ngIf="(isProvider || isOrganization || isClient) && canViewInspecciones"
        class="w-full text-center h-full py-3 text-white font-bold cursor-pointer" routerLink="/inspections" [ngClass]="router.url.includes('/inspect') ? 'bg-am-primary-navy-100' : 'bg-am-secondary-navy-60'" [ngClass]="router.url.includes('/inspect') ? 'bg-am-primary-navy-100' : 'bg-am-secondary-navy-60'">{{'privateNavBar.inspections' | translate}}</p>

    <p *ngIf="(isProvider || isOrganization || isClient) && canViewRetiradas"
        class="w-full text-center h-full py-3 text-white font-bold cursor-pointer" routerLink="/withdrawals/withdrawal"  [ngClass]="router.url.includes('/withdrawals') ? 'bg-am-primary-navy-100' : 'bg-am-secondary-navy-60'">{{'privateNavBar.withdrawals' | translate}}</p>

    <p *ngIf="isProvider || isOrganization"
        class="w-full text-center h-full py-3 text-white font-bold cursor-pointer" (click)="hasSectionPermission()" [ngClass]="router.url.includes('/training') ? 'bg-am-primary-navy-100' : 'bg-am-secondary-navy-60'">{{'privateNavBar.training' | translate}}</p>
</div>
<app-popupnopermissions *ngIf="showPopup" (closePop)="closePopup()"></app-popupnopermissions>
