<div class="newPass__content flex flex-col items-center py-16 gap-6">
<h2 class="font-bold text-am-2xl leading-am-8 text-am-primary-navy-100">{{'login.chooseNewPassTitle' | translate}}</h2>
<p class="text-center text-sm leading-am-4 text-am-secondary-navy-80 font-semibold">{{'login.chooseNewPassSubtitle1' | translate}}<span class="block">{{'login.chooseNewPassSubtitle2' | translate}}</span></p>
<form [formGroup]="form" class="flex flex-col items-center py-4 gap-6">
<div class="new__pass flex flex-col gap-2">
  <label for="newPass" class="text-xs text-am-secondary-navy-60 leading-am-3 font-bold translate-x-1">{{'login.newPass' | translate}}</label>
  <p-password name="newPass" id="newPass" [toggleMask]="true" [feedback]="false" formControlName="newPass"

  [inputStyleClass]="(form.get('newPass')?.invalid && form.get('newPass')?.touched ? 'border-red-500' : '') +
  'outline-0 w-72 h-11 border rounded-none border-t-0 border-l-0 border-r-0 py-1.5 pr-2 pl-4 border-am-secondary-navy-30 text-am-primary-navy-100'" class="new__password"></p-password>
</div>
<div class="repeat__pass flex flex-col gap-2">
  <label for="repeatPass" class="text-xs text-am-secondary-navy-60 leading-am-3 font-bold translate-x-1">{{'login.repeatPass' | translate}}</label>
  <p-password name="repeatPass" id="repeatPass" [toggleMask]="true" [feedback]="false" formControlName="repeatPass"

  [inputStyleClass]="(form.get('repeatPass')?.invalid && form.get('repeatPass')?.touched ? 'border-red-500' : '') +
  'outline-0 w-72 h-11 border rounded-none border-t-0 border-l-0 border-r-0 py-1.5 pr-2 pl-4 border-am-secondary-navy-30 text-am-primary-navy-100'" class="new__password">
  </p-password>
</div>
<div *ngIf="error">
  <p>Hubo un error al cambiar la contraseña</p>
</div>
<button [disabled]="loading || form.invalid"
class="newpass__button bg-am-primary-turquoise-100 rounded-am-md text-white w-72 h-10 text-sm leading-am-4 font-bold py-2.5 px-4 mt-4" (click)="savePass()">
  <span *ngIf="!loading">{{'login.savePass' | translate}}</span>
  <span class="loader" *ngIf="loading"></span>
</button>
</form>
</div>
