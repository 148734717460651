/* eslint-disable @typescript-eslint/unbound-method */
import { HttpClient } from '@angular/common/http';
import { Component, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Confirmation } from 'primeng/api';
import { lastValueFrom } from 'rxjs';
import { PermissionsComponent } from 'src/app/shared/components/permissions/permissions.component';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { environment } from 'src/environments/environment';
import { Location } from '@angular/common';

@Component({
  selector: 'app-empresas-retiradas-upsert',
  templateUrl: './empresas-retiradas-upsert.component.html',
  styleUrls: ['./empresas-retiradas-upsert.component.scss'],
})
export class EmpresasRetiradasUpsertComponent {
  @ViewChild('ap') ap?: PermissionsComponent;
  @ViewChild('ad') ad?: Confirmation;

  user?: any;
  loading?: boolean;
  loadingClients?: boolean;
  deleteLoading?: boolean;
  roles?: any;
  form = new FormGroup({
    id: new FormControl(null),
    cif: new FormControl(null, Validators.required),
    businessName: new FormControl(null, Validators.required),
    phone: new FormControl(null, Validators.required),
    address: new FormControl(null, Validators.required),
    email: new FormControl(null, Validators.required),
    numeroRera: new FormControl(null, Validators.required),
  });

  errors: { [key: string]: string | boolean } = {};

  owner_id?: string;
  embedded: boolean = false;
  inserting: boolean = true;

  constructor(
    private readonly http: HttpClient,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
  ) {}

  async ngAfterViewInit() {
    const params = this.route.snapshot.queryParams;

    if (params['id']) {
      await this.loadCompany(params['id']);
      this.inserting = false;
    } else {
      //await this.ap?.getDefaultsByRole(this.roles[0]?.id);
    }
  }

  async loadCompany(id: string) {
    this.loading = true;

    const result = await lastValueFrom(
      this.http.get<any>(environment.api + 'retiradas/company/' + id),
    );

    this.form.patchValue({
      id: result.id,
      cif: result.cif,
      businessName: result.businessName,
      phone: result.phone,
      address: result.address,
      email: result.email,
      numeroRera: result.numeroRera,
    });

    this.loading = false;
  }

  async save() {
    if (this.form.valid) {
      this.loading = true;

      try {
        this.loading = false;

        const data = {
          id: this.form.get('id')?.value,
          cif: this.form.get('cif')?.value,
          businessName: this.form.get('businessName')?.value,
          phone: this.form.get('phone')?.value,
          address: this.form.get('address')?.value,
          email: this.form.get('email')?.value,
          numeroRera: this.form.get('numeroRera')?.value,
        };

        if (this.inserting) {
          await lastValueFrom(
            this.http.post<any>(environment.api + 'retiradas/company', data),
          );
        } else {
          await lastValueFrom(
            this.http.put<any>(
              environment.api + `retiradas/company/${data.id}`,
              data,
            ),
          );
        }
        await this.router.navigate(['admin/withdrawalcompanies']);
      } catch (e: any) {
        console.log(e);
        UtilsService.markGroupControlsAsDirty(this.form);
      }

      this.loading = false;
    } else {
      this.loading = false;
      UtilsService.markGroupControlsAsDirty(this.form);
    }
  }

  getBack() {
    this.location.back();
  }
}
