import { Component } from '@angular/core';
import { UserService } from 'src/app/shared/services/user.service';
import { lastValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-account-security',
  templateUrl: './security.component.html',
  styleUrls: ['./security.component.scss'],
})
export class SecurityComponent {
  deletePassword: string = '';
  oldPassword: string = '';
  newPassword1: string = '';
  newPassword2: string = '';

  constructor(
    private router: Router,
    private userService: UserService,
    private http: HttpClient,
    private messageSvc: MessageService,
  ) {}

  async deleteAccount() {
    if (this.deletePassword === '') {
      this.messageSvc.add({
        severity: 'error',
        summary: 'Error',
        detail: 'All fields are required',
      });
      return;
    }

    try {
      await lastValueFrom(
        this.http.post(environment.api + 'user/delete-account', {
          email: this.userService.getUser()?.email,
          password: this.deletePassword,
        }),
      );
      this.messageSvc.add({
        severity: 'success',
        summary: 'Exito',
        detail: 'Se ha borrado el usuario con exito',
      });

      this.userService.logout();
      await this.router.navigate(['/login']);
    } catch (e) {
      this.messageSvc.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Error al borrar el usuario',
      });
      return;
    }
  }

  async changePassword() {
    if (
      this.oldPassword === '' ||
      this.newPassword1 === '' ||
      this.newPassword2 === ''
    ) {
      this.messageSvc.add({
        severity: 'error',
        summary: 'Error',
        detail: 'All fields are required',
      });
      return;
    }

    if (this.newPassword1 !== this.newPassword2) {
      this.messageSvc.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Passwords do not match',
      });
      return;
    }

    try {
      await lastValueFrom(
        this.http.post(environment.api + 'user/change-password', {
          email: this.userService.getUser()?.email,
          oldPassword: this.oldPassword,
          newPassword: this.newPassword1,
        }),
      );

      this.userService.logout();
      await this.router.navigate(['/login']);
    } catch (e) {
      this.messageSvc.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Error al cambiar la contraseña',
      });
      return;
    }
  }
}
