<div class="sent__code flex flex-col items-center justify-center py-24 px-8 gap-4">
  <h2 class="text-am-2xl font-bold leading-am-8 text-am-primary-navy-100">{{'login.enterCodeTitle' | translate}}</h2>
  <p class="text-sm leading-am-4 font-semibold text-am-secondary-navy-80 text-center">{{'login.enterCodeSubtitle1' | translate}}<span class="block">{{'login.enterCodeSubtitle2' | translate}}</span></p>
  <form class="flex flex-col gap-9" [formGroup]="form">
  <div class="in__code flex flex-col gap-1.5">
    <label for="code" class="font-bold text-xs leading-am-3 text-am-secondary-navy-60">{{'login.code' | translate}}</label>
    <fieldset class="flex flex-row gap-4">
      <input type="text" name="code" #digit1 class="w-[45px] h-[45px] border border-am-secondary-navy-20 rounded-[10px] outline-none text-am-lg lead-[22.54px] text-center" maxlength="1" formControlName="code1" (focus)="adjust(null)" (keydown)="back($event, digit1, null)" (keyup)="move($event, digit1, digit2)">
      <input type="text" name="code" #digit2 class="w-[45px] h-[45px] border border-am-secondary-navy-20 rounded-[10px] outline-none text-am-lg lead-[22.54px] text-center" maxlength="1" formControlName="code2" (focus)="adjust(digit1)" (keydown)="back($event, digit2, digit1)" (keyup)="move($event, digit2, digit3)">
      <input type="text" name="code" #digit3 class="w-[45px] h-[45px] border border-am-secondary-navy-20 rounded-[10px] outline-none text-am-lg lead-[22.54px] text-center" maxlength="1" formControlName="code3" (focus)="adjust(digit2)" (keydown)="back($event, digit3, digit2)" (keyup)="move($event, digit3, digit4)">
      <input type="text" name="code" #digit4 class="w-[45px] h-[45px] border border-am-secondary-navy-20 rounded-[10px] outline-none text-am-lg lead-[22.54px] text-center" maxlength="1" formControlName="code4" (focus)="adjust(digit3)" (keydown)="back($event, digit4, digit3)" (keyup)="move($event, digit4, digit5)">
      <input type="text" name="code" #digit5 class="w-[45px] h-[45px] border border-am-secondary-navy-20 rounded-[10px] outline-none text-am-lg lead-[22.54px] text-center" maxlength="1" formControlName="code5" (focus)="adjust(digit4)" (keydown)="back($event, digit5, digit4)" (keyup)="move($event, digit5, null)">
    </fieldset>
  </div>
  <div *ngIf="wrongCode">
    <p class="text-xs leading-am-3 text-red-500">El código introducido no es correcto</p>
  </div>
  <button class="w-72 h-10 rounded-am-md bg-am-primary-turquoise-100 text-white px-4" (click)="entryCode()" [disabled]="form.invalid || loading">
    <span *ngIf="!loading">{{'login.continue' | translate}}</span>
    <span class="loader" *ngIf="loading"></span>
  </button>
</form>
</div>
