import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-notify-sent',
  templateUrl: './notify-sent.component.html',
  styleUrls: ['./notify-sent.component.scss'],
})
export class NotifySentComponent implements OnInit {
  ine: string | null = '';
  internalId?: number;

  constructor() {}

  ngOnInit(): void {
    this.ine = localStorage.getItem('ine');
    this.internalId = parseInt(localStorage.getItem('internalId') || '0');
  }
}
