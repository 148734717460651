import { HttpClient } from '@angular/common/http';
import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  Input,
  OnInit,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {
  ConfirmEventType,
  ConfirmationService,
  MessageService,
  SelectItem,
} from 'primeng/api';
import { lastValueFrom } from 'rxjs';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { environment } from 'src/environments/environment';
import { Contact } from './contact.model';
import { DOCUMENT } from '@angular/common';
import { Locality } from '../locality.interface';

import {
  PermissionScope,
  PermissionType,
  User,
  UserService,
} from 'src/app/shared/services/user.service';

export type RecordType = 'NONE' | 'INDEPENDENT' | 'LINKED';

@Component({
  selector: 'app-upsert-record',
  templateUrl: './upsert-record.component.html',
  styleUrls: ['./upsert-record.component.scss'],
})
export class UpsertRecordComponent implements OnInit, AfterViewInit {
  form = new FormGroup({
    reference: new FormControl('', [
      // eslint-disable-next-line @typescript-eslint/unbound-method
      Validators.required,
      Validators.minLength(3),
    ]),
    // eslint-disable-next-line @typescript-eslint/unbound-method
    manager_id: new FormControl(null, [Validators.required]),
    // eslint-disable-next-line @typescript-eslint/unbound-method
    client: new FormControl(null, [Validators.required]),
    // eslint-disable-next-line @typescript-eslint/unbound-method
    status: new FormControl('', [Validators.required]),
    // eslint-disable-next-line @typescript-eslint/unbound-method
    locality: new FormControl<any>(null, [Validators.required]),
    province: new FormControl({ value: '', disabled: true }, [
      // eslint-disable-next-line @typescript-eslint/unbound-method
      Validators.required,
    ]),
    postalCode: new FormControl('', [Validators.minLength(5)]),
    // eslint-disable-next-line @typescript-eslint/unbound-method
    contacts: new FormControl([] as Contact[], [Validators.required]),
    notes: new FormControl(null, []),
    documents: new FormControl<{ name: string; data: string }[]>([]),
    estates: new FormControl<any[]>([], []),
    //showInPublic: new FormControl(false),
    // eslint-disable-next-line @typescript-eslint/unbound-method
    tipoexp: new FormControl('', [Validators.required]),
    recordINEId: new FormControl({ value: 0, disabled: true }),
    selectedContact: new FormControl(null),
    detailed: new FormControl(true),
    owner_id: new FormControl(),
    createdBy: new FormControl(),
  });
  record: any;
  loading: boolean = false;
  deleteLoading: boolean = false;

  errors: { [key: string]: string | boolean } = {};

  statusOptions = [
    {
      key: 'ACTIVE',
      label: 'Activo',
    },
    {
      key: 'INACTIVE',
      label: 'Inactivo',
    },
    {
      key: 'PENDING',
      label: 'Pendiente',
    },
  ];

  tipoexpOptions = ['Independiente', 'Vinculado'];
  recordType: RecordType = 'LINKED';

  isEmpresa = false;
  recordsINE: any[] = [];
  recordINEId: number = 0;
  contacts: any[] = [];
  defaultContact: any;
  contactDirty: boolean = false;
  oldDefaultContact = 0;

  clients: any[] = [];
  clientsDrop: SelectItem[] = [];

  selectedContactClient: any;

  bInserting: boolean = false;

  locality: any;
  owner_id: string = '';

  user?: User;
  canCreate: boolean = false;
  canEdit: boolean = false;
  canDelete: boolean = false;

  canView: boolean = false;
  canViewAll: boolean = false;
  isClient: boolean = false;
  isProvider: boolean = false;
  isOrganization: boolean = false;

  providerOwnerId: string = '';
  clientInternalId?: number;

  @Input() id: string = '';

  private window: Window | null;

  @ViewChildren('contactos') contactComponents: any;

  @ViewChild('addContact') addContactButton?: ElementRef;
  @ViewChild('addDoc') addDocButton?: ElementRef;

  constructor(
    private readonly http: HttpClient,
    private readonly router: Router,
    private route: ActivatedRoute,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    @Inject(DOCUMENT) private document: Document,
    private userSvc: UserService,
  ) {
    this.window = this.document.defaultView;
  }
  ngAfterViewInit(): void {
    if (this.canEdit || this.canCreate) {
      if (
        this.addContactButton?.nativeElement !== undefined &&
        this.addDocButton?.nativeElement !== undefined
      ) {
        this.addContactButton.nativeElement.disabled = false;
        this.addDocButton.nativeElement.disabled = false;
      }
    } else {
      if (
        this.addContactButton?.nativeElement !== undefined &&
        this.addDocButton?.nativeElement !== undefined
      ) {
        this.addContactButton.nativeElement.disabled = true;
        this.addDocButton.nativeElement.disabled = true;
      }
    }
  }

  private clientPermissions() {
    this.canCreate = false;
    this.canEdit = false;
    this.canDelete = false;
    this.canView = true;
    this.canViewAll = true;
  }

  private providerPermissions() {
    this.canCreate =
      this.user?.hasPermission(
        PermissionScope.RECORDS,
        PermissionType.CREATE,
      ) || false;
    this.canEdit =
      this.user?.hasPermission(PermissionScope.RECORDS, PermissionType.EDIT) ||
      false;
    this.canDelete =
      this.user?.hasPermission(
        PermissionScope.RECORDS,
        PermissionType.DELETE,
      ) || false;
    this.canView =
      this.user?.hasPermission(PermissionScope.RECORDS, PermissionType.VIEW) ||
      false;
    this.canViewAll =
      this.user?.hasPermission(
        PermissionScope.RECORDS,
        PermissionType.VIEW_ALL,
      ) || false;
  }

  private organizationPermissions() {
    this.canCreate = false;
    this.canEdit = false;
    this.canDelete = false;
    this.canView =
      this.user?.hasPermission(PermissionScope.RECORDS, PermissionType.VIEW) ||
      false;
    this.canViewAll =
      this.user?.hasPermission(
        PermissionScope.RECORDS,
        PermissionType.VIEW_ALL,
      ) || false;
  }

  async ngOnInit() {
    this.user = this.userSvc.getUser();

    this.isClient = this.user?.is_client || false;
    this.isProvider = this.user?.is_provider || false;
    this.isOrganization = this.user?.is_organization || false;

    if (this.isClient) {
      this.clientPermissions();
    }

    if (this.isProvider) {
      this.providerPermissions();
      this.owner_id = this.user?.owner_id || '';
    }

    if (this.isOrganization) {
      this.organizationPermissions();
    }

    console.log('canCreate : ' + this.canCreate);
    console.log('canEdit : ' + this.canEdit);
    console.log('canDelete : ' + this.canDelete);
    console.log('canView : ' + this.canView);
    console.log('canViewAll : ' + this.canViewAll);

    //const id = this.route.snapshot.queryParams['id'];

    this.recordsINE = await lastValueFrom(
      this.http.get<any[]>(environment.api + 'expedientes/localities'),
    );

    if (this.id !== undefined) {
      this.bInserting = false;

      this.form.get('client')?.clearValidators();
      await this.loadRecord(this.id);
    } else {
      this.bInserting = true;

      this.form.get('tipoexp')?.setValue('Independiente');
      this.form.get('status')?.setValue('ACTIVE');

      this.form.get('createdBy')?.setValue(this.user);

      this.form
        .get('client')
        // eslint-disable-next-line @typescript-eslint/unbound-method
        ?.addValidators([Validators.required, Validators.min(1)]);
      this.form.get('client')?.markAsDirty();
    }

    const data = !this.isClient
      ? await lastValueFrom(
          this.http.get<any>(
            environment.api + 'owners/provider/clients/' + this.owner_id,
          ),
        )
      : null;

    console.log('Clientes : ', data);
    this.clients = data;

    if (
      this.form.get('recordINEId')?.value !== undefined &&
      this.form.get('recordINEId')?.value !== null
    ) {
      this.isEmpresa = false;
      this.form.get('tipoexp')?.setValue('Vinculado');
      //this.form.get('status')?.setValue('PENDING');
      this.form.get('recordINEId')?.enable();
      this.form.get('locality')?.disable();
      this.form.get('client')?.setValue(null);
      this.form.get('client')?.disable();

      this.form
        .get('recordINEId')
        // eslint-disable-next-line @typescript-eslint/unbound-method
        ?.setValidators([Validators.required, Validators.min(1)]);

      this.recordType = 'LINKED';
    } else {
      this.isEmpresa = true;
      this.form.get('tipoexp')?.setValue('Independiente');
      //this.form.get('status')?.setValue('ACTIVE');
      this.form.get('recordINEId')?.disable();
      this.form.get('recordINEId')?.setValue(null);
      this.form.get('locality')?.enable();
      this.form.get('client')?.enable();
      this.form.get('recordINEId')?.setValidators([]);

      this.recordType = 'INDEPENDENT';
    }
    /*
    await this.form.get('tipoexp')?.valueChanges.subscribe((val: any) => {
      if (val === 'Independiente') {
        this.isEmpresa = true;
        this.form.get('recordINEId')?.disable()
        this.form.get('recordINEId')?.setValue(null);
        this.form.get('locality')?.enable()
        this.form.get('recordINEId')?.setValidators([]);
      } else {
        this.isEmpresa = false;
        this.form.get('recordINEId')?.enable();
        this.form.get('locality')?.disable()
        this.form.get('recordINEId')?.setValue(null);
        this.form.get('recordINEId')?.setValidators([Validators.required, Validators.min(1)]);
      }
    });
    */

    /*
    await this.http.get(environment.api + 'client').toPromise().then((d: any) => {
      this.clientsDrop = d.data;
    });
    */

    // eslint-disable-next-line @typescript-eslint/unbound-method
    this.canEdit || this.canCreate ? this.form.enable : this.form.disable();
  }

  changeRecordINE(e: any) {
    console.log(e.value);

    if (e.value !== undefined && e.value !== null) {
      this.form.get('locality')?.patchValue(e.value);
      this.form.get('province')?.patchValue(e.value.province);

      this.locality = e.value;
    }
  }

  changeLocal(e: any) {
    this.form.get('locality')?.patchValue(e.value);
    this.form.get('province')?.patchValue(e.value.province);

    console.log(this.form.get('locality'));

    this.locality = e.value;
  }

  async setLocality(ine: number) {
    const localidad = await this.getLocality(ine);

    console.log(localidad);

    this.form.get('locality')?.patchValue(localidad);
    this.form
      .get('province')
      ?.patchValue(localidad != null ? localidad.province : '');

    this.locality = localidad;

    this.form.get('locality')?.disable();

    console.log(this.form.value);
  }

  changeRecordType(e: any) {
    console.log(e);

    this.recordType = e.value == 'Independiente' ? 'INDEPENDENT' : 'LINKED';

    //this.form.get('recordINEId')?.clearValidators();

    //this.form.get('recordINEId')?.setValue(0);

    if (this.recordType === 'INDEPENDENT') {
      this.isEmpresa = true;
      this.form.get('recordINEId')?.disable();
      this.form.get('recordINEId')?.setValue(null);
      this.form.get('locality')?.enable();
      this.form.get('status')?.setValue('ACTIVE');
      this.form.get('client')?.enable();
    } else {
      this.isEmpresa = false;
      this.form.get('recordINEId')?.enable();
      this.form.get('locality')?.disable();
      this.form.get('status')?.setValue('PENDING');
      this.form.get('client')?.disable();
      this.form.get('client')?.setValue(null);
      //this.form.get('recordINEId')?.addValidators([Validators.required, Validators.min(1)]);
      //this.form.get('recordINEId')?.markAsDirty();
    }

    //this.form.get('recordINEId')?.setErrors(null);
    //this.form.get('recordINEId')?.updateValueAndValidity({onlySelf: true, emitEvent: true});

    console.log(this.form);
  }

  async loadRecord(id: string) {
    this.loading = true;

    const url = environment.api + 'expedientes/byid/' + id;
    console.log('Loading record ' + url);
    await this.http
      .get<any>(url)
      .toPromise()
      .then((record) => {
        console.log('Record loaded', record);
        this.record = record;
      })
      .catch((error) => {
        console.log('Error loading record', error);
      });

    //const record: any = await lastValueFrom(this.http.get<any>(url));

    //this.record = record;

    //await this.loadClients();

    //console.log(record);

    //(this.form.get('contacts') as FormArray).clear();

    this.contacts = this.record.contacts;

    /*
    this.contacts.forEach((c: Contact) => {
      c.clientControl = new FormControl(c);
    });
    */

    const defaultContact = this.contacts.filter((c) => c.default)[0];

    if (!defaultContact) {
      this.contacts[0].default = true;
    }

    //record.contacts.forEach((c: any) => this.addContact(c));

    //const recordINEId = await this.getRecordINEId(record.id)

    //console.log(recordINEId)

    this.form.get('reference')?.setValue(this.record.reference);
    this.form.get('manager_id')?.setValue(this.record.manager_id);
    this.form.get('client')?.setValue(this.record.client);
    this.form.get('status')?.setValue(this.record.status);
    this.form.get('locality')?.setValue(this.record.locality);
    this.form.get('postalCode')?.setValue(this.record.postalCode);
    this.form.get('contacts')?.setValue(this.record.contacts);
    this.form.get('notes')?.setValue(this.record.notes);
    this.form.get('documents')?.setValue(this.record.documents);
    this.form.get('estates')?.setValue(this.record.estates);
    this.form.get('detailed')?.setValue(this.record.detailed);
    //this.form.get('showInPublic')?.setValue(record.showInPublic);
    this.form.get('recordINEId')?.setValue(this.record.recordINEId);
    this.form
      .get('tipoexp')
      ?.setValue(
        this.record?.recordINEId != null ? 'Vinculado' : 'Independiente',
      );
    this.form.get('owner_id')?.setValue(this.owner_id);
    this.form.get('province')?.setValue(this.record.locality.province);

    /*
    this.form.patchValue({
      reference: record.reference,
      manager_id: record.manager_id,
      client: record.client,
      status: record.status,
      locality: record.locality,
      postalCode: record.postalCode,
      contacts: record.contacts,
      notes: record.notes,
      documents: record.documents,
      estates: record.estates,
      detailed: record.detailed,
      //showInPublic: record.showInPublic,
      tipoexp: (record?.recordINEId != null ? 'Vinculado' : 'Independiente'),
      recordINEId: record?.recordINEId,
      owner_id: this.owner_id
    });
    */

    console.log('After load form values');
    console.log(this.form.value);

    this.loading = false;
  }

  private async getRecordINEId(recordInternalId: number): Promise<number> {
    const recordINE: any = await lastValueFrom(
      this.http.get(
        environment.api + `expedientes/record-ine/${recordInternalId}`,
      ),
    );

    return (recordINE?.recordINEId || 0) as number;
  }

  private async getLocality(ine: number): Promise<Locality | null> {
    if (ine !== undefined && ine !== null && ine !== 0) {
      const locality: Locality = await lastValueFrom(
        this.http.get<Locality>(environment.api + `locality/${ine}`),
      );

      return locality;
    }

    return null;
  }

  async save() {
    console.log('Insert status ' + this.bInserting);
    console.log(this.locality);

    const values: any = this.form.getRawValue();

    if (values.contacts.length === 0) {
      this.loading = true;

      this.confirmationService.confirm({
        key: 'confirm',
        accept: async () => {
          this.loading = false;
        },
      });

      return;
    }

    /*
    if (this.bInserting) {
      console.log('Setting client to ' + this.selectedContactClient.client.id);
      this.form.get('client')?.setValue(this.selectedContactClient.client);
    }
    else {
      if (this.form.get('status')?.value === 'INACTIVE') {
        this.form.get('client')?.setValue(this.selectedContactClient.client);
      }
    }
    */

    if (this.form.get('tipoexp')?.value === 'Independiente') {
      this.form.get('status')?.setValue('ACTIVE');
    } else {
      if (this.form.get('status')?.value === null) {
        this.form.get('status')?.setValue('PENDING');
      }
    }

    this.form.get('status')?.setValue('ACTIVE');

    console.log(this.form.getRawValue());
    console.log(this.form.valid);

    if (this.form.valid) {
      this.loading = true;

      //values.recordINEId = this.recordINEId;

      values.contacts.forEach((element: any) => {
        delete element?.isDirty;
        delete element?.clientControl;

        //element.client = this.clientsDrop.filter((c: any) => c.id === element.client)[0];
      });

      console.log('Form despues de ajustes');
      console.log(this.form);

      values['owner_id'] = this.owner_id;

      values['user'] = this.userSvc.user;

      console.log('Guardando expediente : ' + values);

      try {
        let result: any = null;

        if (this.record) {
          result = await lastValueFrom(
            this.http.put(
              environment.api + 'expedientes/' + this.record.id,
              values,
            ),
          );
        } else {
          result = await lastValueFrom(
            this.http.post(environment.api + 'expedientes', values),
          );
          // Una vez creado el expediente creamos el contrato

          if (values.tipoexp === 'Vinculado') {
            const contract = await lastValueFrom(
              this.http.post(
                environment.api +
                  'contracts/create-linked' +
                  `/${this.owner_id}/${result.id}/${values.recordINEId.ineInternalId}`,
                null,
              ),
            );

            console.log(contract);
          } else {
            const contract = await lastValueFrom(
              this.http.post(
                environment.api +
                  'contracts/create-independent' +
                  `/${this.owner_id}/${result.id}`,
                null,
              ),
            );
            console.log(contract);
          }
        }

        console.log(result);

        await this.router.navigate(['../'], { relativeTo: this.route });

        this.loading = false;
      } catch (error: any) {
        console.log(error);
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: error.error.message,
        });
        this.loading = false;
      }
    } else {
      console.log('form invalido ' + this.form.status);

      console.log(this.form);

      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Revisa campos incompletos o erroneos',
      });

      UtilsService.markGroupControlsAsDirty(this.form);
    }
  }

  addNewContact() {
    //(this.form.get('contacts') as FormArray).push(this.newContact());

    let d = false;
    const id = 1000 + Math.floor(Math.random() * 1000000);

    if (this.contacts.length == 0) {
      d = true;
      this.oldDefaultContact = id;
    }

    const contact: Contact = {
      id: id,
      name: '',
      email: '',
      //client: 0,
      phone: '',
      record: this.record?.id,
      default: d,
      clientControl: new FormControl(null),
      isDirty: false,
    };

    this.contactDirty = true;

    this.contacts.push(contact);

    console.log(this.contactComponents);
  }

  canRemoveContact() {
    return (
      (this.form.get('contacts') as FormArray).length > 1 &&
      (this.form.get('contacts') as FormArray).controls.filter(
        (control: AbstractControl) => control.invalid,
      ).length === 0
    );
  }

  async addDocument(event: any) {
    await UtilsService.htmlFileInputEventToBase64(event).then(
      (data: string) => {
        const documents = this.form.get('documents')?.value || [];
        documents.push({
          name: event.target.files[0].name,
          data: data,
        });
        this.form.get('documents')?.setValue(documents);
      },
    );
  }
  delete() {
    this.confirmationService.confirm({
      message: `<strong>¿Seguro que eliminar el expediente ID <strong>${this.record?.id} (${this.record?.reference})</strong>?`,
      acceptLabel: 'Eliminar',
      accept: async () => {
        this.deleteLoading = true;
        await this.http
          .delete(environment.api + 'expedientes/' + this.record.id)
          .toPromise()
          .then(async (_data: any) => {
            await this.router.navigate(['../'], { relativeTo: this.route });
          })
          .catch((error) => {
            console.log(error);
          });
        this.deleteLoading = false;
      },
      reject: (_type: ConfirmEventType) => {},
    });
  }

  saveContact(contact: Contact) {
    console.log('Guardando contacto');
    console.log(contact);

    //contact.client = contact;

    if (this.validateContact(contact)) {
      contact.isDirty = false;

      this.form.get('contacts')?.setValue(this.contacts);

      console.log('Contactos actuales');
      console.log(this.form.get('contacts')?.value);

      this.contactDirty = false;
    } else {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Todos los campos del contacto son obligatorios',
      });
    }
  }

  private validateContact(contact: Contact) {
    if (contact.name == '' || contact.email == '' || contact.phone == '') {
      return false;
    }

    if (this.validateEmail(contact.email) === null) {
      return false;
    }

    return true;
  }

  private validateEmail(email: string) {
    return email.match(
      // eslint-disable-next-line no-useless-escape
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );
  }

  cancelContact(contact: Contact) {
    console.log('Cancelando contacto');

    if (contact.default) {
      return;
    }

    this.contacts = this.contacts.filter((c: Contact) => c.id !== contact.id);
    this.form.get('contacts')?.setValue(this.contacts);

    this.contactDirty = false;
  }

  /*
  selectClient(e: any) {

  if (!this.loading) {
    console.log('selectClient');

    this.selectedContactClient = e;
    e.isDirty = true;
    //c.client = this.selectClient;
  }
}
*/

  markAsDirty(c: any) {
    console.log(c);
    c.isDirty = true;
  }

  async changeDefaultContact(e: Contact) {
    console.log('changeDefaultContact');

    this.selectedContactClient = e;

    this.contacts
      .filter((c: Contact) => c.id !== e.id)
      .forEach((c: Contact) => {
        c.default = false;
      });

    console.log(e);
  }

  async downloadDocument(document: any) {
    console.log(document);

    // Descargamos el documento desde lel valor de la propiedad data
    const doc = this.form
      .get('documents')
      // eslint-disable-next-line angular/document-service
      ?.value?.filter((d: any) => d.name === document.value.name)[0];

    if (doc?.data) {
      console.log('Donwloading document');

      const [_nada, data] = doc.data.split(',');

      if (data !== undefined) {
        const f = this.b64toBlob(data, 'text/plain');
        // Guardamos el documento en el disco
        if (f != null) {
          console.log('Guardando documento');

          const link = this.document.createElement('a');
          link.href = URL.createObjectURL(f);
          link.download = doc.name;
          link.click();
          link.remove();
          this.messageService.add({
            severity: 'success',
            summary: 'Completado',
            detail: 'Fichero descargado correctamente',
          });
        }
      } else {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Error al descargar el fichero',
        });
      }
    } else {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Error al descargar el fichero',
      });
    }

    /*
    if (!this.bInserting) {
      const b64result = await lastValueFrom(this.http.get(environment.api + `record/${this.record.id}/getdocument/${document}`))
      return b64result
    }
    else {
      return null;
    }
    */
  }

  b64toBlob(b64Data: string, contentType = '', sliceSize = 512) {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  async export() {
    this.loading = true;

    const url = environment.api + 'expedientes/export/' + this.record.id;

    await lastValueFrom(this.http.post(url, { user: this.user }));

    this.messageService.add({
      severity: 'success',
      summary: 'Completado',
      detail:
        'Expediente exportado correctamente. En unos minutos lo recibira en su bandeja de entrada',
    });

    this.loading = false;
  }
}
