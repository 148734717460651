import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ConfirmationService, ConfirmEventType } from 'primeng/api';
import { Table } from 'primeng/table';
import { environment } from 'src/environments/environment';
import { lastValueFrom } from 'rxjs';
import { EmpresaRetiradaDTO } from 'src/app/retiradas/domain/empresa-retirada.dto';

@Component({
  selector: 'app-empresas-retiradas-list',
  templateUrl: './empresas-retiradas-list.component.html',
  styleUrls: ['./empresas-retiradas-list.component.scss'],
})
export class EmpresasRetiradasListComponent implements OnInit {
  @ViewChild('dt') table?: Table;

  companies?: any[] = [];
  totalRecords?: number;
  loading?: boolean;

  constructor(
    private http: HttpClient,
    private confirmationService: ConfirmationService,
  ) {}
  async ngOnInit() {
    await this.load();
  }

  async load(_event?: any) {
    this.loading = true;

    this.companies = await lastValueFrom(
      this.http.get<EmpresaRetiradaDTO[]>(
        environment.api + 'retiradas/companies',
      ),
    );

    console.log(this.companies);

    this.totalRecords = this.companies.length;

    this.loading = false;
  }

  async deleteProvider(e: Event, company: EmpresaRetiradaDTO) {
    console.log('Borrando empresa');
    e.stopPropagation();

    this.confirmationService.confirm({
      key: 'delete',
      accept: async () => {
        await lastValueFrom(
          this.http.delete(environment.api + 'retiradas/company/' + company.id),
        );

        await this.load();
      },
      reject: (_type: ConfirmEventType) => {},
    });
  }
}
