

<p-dropdown appendTo="body" id="client"  placeholder="Ej.Ayuntamiento" (onChange)="onChangeClient($event)"
    [options]="clients"
    optionLabel="name"
    [formControl]="control"
    [style]="{'minWidth':'100%'}"
    [showClear]="true"
    [filter]="true" filterBy="businessName"
    optionLabel="businessName">
    <ng-template let-item pTemplate="selectedItem">
        {{item?.cif}} - {{item?.businessName}}
    </ng-template>
    <ng-template let-item pTemplate="item">
        {{item?.cif}} - {{item?.businessName}}
    </ng-template>
    <ng-template pTemplate="loader" let-options="options">
        <div class="flex align-items-center py-2 px-3" style="height: 38px">
            <p-skeleton [width]="options.even ? '60%' : '50%'" height="1rem"></p-skeleton>
        </div>
    </ng-template>

</p-dropdown>
