<div class="m-5">
    <p-steps [model]="steps" [readonly]="true" class="mb-10 block"></p-steps>
    <div *ngFor="let q of questions; let qi = index" [formGroup]="form">
        <h2 class="text-xl mb-5">{{qi+1}}. {{q?.name}}</h2>
        <div class=" ml-10 mb-10">
            <div *ngFor="let o of q?.options; let oi = index" class="flex flex-column">
                <div class="mb-4 flex">
                    <p-radioButton [value]="o" [inputId]="qi + '_' + oi" [formControlName]="qi"></p-radioButton>
                    <label [for]="qi + '_' + oi" class="ml-2">{{o?.name}}</label>
                </div>
            </div>
            <div class="flex justify-end">
                <div class="inline-block px-4 py-1" style="background-color: #E5E5F3">Puntuación: {{form?.get(qi?.toString())?.value?.value || 0}}</div>
            </div>
        </div>
    </div>
</div>