import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MapComponent } from 'src/app/shared/components/map/map.component';
import { locationUseOptions } from '../../edit/inspection-edit.component';
import { ActivatedRoute, Router } from '@angular/router';
import { lastValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { UserService } from 'src/app/shared/services/user.service';

@Component({
  selector: 'app-inspections-create-reduced',
  templateUrl: './inspections-create-reduced.component.html',
  styleUrls: ['./inspections-create-reduced.component.scss'],
})
export class InspectionsCreateReducedComponent
  implements OnInit, AfterViewInit, OnChanges
{
  @Input() form?: FormGroup;

  @Input() ine: string = '';

  @Input() newRecord: boolean = false;
  @Input() recordId: number = 0;

  geometries: any[] = [];

  @ViewChild('map') map!: MapComponent;

  @Output() onGeometrySelected: EventEmitter<any> = new EventEmitter<any>();

  loadingMap: boolean = false;

  geometrySelected: any;

  objectKeys = Object.keys;
  locationUseOptions = locationUseOptions;

  user?: any;

  enableLocations: boolean = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private http: HttpClient,
    private userSvc: UserService,
  ) {}

  async ngOnChanges(changes: SimpleChanges) {
    if (changes['newRecord'] && !changes['newRecord'].firstChange) {
      const result: any[] = await lastValueFrom(
        this.http.get<any[]>(
          environment.api + 'expedientes/geometries/' + this.recordId,
        ),
      );
      this.geometries = result;
      await this.map.loadGeometry(this.geometries);

      this.form?.get('communication_council_date')?.setValue(new Date());
      //this.form?.get('date')?.setValue(new Date(Date.now()));
      this.form
        ?.get('inspector')
        ?.setValue(await this.getUser(this.userSvc.getUser()));
    }
  }

  async ngAfterViewInit() {
    this.enableLocations = true;
    await this.map.loadGeometry(this.geometries);
  }

  async ngOnInit() {
    console.log(this.ine);
    console.log(this.form);

    console.log(this.form);

    //this.form?.get('communication_council_date')?.setValue(new Date());
    //this.form?.get('date')?.setValue(new Date(Date.now()));
    //this.form?.get('inspector')?.setValue(this.userSvc.getUser());

    //this.user = this.userSvc.getUser();

    this.recordId = this.route.snapshot.queryParams['recordId'];

    this.form?.get('communication_council_date')?.setValue(new Date());
    //this.form?.get('date')?.setValue(new Date(Date.now()));

    //const inspector = this.userSvc.getUser();
    //this.form?.get('inspector')?.setValue(inspector);

    //console.log(inspector);

    this.form
      ?.get('inspector')
      ?.setValue(await this.getUser(this.userSvc.getUser()));

    const result: any[] = await lastValueFrom(
      this.http.get<any[]>(
        environment.api + 'expedientes/geometries/' + this.recordId,
      ),
    );
    this.geometries = result;
    await this.map.loadGeometry(this.geometries);

    //const result: any[] = await lastValueFrom(this.http.get<any[]>(environment.api + 'record/geometries/' + this.recordId));
    //console.log(result);

    //this.geometries = result;
  }

  finishLoading() {
    this.loadingMap = false;
  }

  geometry(e: any) {
    console.log(e);
    this.geometrySelected = e;
    this.onGeometrySelected.emit(e);
  }

  async getUser(usr: any) {
    const result: any = await lastValueFrom(
      this.http.get<any[]>(environment.api + 'user/' + usr.id),
    );

    delete result.client;
    delete result.permissions;
    delete result.records;

    console.log(result);

    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return result;
  }
}
