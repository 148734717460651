import { HttpClient } from '@angular/common/http';
import {
  ChangeDetectorRef,
  Component,
  Inject,
  OnChanges,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { lastValueFrom } from 'rxjs';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { environment } from 'src/environments/environment';
import { DOCUMENT, Location } from '@angular/common';
import { MessageService } from 'primeng/api';
import { UserService } from 'src/app/shared/services/user.service';
import { MapComponent } from 'src/app/shared/components/map/map.component';

@Component({
  selector: 'app-inspections-create',
  templateUrl: './inspections-create.component.html',
  styleUrls: ['./inspections-create.component.scss'],
})
export class InspectionsCreateComponent implements OnChanges {
  items = [
    {
      label: 'Selecciona un emplazamiento en el mapa o campo de formulario',
    },
    {
      label: 'Agenda la inspección',
    },
  ];

  loading = false;
  ine: string = '';

  allData: any = null;

  errors: { [key: string]: string | boolean } = {};

  form?: FormGroup;

  recordId?: number;
  record: any;
  geometrySelected: any;
  detailed: boolean = false;

  newRecord: boolean = false;
  waiting: boolean = false;

  @ViewChild('map') map!: MapComponent;
  geometries: any[] = [];
  proposalDate: Date | null = null;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private http: HttpClient,
    public location: Location,
    private messageService: MessageService,
    private userSvc: UserService,
    private detector: ChangeDetectorRef,
    @Inject(DOCUMENT) private document: Document,
  ) {}
  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (changes['ine'] && !changes['ine'].firstChange) {
      this.ine = changes['ine'].currentValue;

      await this.getRecordData();
    }
  }

  private async getRecordData(): Promise<void> {
    const data = await lastValueFrom(
      this.http.get<any>(
        environment.api + 'expedientes/byid/ro/' + this.recordId,
      ),
    ); //.subscribe((data: any) => {

    this.record = data;
    this.detailed = this.record.detailed;

    console.log(this.record);

    this.form?.get('recordNumber')?.setValue(this.record.id);
    this.form?.get('inspectionNumber')?.setValue(new Date().valueOf());

    data.client && this.form?.get('client')?.setValue(data.client);
    data.locality && this.form?.get('locality')?.setValue(data.locality.name);
    //this.form?.get('locality')?.disable();
    this.form?.get('communication_council_date')?.setValue(new Date());

    //this.form?.get('date')?.setValue(new Date(Date.now()));
    const result = await lastValueFrom(
      this.http.get<any[]>(
        environment.api + 'expedientes/geometries/' + this.recordId,
      ),
    );
    this.geometries = result;

    this.form
      ?.get('inspector')
      ?.setValue(await this.getUser(this.userSvc.user));
  }

  async ngOnInit(): Promise<void> {
    //this.detector.detach();

    const params = this.route.snapshot.queryParams;
    //this.route.queryParams.subscribe((params: any) => {
    //this.loading = true;
    this.form = this.createForm();

    this.recordId = params['recordId'];
    this.proposalDate = params['proposalDate']
      ? new Date(parseInt(params['proposalDate']))
      : null;

    console.log(this.recordId);
    console.log(this.proposalDate);

    await this.getRecordData();

    if (this.proposalDate != null) {
      this.form.get('date')?.setValue(this.proposalDate);
      this.form.get('date')?.disable();

      this.ine = this.record.locality.ine;
    } else {
      this.ine = params['ine'];
      this.form?.get('date')?.setValue(new Date(Date.now()));
    }
  }

  createForm() {
    return new FormGroup({
      id: new FormControl(null),

      client: new FormControl({ disabled: true }),
      recordNumber: new FormControl(0),

      locality: new FormControl(''),
      address: new FormControl(null),
      year: new FormControl(null),
      catastralReference: new FormControl(),
      locationUse: new FormControl(),
      buildingOwner: new FormControl(),
      inspectionNumber: new FormControl(),
      inspectionType: new FormControl(),
      inspectionObjective: new FormControl(),
      date: new FormControl(new Date(Date.now())),
      inspector: new FormControl(),
      actions: new FormControl(),
      scope: new FormControl(),
      episEpc: new FormControl(),
      decontaminationProcedure: new FormControl(),
      wasteDisposal: new FormControl(),
      relevantInformation: new FormControl(),
      buildingEvaluationReport: new FormControl<boolean | null>(false),

      locations: new FormArray([]),
      // eslint-disable-next-line @typescript-eslint/unbound-method
      geometry: new FormControl({}, Validators.required),

      communication_council_date: new FormControl(),
      inspection_sampler: new FormControl(),
      inspection_documentation_manager: new FormControl(),
      inspection_duration: new FormControl(),
      inspection_sampling_duration: new FormControl(),
      future_inspector_notes: new FormControl(),
    });
  }

  geometry(e: any) {
    console.log(e);
    this.geometrySelected = e;

    if (this.form) {
      this.form.getRawValue().geometry = this.geometrySelected;
    }

    this.newRecord = false;

    //this.form.getRawValue()[0].get('geometry')?.setValue(this.geometrySelected);
  }

  async continue() {
    this.waiting = true;

    const form = this.form as FormGroup;

    const fields = form.getRawValue();

    fields['record'] = { id: this.recordId };
    fields['geometry'] = this.geometrySelected;

    if (form.valid) {
      this.allData = fields;
      console.log(this.allData);

      try {
        await lastValueFrom(
          this.http.post(environment.api + 'inspection', this.allData, {
            headers: { 'Content-Type': 'application/json' },
          }),
        );
        this.location.back();
      } catch (e) {
        console.error(e);
      }
    } else {
      UtilsService.markGroupControlsAsDirty(form);
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Debe seleccionar un poligono en el mapa',
      });
    }

    this.waiting = false;
  }

  async addInspection() {
    this.waiting = true;

    const form = this.form as FormGroup;

    const fields = form.getRawValue();

    fields['record'] = { id: this.recordId };
    fields['geometry'] = this.geometrySelected;

    if (form.valid) {
      this.allData = fields;

      try {
        await lastValueFrom(
          this.http.post(environment.api + 'inspection', this.allData, {
            headers: { 'Content-Type': 'application/json' },
          }),
        );

        this.geometrySelected = null;
        this.messageService.add({
          severity: 'success',
          summary: 'Success',
          detail: 'Inspección creada correctamente',
        });
        this.form = this.createForm();
        await this.getRecordData();
        this.newRecord = true;
        this.goTop();
      } catch (e) {
        console.error(e);
        this.messageService.add({
          severity: 'warning',
          summary: 'Aviso',
          detail:
            'Hubo problemas al enviar la Inspeccion. Por favor intentelo nuevo',
        });
      }
    } else {
      UtilsService.markGroupControlsAsDirty(form);
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Debe seleccionar un poligono en el mapa',
      });
    }

    this.waiting = false;
  }

  goTop() {
    console.log('goTop');
    const element = this.document.getElementById('top');
    element?.scrollIntoView({ behavior: 'smooth' });
  }

  async getUser(usr: any) {
    const result: any = await lastValueFrom(
      this.http.get<any[]>(environment.api + 'user/' + usr.id),
    );

    delete result.client;
    delete result.permissions;
    delete result.records;

    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return result;
  }
}
