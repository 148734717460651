<div class="flex flex-col py-6 px-10 gap-8">
  <div class="anchor__return flex gap-2 items-center">
    <a href="/" class="text-am-primary-turquoise-100 font-bold text-sm cursor-pointer">{{'Ayuntamiento de Medina del Campo'}}</a>
  </div>
  <form [formGroup]="form" (keydown.enter)="$event.preventDefault()">
    <div class="flex flex-col gap-6">
  <div class="header flex flex-row justify-between">
    <div class="flex flex-col gap-4">
      <h2 class="font-bold text-am-lg leading-am-6 text-am-secondary-navy-80" >{{'records.locationData' | translate}}</h2>
      <h3 class="font-bold text-sm leading-am-4 text-am-secondary-navy-60">{{'records.cadastralReferenceUpperCase' | translate}}</h3>
    </div>
    <div class="top__buttons flex flex-row gap-5" *ngIf="true">
      <button class="border border-am-primary-turquoise-100 text-am-primary-turquoise-100 font-bold text-sm leading-am-4 rounded-am-md w-[136px] h-[40px]">{{'records.cancel' | translate}}</button>
      <button class="bg-am-primary-turquoise-100 text-white font-bold text-sm leading-am-4 rounded-am-md w-[136px] h-[40px]">{{'records.save' | translate}}</button>
    </div>
    <!-- LOS SIGUIENTES BOTONES DEBERÍAN APARECER CUANDO HAY UNA INSPECCIÓN EN MARCHA. NO ESTÁ IMPLEMENTADO -->
    <div class="top__buttons flex flex-row gap-5" *ngIf="false">
      <button class="border border-am-primary-turquoise-100 text-am-primary-turquoise-100 font-bold text-sm leading-am-4 rounded-am-md w-[136px] h-[40px] flex items-center justify-center gap-2"><img src="../../../assets/icons/share_turquoise.svg" alt="">{{'records.share' | translate}}</button>
      <button class="bg-am-primary-turquoise-100 text-white font-bold text-sm leading-am-4 rounded-am-md px-4 h-[40px] flex items-center justify-center gap-2"><img src="../../../assets/icons/downloadrecord_white.svg" alt="">{{'records.closeGlobalReport' | translate}}</button>
    </div>
    <div class="top__buttons flex flex-row gap-5" *ngIf="false">
      <button class="border border-am-primary-turquoise-100 text-am-primary-turquoise-100 font-bold text-sm leading-am-4 rounded-am-md w-[136px] h-[40px] flex items-center justify-center gap-2"><img src="../../../assets/icons/share_turquoise.svg" alt="">{{'records.share' | translate}}</button>
      <button class="bg-am-primary-turquoise-100 text-white font-bold text-sm leading-am-4 rounded-am-md h-[40px] flex items-center justify-center gap-2 px-4"><img src="../../../assets/icons/downloadrecord_white.svg" alt="">{{'records.downloadMetricReport' | translate}}</button>
    </div>
  </div>
  <div class="border border-am-secondary-navy-30 bg-am-secondary-navy-5 h-[535px]"></div>
  <div class="general__inputs grid grid-flow-col grid-rows-3 gap-5 w-3/4 mt-2 mb-2">
    <div class="flex flex-col gap-3">
      <label for="use_site" class="font-medium text-xs text-am-secondary-navy-60 leading-am-3">{{'records.locationUse' | translate}}</label>
      <p-dropdown formControlname="use_site" name="use_site" id="use_site" class="site__dropdown" [placeholder]="'records.placeholder12' | translate"></p-dropdown>
    </div>
    <div class="flex flex-col gap-3">
      <label for="ownership" class="font-medium text-xs text-am-secondary-navy-60 leading-am-3">{{'records.ownerShip' | translate}}</label>
      <p-dropdown formControlname="ownership" name="ownership" id="ownership" class="site__dropdown" [placeholder]="'records.placeholder15' | translate"></p-dropdown>
    </div>
    <div class="flex flex-col gap-3">
      <label for="MCA" class="font-medium text-xs text-am-secondary-navy-60 leading-am-3">{{'records.MCA' | translate}}</label>
      <p-dropdown formControlname="MCA" name="MCA" id="MCA" class="site__dropdown" [placeholder]="'records.placeholder18' | translate" (onClick)="showMCA()"></p-dropdown>
    </div>
    <div class="flex flex-col gap-3">
      <label for="cadastral_reference" class="font-medium text-xs text-am-secondary-navy-60 leading-am-3">{{'records.cadastralReference' | translate}}</label>
      <input formControlName="cadastral_reference" name="cadastral_reference" id="cadastral_reference" type="text" class="border-b w-[260px] h-[24px] text-[#333333] text-sm leading-am-4 font-semibold placeholder:text-am-gray placeholder:font-medium placeholder:text-sm placeholder:leading-am-4 outline-none"
      [placeholder]="'records.placeholder13' | translate">
    </div>
    <div class="flex flex-col gap-3">
      <label for="locality" class="font-medium text-xs text-am-secondary-navy-60 leading-am-3">{{'records.locality' | translate}}</label>
      <input formControlName="locality" name="locality" id="locality" type="text" class="border-b w-[260px] h-[24px] text-[#333333] text-sm leading-am-4 font-semibold placeholder:text-am-gray placeholder:font-medium placeholder:text-sm placeholder:leading-am-4 outline-none"
      [placeholder]="'records.placeholder16' | translate">
    </div>
    <div class="flex flex-col gap-3">
      <label for="risk_level" class="font-medium text-xs text-am-secondary-navy-60 leading-am-3">{{'records.riskLevel' | translate}}</label>
      <input formControlName="risk_level" name="risk_level" id="risk_level" type="text" class="border-b w-[260px] h-[24px] text-[#333333] text-sm leading-am-4 font-semibold placeholder:text-am-gray placeholder:font-medium placeholder:text-sm placeholder:leading-am-4 outline-none"
      [placeholder]="'records.placeholder19' | translate">
    </div>
    <div class="flex flex-col gap-3">
      <label for="year" class="font-medium text-xs text-am-secondary-navy-60 leading-am-3">{{'records.year' | translate}}</label>
      <input formControlName="year" name="year" id="year" type="text" class="border-b w-[260px] h-[24px] text-[#333333] text-sm leading-am-4 font-semibold placeholder:text-am-gray placeholder:font-medium placeholder:text-sm placeholder:leading-am-4 outline-none"
      [placeholder]="'records.placeholder14' | translate">
    </div>
    <div class="flex flex-col gap-3">
      <label for="address" class="font-medium text-xs text-am-secondary-navy-60 leading-am-3">{{'records.locationAddress' | translate}}</label>
      <input formControlName="address" name="address" id="address" type="text" class="border-b w-[260px] h-[24px] text-[#333333] text-sm leading-am-4 font-semibold placeholder:text-am-gray placeholder:font-medium placeholder:text-sm placeholder:leading-am-4 outline-none"
      [placeholder]="'records.placeholder17' | translate">
    </div>
    <div class="flex flex-col gap-3">
      <label for="withdrawal_schedule" class="font-medium text-xs text-am-secondary-navy-60 leading-am-3">{{'records.withdrawalSchedule' | translate}}</label>
      <input formControlName="withdrawal_schedule" name="withdrawal_schedule" id="withdrawal_schedule" type="text" class="border-b w-[260px] h-[24px] text-[#333333] text-sm leading-am-4 font-semibold placeholder:text-am-gray placeholder:font-medium placeholder:text-sm placeholder:leading-am-4 outline-none"
      [placeholder]="'records.placeholder20' | translate">
    </div>
  </div>
  <div class="locations_notes flex flex-col gap-3">
    <label for="locations_notes" class="font-medium text-xs text-am-secondary-navy-60 leading-am-3">{{'records.notes' | translate}}</label>
    <textarea formControlName="locations_notes" name="locations_notes" id="locations_notes" cols="30" rows="10" class="locations_notes__textarea w-[896px] h-[146.18px] border border-am-secondary-navy-30 rounded-am-md outline-none px-[10px] py-[8px]" [placeholder]="'records.placeholder11' | translate"></textarea>
    <div *ngIf="form.value.MCA" class="mt-16">
      <h3 class="font-medium text-am-lg leading-am-6 text-am-primary-navy-100 mb-10">{{'records.MCA' | translate}}</h3>
      <p-table
      class="reports__list__table"
      [value]="MCA"
      [tableStyle]="{ 'min-width': '50rem' }"
      [rows]="7"

      >
      <ng-template pTemplate="header">
          <tr>
              <th pSortableColumn="type" class="id__th">{{'records.withdrawalSchedule' | translate}}<p-sortIcon field="type"></p-sortIcon></th>
              <th pSortableColumn="risk" class="table__th">{{'records.riskLevel' | translate}}<p-sortIcon field="risk"></p-sortIcon></th>
              <th pSortableColumn="schedule" class="table__th">{{'records.withdrawalSchedule' | translate}}<p-sortIcon field="schedule"></p-sortIcon></th>
              <th pSortableColumn="withdrawal" class="table__th">{{'records.withdrawal' | translate}}<p-sortIcon field="withdrawal"></p-sortIcon></th>
              <th pSortableColumn="withdrawal_schedule" class="table__th">{{'records.withdrawalDate' | translate}}<p-sortIcon field="withdrawal_schedule"></p-sortIcon></th>
              <th></th>
              <th></th>
          </tr>
      </ng-template>
      <ng-template pTemplate="body" let-mca>
          <tr>
              <td class="td__id" [ngClass]="mca.withdrawal ? 'bg-am-secondary-navy-5' : ''">{{ mca.type }}</td>
              <td class="table__td td__align" [ngClass]="mca.withdrawal ? 'bg-am-secondary-navy-5' : ''" [ngSwitch]="mca.risk">
                <span *ngSwitchCase="0">{{'records.low' | translate}}</span>
                <span *ngSwitchCase="1">{{'records.medium' | translate}}</span>
                <span *ngSwitchCase="2">{{'records.high' | translate}}</span>
              </td>
              <td class="table__td" [ngClass]="mca.withdrawal ? 'bg-am-secondary-navy-5' : ''">{{ mca.schedule }}</td>
              <td class="table__td td__inspector" [ngClass]="mca.withdrawal ? 'bg-am-secondary-navy-5' : ''" [ngSwitch]="mca.withdrawal">
                <span *ngSwitchCase="true">{{'records.yes' | translate}}</span>
                <span *ngSwitchCase="false">{{'records.no' | translate}}</span>
              </td>
              <td class="table__td td__align" [innerText]="mca.withdrawal ? mca.withdrawal_date : '--'" [ngClass]="mca.withdrawal ? 'bg-am-secondary-navy-5' : ''"></td>
              <td class="table__td" [ngClass]="mca.withdrawal ? 'bg-am-secondary-navy-5' : ''">
                <button *ngIf="!mca.withdrawal" class="flex items-center justify-center border border-am-primary-turquoise-100 text-am-primary-turquoise-100 font-bold text-sm leading-am-4 rounded-am-md w-[184px] h-[40px]"><img src="../../../assets/icons/uploadrecord.svg" alt="">{{'records.uploadReport' | translate}}</button>
                <button *ngIf="mca.withdrawal" class="flex items-center justify-center border border-am-primary-navy-100 text-am-primary-navy-100 font-bold text-sm leading-am-4 rounded-am-md w-[184px] h-[40px]"><img src="../../../assets/icons/downloadrecord.svg" alt="">{{'records.downloadReport' | translate}}</button>
              </td>
              <td class="td__go cursor-pointer" [ngClass]="mca.withdrawal ? 'bg-am-secondary-navy-5' : ''"><img src="../../../assets/icons/togo.svg" width="16px"></td>
          </tr>
      </ng-template>
    </p-table>
    </div>
  </div>
  <div *ngIf="!form.value.inspections">
  <h3 class="text-am-secondary-navy-80 font-medium text-am-lg leading-am-6 mb-2">{{'records.inspections' | translate}}</h3>
  <div class="not__locations__yet h-[173px] bg-am-secondary-navy-5 flex flex-col gap-6 justify-center items-center">
    <span class="font-medium text-base leading-[18.78px] text-am-secondary-navy-60">{{'records.notInspectionsYet' | translate}}</span>
    <button class="border border-am-primary-turquoise-100 text-am-primary-turquoise-100 font-bold text-sm leading-am-4 rounded-am-md px-4 h-[40px] flex flex-row gap-2 items-center justify-center" (click)="openPop()"> <img src="../../../assets/icons/calendaricon.svg" alt="">{{'records.scheduleInspection' | translate}}</button>
  </div>
  </div>
  <div *ngIf="form.value.inspections">
    <div class="flex item-center w-full h-12 mt-0.5">
      <p
          class="w-full text-center h-full py-3 font-bold text-am-secondary-navy-80 border-b-4 border-am-primary-turquoise-100 cursor-pointer">
          {{'records.inspections' | translate}}</p>
      <p class="w-full text-center h-full py-3 font-bold text-am-secondary-navy-80 border-b-4 border-am-secondary-navy-30 cursor-pointer">
        {{'records.withdrawals' | translate}}</p>
  </div>
  <div class="flex flex-row justify-between pt-10">
  <div class="flex flex-col gap-4">
    <h3 class="font-medium text-am-lg leading-am-6 text-am-primary-navy-100">{{'records.inspections' | translate}}</h3>
    <div class="search__div flex items-center justify-center">
    <input type="text" class="search__reports" [placeholder]="'records.search' | translate" size="2">
    <span class="search__icon"></span>
    </div>
  </div>
  <button class="flex items-center bg-am-primary-turquoise-100 text-white font-bold text-sm leading-am-4 py-[10px] px-4 h-[40px] gap-2 rounded-am-md" (click)="openPop()"><img src="../../../assets/icons/whitecalendaricon.svg" alt="">{{'records.scheduleInspection' | translate}}</button>
  </div>
  <div class="mt-10">
    <h4 class="text-am-secondary-navy-80 font-bold text-sm leading-am-4">{{'records.next' | translate}}</h4>
    <p-table
    class="reports__list__table"
    [value]="INSPECTIONSPENDING"
    [tableStyle]="{ 'min-width': '50rem' }"
    [rows]="7"

    >
    <ng-template pTemplate="header">
        <tr>
            <th pSortableColumn="date" class="id__th">{{'records.date' | translate}}<p-sortIcon field="date"></p-sortIcon></th>
            <th pSortableColumn="inspector" class="table__th">{{'records.inspector' | translate}}<p-sortIcon field="inspector"></p-sortIcon></th>
            <th pSortableColumn="type" class="table__th">{{'records.type' | translate}}<p-sortIcon field="type"></p-sortIcon></th>
            <th pSortableColumn="status" class="table__th">{{'records.status' | translate}}<p-sortIcon field="status"></p-sortIcon></th>
            <th pSortableColumn="notes" class="table__th">{{'records.notes' | translate}}<p-sortIcon field="notes"></p-sortIcon></th>
            <th></th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-inspection>
        <tr>
            <td class="td__id" >{{ inspection.date }}</td>
            <td class="table__td td__align" >{{ inspection.inspector }}</td>
            <td class="table__td" >{{ inspection.type }}</td>
            <td class="table__td td__align" [innerText]="inspection.status ? 'Finalizada' : 'Pendiente'" ></td>
            <td class="table__td td__align" [innerText]="inspection.notes ? inspection.notes : '--'" ></td>
            <td class="table__td" >
              <button *ngIf="!inspection.inProccess" class="flex items-center justify-center text-white bg-am-primary-turquoise-100 font-bold text-sm leading-am-4 rounded-am-md w-[184px] h-[40px]">{{'records.startInspection' | translate}}</button>
              <button *ngIf="inspection.inProccess" class="flex items-center justify-center border border-am-primary-navy-100 text-am-primary-navy-100 font-bold text-sm leading-am-4 rounded-am-md w-[184px] h-[40px]">{{'records.continue' | translate}}</button>
            </td>
        </tr>
    </ng-template>
  </p-table>
  </div>
  <div class="mt-10">
    <h4 class="text-am-secondary-navy-80 font-bold text-sm leading-am-4">{{'records.finished2' | translate}}</h4>
    <p-table
    class="reports__list__table"
    [value]="INSPECTIONSFINISH"
    [tableStyle]="{ 'min-width': '50rem' }"
    [rows]="7"

    >
    <ng-template pTemplate="header">
        <tr>
            <th pSortableColumn="date" class="id__th">{{'records.date' | translate}}<p-sortIcon field="date"></p-sortIcon></th>
            <th pSortableColumn="inspector" class="table__th">{{'records.inspector' | translate}}<p-sortIcon field="inspector"></p-sortIcon></th>
            <th pSortableColumn="type" class="table__th">{{'records.type' | translate}}<p-sortIcon field="type"></p-sortIcon></th>
            <th pSortableColumn="status" class="table__th">{{'records.status' | translate}}<p-sortIcon field="status"></p-sortIcon></th>
            <th pSortableColumn="notes" class="table__th">{{'records.notes' | translate}}<p-sortIcon field="notes"></p-sortIcon></th>
            <th></th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-inspection>
        <tr>
            <td class="td__id bg-am-secondary-navy-5" >{{ inspection.date }}</td>
            <td class="table__td td__align  bg-am-secondary-navy-5" >{{ inspection.inspector }}</td>
            <td class="table__td  bg-am-secondary-navy-5" >{{ inspection.type }}</td>
            <td class="table__td td__align  bg-am-secondary-navy-5" [innerText]="inspection.status ? 'Finalizada' : 'Pendiente'" ></td>
            <td class="table__td td__align  bg-am-secondary-navy-5" [innerText]="inspection.notes ? inspection.notes : '--'" ></td>
            <td class="table__td  bg-am-secondary-navy-5" >
              <button class="flex items-center justify-center border border-am-primary-navy-100 text-am-primary-navy-100 font-bold text-sm leading-am-4 rounded-am-md w-[184px] h-[40px]"><img src="../../../assets/icons/downloadrecord.svg" alt="">{{'records.downloadReport' | translate}}</button>
            </td>
        </tr>
    </ng-template>
  </p-table>
  </div>
  <div class="flex justify-end mt-10">
    <button class="flex items-center gap-2 justify-center border border-am-utility-error text-am-utility-error font-bold text-sm leading-am-4 rounded-am-md h-[40px] py-[10px] px-4"><img src="../../../assets/icons/deletered.svg" alt="">{{'records.deleteLocation' | translate}}</button>
  </div>
  </div>
</div>
  </form>
</div>
<app-schedule-pop *ngIf="schedulePopVisible" (emitClose)="closePop()" (emitSchedule)="showInspections()"></app-schedule-pop>
