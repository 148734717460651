import { HttpClient } from '@angular/common/http';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { FilterMetadata } from 'primeng/api';
import { Table } from 'primeng/table';
import { lastValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-estates-list',
  templateUrl: './estates-list.component.html',
  styleUrls: ['./estates-list.component.scss'],
})
export class EstatesListComponent implements OnInit, OnChanges {
  @ViewChild('dt') table?: Table;

  listTypeSelectButtonValues = [
    { label: 'Mapa', value: 1, icon: 'pi pi-map' },
    { label: 'Listado', value: 0, icon: 'pi pi-list' },
  ];

  listType = 1;

  data: any;
  _ine?: string;

  @Input() ine: string = '';
  @Input() selectable: boolean = false;

  @Input() loadData?: boolean = true;

  _control?: FormControl;

  @Input() set control(control: FormControl) {
    this._control = control;
    control.valueChanges.subscribe((value: any) => {
      this.selected = value;
    });
  }

  @Output() onSelect = new EventEmitter<any>();
  @Output() count = new EventEmitter<number>();

  totalRecords?: number;
  loading?: boolean;

  loadingMap?: boolean = true;

  selected: any[] = [];

  constructor(private http: HttpClient) {}
  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    console.log(changes);

    if (changes['ine']) {
      this.ine = changes['ine'].currentValue;
      this.loadingMap = true;
      await this.load();
    }
  }

  async ngOnInit(): Promise<void> {
    await this.load();
  }

  onViewChange(event: any) {
    if (event.value == 0) {
      this.loading = false;
    }
  }

  finishLoading() {
    console.log('finishLoading');
    this.loadingMap = false;
  }

  beginLoading() {
    console.log('beginLoading');
    this.loadingMap = true;
  }
  async load(event?: any) {
    if (!this.loadData) return;

    console.log('ine : ', this.ine);

    this.loading = true;

    const page = event ? event.first / event.rows : 0;
    const limit = event ? event.rows : 10;
    const searchTerm =
      event?.globalFilter ||
      (this.table?.filters['global'] as FilterMetadata)?.value ||
      '';

    if (!this.ine) {
      this.loading = false;
      return;
    }

    const requestUrl = `${environment.api}estate/${this.ine}?page=${page}&limit=${limit}&searchTerm=${searchTerm}`;

    const data: any = await lastValueFrom(this.http.get(requestUrl));

    this.data = data.data;
    this.totalRecords = data.total;
    this.count.emit(data.total);

    this.loading = false;
  }

  /*
  loadMap() {
    this.loadingMap = true;
    this.http
      .get(`${environment.api}notification/map/${this.ine}`, {})
      .subscribe(async (r: any) => {
        var map: any = document.getElementById('map');
        map?.setAttribute('data-geojsons', JSON.stringify(r));
        map?.connectedCallback();
        this.loadingMap = false;
      });
  }
  */

  selectionChange(event: any) {
    this.onSelect.emit(event);
    this._control?.setValue(event);
  }
}
