<div class="m-8" style="text-align: left;">
  <div>
    <div style="text-align: left;">
      <div>
        <div>
          <h1><strong>Privacy and Cookie Policy</strong></h1>
        </div>
      </div>
    </div>
    <div style="text-align: justify;">
      <div>
        <div>
          <div><br></div>
        </div>
        <div>
          <div>
            <div>
              <p
                style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Aptos",sans-serif;margin-top:0cm;margin-bottom:8.0pt;line-height:115%;text-align:justify;'>
                In relation to the personal data provided by the interested parties in the forms available on the
                website&nbsp;<a href="https://censo.agforest.ai" target="_new">https://censo.agforest.ai</a>, it is
                informed that strict
                compliance with the current regulations on personal data protection is maintained and that the provided
                data, if
                any, will be included in a file managed by AGFOREST, S.L. (hereinafter, Agforest) for processing in
                accordance
                with the purposes described in this Privacy Policy and in the forms available in each case, giving the
                interested
                parties consent for such processing by accepting this privacy policy or by sending information through
                any of the
                means provided on the website.</p>
              <p
                style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Aptos",sans-serif;margin-top:0cm;margin-bottom:8.0pt;line-height:115%;text-align:justify;'>
                <strong>Purpose of Data</strong>
              </p>
              <p
                style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Aptos",sans-serif;margin-top:0cm;margin-bottom:8.0pt;line-height:115%;text-align:justify;'>
                Agforest collects certain personal data voluntarily entered by users in the various forms available on
                the
                website&nbsp;<a href="https://censo.agforest.ai/" target="_new">https://censo.agforest.ai/</a>.</p>
              <p
                style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Aptos",sans-serif;margin-top:0cm;margin-bottom:8.0pt;line-height:115%;text-align:justify;'>
                <strong>The data is used for the following purposes:</strong>
              </p>
              <p
                style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Aptos",sans-serif;margin-top:0cm;margin-bottom:8.0pt;line-height:115%;text-align:justify;'>
                To manage information requests made through the contact form provided on the website (&ldquo;Contact
                Us&rdquo;).
                To manage service reservation requests made through the website (&ldquo;Book Your Service&rdquo;). To
                send the
                newsletter electronically to users who have indicated that they wish to receive this type of information
                through
                the Newsletter forms available on the website. To manage applications submitted through the &ldquo;Join
                Our
                Team&rdquo; form on the website. To manage comments posted and/or shared by website users.</p>
              <p
                style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Aptos",sans-serif;margin-top:0cm;margin-bottom:8.0pt;line-height:115%;text-align:justify;'>
                <strong>Mandatory Data</strong>
              </p>
              <p
                style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Aptos",sans-serif;margin-top:0cm;margin-bottom:8.0pt;line-height:115%;text-align:justify;'>
                All fields require a mandatory response unless indicated as optional in the form itself. If the
                mandatory fields
                are not filled in, it will not be possible to process the reservation request, publish the comment,
                respond to the
                contact request, send the newsletter, or consider the submitted application, depending on the form used.
              </p>
              <p
                style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Aptos",sans-serif;margin-top:0cm;margin-bottom:8.0pt;line-height:115%;text-align:justify;'>
                <strong>Rights of Access, Rectification, Cancellation, and Opposition</strong>
              </p>
              <p
                style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Aptos",sans-serif;margin-top:0cm;margin-bottom:8.0pt;line-height:115%;text-align:justify;'>
                The interested parties can exercise their rights of access, rectification, cancellation, and opposition,
                and
                revoke the consent given in each case for the processing and/or transfer of their data, at any time by
                requesting
                it via postal mail to AGFOREST S.L.; or by sending an email to the following email address
                <a href="mailto:agforest&#64;agforest.ai">agforest&#64;agforest.ai</a>.
                In any case, the request must be accompanied by a copy of the official document that proves the identity
                of the
                data owner.</p>
              <p
                style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Aptos",sans-serif;margin-top:0cm;margin-bottom:8.0pt;line-height:115%;text-align:justify;'>
                <strong>Data Controller</strong>
              </p>
              <p
                style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Aptos",sans-serif;margin-top:0cm;margin-bottom:8.0pt;line-height:115%;text-align:justify;'>
                The Data Controller is AGFOREST S.L., located at Calle Conde de Vilches, 25, Madrid, 28028, Madrid.</p>
              <p
                style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Aptos",sans-serif;margin-top:0cm;margin-bottom:8.0pt;line-height:115%;text-align:justify;'>
                Agforest complies with the regulations of the Spanish Data Protection Agency, which can be consulted
                at&nbsp;<a href="http://www.agpd.es" target="_new">www.agpd.es</a></p>
              <p
                style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Aptos",sans-serif;margin-top:0cm;margin-bottom:8.0pt;line-height:115%;text-align:justify;'>
                <strong>Data Transfer</strong>
              </p>
              <p
                style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Aptos",sans-serif;margin-top:0cm;margin-bottom:8.0pt;line-height:115%;text-align:justify;'>
                The data provided by the interested parties are not transferred to third parties unless expressly
                authorized by
                Agforest and are only communicated, if applicable, to manage the services requested by users. The
                communication of
                the data is linked to the very purpose for which the data has been entered and is therefore related to
                the free
                and legitimate acceptance of the legal relationship between the interested parties and Agforest, whose
                development, compliance, and control necessarily involve the connection of the data and includes the
                necessary
                transmission thereof. By accepting this privacy policy, the interested parties declare to have been
                informed and
                consent, without reservations, to the communication of their data under the terms indicated in this
                clause. The
                interested parties can revoke the consent given as indicated in this policy.</p>
              <p
                style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Aptos",sans-serif;margin-top:0cm;margin-bottom:8.0pt;line-height:115%;text-align:justify;'>
                When a user posts or shares a comment, they expressly authorize the publication of their identification
                on the
                website and, therefore, the communication to the rest of the users accessing agforest.es.</p>
              <p
                style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Aptos",sans-serif;margin-top:0cm;margin-bottom:8.0pt;line-height:115%;text-align:justify;'>
                Users should refrain from providing personal data of other people unless expressly authorized by them to
                provide
                it. Agforest may contact users at any time to verify the existence and content of the mentioned
                authorizations.
              </p>
              <p
                style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Aptos",sans-serif;margin-top:0cm;margin-bottom:8.0pt;line-height:115%;text-align:justify;'>
                <strong>Security</strong>
              </p>
              <p
                style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Aptos",sans-serif;margin-top:0cm;margin-bottom:8.0pt;line-height:115%;text-align:justify;'>
                Agforest ensures the absolute confidentiality and privacy of the personal data processed, adopting the
                necessary
                technical and/or organizational security measures to prevent alteration, loss, unauthorized processing,
                or access
                to the data, thus guaranteeing the security of the data. Agforest will not be held responsible in any
                case for any
                incidents that may arise around personal data when they result from an attack or unauthorized access to
                the
                systems in such a way that it is impossible to detect by the security measures implemented.</p>
              <p
                style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Aptos",sans-serif;margin-top:0cm;margin-bottom:8.0pt;line-height:115%;text-align:justify;'>
                <strong>Cookies</strong>
              </p>
              <p
                style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Aptos",sans-serif;margin-top:0cm;margin-bottom:8.0pt;line-height:115%;text-align:justify;'>
                Agforest only uses cookies, if any, to exchange data through electronic communications networks, or when
                they are
                strictly necessary to provide a functionality, which is part of an information society service,
                expressly
                requested by the user. If the use of cookies does not meet any of the indicated purposes, Agforest will
                request
                the user&apos;s informed consent to use such storage and data retrieval devices on their equipment
                (e.g., through
                a pop-up window or user configuration of the browser or other applications during installation or
                update). The
                cookies used do not contain personal information that identifies the user. In any case, the user can
                configure
                their browser not to install cookies or to receive an on-screen warning each time a cookie attempts to
                be
                installed, being able to decide at any time whether to accept it or not.</p>
              <p
                style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Aptos",sans-serif;margin-top:0cm;margin-bottom:8.0pt;line-height:115%;text-align:justify;'>
                <strong>Accuracy of Data</strong>
              </p>
              <p
                style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Aptos",sans-serif;margin-top:0cm;margin-bottom:8.0pt;line-height:115%;text-align:justify;'>
                Users are responsible for the accuracy of the data provided, committing not to enter false data and to
                proceed
                with its modification if necessary.</p>
              <p
                style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Aptos",sans-serif;margin-top:0cm;margin-bottom:8.0pt;line-height:115%;text-align:justify;'>
                Request for further information.</p>
              <p
                style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Aptos",sans-serif;margin-top:0cm;margin-bottom:8.0pt;line-height:115%;'>
                &nbsp;</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>