<div>
  <div class="relative">
    <div class="absolute z-10 w-full h-full flex items-center justify-center bg-opacity-50 bg-gray-700" *ngIf="loading">
      <p-progressSpinner styleClass="custom-spinner"></p-progressSpinner>
    </div>
    <div class="flex flex-col gap-8 m-10">
      <a class="flex items-center text-am-primary-turquoise-100 font-bold text-sm cursor-pointer" routerLink="/records">
        <i class="pi pi-angle-left mr-2"></i>
        {{'admin.back' | translate}}
      </a>

      <div class="flex item-center w-full">
        <h2 class="flex-grow text-xl font-bold text-am-primary-navy-100">{{form.get('reference')?.value ?
          form.get('reference')?.value : 'records.newRecord' | translate}}</h2>
      </div>

      <h3 class="text-am-secondary-navy-80 font-medium text-am-lg leading-am-6">{{'records.recordType' | translate}}
      </h3>

      <div class=" flex flex-wrap items-start gap-10">
        <!-- Tipo Expediente -->
        <div class=" flex flex-col lg:w-1/4 p-input-underline">
          <div class="flex">
            <label for="tipoexp" class="font-medium text-sm text-am-secondary-navy-60 ">{{'records.recordType' | translate}}</label>
            <i class="pi pi-info font-medium text-sm text-am-secondary-navy-60 rounded-full w-5 ring-1 ring-blue-500 items-center ml-6 flex justify-center"
              [pTooltip]="'records.recordTypeInfo' | translate"></i>
          </div>
          <p-dropdown [options]="tipoexpOptions" [formControl]="form.get('tipoexp')" [style]="{'minWidth':'100%'}"
            (onChange)="changeRecordType($event)"></p-dropdown>
        </div>

        <!-- Ayuntamiento -->
        <div *ngIf="recordType == 'LINKED'" class=" flex flex-col w-1/4 p-input-underline">
          <label for="ayuntamiento" class="font-medium text-sm text-am-secondary-navy-60">{{'records.aytolink' | translate}}</label>
          <p-dropdown [options]="recordsINE" optionLabel="name" [formControl]="form.get('recordINEId')" [filter]="true"
            filterBy="name" [showClear]="true" [virtualScroll]="true" [virtualScrollItemSize]="38"
            (onChange)="changeRecordINE($event)" [style]="{'minWidth':'100%'}">
          </p-dropdown>
        </div>
        <!-- Cliente -->
        <div *ngIf="recordType == 'INDEPENDENT'" class=" flex flex-col w-1/4 p-input-underline">
          <label for="cliente" class="font-medium text-sm text-am-secondary-navy-60">{{'records.client' | translate}}</label>
          <p-dropdown [options]="clients" optionLabel="businessName" [formControl]="form.get('client')" [filter]="true"
            filterBy="businessName" [showClear]="true" [style]="{'minWidth':'100%'}">
          </p-dropdown>
        </div>
      </div>

      <h3 class="text-am-secondary-navy-80 font-medium text-am-lg leading-am-6">{{'records.generalInfo' | translate}}
      </h3>

      <div class=" flex flex-wrap items-start gap-10 columns-3">
        <!-- Numero expediente -->
        <div class=" flex flex-col w-1/4 p-input-underline">
          <label for="reference" class="font-medium text-sm text-am-secondary-navy-60 ">{{'records.recordNum' | translate}}</label>
          <input pInputText id="reference" type="text" [placeholder]="'Ej 39482923'"
            [formControl]="form.get('reference')" maxlength="16">
        </div>
        <!-- Gestor -->
        <div class=" flex flex-col w-1/4 p-input-underline">
          <label for="manager" class="w-auto text-am-secondary-navy-60 ">{{'records.manager' | translate}}</label>
          <app-user-input class="w-[90%]" [control]="form.get('manager_id')"></app-user-input>
        </div>

        <!-- Estado -->
        <!--
      <div class=" flex flex-col w-1/4 p-input-underline">
          <label for="status" class="font-medium text-sm text-am-secondary-navy-60 ">Estado</label>
          <p-dropdown [options]="statusOptions" optionLabel="label" optionValue="key" [formControl]="form.get('status')" [style]="{'minWidth':'100%'}"></p-dropdown>
      </div>
      -->

        <!-- Localidad -->
        <div class=" flex flex-col w-1/4 p-input-underline">
          <label for="locality" class="font-medium text-sm text-am-secondary-navy-60 ">{{'records.locality' | translate}}</label>
          <app-locality-input [control]="form.get('locality')" [disabled]="!isEmpresa"
            (onSelect)="changeLocal($event)"></app-locality-input>

        </div>
        <!-- Provincia -->
        <div class=" flex flex-col w-1/4 p-input-underline">
          <label for="province" class="font-medium text-sm text-am-secondary-navy-60 ">{{'records.province' | translate}}</label>
          <input pInputText id="province" type="text" [placeholder]="'Ej. Madrid'" [formControl]="form.get('province')"
            maxlength="25">
        </div>
        <!-- Codigo postal -->
        <div class=" flex flex-col w-1/4 p-input-underline sm:break-before-column">
          <label for="postalCode" class="font-medium text-sm text-am-secondary-navy-60 ">{{'records.postalCode' | translate}}</label>
          <!-- <input pInputText id="postalCode" type="text" [placeholder]="'Ej. 28001'" [formControl]="form.get('postalCode')" maxlength="5"> -->
          <p-inputNumber [formControl]="form.get('postalCode')" inputId="withoutgrouping" [useGrouping]="false"
            maxlength="5"></p-inputNumber>
        </div>

        <div class="flex">
          <div class="flex flex-col p-input-underline">
            <p-checkbox binary="true" label="{{'records.showDetailedForm' | translate}}"
              [formControl]="form.get('detailed')"></p-checkbox>
          </div>
          <i class="pi pi-info font-medium text-sm text-am-secondary-navy-60 rounded-full w-5 ring-1 ring-blue-500 items-center ml-6 flex justify-center"
            [pTooltip]="'records.detailedFormInfo' | translate"></i>
        </div>

      </div>

      <h3 class="text-am-secondary-navy-80 font-medium text-am-lg leading-am-6 mt-10">{{'records.clientInfo' | translate}}</h3>

      <h4 class="text-am-primary-navy-80 font-bold text-sm leading-am-4 mb-5">{{'records.mainContact' | translate}}</h4>
      <h5 class="p-error-text" *ngIf="form.get('contacts')?.errors?.['required']">{{'records.requiredContact' | translate}}</h5>

      <div>

        <p-table #contactos [value]="contacts" [(selection)]="defaultContact" dataKey="id"
          [tableStyle]="{ 'min-width': '50rem' }">
          <ng-template pTemplate="header">
            <tr>
              <th>{{'records.contactTable.principal' | translate}}</th>
              <th>{{'records.contactTable.name' | translate}}</th>
              <th>{{'records.contactTable.phone' | translate}}</th>
              <th>{{'records.contactTable.email' | translate}}</th>
              <th></th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-contact>
            <tr id="{{contact.id}}">
              <td><p-checkbox [binary]="true" [(ngModel)]="contact.default"
                  [disabled]="contactDirty || contacts.length < 2"
                  (onChange)="changeDefaultContact(contact)"></p-checkbox></td>
              <td class="p-input-underline"><input class="w-full" pInputText id="name" type="text"
                  [placeholder]="'Ej. Juan'" [(ngModel)]="contact.name" (change)="markAsDirty(contact)" maxlength="30">
              </td>
              <td class="p-input-underline"><p-inputNumber class="w-full" inputId="withoutgrouping"
                  [useGrouping]="false" [placeholder]="'900000000'" [min]="0" [(ngModel)]="contact.phone"
                  (change)="markAsDirty(contact)" maxlength="12"></p-inputNumber></td>
              <td class="p-input-underline"><input pInputText class="w-full" id="email" type="text"
                  pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}" [placeholder]="'test@email.com'"
                  [(ngModel)]="contact.email" (change)="markAsDirty(contact)" maxlength="50"></td>
              <!--
                <td class="p-input-underline">
                  <p-dropdown appendTo="body" id="client"  placeholder="Ej.Ayuntamiento" (onChange)="selectClient(contact)"
                  [options]="clientsDrop"
                  optionLabel="name"
                  [style]="{'minWidth':'100%'}"
                  [filter]="true" filterBy="businessName"
                  [(ngModel)]="contact.client"
                  optionLabel="businessName">
                  <ng-template let-item pTemplate="selectedItem">
                      {{item?.cif}} - {{item?.businessName}}
                  </ng-template>
                  <ng-template let-item pTemplate="item">
                      {{item?.cif}} - {{item?.businessName}}
                  </ng-template>
                  <ng-template pTemplate="loader" let-options="options">
                      <div class="flex align-items-center py-2 px-3" style="height: 38px">
                          <p-skeleton [width]="options.even ? '60%' : '50%'" height="1rem"></p-skeleton>
                      </div>
                  </ng-template>

                </p-dropdown>
              </td>
              -->
              <td><button *ngIf="!loading" [hidden]="!contact.isDirty" (click)="saveContact(contact)"><i
                    class="pi pi-check"></i></button></td>
              <td><button (click)="cancelContact(contact)" [hidden]="contact.default"><i
                    class="pi pi-times"></i></button></td>
            </tr>
          </ng-template>
        </p-table>


        <div class="flex mt-4">
          <p-button pRipple #addContact [disabled]="contactDirty" (click)="addNewContact()" label="{{'records.addOther' | translate}}"
            [style]="{'minWidth':'160px'}" iconPos="left" size="small" [loading]="loading"></p-button>
        </div>


      </div>


      <div class="mt-8">
        <h3 class="text-am-secondary-navy-80 font-medium text-am-lg leading-am-6 mb-8">{{'records.docsAndNotes' | translate}}</h3>
        <button #addDoc class="flex items-center mt-4" (click)="documentInput.click()">
          <img src="/assets/icons/addUser_Blue.svg">
          <p class="text-sm font-bold ml-2 text-am-primary-turquoise-100">{{'records.addDoc' | translate}}</p>
          <input #documentInput type="file" hidden (change)="addDocument($event)">
        </button>
        <p-chips [formControl]="form.get('documents')" class="stacked-readonly"
          (onChipClick)="downloadDocument($event)">
          <ng-template let-item pTemplate="item"> {{ item?.name }}</ng-template>
        </p-chips>
      </div>

      <div class=" flex flex-col w-full ">
        <label for="notes" class="font-medium text-sm text-am-secondary-navy-60 ">{{'records.notes' | translate}}</label>
        <textarea rows="5" cols="30" pInputTextarea [formControl]="form.get('notes')" maxlength="255"></textarea>
      </div>

      <div *ngIf="form.get('estates')?.value?.length > 0">
        <h3 class="text-am-secondary-navy-80 font-medium text-am-lg leading-am-6">{{'records.locationWithAsbestos' | translate}}
          ({{form.get('estates')?.value?.length || '0'}}) </h3>
        <p-chips [formControl]="form.get('estates')" class="stacked-readonly">
          <ng-template let-item pTemplate="item"> {{ item?.catastralReference }} - {{item?.date | date}}</ng-template>
        </p-chips>
        <app-estates-list [selectable]="true" [control]="form.get('estates')"
          [ine]="form.get('locality')?.value?.ine"></app-estates-list>
      </div>

    </div>

    <div class="flex">
      <div class="flex pl-10">

        <p-button pRipple [hidden]="!canCreate || !canEdit" label="{{'records.save' | translate}}" [style]="{'minWidth':'160px'}"
          iconPos="left" size="small" (click)="save()" [loading]="loading" [disabled]="deleteLoading"></p-button>


      </div>
      @if (canEdit && !bInserting) {
        <div class="flex pl-10 mr-4">
          <p-button pRipple label="{{'records.exportRecordData' | translate}}" [style]="{'minWidth':'160px'}"
          iconPos="left" size="small" (click)="export()" [loading]="loading" [disabled]="deleteLoading"></p-button>
        </div>
      }
    </div>

    <!--
  <p-confirmDialog #cd [style]="{ width: '50vw' }">
    <ng-template pTemplate="header">
        <h3 class="font-bold">Eliminar expediente</h3>
    </ng-template>
    <ng-template pTemplate="message">
        <div style="width:100%" class="text-sm text-center font-semibold w-full">
            <div>
              ¿Seguro que deseas eliminar el expediente ID <strong>{{record?.id}} ({{record?.reference}})</strong>?
            </div>

        </div>
    </ng-template>
    <ng-template pTemplate="footer">
        <p-button type="button" [label]="'admin.cancel' | translate" (click)="cd.reject()" size="small" [outlined]="true"></p-button>
        <p-button type="button" [label]="'Eliminar' | translate" (click)="cd.accept()" size="small" severity="danger"></p-button>
    </ng-template>
</p-confirmDialog>
-->

    <p-confirmDialog #inactive_dialog [style]="{ width: '50vw' }">
      <ng-template pTemplate="header">
        <h3 class="font-bold">Eliminar expediente</h3>
      </ng-template>
      <ng-template pTemplate="message" let-message>
        <div style="width:100%" class="text-sm text-center font-semibold w-full">
          <div>
            {{ message.message }}
          </div>

        </div>
      </ng-template>
      <ng-template pTemplate="footer">
        <p-button type="button" [label]="'admin.cancel' | translate" size="small" [outlined]="true"></p-button>
        <p-button type="button" [label]="'Ejecutar' | translate" size="small" severity="danger"></p-button>
      </ng-template>
    </p-confirmDialog>

    <p-confirmDialog key="confirm" #cd [style]="{ width: '50vw' }">
      <ng-template pTemplate="header">
        <h3 class="font-bold">Contactos</h3>
      </ng-template>
      <ng-template pTemplate="message">
        <div style="width:100%" class="text-sm text-center font-semibold w-full">
          <div>
            Debe añadir al menos un contacto
          </div>
        </div>
      </ng-template>
      <ng-template pTemplate="footer">
        <p-button type="button" [label]="'Aceptar'" (click)="cd.accept()" size="small" severity="danger"></p-button>
      </ng-template>
    </p-confirmDialog>


  </div>
</div>
