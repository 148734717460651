import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-inspections-explore',
  templateUrl: './inspections-explore.component.html',
  styleUrls: ['./inspections-explore.component.scss'],
})
export class InspectionsExploreComponent implements OnInit {
  items: any[] = [];
  activeItem? = this.items[0];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private translate: TranslateService,
  ) {
    //select tab based on url
    const url = this.router.url;
    const urlParts = url.split('?')[0].split('/');
    const lastPart = urlParts[urlParts.length - 1];
    this.activeItem = this.items.find((item) => item.id === lastPart);
  }

  ngOnInit(): void {
    this.items = [
      {
        id: 'map',
        label: this.translate.instant('inspections.tabs.map'),
        command: async () => {
          await this.router.navigate(['map'], {
            relativeTo: this.route,
            queryParamsHandling: 'preserve',
          });
        },
      },
      {
        id: 'list',
        label: this.translate.instant('inspections.tabs.list'),
        command: async () => {
          await this.router.navigate(['list'], {
            relativeTo: this.route,
            queryParamsHandling: 'preserve',
          });
        },
      },
      {
        id: 'calendar',
        label: this.translate.instant('inspections.tabs.agenda'),
        command: async () => {
          await this.router.navigate(['calendar'], {
            relativeTo: this.route,
            queryParamsHandling: 'preserve',
          });
        },
      },
    ];
  }
}
