import { HttpClient } from '@angular/common/http';
import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  ConfirmEventType,
  ConfirmationService,
  MessageService,
} from 'primeng/api';
import { Table } from 'primeng/table';
import { environment } from 'src/environments/environment';
import { lastValueFrom } from 'rxjs';
import { FormControl, FormGroup } from '@angular/forms';
import { UserService } from '../../../shared/services/user.service';

@Component({
  selector: 'app-admin-list-users',
  templateUrl: './admin-list-users.component.html',
  styleUrls: ['./admin-list-users.component.scss'],
})
export class AdminListUsersComponent implements OnInit, AfterViewInit {
  @ViewChild('dt') table?: Table;

  @Input() users?: any[];
  @Input() autoload: boolean = false;
  @Input() owner_id?: string;
  @Input() inserting: boolean = false;

  @Input() embed?: boolean;

  user?: any;

  @Output() userDeleted: EventEmitter<any> = new EventEmitter();

  totalRecords?: number;
  loading?: boolean;

  f = new FormGroup({
    user: new FormControl(),
  });

  constructor(
    private http: HttpClient,
    private router: Router,
    private route: ActivatedRoute,
    private userSvc: UserService,
    private confirmationService: ConfirmationService,
    private messageSvc: MessageService,
  ) {}
  ngAfterViewInit(): void {
    console.log('Embedded', this.embed);
  }

  async ngOnInit() {
    console.log(this.inserting);

    if (this.autoload) {
      await this.load();
    }
  }

  async load(_event?: any) {
    /*
    this.loading = true;
    const page = event ? event.first / event.rows : 0;
    const limit = event ? event.rows : 10;
    const sortField = event ? event.sortField : (this.table?._sortField || '');
    const sortOrder = (event ? (event.sortOrder === 1 ? 'asc' : 'desc') :  (this.table?._sortOrder === 1 ? 'asc' : 'desc')) || 'asc';
    const searchTerm = event?.globalFilter || (this.table?.filters['global'] as FilterMetadata)?.value || '';

    const requestUrl = `${environment.api}user?page=${page}&limit=${limit}&sortField=${sortField}&sortOrder=${sortOrder}&searchTerm=${searchTerm}`;
    await this.http.get(requestUrl).toPromise()
    .then((data: any) => {
      this.users = data.data;
      this.totalRecords = data.total;
      this.loading = false;
    }).catch((e) => {
      console.log(e);
      this.users = [];
      this.totalRecords = 0;
    });
    this.loading = false;
    */
  }

  async deleteUser(data: any) {
    this.user = data;

    const loggedUser = this.userSvc.getUser();

    console.log(this.user, loggedUser);
    console.log(this.user.id, loggedUser?.id);

    if (this.user.id == loggedUser?.id) {
      this.messageSvc.add({
        severity: 'error',
        summary: 'Error',
        detail: 'No puedes borrar tu propio usuario',
      });
      return;
    }

    this.confirmationService.confirm({
      key: 'delete',
      accept: async () => {
        this.loading = true;

        try {
          const t = await lastValueFrom(
            this.http.get<any>(environment.api + 'owners/' + data.id),
          );

          console.log(t);

          if (t.type != 'none') {
            await lastValueFrom(
              this.http.post(
                environment.api + 'owners/' + t?.type + '/user/' + data.id,
                this.f.value?.user,
              ),
            );
            await lastValueFrom(
              this.http.delete(
                environment.api + 'owners/' + t?.type + '/user/' + data.id,
              ),
            );
            console.log('deleted');
          }

          //await lastValueFrom(this.http.delete(environment.api + 'user/' + data.id))

          this.userDeleted.emit();

          console.log('load finished');
          this.loading = false;
        } catch (e) {
          console.log(e);
          this.messageSvc.add({
            severity: 'error',
            summary: 'Error',
            detail:
              'No se puede borrar el usuario al estar referenciado en una Inspeccion',
          });
          this.loading = false;
        }
      },
      reject: (_type: ConfirmEventType) => {},
    });
  }
}
