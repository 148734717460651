

<div class="fixed inset-0 flex w-full h-full items-center justify-center z-50 bg-[#000000aa]">
  <div class=" w-124 h-85 mx-auto ">

      <div class="bg-am-secondary-turquoise-30 flex flex-col px-6 py-6">
        <div class="flex justify-between">
          <h2 class="font-bold text-base leading-[18.78px] text-am-primary-navy-100">{{'records.scheduleInspection' | translate}}</h2>
          <img src="../../../assets/icons/close_IconBlue.svg" (click)="close()">
        </div>
        <div class="flex flex-col gap-1 pt-4">
          <h3 class="font-bold text-sm leading-am-4 text-am-primary-navy-100">Vivienda en Calle Mayor, 9</h3>
          <span class="font-medium text-sm leading-am-4 text-am-primary-navy-100">Ayuntamiento de Medina del Campo</span>
          <span class="font-bold text-sm leading-am-4 text-am-secondary-navy-60">1234567 AB1234A 0000 AB</span>
        </div>
      </div>
      <div class="bg-white flex flex-col pb-6 pt-12 px-6 gap-12">
        <div class="flex flex-col self-center">
          <label for="date">{{'records.date' | translate}}</label>
          <p-calendar class="custom__datepicker" [showIcon]="true" dateFormat="dd - mm - yy" placeholder="dd - mm - aa"></p-calendar>
        </div>
        <div class="flex justify-end gap-6">
          <button class="border border-am-primary-turquoise-100 text-am-primary-turquoise-100 font-bold text-sm leading-am-4 rounded-am-md h-[40px] py-[10px] px-4" (click)="startNow()">{{'records.startNow' | translate}}</button>
          <button class="bg-am-primary-turquoise-100 text-white font-bold text-sm leading-am-4 rounded-am-md h-[40px] py-[10px] px-4" (click)="scheduleFn()">{{'records.schedule' | translate}}</button>
        </div>
      </div>
</div>
</div>

